import {React, useState, useEffect, useRef} from "react"; 
import './App.css';
import CustomNavbar from "./components/CustomNavbar";
import ProductsScreen from "./screens/ProductsScreen";
import { BrowserRouter, Routes, Route, Link, Outlet, useParams, router, useNavigate, } from "react-router-dom";
import ElectronicsScreen from './screens/ElectronicsScreen';
import FurnitureScreen from "./screens/FurnitureScreen";
import CarPartsScreen from "./screens/CarPartsScreen";
import PostDetailsScreen from "./screens/PostDetailsScreen";
import AddPostScreen from "./screens/AddPostScreen";
import SignUpScreen from "./screens/SignUpScreen";
import SignInScreen from "./screens/SignInScreen";
import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import MyAccountScreen from "./screens/MyAccountScreen";
import MyPostsScreen from "./screens/MyPostsScreen";
import MyPostDetailsScreen from "./screens/MyPostDetailsScreen";
import UserScreen from './screens/UserScreen'
import MyBidsScreen from "./screens/MyBidsScreen";
import LandingScreen from "./screens/LandingScreen";
import SignInOrSignUpScreen from "./screens/SignInOrSignUpScreen";
import logo from './assets/logo2.png'
import payment from './assets/payment_methods.png'
import { Divider } from "@mui/material";
import { IoLogoWhatsapp } from "react-icons/io5";
import Footer from "./components/Footer";
import UserAgreement from "./screens/UserAgreement";
import AboutUsScreen from "./screens/AboutUs";
import ContactUs from "./screens/ContactUs";
import MoreScreen from "./screens/MoreScreen";
import Checkout from "./screens/Checkout";
import MyPurchases from "./screens/MyPurchases";
import ComingSoon from "./screens/ComingSoonScreen";
import TestScreen from "./screens/TestScreen";
import PaymentStatus from "./screens/PaymentStatus";
import LoadingScreen from "./screens/LoadingScreen";
import MySoldProductsScreen from './screens/MySoldProductsScreen'
import MyPurchasedProductsScreen from "./screens/MyPurchasedProductScreen";
import useIsSafari from "./hooks/useIsSafari";
import { IoChatbubbleSharp } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io"; 
import Lottie from 'lottie-react';
import noChatAnim from './assets/animations/NoChatAnim.json'
import { db, auth, storage } from './config/firebase'
import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion, FieldValue, increment, addDoc, orderBy, Timestamp, startAfter, writeBatch } from 'firebase/firestore';
import Drawer from '@mui/material/Drawer';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { GoPaperclip } from "react-icons/go";
import { IoSend } from "react-icons/io5"; 
import { Oval } from 'react-loader-spinner'
import { GoChevronLeft } from "react-icons/go";
import ChatProvider, { useChat } from './hooks/ChatContext';
import MyExpiredBidsScreen from "./screens/MyExpiredBidsScreen";



const App = () => { 
  const isSafari = useIsSafari();
  useEffect(() => {
    if (isSafari) {
      document.body.classList.add('safari');
    } else {
      document.body.classList.remove('safari');
    }
  }, [isSafari]);
  
  // resize rerender
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    
    window.addEventListener('resize', handleResize);
    
    // Initial call to set the state with current window dimensions
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);




















  const [appJsMyId, setAppJsMyId] = useState(null)

  const {setChatIsOpen, setMyId, setChats, setMessages, setUsersInfo, activeChatId, setActiveChatId, drawerOpen, setDrawerOpen, handleListItemClick, setChatLoading, message } = useChat();


  const toggleChat = () => {
    setChatIsOpen(true)
  };







  useEffect(() => {
    if (!appJsMyId) return;
  
    setChatLoading(true);
  
    const messageUnsubscribes = [];
  
    const chatsQuery = query(
      collection(db, 'Chats'),
      where('group', 'array-contains', appJsMyId),
      where('isDeleted', '==', false)
    );
  
    const unsubscribeChats = onSnapshot(chatsQuery, async (chatsSnapshot) => {
      if (chatsSnapshot.empty) {
        console.log('No chats found');
        setChats([]); // Set empty chats
        setUsersInfo({}); // Clear users info
        setChatLoading(false); // Stop loading
        return;
      }
  
      const fetchedChats = chatsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
  
  
      const allUserIds = fetchedChats.flatMap(chat => chat.group.filter(userId => userId !== appJsMyId));
      const userQuery = query(
        collection(db, 'Users'),
        where('id', 'in', Array.from(new Set(allUserIds)))
      );
  
      try {
        const userSnapshot = await getDocs(userQuery);
        const users = {};
        userSnapshot.forEach(userDoc => {
          if (userDoc.exists()) {
            const userData = userDoc.data();
            users[userData.id] = {
              name: userData.fullName,
              image: userData.profileImgUrl,
              rating: userData.rating,
              numRaters: userData.numRaters,
              isVerified: userData.isVerified,
              id: userData.id
            };
          }
        });
  
        // Fetch the last message date for each chat
        const chatWithLastMessageDates = await Promise.all(fetchedChats.map(async chat => {
          const messagesQuery = query(
            collection(db, 'Chats', chat.id, 'Messages'),
            orderBy('date', 'desc'),
            limit(1) // Get the most recent message
          );
  
          const messagesSnapshot = await getDocs(messagesQuery);
          const lastMessageDate = messagesSnapshot.empty
            ? null
            : messagesSnapshot.docs[0].data().date
              ? messagesSnapshot.docs[0].data().date.toDate() // Convert Firestore timestamp to JS Date
              : null;
  
          return {
            ...chat,
            lastMessageDate,
          };
        }));
  
        // Sort chats by the last message date in descending order
        chatWithLastMessageDates.sort((a, b) => {
          if (!a.lastMessageDate && !b.lastMessageDate) return 0;
          if (!a.lastMessageDate) return 1; // Place chats with no lastMessageDate at the end
          if (!b.lastMessageDate) return -1; // Place chats with no lastMessageDate at the end
          return b.lastMessageDate - a.lastMessageDate;
        });
  
  
        setChats(chatWithLastMessageDates);
        setUsersInfo(users);
  
        // Clear previous message listeners
        messageUnsubscribes.forEach(unsub => unsub());
        messageUnsubscribes.length = 0;
  
        // Track number of message snapshots fetched
        let remainingSnapshots = chatWithLastMessageDates.length;
  
        // Set up message listeners for each chat
        chatWithLastMessageDates.forEach(chat => {
          const messagesQuery = query(
            collection(db, 'Chats', chat.id, 'Messages'),
            orderBy('date', 'asc')
          );
  
          const unsubscribeMessages = onSnapshot(messagesQuery, (messageSnapshot) => {
            const fetchedMessages = messageSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              date: doc.data().date ? doc.data().date.toDate() : new Date(), // Convert Firestore timestamp to JS Date
            }));
  
            setMessages(prevMessages => {
              const existingMessages = prevMessages[chat.id] || [];
              const updatedMessages = [...existingMessages];
  
              fetchedMessages.forEach(fetchedMessage => {
                const index = updatedMessages.findIndex(msg => msg.id === fetchedMessage.id);
                if (index >= 0) {
                  // Update existing message
                  updatedMessages[index] = fetchedMessage;
                } else {
                  // Add new message
                  updatedMessages.push(fetchedMessage);
                }
              });
  
              return {
                ...prevMessages,
                [chat.id]: updatedMessages,
              };
            });
  
            // Decrement remaining snapshots count
            remainingSnapshots -= 1;
            // Set loading to false only after all snapshots are fetched
            if (remainingSnapshots === 0) {
              setChatLoading(false);
            }
          }, (error) => {
            console.error('Error fetching messages:', error);
            remainingSnapshots -= 1;
            if (remainingSnapshots === 0) {
              setChatLoading(false);
            }
          });
  
          messageUnsubscribes.push(unsubscribeMessages);
        });
  
        // Ensure loading is set to false if there are no messages to fetch
        if (chatWithLastMessageDates.length === 0) {
          setChatLoading(false);
        }
      } catch (error) {
        console.error('Error fetching users or chats:', error);
        setChatLoading(false); // Ensure loading is false even if there's an error
      }
    }, (error) => {
      console.error('Error fetching chats:', error);
      setChatLoading(false); // Ensure loading is false even if there's an error
    });
  
    return () => {
      unsubscribeChats();
      messageUnsubscribes.forEach(unsub => unsub());
    };
  }, [appJsMyId]);
  









  useEffect(() => {
    const handleAuthStateChange = async (user) => {
      if (user != null) {
        try {
          const docRef = doc(db, 'Users', user.uid);
    
          // Try to read from cache first
          const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);
    
          if (cachedDoc && cachedDoc.exists()) {
            const userData = cachedDoc.data();
            setUserData(userData);
            setMyId(userData.id)
            setAppJsMyId(userData.id)
          }
    
          const unsubscribeUser = onSnapshot(docRef, (docu) => {
            if (docu.exists()) {
              const userData = docu.data();
              setUserData(userData);
            } else {
              console.error('User data not found');
            }
            // setUserInfoLoading(false);
          });
    
          // setLoading(false);
    
          return unsubscribeUser; // Correctly return the unsubscribe function
        } catch (error) {
          console.error('Error fetching user data:', error);
          // setUserInfoLoading(false);
        }
      } else {
        setAppJsMyId(null)
        setChatLoading(false); // Ensure loading is false even if there's an error
      }
    };
    
  
    const setUserData = (userData) => {
      setMyId(userData.id);
    };
  
    const unsubscribeAuth = auth.onAuthStateChanged(handleAuthStateChange);
  
    return () => {
      if (unsubscribeAuth) unsubscribeAuth();
    };
  }, []);

	return ( 
    <BrowserRouter>
    {/* <BasicExample/> */}
    
      <Routes>
        <Route path="/" element={
          <ComingSoon />
//           <>
//           <CustomNavbar/>
//           <div
//       onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgb(15, 219, 202)'}
//       onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#00C8B8'}
//       style={{
//         backgroundColor: "#00C8B8",
//         position: "fixed",
//         right: window.innerWidth > window.innerHeight ? "32px" : "16px",
//         bottom: window.innerWidth > window.innerHeight ? "32px" : "64px",
//         width: "60px",
//         height: "60px",
//         borderRadius: "1000px",
//         zIndex: 1000,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         cursor:"pointer"
//       }}
//       onClick={()=>{toggleChat()}}
//     >

// {/* // notification counter  */}
//       {/* <div style={{position:"absolute", backgroundColor:"#F00057", top:0, right:5, borderRadius:"1000px", width:"19px", height:"19px",
//     display:"flex", justifyContent:"center", alignItems:"center"

//       }}>
//         <p style={{fontSize:"10px", margin:"auto", color:"white"}}>42</p>
//       </div> */}



//       <IoChatbubbleSharp color="white" size={27} />




//     </div>
















//           <div style={{paddingTop:"65px"}}>
//           <Outlet/>

//           </div>
//           <Footer />
//           </>
        }>
          <Route index  element={<LandingScreen/>}/>
          <Route path="/?search" element={<ProductsScreen />} />
          {/* <Route path="electronics" element={<ElectronicsScreen />} /> */}
          {/* <Route path="furniture" element={<FurnitureScreen />} /> */}
          {/* <Route path="car-parts" element={<CarPartsScreen />} /> */}
          <Route path='product/:id' element={ <PostDetailsScreen />} />
          <Route path='products' element={ <ProductsScreen />} />
          <Route path='user/:id' element={ < UserScreen />} />
          <Route path="add_post" element={<AddPostScreen/>} />
          <Route path="my_bids" element={<MyBidsScreen />} />
          <Route path="my_account/*" element={<MyAccountScreen />} />
          {/* <Route path="sign_up" element={<SignUpScreen />} /> */}
          <Route path="sign_in" element={<SignInOrSignUpScreen />} />
          <Route path="my_posts" element={<MyPostsScreen />} />
          <Route  path='my_products/:id'   element={<MyPostDetailsScreen />} />
          <Route  path='my_purchases/:id'   element={<MyPurchasedProductsScreen />} />
          <Route  path='/my_expired_bids/:id'   element={<MyExpiredBidsScreen />} />
          <Route  path='my_sold_products/:id'   element={<MySoldProductsScreen />} />
          <Route  path='more' element={<MoreScreen />} />
          <Route  path='user_policy' element={<UserAgreement />} />
          <Route  path='about_us' element={<AboutUsScreen />} />
          <Route  path='contact_us' element={<ContactUs />} />
          <Route  path='more' element={<ContactUs />} />
          <Route  path='/checkout/:id' element={<Checkout />} />
          <Route  path='orders' element={<PaymentStatus />} />
          <Route  path='test-screen' element={<TestScreen />} />
          <Route path="*" element={<div style={{height: "100vh"}}><h1 style={{marginTop : "100px"}}>Page Not Found</h1></div>} />
        </Route>
      </Routes>

  </BrowserRouter>
	); 
}; 

export default App;
