import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs ,onSnapshot, serverTimestamp ,query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, FieldValue, getDocsFromCache, getDocFromCache, writeBatch, orderBy } from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { Divider } from '@mui/material';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { ref, uploadBytesResumable, getDownloadURL, listAll} from "firebase/storage";
import { MdVerifiedUser } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import Comment from '../components/Comment';
import { HiOutlinePencil } from "react-icons/hi";
import { Oval } from 'react-loader-spinner'
import { FaChevronDown } from "react-icons/fa";
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { db, auth, storage } from '../config/firebase'
import galleryPlaceholder from '../assets/galley-placeholder.jpg'
import { BrowserRouter, NavLink, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import { FaTrashCan } from "react-icons/fa6";
import { IoWalletOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import LoadingScreen from './LoadingScreen';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import SuccessAnim from '../components/SuccessAnim';
import { GoHomeFill, GoReport } from "react-icons/go";
import { BsPerson } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";
import { MdOutlineWatch } from "react-icons/md";
import { MdWatch } from "react-icons/md";
import { RiPriceTag3Line } from "react-icons/ri";
import { RiPriceTag3Fill } from "react-icons/ri";
import { IoBagCheckOutline } from "react-icons/io5";
import { IoBagCheck } from "react-icons/io5";
import { RiFileListFill } from "react-icons/ri";
import { RiFileListLine } from "react-icons/ri";
import { BiSolidComment } from "react-icons/bi";
import { BiComment } from "react-icons/bi";
import { RiStarSLine } from "react-icons/ri";
import { RiStarSFill } from "react-icons/ri";
import { PiGavel } from "react-icons/pi";
import { MdOutlinePlaylistAddCheckCircle } from "react-icons/md";
import { MdPlaylistAddCheckCircle } from "react-icons/md";
import { parseISO, isBefore } from 'date-fns'; // Import date-fns for date comparison
import Offer from '../components/Offer'

function MyAccountScreen() {
    const [myProducts, setMyProducts] = useState([]);
    const [mySoldProducts, setMySoldProducts] = useState([]);
    const [myBids, setMyBids] = useState([]);
    const [myPurchases, setMyPurchases] = useState([]);

    const [beneficiaryName, setBeneficiaryName] = useState("")
    const [newBeneficiaryName, setNewBeneficiaryName] = useState("")
    const beneficiaryRef = useRef();
    const [isLoading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [newFullName, setNewFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [originalImg, setOriginalImg] = useState("");
    const [balance, setBalance] = useState();
    const [feeBalance, setFeeBalance] = useState();
    const [blobImg , setBlobImg] = useState("");
    const [city, setCity] = useState("")
    const [newCity, setNewCity] = useState("")
    const [isVerified, setIsVerified] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [userRating, setUserRating] = useState(0);
    const [numRaters, setNumRaters] = useState(0);
    const [comment, setComment] = useState("");
    const [newEmail, setNewEmail] = useState("")
    const [iban, setIban] = useState("")
    const [NewIban, setNewIban] = useState("")
    const [userId, setUserId] = useState("")
    const [comments, setComments] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const [updatingDataLoading, setUpdatingDataLoading] = useState(false);
    const [bank, setBank] = useState("");
    const [newBank, setNewBank] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [myInfoIconColor, setMyInfoIconColor] = useState("#FFF")    
    const [myProductsIconColor, setMyProductsIconColor] = useState("#00C8B8")    

    const [userInfoLoading, setUserInfoLoading] = useState(true);
    const [myProductsIsLoading, setMyProductsIsLoading] = useState(true);
    const [mySoldProductsLoading, setMySoldProductsLoading] = useState(true);
    const [myBidsLoading, setMyBidsLoading] = useState(true);
    const [myPurchasesLoading, setMyPurchasesLoading] = useState(true);

    const [isCached, setIsCached] = useState(false)

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const fullNameRef = useRef();
    const cityRef = useRef();
    const commentRef = useRef();
    const emailRef =useRef();
    const ibanRef =useRef();
    const bankRef =useRef();
    
    const navigate = useNavigate();
    const [showSuccessAnim, setShowSuccessAnim] = useState(false);
    const handleAnimationComplete = () => {
      setShowSuccessAnim(false); // Reset button state after animation completes
    };


    const [offerResponseLoading, setOfferResponseLoading] = useState(false)
    
    const newProfilePictureRef = useRef();
    const [profilePictureUpdated, setProfilePictureUpdated] = useState(false);
    const [newUrl, setNewUrl] = useState("");
    const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);

    const [offersLoading, setOffersLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    
    // const fetchComments = async () => {
    //   try {
    //     setCommentsLoading(true);
    
    //     // Assuming 'commentsOnTheUser' is a subcollection within the current user's document
    //     const commentsRef = collection(db, 'Users', auth.currentUser.uid, 'commentsOnTheUser');
    //     const querySnapshot = await getDocs(commentsRef);
    
    //     if (!querySnapshot.empty) {
    //       const commentsData = querySnapshot.docs.map(doc => doc.data());
    
    //       // Fetch user data for each comment by querying based on the 'id' field
    //       const commentsWithUserData = await Promise.all(
    //         commentsData.map(async (comment) => {
    //           // Query for the user document based on the 'id' field matching 'userId'
    //           const usersQuery = query(collection(db, 'Users'), where('id', '==', comment.userId));
    //           const userQuerySnapshot = await getDocs(usersQuery);
    
    //           // Assuming each 'userId' corresponds to a unique user, so we take the first document
    //           const userDoc = userQuerySnapshot.docs[0];
    //           const userData = userDoc.exists() ? userDoc.data() : null;
    
    //           return {
    //             ...comment,
    //             user: userData,
    //           };
    //         })
    //       );
    
    //       setComments(commentsWithUserData);
    //     } else {
    //       setComments([]);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching comments:', error);
    //   } finally {
    //     setCommentsLoading(false);
    //   }
    // };


    const fetchOffers = async () => {
      try {
        setOffersLoading(true);
    
        // Define the query with the specified conditions
        const offersQuery = query(
          collection(db, 'Offers'),
          where('sellerId', '==', userId),
          where('isRejected', '==', false),
          where('isAccepted', '==', false)
        );
    
        // Get the offers matching the query
        const querySnapshot = await getDocs(offersQuery);
    
        if (!querySnapshot.empty) {
          const offersData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
    
          // Fetch user data and product data for each offer
          const offersWithUserDataAndProductData = await Promise.all(
            offersData.map(async (offer) => {
              // Fetch user data
              const usersQuery = query(collection(db, 'Users'), where('id', '==', offer.userId));
              const userQuerySnapshot = await getDocs(usersQuery);
              const userDoc = userQuerySnapshot.docs[0];
              const userData = userDoc ? userDoc.data() : null;
    
              // Fetch product data
              const productRef = doc(db, `Posts/post${offer.productId}`);
              const productSnapshot = await getDoc(productRef);
              const productData = productSnapshot.exists() ? productSnapshot.data() : null;
    
              return {
                ...offer,
                user: userData,
                product: productData,
              };
            })
          );
    
          setOffers(offersWithUserDataAndProductData);
        } else {
          setOffers([]);
        }
      } catch (error) {
        console.error('Error fetching offers:', error);
      } finally {
        setOffersLoading(false);
      }
    };
    
    
    
    
    

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    
    const location = useLocation();
    const [activeSubScreen, setActiveSubScreen] = useState("");
  
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    useEffect(() => {
      
      if(lastSegment === "my_info"){
        setActiveSubScreen("معلوماتي")
      } else if(lastSegment === "my_products"){
        setActiveSubScreen("منتجاتي")
      } else if(lastSegment === "my_bids"){
        setActiveSubScreen("مزايداتي")
      } else if(lastSegment === "my_purchases"){
        setActiveSubScreen("مشترياتي")
      } else if(lastSegment === "my_sold_products"){
        setActiveSubScreen("الطلبات")
      } else if(lastSegment === "my_products_offers"){
        setActiveSubScreen("عروض على منتجاتي")
      } else if(lastSegment === "my_offers"){
        setActiveSubScreen("عروض تم تقديمها")
      } else if(lastSegment === "my_comments"){
        setActiveSubScreen("التعليقات")
      } else if(lastSegment === "my_ratings"){
        setActiveSubScreen("التقييمات")
      } 
    }, [location.pathname]);


    useEffect(()=>{

    }, [blobImg])

    
    useEffect(() => {
      const handleAuthStateChange = async (user) => {
        if (user != null) {
          try {
            const docRef = doc(db, 'Users', user.uid);
      
            // Try to read from cache first
            const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);
      
            if (cachedDoc && cachedDoc.exists()) {
              const userData = cachedDoc.data();
              setUserData(userData);
            }
      
            const unsubscribeUser = onSnapshot(docRef, (docu) => {
              if (docu.exists()) {
                const userData = docu.data();
                setUserData(userData);
              } else {
                console.error('User data not found');
              }
              setUserInfoLoading(false);
            });
      
            // Ensure that setFetching(false) is called only after the user data is fetched
            setLoading(false);
      
            return unsubscribeUser; // Correctly return the unsubscribe function
          } catch (error) {
            console.error('Error fetching user data:', error);
            setUserInfoLoading(false);
          }
        } else {
          setUserInfoLoading(false);
          setLoading(false)
        }
      };
      
    
      const setUserData = (userData) => {
        setEmail(userData.email);
        setNewEmail(userData.email);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setFullName(userData.fullName);
        setNewFullName(userData.fullName);
        setPhoneNumber(userData.phoneNumber);
        setOriginalImg(userData.profileImgUrl);
        setBalance(userData.balance);
        setBeneficiaryName(userData.beneficiaryName);
        setNewBeneficiaryName(userData.beneficiaryName);
        setFeeBalance(userData.feeBalance);
        setCity(userData.city);
        setNewCity(userData.city);
        setUserId(userData.id);
        setIsVerified(userData.isVerified);
        setIban(userData.iban);
        setNewIban(userData.iban);
        setBank(userData.bank);
        setNewBank(userData.bank);
        setNumRaters(userData.numRaters);
        setUserRating(userData.rating);
        setLoading(false);
        setFetching(false)

      };
    
      const unsubscribeAuth = auth.onAuthStateChanged(handleAuthStateChange);
    
      const fetchPosts = async (queryRef, setState, setLoadingState) => {
        try {
          // Try to read from cache first
          const cachedSnapshot = await getDocs(queryRef, { source: 'cache' }).catch(() => null);
    
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const posts = cachedSnapshot.docs
              .filter(doc => !doc.data().isDeleted)
              .map(doc => doc.data())
              .sort((a, b) => b.createdAt - a.createdAt);
            setState(posts);
            setLoadingState(false);
        setFetching(false)

          }
    
          // Real-time updates
          const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
            const posts = querySnapshot.docs
              .filter(doc => !doc.data().isDeleted)
              .map(doc => doc.data())
              .sort((a, b) => b.createdAt - a.createdAt);
            setState(posts);
            setLoadingState(false);
        setFetching(false)

          }, (err) => {
            console.error('Error fetching posts:', err);
            setLoadingState(false);
        setFetching(false)

          });
    
          return unsubscribe;
        } catch (error) {
          console.error('Error fetching posts:', error);
          setLoadingState(false);
        setFetching(false)

        }
      };
    
      const fetchBids = async (queryRef, setState, setLoadingState) => {
        try {
          // Try to read from cache first
          const cachedSnapshot = await getDocs(queryRef, { source: 'cache' }).catch(() => null);
    
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const bids = cachedSnapshot.docs
              .filter(doc => {
                const bidders = doc.data().bidders || {};
                const numericUserId = parseFloat(userId);
                return Object.keys(bidders).map(Number).includes(numericUserId);
              })
              .map(doc => doc.data())
              .sort((a, b) => a.expiryDate - b.expiryDate);
            setState(bids);
            setLoadingState(false);
        setFetching(false)

          }
    
          // Real-time updates
          const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
            const bids = querySnapshot.docs
              .filter(doc => {
                const bidders = doc.data().bidders || {};
                const numericUserId = parseFloat(userId);
                return Object.keys(bidders).map(Number).includes(numericUserId);
              })
              .map(doc => doc.data())
              .sort((a, b) => a.expiryDate - b.expiryDate);
            setState(bids);
            setLoadingState(false);
        setFetching(false)

          }, (err) => {
            console.error('Error fetching bids:', err);
            setLoadingState(false);
        setFetching(false)

          });
    
          return unsubscribe;
        } catch (error) {
          console.error('Error fetching bids:', error);
          setLoadingState(false);
        setFetching(false)

        }
      };
    
      const fetchPurchases = async (queryRef, setState, setLoadingState) => {
        try {
          // Try to read from cache first
          const cachedSnapshot = await getDocs(queryRef, { source: 'cache' }).catch(() => null);
    
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const purchases = cachedSnapshot.docs
              .map(doc => doc.data())
              .sort((a, b) => a.soldDate - b.soldDate);
            setState(purchases);
            setLoadingState(false);
          }
    
          // Real-time updates
          const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
            const purchases = querySnapshot.docs
              .map(doc => doc.data())
              .sort((a, b) => a.soldDate - b.soldDate);
            setState(purchases);
            setLoadingState(false);
          }, (err) => {
            console.error('Error fetching purchases:', err);
            setLoadingState(false);
          });
    
          return unsubscribe;
        } catch (error) {
          console.error('Error fetching purchases:', error);
          setLoadingState(false);
        }
      };
    
      const queryRefMap = {
        'my_products': query(collection(db, 'Posts'), where('sellerId', '==', userId)),
        'my_sold_products': query(collection(db, 'Orders'), where("type", "==", "Purchase"),where('sellerId', '==', userId)),
        'my_bids': query(collection(db, 'Posts')),
        'my_purchases': query(collection(db, 'Posts'), where('buyerId', '==', userId)),
      };
    
      const fetchMap = {
        'my_products': [fetchPosts, setMyProducts, setMyProductsIsLoading],
        'my_sold_products': [fetchPosts, setMySoldProducts, setMySoldProductsLoading],
        'my_bids': [fetchBids, setMyBids, setMyBidsLoading],
        'my_purchases': [fetchPurchases, setMyPurchases, setMyPurchasesLoading],
      };
    
      const fetchData = async () => {
        if (fetchMap[lastSegment]) {
          const [fetchFunction, setState, setLoadingState] = fetchMap[lastSegment];
          const queryRef = queryRefMap[lastSegment];
          const unsubscribe = await fetchFunction(queryRef, setState, setLoadingState);
    
          return () => {
            setLoadingState(true);
            if (unsubscribe) unsubscribe();
          };
        }
      };

    
      fetchData();
      return () => {
        if (unsubscribeAuth) unsubscribeAuth();
      };
    }, [userId, lastSegment]);
    

    useEffect(() => {
      const fetchOffers = () => {
        setOffersLoading(true);
        const offersQuery = query(
          collection(db, 'Offers'),
          where('sellerId', '==', userId),
          where('isRejected', '==', false),
          where('isAccepted', '==', false),
          orderBy('productId') // Order by productId
        );
  
        const unsubscribe = onSnapshot(offersQuery, async (querySnapshot) => {
          if (!querySnapshot.empty) {
            const offersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
            const offersWithDetails = await Promise.all(
              offersData.map(async (offer) => {
                const userQuerySnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', offer.userId)));
                const userDoc = userQuerySnapshot.docs[0];
                const userData = userDoc ? userDoc.data() : null;
  
                const productQuerySnapshot = await getDocs(query(collection(db, 'Posts'), where('id', '==', offer.productId)));
                const productDoc = productQuerySnapshot.docs[0];
                const productData = productDoc ? productDoc.data() : null;
  
                return {
                  ...offer,
                  user: userData,
                  product: productData,
                };
              })
            );
  
            setOffers(offersWithDetails);
          } else {
            setOffers([]);
          }
          setOffersLoading(false);
        });
  
        return () => unsubscribe();
      };
  
      fetchOffers();
    }, [userId]);
  
      function formatAccountingArabic(number) {
        // Check if the input is a valid number
        if (typeof number !== 'number' || isNaN(number)) {
          return 'Invalid input';
        }
      
        // Use toLocaleString to format the number with commas and two decimal places
        const formattedNumber = number.toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      
        // Add the Arabic currency symbol manually
        const arabicFormattedNumber = `${formattedNumber} ر.س`;
      
        return arabicFormattedNumber;
    }
      
    if(isLoading){
      return <LoadingScreen/>
    }

    if(auth.currentUser === null && !isLoading){
      return <SignInOrSignUpScreen prompt={"الرجاء التسجيل او تسجيل الدخول لرؤية هذه الصفحة"} />
     }

     const MyInfo = () => {
      if (userInfoLoading) {
        return (
          <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
            <Oval
              height={"30px"}
              width={"30px"}
              color={"#00C8B8"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block", margin: "auto" }}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
          </div>
        );
      }
      return (
        <div style={{ height: "100%", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", width: window.innerWidth > window.innerHeight ? "70%" : "90%", marginLeft: "auto", marginRight: "auto", padding: "16px" }}>
          <div style={{ display: "flex", flexDirection: "column", position: "relative", marginBottom: "32px", alignItems: "center" }}>
            <img
              onClick={() => newProfilePictureRef.current.click()}
              style={{
                width: "140px",
                opacity: 1,
                height: "140px",
                borderRadius: "1000px",
                objectFit: "cover",
                cursor: "pointer",
                border: ".5px solid #ddd"
              }}
              src={blobImg === "" ? originalImg : blobImg}
              className='skeleton'
              loading='eager'
            />
            <input
              ref={newProfilePictureRef}
              type='file'
              style={{ display: "none", visibility: "hidden" }}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  const blobUrl = URL.createObjectURL(file);
                  setBlobImg(blobUrl);
                }
                setProfilePictureUpdated(true);
              }}
            />
            {
              blobImg != "" &&
              <button style={{ backgroundColor: "#F00057", border: "none", borderRadius: "4px", width: "20px", height: "20px", marginTop: "4px" }} onClick={() => {
                setBlobImg("")
                newProfilePictureRef.current.value = "";
                setProfilePictureUpdated(false);
              }}>
                <FaTrashCan size={11} style={{ marginBottom: "17px", color: "white", marginTop: "4px" }} />
              </button>
            }
            <div style={{ display: "flex", direction: "rtl", marginTop: "8px" }}>
              <IoWalletOutline size={20} color='#F00057' />
              <h6 style={{ marginRight: "4px" }}>{formatAccountingArabic(balance)}</h6>
            </div>
            <div style={{ display: "flex", marginTop: "0px" }}>
              <AiFillStar size={20} style={{ marginTop: "3px" }} color='gold' />
              <p style={{ marginBottom: "0px" }}>{parseFloat(userRating.toFixed(1))}</p>
              <p style={{ marginBottom: "0px", marginRight: "4px" }}>({numRaters})</p>
            </div>
          </div>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <input
              onChange={(e) => {
                setNewFullName(e.target.value);
              }}
              ref={fullNameRef}
              id='input-field'
              placeholder='الإسم'
              style={{ color: "#00C8B8", fontWeight: "500" }}
              type='text' defaultValue={fullName}
            />
            {isVerified && <RiVerifiedBadgeFill size={25} style={{ position: "relative", marginRight: "8px" }} color='#00C8B8' />}
          </div>
          <input
            id='input-field'
            style={{ color: "#646464", marginTop: "32px", direction: "ltr" }}
            readOnly
            type='text' value={phoneNumber}
          />
          <input
            placeholder='البريد الالكتروني'
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
            ref={emailRef}
            style={{ marginTop: "32px", color: "#00C8B8" }}
            id='input-field'
            type='email' defaultValue={newEmail !== "" ? newEmail : email}
          />
          {
            email === "" && newEmail === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <p style={{ marginBottom: "0px", color: "#646464" }}>قم بإضافة بريدك الإلكتروني لتصلك إشعارات مثل اذا قام شخص بإضافة تعليق على منتجك</p>
            </div>
          }
          <div style={{
            display: "flex",
            width: "100%", justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
          }}>
            <select
              ref={cityRef}
              id='input-field'
              defaultValue={city}
              onChange={(value) => {
                setNewCity(value.target.value);
              }}
            >
              <option style={{ color: "black" }} disabled value="">المدينة</option>
              <option style={{ color: "black" }} value="الرياض">الرياض</option>
              <option style={{ color: "black" }} value="جدة">جدة</option>
              <option style={{ color: "black" }} value="الدمام">الدمام</option>
              <option style={{ color: "black" }} value="الخبر">الخبر</option>
              <option style={{ color: "black" }} value="الظهران">الظهران</option>
            </select>
            {
              newCity !== "" &&
              <IoCloseSharp style={{ marginRight: "8px", color: "red", marginTop: "auto", marginBottom: "auto" }} size={19} onClick={() => { cityRef.current.value = ""; setNewCity("") }} />
            }
          </div>
          {
            city === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <p style={{ marginBottom: "0px", color: "#646464" }}>قم بإضافة مدينتك لاظهارها في حسابك كبائع</p>
            </div>
          }
          <input
            placeholder='الآيبان'
            onChange={(e) => {
              setNewIban(e.target.value);
            }}
            ref={ibanRef}
            id='input-field'
            defaultValue={iban}
            style={{ marginTop: "32px", color: "#646464" }}
          />
          {
            iban === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <span style={{ color: "red" }}>*</span>
              <p style={{ marginBottom: "0px", color: "#646464" }}>مطلوب لتحويل مبالغ مبيعاتك</p>
            </div>
          }
          <input
            placeholder='اسم المستفيد الكامل'
            onChange={(e) => {
              setNewBeneficiaryName(e.target.value);
            }}
            ref={beneficiaryRef}
            id='input-field'
            defaultValue={beneficiaryName}
            style={{ marginTop: "32px", color: "#646464" }}
          />
          {
            newBeneficiaryName === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <span style={{ color: "red" }}>*</span>
              <p style={{ marginBottom: "0px", color: "#646464" }}>مطلوب للتأكد من الحساب البنكي</p>
            </div>
          }
          <div style={{
            display: "flex",
            width: "100%", justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
          }}>
            <select
              ref={bankRef}
              id='input-field'
              style={{ color: "#646464" }}
              defaultValue={bank}
              onChange={(value) => {
                setNewBank(value.target.value);
              }}
            >
              <option disabled value="">البنك</option>
              <option value="الأهلي">الأهلي</option>
              <option value="بنك ساب">بنك ساب</option>
              <option value="مصرف الإنماء">مصرف الإنماء</option>
              <option value="البنك السعودي الفرنسي">البنك السعودي الفرنسي</option>
              <option value="بنك الرياض">بنك الرياض</option>
              <option value="مصرف الراجحي">مصرف الراجحي</option>
              <option value="البنك العربي الوطني">البنك العربي الوطني</option>
              <option value="بنك البلاد">بنك البلاد</option>
              <option value="بنك الجزيرة">بنك الجزيرة</option>
              <option value="البنك السعودي للاستثمار">البنك السعودي للاستثمار</option>
            </select>
            {
              newBank !== "" &&
              <IoCloseSharp style={{ marginRight: "8px", color: "red", marginTop: "auto", marginBottom: "auto" }} size={19} onClick={() => { bankRef.current.value = ""; setNewBank("") }} />
            }
          </div>
          {
            bank && newBank === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <span style={{ color: "red" }}>*</span>
              <p style={{ marginBottom: "0px", color: "#646464" }}>يرجى اختيار البنك</p>
            </div>
          }
          <button disabled={updatingDataLoading} style={{ width: "100%", marginTop: "32px" }} id='my-button' onClick={async () => {
            if (
              !profilePictureUpdated &&
              fullName.toString().trim() === newFullName.toString().trim() &&
              email.toString().trim() === newEmail.toString().trim() &&
              city.toString().trim() === newCity.toString().trim() &&
              iban.toString().trim() === NewIban.toString().trim() &&
              beneficiaryName.toString().trim() === newBeneficiaryName.toString().trim() &&
              bank.toString().trim() === newBank.toString().trim()
            ) return;
    
            try {
              setUpdatingDataLoading(true);
    
              const washingtonRef = doc(db, "Users", auth.currentUser.uid);
              let url; // Declare url variable outside the if block
    
              // Update profile picture if changed
              if (profilePictureUpdated) {
                const desertRef = ref(storage, `Users/${auth.currentUser.uid}/Profile Picture`);
                await uploadBytesResumable(desertRef, newProfilePictureRef.current.files[0]);
                url = await getDownloadURL(desertRef); // Assign value to url
              }
    
              // Update user document
              await updateDoc(washingtonRef, {
                profileImgUrl: url ? url : originalImg, // Use url here
                fullName: newFullName,
                email: newEmail,
                city: newCity,
                bank: newBank,
                iban: NewIban,
                beneficiaryName: newBeneficiaryName
              });
    
              // Set state or perform other operations using url
              setNewUrl(url);
              setOriginalImg(url);
              setBlobImg("");
    
              setUpdatingDataLoading(false);
              setTimeout(() => {
                setSuccessMessage("!تم تحديث معلوماتك بنجاح")
                setShowSuccessAnim(true);
              }, 100);
            } catch (error) {
              console.error("Error updating information:", error);
              // Set loading to false in case of an error
              setUpdatingDataLoading(false);
            }
          }}>
            {
              updatingDataLoading ? <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", paddingTop: "4px" }}>
                <Oval
                  height={"20px"}
                  width={"20px"}
                  color={"white"}
                  wrapperClass=""
                  visible={true}
                  wrapperStyle={{ display: "block" }}
                  ariaLabel='oval-loading'
                  secondaryColor="#ccc"
                  strokeWidth={1}
                  strokeWidthSecondary={1}
                />
              </div> : <p style={{ marginBottom: "0px" }}>حفظ</p>
            }
          </button>
        </div>
      );
    };
    


      const MyBids = () => {
        if(myBidsLoading){
          return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
          <Oval
                      height={"30px"}
                      width={"30px"}
                      color={"#00C8B8"}
                      wrapperClass=""
                      visible={true}
                      wrapperStyle={{display: "block", margin:"auto"}}
                      ariaLabel='oval-loading'
                      secondaryColor="#ccc"
                      strokeWidth={1}
                      strokeWidthSecondary={1}
                    /> 

          </div>
        }
        return (
          myBids.length > 0 ? (
            <div
              style={{
                height: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "8px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  width: "100%",
                  height: "fit-content",
                  direction: "rtl",
                  rowGap: "16px",
                  columnGap: "16px",
          gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth < 1100 ? `repeat(auto-fill, minmax(150px, 1fr))` : `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
                  justifyContent: "center",
                  justifyItems: "stretch",
                  alignItems: "stretch",
                }}
              >
                {myBids.map((product) => {
                  const isExpired = isBefore(parseISO(product.expiryDate.toDate()), new Date());
                  return <Product
                  key={product.id}
                  imgs={product.imgs}
                  productName={product.productName}
                  numBidders={product.numBidders}
                  expiryDate={product.expiryDate}
                  carPlateKind={product.carPlateKind}
                  productSeller={product.sellerId}
                  myId={parseFloat(userId)}
                  heighestBidder={parseFloat(userId)}
                  acceptsOffers={product.postType.includes("عروض")}
                  currentBid={product.currentBid}
                  condition={product.condition}
                  isAuction={true}
                  category={product.category}
                  productPrice={product.productPrice}
                  link={isExpired ? `/my_expired_bids/${product.id}` : `/product/${product.id}`}
                />
                })}
              </div>
            </div>
          ) : (
            <div style={{ width: "100%", textAlign: "center", marginTop:"8px" }}>
              <h6>أنت لم تقم بأي مزايدة بعد</h6>
            </div>
          )
        );
      };

// MyProducts.js
const MyProducts = () => {
  if(myProductsIsLoading){
    return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
    <Oval
                height={"30px"}
                width={"30px"}
                color={"#00C8B8"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block", margin:"auto"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 

    </div>
  }
  return(
    myProducts.length > 0 ? (
      <div style={{height:"100%", alignItems:"center", display:"flex", flexDirection:"column", width: "100%",  padding:"8px"}}>
        <div 
        style={{
          display: "grid",
          width: "100%",
          height: "fit-content",
          direction: "rtl",
          rowGap: "16px",
          columnGap: "16px",
          gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth < 1100 ? `repeat(auto-fill, minmax(150px, 1fr))` : `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
          justifyContent: "center",
          justifyItems: "stretch",
          alignItems: "stretch",
        }}
        
        >


        {myProducts.map(product => (
          <Product
          key={product.id}
          imgs={product.imgs}
          productName={product.productName}
          numBidders={product.numBidders}
          expiryDate={product.expiryDate}
          acceptsOffers={product.postType.includes("عروض")}
          currentBid={product.currentBid}
          condition={product.condition}
          isAuction={product.postType.includes("مزاد")}
          category={product.category}
          carPlateKind={product.carPlateKind}

          productPrice={product.productPrice}
          link={`/my_products/${product.id}`}
          />
          ))}
          </div>
</div>
          ) : <div style={{width:"100%", textAlign:"center", marginTop:"8px"}}><h6>أنت لم تقم بإضافة منتجات بعد</h6></div>
        )
}
const MySoldProducts = () => {

  if(mySoldProductsLoading){
    return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
    <Oval
                height={"30px"}
                width={"30px"}
                color={"#00C8B8"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block", margin:"auto"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 

    </div>
  }

  return(
    mySoldProducts.length > 0 ? (
      <div style={{height:"100%", alignItems:"center", display:"flex", flexDirection:"column", width: "100%",  padding:"8px"}}>
        <div 
        style={{
          display: "grid",
          width: "100%",
          height: "fit-content",
          direction: "rtl",
          rowGap: "16px",
          columnGap: "16px",
          gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth < 1100 ? `repeat(auto-fill, minmax(150px, 1fr))` : `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
          justifyContent: "center",
          justifyItems: "stretch",
          alignItems: "stretch",
        }}
        
        >


        {mySoldProducts.map(order => (
          // <Product
          // key={product.id}
          // imgs={product.imgs}
          // productName={product.productName}
          // numBidders={product.numBidders}
          // expiryDate={product.expiryDate}
          // currentBid={product.currentBid}
          // carPlateKind={product.carPlateKind}

          // // acceptsOffers={product.postType.includes("عروض")}

          // condition={product.condition}
          // isAuction={product.postType.includes("مزاد")}
          // category={product.category}
          // productPrice={product.productPrice}
          // link={`/my_sold_products/${product.id}`}
          // />

          order.id
        
          ))}

          </div>
</div>
          ) : <div style={{width:"100%", textAlign:"center", marginTop:"8px"}}><h6>أنت لم تقم ببيع منتجات بعد</h6></div>
        )
}

const MyPurchases = () => {

  if(myPurchasesLoading){
    return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
    <Oval
                height={"30px"}
                width={"30px"}
                color={"#00C8B8"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block", margin:"auto"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 

    </div>
  }
  return(
    myPurchases.length > 0 ? (
      <div style={{height:"100%", alignItems:"center", display:"flex", flexDirection:"column", width: "100%",  padding:"8px"}}>
        <div 
        style={{
          display: "grid",
          width: "100%",
          height: "fit-content",
          direction: "rtl",
          rowGap: "16px",
          columnGap: "16px",
          gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth < 1100 ? `repeat(auto-fill, minmax(150px, 1fr))` : `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
          justifyContent: "center",
          justifyItems: "stretch",
          alignItems: "stretch",
        }}
        
        >


        {myPurchases.map(product => (
          <Product
          key={product.id}
          imgs={product.imgs}
          productName={product.productName}
          numBidders={product.numBidders}
          expiryDate={product.expiryDate}
          currentBid={product.currentBid}
          carPlateKind={product.carPlateKind}

          acceptsOffers={product.postType.includes("عروض")}

          condition={product.condition}
          isAuction={product.postType.includes("مزاد")}
          category={product.category}
          productPrice={product.productPrice}
          link={`/my_purchases/${product.id}`}
          />
          ))}
          </div>
</div>
          ) : <div style={{width:"100%", textAlign:"center", marginTop:"8px"}}><h6>أنت لم تقم بشراء أي منتج بعد</h6></div>
        )
};

const MyComments = () => {

  return (
    comments.length > 0 ? (

      <div style={{height:"100%", justifyContent:"center", flexDirection:"column", width: "100%", marginLeft:"auto", marginRight:"auto",padding:"16px"}}>
        

        <div 
        style={{
          width: "100%",
          height: "fit-content",
        }}
        >
        {comments.map((comment) => (
          <Comment
          key={comment.timestamp} // Make sure to add a unique key
          comment={comment.comment}
          userRating={comment.user.rating}
          numRaters={comment.user.numRaters}
          userImg={comment.user.profileImgUrl}
          commentId={comment.id}
          isUpdated={comment.isUpdated}
            userName={comment.user.fullName}
            timestamp={comment.timestamp}
            userId={comment.userId}
            link={`/user/${comment.user.id}`}
            />
            ))}
          </div>
    </div>
            ) : <div style={{width:"100%", textAlign:"center", marginTop:"8px"}}><h6 > لم يقم أي شخص بالتعليق على حسابك  بعد</h6  ></div>
          )
};



const handleRefund = async (
  tran_ref,
  cart_id,
  cart_description,
  cart_amount,
  paidShipping
) => {
  try {
    if (auth.currentUser) {
      const requestBody = {
        profile_id: 106859,
        tran_type: "refund",
        tran_ref: tran_ref,
        cart_id: cart_id,
        cart_description: cart_description,
        cart_currency: "SAR",
        tran_class: "ecom",
        cart_amount: paidShipping ? (cart_amount + 23) : cart_amount
      };

      // Log the request body for debugging
      console.log('Request Body:', requestBody);

      const response = await fetch('https://us-central1-men-ela-testing.cloudfunctions.net/processRefund', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

    }
  } catch (error) {
    // Handle error
    console.error('Error calling processPayment Cloud Function:', error);
  }
};

const updateProductQuantity = async (productId, newQuantity) => {
  try {
    const productRef = doc(db, 'Posts', `post${productId}`);
    await updateDoc(productRef, { productQty: newQuantity });
    console.log(`Product ${productId} quantity updated to ${newQuantity}`);
  } catch (error) {
    console.error('Error updating product quantity:', error);
  }
};

const fetchProductQuantity = async (productId) => {
  try {
    const productRef = doc(db, 'Posts', `post${productId}`);
    const productDoc = await getDoc(productRef);
    if (productDoc.exists()) {
      const productData = productDoc.data();
      return productData.productQty;
    } else {
      throw new Error(`Product with ID ${productId} does not exist.`);
    }
  } catch (error) {
    console.error('Error fetching product quantity:', error);
    return null;
  }
};

const acceptOffer = async (offerId, productId, tran_ref, product) => {
  setOfferResponseLoading(true)
  try {
    // Fetch the current product quantity
    let currentQuantity = await fetchProductQuantity(productId);
    if (currentQuantity === null) return;

    // Update the accepted offer
    const acceptedOfferRef = doc(db, 'Offers', `Offer${offerId}`);
    await updateDoc(acceptedOfferRef, {
      isAccepted: true,
    });

    console.log(`Offer ${offerId} was accepted with tran_ref: ${tran_ref}`);

    // Reduce the product quantity by 1
    const newQuantity = currentQuantity - 1;
    await updateProductQuantity(productId, newQuantity);

    // Fetch the updated product quantity
    currentQuantity = await fetchProductQuantity(productId);
    if (currentQuantity === null) return;

    if (currentQuantity === 0) {
      // Query and reject all other offers for the same product
      const offersQuery = query(
        collection(db, 'Offers'),
        where('productId', '==', productId),
        where('isAccepted', '==', false),
        where('isRejected', '==', false)
      );
      const querySnapshot = await getDocs(offersQuery);

      const batch = writeBatch(db);
      querySnapshot.forEach(async docSnapshot => {
        const offerData = docSnapshot.data();
        if (docSnapshot.id !== offerId) {
          console.log(`Offer ${docSnapshot.id} was rejected with tran_ref: ${offerData.tran_ref}`);

          // call a refund for each offer
          await handleRefund(
            offerData.tran_ref,
            offerData.cart_id,
            offerData.cart_description,
            offerData.amount,
            offerData.paidShipping
          );
          batch.update(docSnapshot.ref, { isRejected: true });
        }
      });

      await batch.commit();
      console.log('All other offers for the product were rejected.');
    } else {
      console.log('Product quantity is more than 1. Other offers were not rejected.');
    }
  } catch (error) {
    console.error('Error accepting the offer:', error);
  }
  setOfferResponseLoading(false)
};

const rejectOffer = async (offerId, productId, tran_ref) => {
  try {
    setOfferResponseLoading(true);

    // Fetch the offer details to get necessary refund parameters
    const offerRef = doc(db, 'Offers', `Offer${offerId}`);
    const offerDoc = await getDoc(offerRef);
    if (!offerDoc.exists()) {
      throw new Error(`Offer with ID ${offerId} does not exist.`);
    }

    const offerData = offerDoc.data();

    // Call a refund
    await handleRefund(
      tran_ref,
      offerData.cart_id,
      offerData.cart_description,
      offerData.amount,
      offerData.paidShipping
    );

    // Mark the offer as rejected
    await updateDoc(offerRef, { isRejected: true });

    console.log(`Offer ${offerId} was rejected with tran_ref: ${tran_ref}`);
  } catch (error) {
    console.error('Error rejecting the offer:', error);
  }
  setOfferResponseLoading(false);
};


const MyProductsOffers = () => {
  return (
    offers.length > 0 ? (
      <div
        style={{
          height: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "8px",
        }}
      >
        <div
          style={{
            display: "grid",
            width: "100%",
            height: "fit-content",
            direction: "rtl",
            rowGap: "16px",
            columnGap: "16px",
            gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth < 1100 ? `repeat(auto-fill, minmax(150px, 1fr))` : `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
            justifyContent: "center",
            justifyItems: "stretch",
            alignItems: "stretch",
          }}
        >
          {offers.map((offer) => {
            return (
              <Offer
                imgs={offer.product.imgs}
                productName={offer.product.productName}
                amount={offer.amount}
                category={offer.product.category}
                carPlateKind={offer.product.carPlateKind}
                link={`/product/${parseFloat(offer.product.id)}`}
                acceptOffer={() => acceptOffer(offer.id, offer.product.id, offer.tran_ref, offer.product)}
                rejectOffer={() => rejectOffer(offer.id, offer.product.id, offer.tran_ref)}
              />
            );
          })}
        </div>
      </div>
    ) : (
      <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
        <h6>لا توجد عروض على منتجاتك بعد</h6>
      </div>
    )
  );
};




const MyOffers = () => {
  return (
    comments.length > 0 ? (

      <div style={{height:"100%", justifyContent:"center", flexDirection:"column", width: "100%", marginLeft:"auto", marginRight:"auto",padding:"16px"}}>
        

        <div 
        style={{
          width: "100%",
          height: "fit-content",
        }}
        >
        {comments.map((comment) => (
          <Comment
          key={comment.timestamp} // Make sure to add a unique key
          comment={comment.comment}
          userRating={comment.user.rating}
          numRaters={comment.user.numRaters}
          userImg={comment.user.profileImgUrl}
          commentId={comment.id}
          isUpdated={comment.isUpdated}
            userName={comment.user.fullName}
            timestamp={comment.timestamp}
            userId={comment.userId}
            link={`/user/${comment.user.id}`}
            />
            ))}
          </div>
    </div>
            ) : <div style={{width:"100%", textAlign:"center", marginTop:"8px"}}><h6 > لم يقم أي شخص بالتعليق على حسابك  بعد</h6  ></div>
          )
};

const MyRatings = () => {
  return (
            <div style={{width:"100%", textAlign:"center", marginTop:"8px"}}><h6 > لم يقم أي شخص بتقييمك بعد</h6  ></div>
          )
};

  return ( 
    
    <>
{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}
{offerResponseLoading && (
  <>
    <div style={{width:"100vw", height:"100vh", backgroundColor: 'rgba(0, 0, 0, 0.2)',  position:"fixed", zIndex:"1010", display:"flex", justifyContent:"center", alignItems:"center", top:0}}>
      <div style={{backgroundColor:"white",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", padding:"3% 2%", borderRadius:"12px"}}>
      <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor:"white",
              marginBottom:"8px"
            }}
          >
                    <Oval
          height={"35px"}
          width={"35px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={1}
        />
          </div>

          <p style={{marginBottom:"0px"}}>جاري المعالجة</p>
      </div>
    </div>
  </>
)}
<div style={{width:"100%", height:"fit-content", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>

          {/* path */}
          <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: window.innerWidth > window.innerHeight ? "80%" : "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>حسابي</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>{activeSubScreen}</h6>
        </div>
      </div>

<div style={{ display: window.innerWidth > window.innerHeight ? 'flex' : "block", height: 'fit-content', minHeight:"calc(100vh - 65px)" ,width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:  "auto", marginRight: "auto", direction: "rtl", marginTop: window.innerWidth > window.innerHeight ? "42px" : "8px", paddingBottom:"65px"}}>
  
  <div style={{
    flex: 0.65,
    display: "flex", // Use flexbox for vertical alignment
    flexDirection: window.innerWidth > window.innerHeight ? 'column' : "row", // Stack children vertically
    height: '100%', // Fill the available height
    position: "relative",
    minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "fit-content",
    alignItems:"center",
    marginBottom:"8px",
    overflowX: window.innerWidth > window.innerHeight ? "hidden" : "scroll",
  }}>
    {/* Navigation Links with NavLink */} 
    <NavLink to="my_info" className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
    
    {
      activeSubScreen === "معلوماتي" ? (
        <BsPersonFill style={{marginLeft:"4px"}} size={19}  />
        
      ) : (
        <BsPerson style={{marginLeft:"4px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>معلوماتي</p> 
    
    </NavLink>
    <NavLink to="my_products" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
      {
        activeSubScreen === "منتجاتي" ? (
          <MdWatch style={{marginLeft:"4px"}} size={19}  />
          
        ) : (
          <MdOutlineWatch style={{marginLeft:"4px"}} size={19} />
          
        )
      }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>منتجاتي</p> 

      </NavLink>
    <NavLink to="my_bids" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
        activeSubScreen === "مزايداتي" ? (
          <PiGavelFill style={{marginLeft:"4px"}} size={19}  />
          
        ) : (
          <PiGavel style={{marginLeft:"4px"}} size={19} />
          
        )
      }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>مزايداتي</p> 
      
      </NavLink>
    <NavLink to="my_purchases" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "مشترياتي" ? (
        <RiPriceTag3Fill style={{marginLeft:"4px"}} size={19}  />
        
      ) : (
        <RiPriceTag3Line style={{marginLeft:"4px"}} size={19} />

      )
    }
      
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>مشترياتي</p> 
      
      </NavLink>
    <NavLink to="my_sold_products" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px", whiteSpace:"nowrap"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "الطلبات" ? (
        <IoBagCheck style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <IoBagCheckOutline style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>الطلبات</p> 
      
      </NavLink>
    <NavLink to="my_products_offers" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px", whiteSpace:"nowrap"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "عروض على منتجاتي" ? (
        <RiFileListFill style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <RiFileListLine  style={{marginLeft:"4px", minHeight:"19px", minWidth:"20px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>عروض على منتجاتي</p> 
      
      </NavLink>
    <NavLink to="my_offers" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px", whiteSpace:"nowrap"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "عروض تم تقديمها" ? (
        <MdPlaylistAddCheckCircle style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <MdOutlinePlaylistAddCheckCircle style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>عروض تم تقديمها</p> 
      
      </NavLink>
    <NavLink to="my_comments" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      

    {
      activeSubScreen === "التعليقات" ? (
        <BiSolidComment style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <BiComment style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19} />

      )
    }

<p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>التعليقات</p> 

      </NavLink>
    <NavLink to="my_ratings" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "التقييمات" ? (
        <RiStarSFill style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <RiStarSLine style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19} />

      )
    }
      
      <p style={{marginBottom:"0px",whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>التقييمات <span style={{color:"#ccc", marginRight:"2px", fontWeight:"400"}}>(قريباً)</span>
      </p>
      </NavLink>
    {/* Logout div positioned at the bottom */}
    <div style={{
      fontSize: "18px",
      fontWeight: "400",
      cursor: 'pointer', // Changes the cursor to pointer when hovering over the div
      width:"fit-content",
      padding: window.innerWidth > window.innerHeight? "8px" : "8px 16px",
      marginTop:window.innerWidth > window.innerHeight ?  "16px" : "0px",
      // marginRight: window.innerWidth > window.innerHeight ? "auto" : "0px",
      marginLeft: window.innerWidth > window.innerHeight ? "auto" : "0px",
    }}
    onClick={()=>{
      if (window.confirm("تسجيل الخروج؟")) {
        navigate("/")
        auth.signOut();
      }
    }}
    >
      <p style={{marginBottom: "0px", color: "red", textAlign: "right"}}><MdLogout size={17} /></p>
    </div>
  </div>
  <div style={{flex:2.5, width:"100%", border: "1.5px solid #00C8B8", marginRight: window.innerWidth > window.innerHeight ?"16px" : "0px", borderRadius:"24px", minHeight: window.innerWidth > window.innerHeight ? "20vh" : "80vh"}}>
    {/* Route Configuration */}
    <Routes>
      <Route index element={<Navigate replace to="my_info" />} />
      <Route path="my_info" element={MyInfo()} />
      <Route path="my_products" element={<MyProducts />} />
      <Route path="my_bids" element={<MyBids />} />
      <Route path="my_purchases" element={<MyPurchases />} />
      <Route path="my_sold_products" element={<MySoldProducts />} />
      <Route path="my_comments" element={<MyComments />} />
      <Route path="my_ratings" element={<MyRatings />} />
      <Route path="my_products_offers" element={<MyProductsOffers />} />
      <Route path="my_offers" element={<MyOffers />} />
    </Routes>
  </div>
</div>
</div>
</>

   );
  }

export default MyAccountScreen;