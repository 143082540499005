import React from 'react'
import CustomNavbar from '../components/CustomNavbar';

function CarPartsScreen () {
        return (
            <>
            {/* <CustomNavbar/> */}
            <h2
            style={{
                marginTop: "100px"
            }}
            >this is car parts screen</h2>
            </>
        );
}
 
export default CarPartsScreen;