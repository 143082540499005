import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, doc, getDoc, onSnapshot } from 'firebase/firestore'; // Assuming Firebase
import { db, auth, storage } from '../config/firebase';
import { MdInfo } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
  import LoadingScreen from './LoadingScreen';
  import { GoHomeFill, GoReport } from "react-icons/go";

function MySoldProductsScreen() {
  const [myId, setMyId] = useState(null); // State to store user ID
  const [orderData, setOrderData] = useState(null); // State for order data
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState("")
  const [productImg, setProductImg] = useState("")
  const [productName, setProductName] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [productCategory, setProductCategory] = useState("")
  const [orderAmount, setOrderAmount] = useState("")
  const [postType, setPostType] = useState([])
  const [buyerId, setBuyerId] = useState("")
  const [entryFee, setEntryFee] = useState("")
  const [bidders, setBidders] = useState([])
  const [city, setCity] = useState("")
  const [neighbourhood, setNeighbourhood] = useState("")
  const [street, setStreet] = useState("")
  const [notes, setNotes] = useState("")

  const navigate = useNavigate();

  
  const {id} = useParams();
  const [postId, setPostId] = useState(id);
  const [isReceived, setIsReceived] = useState("");
  const [isRejected, setIsRejected] = useState("");


  // user data
  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        const userDocRef = doc(db, "Users", user.uid); // Assuming "uid" field for user ID
        const unsubscribeFromUser = onSnapshot(
          userDocRef,
          (userDocSnap) => {
            if (userDocSnap.exists()) {
              setMyId(userDocSnap.data().id); // Assuming "id" field for user ID
            } else {
              console.warn("User document not found");
            }
            setIsLoading(false);
          },
          (err) => {
            console.error("Error fetching user data:", err);
            setError(err);
          }
        );

        // Cleanup function to unsubscribe from user doc listener on component unmount or user change
        return () => unsubscribeFromUser();
      } else {
        // User is not logged in
        setMyId(null); // Reset myId if user logs out
        setIsLoading(false);
      }

      // Cleanup function to unsubscribe from auth state changes on component unmount
      return unsubscribeFromAuth;
    });

    // Cleanup function to unsubscribe from both listeners on component unmount
    return () => {
      unsubscribeFromAuth();
    };
  }, []); // Empty dependency array for effect to run once on mount

  // post data
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        // Construct a reference to the post document
        const postDocRef = doc(db, "Posts", `post${id}`);

        // Fetch post data
        const postSnap = await getDoc(postDocRef);

        if (postSnap.exists()) {
          setProductImg(postSnap.data().imgs[0]);
          setProductName(postSnap.data().productName);
          setProductDescription(postSnap.data().productDescription);
          setProductCategory(postSnap.data().category);
          setPostType(postSnap.data().postType);
          setBidders(postSnap.data().bidders);
          setEntryFee(postSnap.data().entryFee);
        } else {
          console.warn("Post not found");
        }

        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching post data:", err);
        setError(err);
      }
    };

    fetchPostData();
  }, [postId]); // Depend on postId for data fetching

  // order data
  useEffect(() => {
    if (myId) {
      const unsubscribeFromOrder = onSnapshot(
        query(
          collection(db, "Orders"),
          where("sellerId", "==", myId),
          where("productId", "==", parseFloat(id)),
          where("status", "==", "success")
        ),
        (orderSnapshot) => {
          const order = orderSnapshot.docs.length > 0 ? orderSnapshot.docs[0].data() : null;
          setOrderData(order);
          setDeliveryOption(order.deliveryOption)
          setOrderAmount(order.amount)
          setBuyerId(order.buyerId)
          setCity(order.city)
          setNeighbourhood(order.neighbourhood)
          setStreet(order.street)
          setNotes(order.notes)
          setIsReceived(order.isReceived)
          setIsRejected(order.isRejected)
        },
        (err) => {
          console.error("Error fetching order data:", err);
          setError(err);
        }
      );

      // Cleanup function to unsubscribe from order listener on myId change or component unmount
      return () => unsubscribeFromOrder();
    }
  }, [myId]); // Depend on myId for order data fetching

  if(isLoading){
    return <LoadingScreen/>
  }

  if (error) {
    return (
      <div>
        <p>Error fetching data: {error.message}</p>
      </div>
    );
  }

  function formatAccountingArabic(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with commas and two decimal places
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits:2,
      maximumFractionDigits: 2
    });
  
    // Add the Arabic currency symbol manually
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
  
    return arabicFormattedNumber;
}

  return (
    <div style={{minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
     
                 {/* path */}
<div style={{width:window.innerWidth > window.innerHeight ? "80%" : "100%", margin:"auto"}}>
<div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
                    <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />

          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    onClick={()=>{navigate(-2)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >حسابي</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    onClick={()=>{navigate(-1)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >منتجات تم بيعها</h6>

<h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 
                    style={{marginBottom:"0px", 
                    maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
                    overflow: "hidden",  /* Hide any overflow content */
                    whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
                    textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
                  fontSize:"14px"}}
          >{productName}</h6>
        </div>
      </div>
</div>
     
      {orderData && ( // Only render if orderData exists
       <div style={{display: "flex", flexDirection:  window.innerWidth > window.innerHeight ? "row" : "column",justifyContent: window.innerWidth > window.innerHeight ? "space-between" : "start",direction: "rtl", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:"auto", marginRight:"auto", height: "fit-content", position:"relative", paddingBottom:"32px", minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
     
                               <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                       <div>
                               <h5 style={{marginTop:"16px"}}>ملخص الطلب</h5>
                               <div style={{ height: "fit-content", border : "1.5px solid #00C8B8", borderRadius: "12px", padding: "8px"}}>
                                       <h6 style={{marginRight: "8px"}}>{`طلب رقم#   ${orderData.id}`}</h6>
     
                                       <div style={{width: "95%", display: "flex"}}>
         <img src={productImg} alt="" style={{width: "30%", borderRadius: "12px", objectFit: productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" ? "cover" : "scale-down", height: window.innerWidth > window.innerHeight ? "150px" : "100px"}} />
         <div style={{marginRight: "8px", width: "70%"}}>
           <p style={{
             marginBottom: "4px",
             fontWeight: "500",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 8,
             overflow: "hidden"
           }}>
             {productName}
           </p>
           <p style={{
             marginBottom: "0px",
             fontSize: "14px",
             color: "gray",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 10,
             overflow: "hidden"
           }}>
             {productDescription}
           </p>
           <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
             <p style={{marginBottom: "0px"}}>{formatAccountingArabic(orderAmount)}</p>
           </div>
         </div>
         
       </div>
     
                               </div>
     

                              {
                                deliveryOption === "شحن" && (
                                  <>
                                  <div style={{display:"flex", marginTop: "24px"}}>
     
                                  <h5 style={{fontWeight: "bold", color: "#444" }}>العنوان</h5>
                                  </div>

                                  

                                  <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1.5px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
      <IoLocation size={32} color='#00C8B8' />
      <div style={{marginRight: "8px"}}>
        <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>{city}</p>    
        <p style={{ marginBottom: "8px", color: "gray" }}>
        {`حي ${neighbourhood}${street ? `, شارع ${street}` : ''}`}
        </p>
        <p style={{marginBottom:"0px", color:"gray"}}>{notes}</p>
      </div>
    </div>



    <div style={{display:"flex", marginTop: "24px"}}>
     
     <h5 style={{fontWeight: "bold", color: "#444" }}>تتبع الشحنة</h5>
     </div>
                                  </>
                                )
                              }
                               
                               </div>
                               
                               </div>
                               <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                       <h5  style={{fontWeight: "bold", color: "#444", marginTop: window.innerWidth > window.innerHeight ? "16px" : "42px", }}>ملخص المبلغ</h5>
     
                                       <div style={{ height: "fit-content",borderRadius: "12px" , width: "100%" ,backgroundColor: "#F4F4F4", padding: "16px 0px"}}>
       <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
       <h6>المبلغ</h6>
       <h6>{formatAccountingArabic(orderAmount)}</h6>  
       </div>
                                       </div>


                                       {
       productCategory !== "ارقام جوالات" && productCategory !== "لوح سيارات" && (
         <>

<div style={{height:"fit-content", width:"100%", marginBottom:"24px", borderRadius:"8px", direction:"rtl", margin:"16px auto", marginTop:"32px"}}>
  <h5 style={{marginBottom:"8px"}}>طرق الشحن و التوصيل</h5>

  <div style={{ backgroundColor: "#f7f7f7", padding: "24px 16px", borderRadius: "8px", alignItems: "center", width:"100%"}}>
  
  {
    deliveryOption === "شحن" && (
      <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center"}}>
      <FaShippingFast color='#00C8B8' size={32} style={{ marginBottom: "0px", marginLeft: "8px" }} />
      <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>الشحن السريع</p>
    </div>
    )
  }

{
  deliveryOption === "تسليم" && (
  <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center", marginTop:"0px"}}>
    <FaRegHandshake color='#00C8B8' size={32} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>التسليم وجهاً لوجه</p>
  </div>
  )
}

  </div>
  
  {
                                 deliveryOption === "تسليم" && (
                                   <div style={{display: "flex", backgroundColor: "#fff0f2" , width: "100%", marginBottom: "16px", marginTop: "8px", borderRadius: "6px", padding: "8px 10px"}}>
                                   <MdInfo size={30} color='#ff7385' />
                                   <div>
                               
                                   <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>نوصي بالتقاء المشتري في مكان عام لتسليم المنتج و توخي الحذر لأي ظرف كان, و التأكد من ان المشتري فحص المنتج في حينه, و تجنب تلقي اي وسيلة دفع خارج المنصة.</p>
                                   <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>منصة (بيع) تخلي مسؤوليتها في حال عدم اتباع اياً من المذكور أعلاه.</p>
                                   </div>
                                 </div> 
                                 )
                               }
</div>
          
                                 </>
                               )}
                               
                               <h5 style={{marginTop:"16px"}}>تأكيد الاستلام</h5>

                               {
                                isReceived === true && (
                                  <p>تم استلام المنتج من المشتري</p>
                                )
                               }
                               {
                                isRejected === true && (
                                  <p>تم رفض استلام المنتج من المشتري</p>
                                )
                               }
                               </div>
                       </div>
      )}
    </div>
  );
}

export default MySoldProductsScreen;
