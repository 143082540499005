import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, where, doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore'; // Assuming Firebase
import { db, auth, storage } from '../config/firebase';
import { MdInfo } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import Button from '@mui/material/Button';
import { Oval } from 'react-loader-spinner'
import Countdown from 'react-countdown';
import { IoSend } from "react-icons/io5"; 
import SuccessAnim from '../components/SuccessAnim';
import { GoHomeFill, GoReport } from "react-icons/go";


function MyExpiredBidsScreen() {
  const [myId, setMyId] = useState(null); // State to store user ID
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState("")
  const [productImg, setProductImg] = useState("")
  const [productName, setProductName] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [productCategory, setProductCategory] = useState("")
  const [orderAmount, setOrderAmount] = useState("")
  const [postType, setPostType] = useState([])
  const [buyerId, setBuyerId] = useState("")
  const [entryFee, setEntryFee] = useState("")
  const [bidders, setBidders] = useState([])
  const [city, setCity] = useState("")
  const [neighbourhood, setNeighbourhood] = useState("")
  const [street, setStreet] = useState("")
  const [notes, setNotes] = useState("")


  const navigate = useNavigate();



  const [isReceived, setIsReceived] = useState("")
  const [isRejected, setIsRejected] = useState("")
  
  const {id} = useParams();
  const [postId, setPostId] = useState(id);
  const [autoReceiveDate, setAutoReceiveDate] = useState();
  
const messageRef = useRef();

  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const [sellerId, setSellerId] = useState("");
  const [sellerProfilePicture, setSellerProfilePicture] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [sellerIsVerified, setSellerIsVerified] = useState("");
  const [sellerRating, setSellerRating] = useState("");
  const [sellerNumRaters, setSellerNumRaters] = useState("");
  const [sellerCity, setSellerCity] = useState("");
  const [currentBid, setCurrentBid] = useState(0);
  const [rejectionReasonMessage, setRejectionReasonMessage] = useState("");
  
  
  const [productReceiveLoading, setProductReceiveLoading] = useState(false);
  const [productRejectLoading, setProductRejectLoading] = useState(false);
  const [messageHasError, setMessageHasError] = useState("");
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');


  
  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        const userDocRef = doc(db, "Users", user.uid); // Assuming "uid" field for user ID
        const unsubscribeFromUser = onSnapshot(
          userDocRef,
          (userDocSnap) => {
            if (userDocSnap.exists()) {
              setMyId(userDocSnap.data().id); // Assuming "id" field for user ID
            } else {
              console.warn("User document not found");
            }
            setIsLoading(false);
          },
          (err) => {
            console.error("Error fetching user data:", err);
            setError(err);
          }
        );

        // Cleanup function to unsubscribe from user doc listener on component unmount or user change
        return () => unsubscribeFromUser();
      } else {
        // User is not logged in
        setMyId(null); // Reset myId if user logs out
        setIsLoading(false);
      }

      // Cleanup function to unsubscribe from auth state changes on component unmount
      return unsubscribeFromAuth;
    });

    // Cleanup function to unsubscribe from both listeners on component unmount
    return () => {
      unsubscribeFromAuth();
    };
  }, []); // Empty dependency array for effect to run once on mount

  // post data
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        // Construct a reference to the post document
        const postDocRef = doc(db, "Posts", `post${id}`);

        // Fetch post data
        const postSnap = await getDoc(postDocRef);

        if (postSnap.exists()) {
          setProductImg(postSnap.data().imgs[0]);
          setProductName(postSnap.data().productName);
          setProductDescription(postSnap.data().productDescription);
          setProductCategory(postSnap.data().category);
          setPostType(postSnap.data().postType);
          setBidders(postSnap.data().bidders);
          setEntryFee(postSnap.data().entryFee);
          setSellerId(postSnap.data().sellerId)
          setCurrentBid(postSnap.data().currentBid)
        } else {
          console.warn("Post not found");
        }

        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching post data:", err);
        setError(err);
      }
    };

    fetchPostData();
  }, [postId]); // Depend on postId for data fetching


  // seller data
  useEffect(() => {
    if (sellerId) {
      const fetchSellerProfilePic = async () => {
        try {
          const sellerRef = collection(db, "Users"); // Reference to Users collection
          const sellerQuery = query(sellerRef, where("id", "==", sellerId)); // Query based on "id" field
  
          const sellerSnapshot = await getDocs(sellerQuery); // Fetch documents matching the query
  
          if (sellerSnapshot.docs.length > 0) {
            const sellerData = sellerSnapshot.docs[0].data(); // Get data from the first document
            setSellerProfilePicture(sellerData?.profileImgUrl); // Check for existence before accessing
            setSellerName(sellerData?.fullName); // Check for existence before accessing
            setSellerIsVerified(sellerData?.isVerified); // Check for existence before accessing
            setSellerRating(sellerData?.rating); // Check for existence before accessing
            setSellerNumRaters(sellerData?.numRaters); // Check for existence before accessing
            setSellerCity(sellerData?.city); // Check for existence before accessing
          } else {
            console.warn("Seller not found");
          }
        } catch (err) {
          console.error("Error fetching seller data:", err);
          // Handle error (optional)
        }
      };
  
      fetchSellerProfilePic();
    }
  }, [sellerId]); // Depend on sellerId for fetching profile picture

  if(isLoading){
    return <LoadingScreen/>
  }

  if (error) {
    return (
      <div>
        <p>Error fetching data: {error.message}</p>
      </div>
    );
  }

  function formatAccountingArabic(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with commas and two decimal places
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits:2,
      maximumFractionDigits: 2
    });
  
    // Add the Arabic currency symbol manually
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
  
    return arabicFormattedNumber;
}

const handleAnimationComplete = () => {
  setShowSuccessAnim(false); // Reset button state after animation completes
};


  return (
    <div style={{minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}


<div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", margin:"auto"}}>
            {/* path */}
            <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
                    <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />

          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    onClick={()=>{navigate(-2)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >حسابي</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    onClick={()=>{navigate(-1)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >مزايداتي</h6>

<h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 
                    style={{marginBottom:"0px", 
                    maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
                    overflow: "hidden",  /* Hide any overflow content */
                    whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
                    textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
                  fontSize:"14px"}}
          >{productName}</h6>
        </div>
      </div>
</div>



       <div style={{display: "flex", flexDirection:  window.innerWidth > window.innerHeight ? "row" : "column",justifyContent: window.innerWidth > window.innerHeight ? "space-around" : "start",direction: "rtl", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:"auto", marginRight:"auto", height: "fit-content", position:"relative", paddingBottom:"32px", minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
     
                               <div style={{width: window.innerWidth > window.innerHeight ? "55%" : "100%", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                       <div>
                               <h5 style={{marginTop:"16px"}}>معلومات المزاد</h5>
                               <div style={{ height: "fit-content", border : "1.5px solid #00C8B8", borderRadius: "12px", padding: "8px"}}>
                                       <h6 style={{marginRight: "8px"}}>{`رقم المنتج#   ${id}`}</h6>
     
                                       <div style={{width: "95%", display: "flex"}}>
         <img src={productImg} alt="" style={{width: "30%", borderRadius: "12px", objectFit: productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" ? "cover" : "scale-down", height: window.innerWidth > window.innerHeight ? "150px" : "100px"}} />
         <div style={{marginRight: "8px", width: "70%"}}>
           <p style={{
             marginBottom: "4px",
             fontWeight: "500",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 8,
             overflow: "hidden"
           }}>
             {productName}
           </p>
           <p style={{
             marginBottom: "0px",
             fontSize: "14px",
             color: "gray",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 10,
             overflow: "hidden"
           }}>
             {productDescription}
           </p>
           <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
             <p style={{marginBottom: "0px"}}>{formatAccountingArabic(currentBid)}</p>
           </div>
         </div>
         
       </div>
     
                               </div>

                              {
                                deliveryOption === "شحن" && (
                                  <>
                                  <div style={{display:"flex", marginTop: "24px"}}>
     
                                  <h5 style={{fontWeight: "bold", color: "#444" }}>العنوان</h5>
                                  </div>

                                  

                                  <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1.5px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
      <IoLocation size={32} color='#00C8B8' />
      <div style={{marginRight: "8px"}}>
        <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>{city}</p>    
        <p style={{ marginBottom: "8px", color: "gray" }}>
        {`حي ${neighbourhood}${street ? `, شارع ${street}` : ''}`}
        </p>
        <p style={{marginBottom:"0px", color:"gray"}}>{notes}</p>
      </div>
    </div>


    <div style={{display:"flex", marginTop: "24px"}}>
     
     <h5 style={{fontWeight: "bold", color: "#444" }}>تتبع الشحنة</h5>
     </div>
                                  </>
                                )
                              }
                               
                               </div>
                               
                               </div>
                               <div style={{width: window.innerWidth > window.innerHeight ? "40%" : "100%", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px", marginRight: window.innerWidth > window.innerHeight ? "auto" : "0px"}}>

                                   <h5 style={{marginTop:"16px"}}>البائع</h5>
                               <div 
                  style={{
                      display: "flex",
                      marginTop: "20px",
                    }}
                    >
                 <Link to={`/user/${sellerId}`} style={{display: "contents", color: "black", textDecoration: "none"}}>
            <div style={{display: "flex", width: "100%", padding:"16px" , marginBottom:"16px", backgroundColor:"#f7f7f7", borderRadius:"8px", alignItems:"center"}}>
                <div style={{ position:"relative"}}>
                <img src={sellerProfilePicture} width={65} height={65} style={{borderRadius: "1000px", objectFit: "cover"}}/>
                        </div>
                <div style={{height:"fit-content", marginBottom:"auto",justifyContent:"flex-end", display:"flex", flexDirection:"column", width: "fit-content"}}>
                  <div style={{display:"flex"}}>
                  <p style={{marginRight: "8px", marginBottom: "0px"}}>{sellerName}</p>
                    <div style={{display: "flex",alignItems: "center"}}>
                      {sellerIsVerified && (
                        <RiVerifiedBadgeFill size={22} color='#00c8b8' style={{marginRight:"4px"}}/>
                        )}
                    <AiFillStar size={14} color='gold' style={{ marginRight:"4px"}}/>
                         <p style={{marginBottom:"0px", marginRight:"2px", fontSize: "14px"}}>{parseFloat(sellerRating).toFixed(1)}</p>
                         <p style={{marginBottom:"0px"   , marginRight:"2px", fontSize: "14px"}}>({sellerNumRaters})</p>
                    </div>
                  </div>
                    {
                      sellerCity != "" &&
                                        <div style={{direction: "rtl", display: "flex"}}>
                                        <FaLocationDot size={14} color='red' style={{marginRight: "4px", marginTop: "4px"}} />
                                        <p style={{marginRight: "2px", marginBottom:"0px"}}>{sellerCity}</p>
                                        </div>
                    }

                    
                </div>
            </div>
            </Link>
                </div>

                <Button
          id="my-button"
          style={{ width: "100%" }}
          onClick={()=>{
            navigate(`/checkout/${id}`, { state: { mode: 'Bid' } })
          }}
        >
            <p style={{ whiteSpace: "nowrap", marginBottom: "0px" }}>شراء المنتج بمبلغ {formatAccountingArabic(currentBid)}</p>
        </Button>
                               </div>

                       </div>
                       
    </div>
  );
}

export default MyExpiredBidsScreen;
