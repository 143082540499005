import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CiEdit } from 'react-icons/ci';
import { FaRegTrashCan } from 'react-icons/fa6';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { doc, updateDoc, getDoc, collection, Timestamp } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar-SA';
import { Divider } from '@mui/material';
import { MdOutlineWatchLater } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { Oval } from 'react-loader-spinner'


function Comment({ userName, isUpdated, userImg, commentRef, userRating, numRaters, link, comment, timestamp, userId, commentId, postId
,handleDeleteComment, isVerified, handleUpdateComment, isCommentOptionsOpen, onClose

}) {
  const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;

  // Format the date
  const timeAgo = date ? formatDistanceToNow(date, { addSuffix: true, locale: arLocale }) : '';

  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [originalComment, setOriginalComment] = useState(comment);
  const [newComment, setNewComment] = useState('');
  const [myUserId, setMyUserId] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false)
  const [commentOptionsLoading, setCommentOptionsLoading] = useState(false)
  const [shouldRender, setShouldRender] = useState(false);


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => setShouldRender(false), 500); // Duration matches the animation time
  };


  useEffect(() => {
    const getUserId = async () => {
      if (auth.currentUser !== null) {
        const userDocRef = doc(db, 'Users', auth.currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        setMyUserId(userDocSnapshot.data().id);
      }
    };
    getUserId();
  }, []);


  const updateComment = async (commentId, updatedCommentText) => {
    try {
      console.log(commentId);
      if (!commentId) {
        console.error('CommentId is empty or undefined');
        return;
      }
  
      // Show loading state
      setUpdateLoading(true);
  
      // Reference to the specific comment document in the Comments collection
      const commentDocRef = doc(db, 'Comments', commentId);
  
      // Get the specific comment document to update
      const commentDoc = await getDoc(commentDocRef);
  
      if (commentDoc.exists()) {
        handleUpdateComment();
        console.log('Updating comment...');
        // Update the comment with the new text and set isUpdated to true
        await updateDoc(commentDocRef, {
          comment: updatedCommentText,
          isUpdated: true,
        });
        console.log('Comment updated successfully');
  
        // Show an alert after successful update
        // alert('تم تحديث التعليق بنجاح!');
        setOriginalComment(updatedCommentText);
        setNewComment("");
        // Optionally, you may want to refetch comments after updating a comment
      } else {
        console.error('Comment not found');
      }
    } catch (error) {
      console.error('Error updating comment:', error);
    } finally {
      // Hide loading state
      setUpdateLoading(false);
    }
  };
  


const deleteComment = async (commentId) => {
  try {
    // Show confirmation message before deleting the comment
    const confirmation = window.confirm('حذف التعليق؟');
    
    if (!confirmation) {
      return; // If user cancels, exit the function
    }
    
    setCommentOptionsLoading(true)
    // Reference to the specific comment document in the Comments collection
    const commentDocRef = doc(db, 'Comments', commentId);

    // Get the specific comment document to update
    const commentDoc = await getDoc(commentDocRef);

    if (commentDoc.exists()) {
      // Update the comment to set isDeleted to true
      await updateDoc(commentDocRef, {
        isDeleted: true,
      });
      handleDeleteComment(commentId);
      setCommentOptionsLoading(false)
    } else {
      console.error('Comment not found');
    }
  } catch (error) {
    console.error('Error deleting comment:', error);
  }
};

  

  return (
    <div
      style={{
        width: window.innerWidth > window.innerHeight ? '50%' : "100%",
      }}
      onClick={()=>{setIsOpen(false)}} // Close dropdown when clicking anywhere in the parent component
    >
      <div
        style={{
          width: '100%',
          wordBreak: 'break-word',
          paddingTop: '8px',
          position: 'relative',
        }}
        onClick={() => setIsOpen(false)} // Close dropdown when clicking on the yellow div
      >
        <Link to={link} style={{ display: 'contents', color: 'black', textDecoration: 'none' }}>
          <div style={{ display: 'flex', width: 'fit-content', paddingLeft: '32px' }}>
            <img className='skeleton-profileImg-comment' src={userImg} width={60} height={60} style={{ borderRadius: '1000px', objectFit: 'cover', border:"0.5px solid #E8E9E9", opacity:1 }} />
            <div>
              <div style={{display:"flex"}}>
              <h6 style={{ marginRight: '8px', marginBottom: '0px', width: 'fit-content', whiteSpace: 'nowrap' }}>{userName}</h6>
              {isVerified && (
                        <RiVerifiedBadgeFill size={22} color='#00c8b8' style={{marginRight:"4px"}}/>
                        )}
              </div>
              <div style={{display:"flex", direction:"rtl", marginRight:"8px", marginTop:"4px", alignItems:"center"}}>
              <MdOutlineWatchLater size={14} color='GrayText' />

              <p style={{ marginRight: '2px', marginBottom: '0px', width: 'fit-content', whiteSpace: 'nowrap', fontSize: '12px', color: 'GrayText', direction: 'rtl' }}>{timeAgo}{isUpdated && <CiEdit style={{ marginRight: '4px' }} />}</p>
              </div>
            </div>
          </div>
        </Link>

        {auth.currentUser !== null && userId === myUserId && commentRef === 'Post' && (
          !commentOptionsLoading ? (
            <>
                      <div
            style={{
              position: 'absolute',
              padding: '0px 4px',
              left: 0,
              top: 8,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
              toggleDropdown();
            }}
          >
            <BsThreeDotsVertical />
          </div>
            </>
          ) : (
            <div
            style={{
              position: 'absolute',
              padding: '0px 4px',
              left: 0,
              top: 8,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
                    <Oval
          height={"22px"}
          width={"22px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={1}
        />
          </div>
          )
        )}

{shouldRender && (
        <div
          className={isOpen ? 'pop-up' : 'pop-down'}
          style={{
            width: 'fit-content',
            // padding: '8px 16px',
            position: 'absolute',
            backgroundColor: 'white',
            left: 30,
            top: -10,
            direction: 'rtl',
            borderRadius: '8px',
            boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
            // border: "1px solid #E8E9E9"
          }}
        >
          <div
  style={{
    transition: 'background-color 0.3s',
    padding: '4px 0px 4px 32px',
    borderTopLeftRadius:"8px",
    borderTopRightRadius:"8px",
    cursor: 'pointer',
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
  onClick={() => { setEditMode(true); handleClose(); }}

>
  <p
    style={{ color: '#00C8B8', margin: 0 , padding:"4px 16px"}}
  >
    <CiEdit size={18} style={{ marginLeft: '4px' }} />تعديل
  </p>
</div>

          <Divider sx={{ width: '100%' }} />
          <div
  style={{
    transition: 'background-color 0.3s',
    padding: '4px 0px 4px 32px',
    borderBottomLeftRadius:"8px",
    borderBottomRightRadius:"8px",
    cursor: 'pointer',
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
  onClick={() => { deleteComment(commentId); handleClose(); }}
>
  <p
    style={{ color: '#F00057', margin: 0,  padding:"4px 16px" }}
  >
    <FaRegTrashCan size={14} style={{ marginLeft: '4px' }} /> حذف
  </p>
</div>
        </div>
      )}


      </div>

      <div
        style={{
          direction: 'rtl',
          wordBreak: 'break-word',
          // marginLeft: '16px',
          width: '80%',
        }}
        onClick={() => setIsOpen(false)} // Close dropdown when clicking on the cyan div
      >
        {editMode ? (
          <>
            <textarea defaultValue={newComment || originalComment} id='input-comment' 
            style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginTop:"16px"}}
            onChange={(e) => { setNewComment(e.target.value) }}>
            </textarea>
            <button onClick={() => {
              if (newComment === '') return;
              updateComment(commentId, newComment);
              setEditMode(false);
            }} id='my-button' disabled={updateLoading} style={{ width: 'fit-content', padding: '0px 16px', borderRadius: '4px', marginTop: '8px', marginBottom: '16px' }}>

{   
     updateLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>حفظ</p>
              }
            </button>
            <button disabled={updateLoading} onClick={() => { setNewComment(''); setEditMode(false); }} id='my-button' style={{ width: 'fit-content', padding: '8px', backgroundColor: 'transparent', color: 'red', marginRight: '12px', fontSize: '14px', boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.0)' }}>الغاء</button>
          </>
        ) : (
          <p style={{ marginRight: '55px', paddingTop: '8px', paddingBottom: '32px', width: '100%' }}>{newComment === '' ? originalComment : newComment}</p>
        )}
      </div>

      <Divider sx={{ width: '100%' }} />
    </div>
  );
}

export default Comment;
