import React, { useState, useRef, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { FaTrashCan } from "react-icons/fa6";
import { IoIosCrop } from "react-icons/io";
import { Divider } from '@mui/material';
import galleryPlaceholder from '../assets/galley-placeholder.jpg';
import { BiImageAdd } from "react-icons/bi";
import { Oval } from 'react-loader-spinner'; // Ensure you have imported the Oval component from react-loader-spinner


function ImageCropper({ imagess, setImages: updateImages }) {
    const initialImageState = {
        src: null,
        croppedImage: null,
        isCropped: false,
    };

    // Initialize images state with placeholder data
    const [images, setImages] = useState(Array(10).fill(null).map((_, idx) => ({ ...initialImageState, id: idx })));
    const [activeIndex, setActiveIndex] = useState(null);
    const cropperRef = useRef(null);
    const fileInputRefs = useRef(new Array(10).fill(null));

    const [cropperLoading, setCropperLoading] = useState(false);

    const [loading, setLoading] = useState(true);

    const onSelectFile = (index) => (event) => {
      if (event.target.files && event.target.files.length > 0) {
        const selectedFiles = Array.from(event.target.files);
    
        Promise.all(
          selectedFiles.map((file) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result); // Resolve with the base64 URL
              reader.readAsDataURL(file);
            })
          )
        )
          .then((results) => {
            const updatedImages = [...images];
    
            // Handle multiple selected files
            results.forEach((result, fileIndex) => {
              // Find the first empty slot or create a new slot if all are full
              let emptySlotIndex = updatedImages.findIndex((image) => image.src === null);
              if (emptySlotIndex === -1) {
                emptySlotIndex = updatedImages.length;
                updatedImages.push({}); // Create a new slot
              }
    
              updatedImages[emptySlotIndex] = { src: result, isCropped: false };
            });
    
            setImages(updatedImages);
            updateImages(updatedImages.filter((image) => image && image.src));
            setImagesUpdated(true);
            event.target.value = ''; // Optionally, clear the file input after selecting files
          })
          .catch((error) => console.error('Error reading selected file:', error));
      }
    };
    
    function moveImageToFirst(selectedIndex, images) {
      // Early return to avoid unnecessary operations if the image is already at index 0
      if (selectedIndex === 0) return;
    
      // Extract the image to move
      const [imageToMove] = images.splice(selectedIndex, 1);
    
      // Insert the image at the beginning (index 0)
      images.unshift(imageToMove);
    
      // Update the parent component's state with the correctly ordered images
      setImages(images); // No additional order manipulation needed
      updateImages(images)
    }
    
    

    const onCrop = () => {
        if (cropperRef.current) {
          const cropper = cropperRef.current.cropper;
          if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
              if (blob) {
                const croppedUrl = URL.createObjectURL(blob);
                const updatedImages = images.map((image, index) =>
                  index === activeIndex ? { ...image, src: croppedUrl } : image
                );
                setImages(updatedImages);
                setImagesUpdated(true);
                setActiveIndex(null);
              }
            });
          }
        }
      };
    
    const [imagesUpdated, setImagesUpdated] = useState(false)

      useEffect(() => {

            const updatedImages = imagess.map((image, index) => ({
                ...initialImageState,
                id: index,
                src: typeof image === 'string' ? image : image.src,
                isCropped: false,
                isRecropping: typeof image === 'object' ? image.isRecropping : false,
            }));
            setLoading(false);
    
            const emptySlots = 10 - updatedImages.length;
            for (let i = 0; i < emptySlots; i++) {
                updatedImages.push({
                    ...initialImageState,
                    id: updatedImages.length,
                });
            }
    
            setImages(updatedImages);
    }, [imagess]); // Consider removing this dependency or managing it carefully
    
    const onDiscard = () => {
        if (fileInputRefs.current[activeIndex]) {
            fileInputRefs.current[activeIndex].value = ''; // Clear the file input
        }
        setImages(images.map((img, index) => (index === activeIndex ? initialImageState : img)));
        setActiveIndex(null); // Reset active index after discarding
    };

    const onDelete = (index) => {
        if (window.confirm("حذف الصورة؟")) {
            const newImages = images.filter((_, idx) => idx !== index);
            newImages.forEach((image, idx) => {
                image.id = idx; // Update the id to maintain uniqueness
            });
    
            setImages(newImages);
            setImagesUpdated(true)
            updateImages(newImages.filter((image) => image.src !== null));
    
            if (fileInputRefs.current[index]) {
                fileInputRefs.current[index].value = '';
            }
        }
    };

    const onRecrop = (index) => {
        setCropperLoading(true);
        setActiveIndex(index); // Set the active index to the image to be re-cropped
        // Update the state to re-crop the image
        setImages(prevImages => {
            const updatedImages = prevImages.map((img, idx) => {
                if (idx === index) {
                    return { ...img, isCropped: false, isRecropping: true };
                }
                return img;
            });
            return updatedImages;
        });
    };

    const onCancelRecrop = (index) => {
        const newImages = [...images];
        newImages[index] = { ...newImages[index], isRecropping: false };
        setImages(newImages);
        setActiveIndex(null); // Optionally reset the active index
    };

    return (
        <div style={{ width: '100%' , height:"fit-content",direction:"rtl", justifyContent:"center", backgroundColor:"#f0f0f0", padding:"8px", borderRadius:"8px"}}>
 {activeIndex !== null && images[activeIndex].src && !images[activeIndex].isCropped && (
  <div
    style={{
      height: '100vh',
      width: '100vw',
      backgroundColor: 'black',
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      right: 0,
      overflow: 'hidden', // Prevent scrolling in the background
      
    }}
  >
    {cropperLoading && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'black', // Dark overlay
          overflow:"hidden"
        }}
      >
        {/* Your loading indicator here, e.g., a spinner */}
        <Oval height={50} width={50} color="#00C8B8" />
      </div>
    )}

    {/* Cropper component */}
    <Cropper
      src={images[activeIndex].src}
      style={{
        height: '70vh',
        width: window.innerWidth > window.innerHeight ? '100%' : '70vw',
        backgroundColor: 'black',
      }}
      guides={false}
      ref={cropperRef}
      viewMode={2}
      background={false}
      responsive={true}
      minCropBoxWidth={100}
      minCropBoxHeight={100}
      ready={() => {
        setCropperLoading(false);
        document.querySelectorAll('.cropper-point').forEach((el) => {
          el.style.width = '20px';
          el.style.height = '20px';
          el.style.opacity = '1';
          el.style.borderRadius = '20px';
          el.style.backgroundColor = '#00C6B7';
        });
      }}
      autoCropArea={1}
      checkOrientation={false}
    />

    {/* Cropper action buttons */}
    <div
      style={{
        display: 'flex',
        width: '40%',
        justifyContent: window.innerWidth > window.innerHeight ? 'center' : 'space-around',
        padding: '10px',
        backgroundColor: 'transparent',
      }}
    >
      {/* Cancel button */}
      <button
        onClick={() =>
          images[activeIndex].isRecropping ? onCancelRecrop(activeIndex) : onDiscard()
        }
        style={{
          marginRight: '10px',
          backgroundColor: '#F00057',
          border: 'none',
          borderRadius: '1000px',
          width: '40px',
          height: '40px',
        }}
      >
        <IoCloseSharp color="white" size={26} />
      </button>

      {/* Save button */}
      <button
        onClick={onCrop}
        style={{
          marginRight: '10px',
          backgroundColor: '#00C6B7',
          border: 'none',
          borderRadius: '1000px',
          width: '40px',
          height: '40px',
        }}
      >
        <FaCheck color="white" size={20} />
      </button>
    </div>
  </div>
)}



            <div style={{ padding: window.innerWidth > window.innerHeight ? "8px 0px" :"8px 16px"}}>

<div style={{ display: images.filter(image => image.src !== null).length === 0 ? "block" : 'grid', textAlign:"center", justifyContent:"center" ,gridTemplateColumns: window.innerWidth > window.innerHeight ? "repeat(auto-fill, minmax(200px, 1fr))" : "repeat(auto-fill, minmax(40%, 1fr))", columnGap:"16px", rowGap:"22px", height:"fit-content" }}>
{images.map((image, index) => {
  const key = image.id !== undefined ? image.id : `image_${index}`;
  const checked = index === 0;
  // Conditional rendering for image slot
  return (index === 0 || images[index - 1]?.src) && (
    <div key={key} style={{ width: window.innerWidth > window.innerHeight ? '200px' : "100%", height: '100%', position: 'relative', cursor:"pointer" }}>
      {!image.src && (
        <div style={{ width: '100%', height: '200px', position: 'relative', justifyContent: "center", textAlign:"center",display:"flex", cursor:"pointer"}}>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={onSelectFile(index)}
            max={10 - images.length}
            style={{ width: '100%', height: '100%', opacity: 0, position: 'absolute', zIndex: 1, cursor:"pointer" }}
            ref={el => (fileInputRefs.current[index] = el)}
          />
          <div onClick={() => fileInputRefs.current[index].click()}
            style={{ width: '200px', height: '200px' ,display: 'flex', backgroundColor:"#f7f7f7", justifyContent: 'center', alignItems: 'center', cursor: 'pointer', borderRadius:"8px", border:"1.5px SOLid #00C8B8" }}>
            <BiImageAdd style={{width:"45%", height:"45%", color:"#00C6B7", cursor:"pointer"}} />
          </div>
        </div>
      )}

      {image.src && (
        <>
          <img
            src={image.isCropped ? image.croppedImage : image.src}
            alt={`Image ${index}`}
            style={{ width: window.innerWidth > window.innerHeight ? "200px" : "100%", objectFit: 'cover', borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
          />
          <div style={{ justifyContent: "start", direction: "rtl", padding: "8px", backgroundColor: "#f7f7f7", borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px", alignItems: "center", display: "flex", width: "100%", marginTop: "-1px", borderBottom: "0.5px solid #ccc" }}>
            <button style={{ backgroundColor: "#F00057", border: "none", borderRadius: "8px", marginLeft: "4px", width: "20px", height: "20px" }} onClick={(e) => { e.stopPropagation(); onDelete(index); }}>
              <FaTrashCan size={11} style={{ marginBottom: "17px", color: "white", marginTop: "4px" }} />
            </button>
            <button style={{ backgroundColor: "#00C6B7", border: "none", borderRadius: "8px", marginLeft: "4px", width: "20px", height: "20px" }} onClick={(e) => { e.stopPropagation(); onRecrop(index); }}>
              <IoIosCrop size={14} style={{ marginBottom: "19px", color: "white", marginTop: "3px" }} />
            </button>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position:"relative" }}>
            <label style={{ marginLeft: "4px", color: "#00C8B8", display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={checked} // Set checked state based on index
          style={{
            appearance: "none", // Remove default checkbox styles
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            border: "1px solid #00C8B8",
            backgroundColor: checked ? "#00C8B8" : "transparent",
            marginRight: "4px",
            cursor: "pointer",
            lineHeight: "16px", // Center content vertically
            textAlign: "center", // Center content horizontally
            position: "relative", // Make checkbox a positioning context
          }}
          onChange={(e) => {
            // Handle checkbox change to move image data
            const newImages = [...images];
            moveImageToFirst(index, newImages); // Call the redefined function
          }}
        />
        <FaCheck
          style={{
            color: checked ? "white" : "transparent ",
            fontSize: "8px",
            padding: 0,
            margin: 0,
            position: "absolute", // Make FaCheck absolutely positioned
            top: "50%", // Position vertically centered within checkbox
            left: "50%", // Position horizontally centered within checkbox
            transform: "translate(-50%, -50%)", // Adjust position for centering
          }}
        />
      </label>
</div>
        الصورة الأساسية
          </div>
        </>
      )}
    </div>
  );
})}



            </div>

            </div>
        </div>
    );
}
export default ImageCropper;