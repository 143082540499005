import React , {useState, useEffect, useRef} from 'react'
import {collection ,getDoc,getCountFromServer ,doc, setDoc, Timestamp, serverTimestamp, addDoc } from 'firebase/firestore';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Oval } from 'react-loader-spinner'
import galleryPlaceholder from '../assets/galley-placeholder.jpg'
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { db, auth, storage } from '../config/firebase'
import { IoMdClose } from "react-icons/io";
import { MdInfo } from "react-icons/md";
import ImageCropper from '../components/ImageCropper';
import { Divider } from '@mui/material';
import DateTimeWithPlaceholder from '../components/DateTimeWithPlaceHolder';
import e from 'cors';
import { IoCloseSharp } from "react-icons/io5";
import LoadingScreen from './LoadingScreen';
import accounting from 'accounting';
import SuccessAnim from '../components/SuccessAnim';
import SelectableCategory from '../components/SelectableCategory';
import SelectableWatchCompany from '../components/SelectableWatchCompany';
import watch from '../assets/watches.png'
import sbhah from '../assets/sbhah.png'
import pen from '../assets/pen2.png'
import wallet from '../assets/wallets.png'
import plateIcon from '../assets/carPlateW.png'
import purses from '../assets/purses.png'
import rolexLogo from '../assets/RolexLogo.png'
import omegaLogo from '../assets/omega-logo.png'
import cartierLogo from '../assets/cartier-logo.png'
import versaceLogo from '../assets/Versace-Logo.png'
import montBlancLogo from '../assets/mont-blanc-logo.png'
import aignerLogo from '../assets/aigner-logo.png'
import aldoLogo from '../assets/aldo-logo.png'
import radoLogo from '../assets/rado-logo.png'
import givenchyLogo from '../assets/givenchy-logo.png'
import chopardLogo from '../assets/chopard-logo.png'
import tissotLogo from '../assets/tissot-logo.png'
import patekPhilippeLogo from '../assets/patek-philippe-logo.png'
import armaniExchangeLogo from '../assets/armani-exchange-logo.png'
import chanelLogo from '../assets/chanel-logo.png'
import lvLogo from '../assets/lv-logo.png'
import gucciLogo from '../assets/gucci-logo.png'
import DGLogo from '../assets/dg-logo.png'
import tomFordLogo from '../assets/tom-ford-logo.png'
import fredPerryLogo from '../assets/fred-perry-logo.png'
import pradaLogo from '../assets/prada-logo.png'
import balenciagaLogo from '../assets/balenciaga-logo.png'
import yslLogo from '../assets/YSL-logo.png'
import toryBurchLogo from '../assets/tory-borch-logo.png'
import coachLogo from '../assets/coach-logo.png'
import tedBakerLogo from '../assets/ted-baker-logo.png'
import charlesKeithLogo from '../assets/charles-and-keith-logo.png'
import carPlateWhite from '../assets/car-plate-empty.png'
import carPlateYellow from '../assets/car-plate-empty-yellow.png'
import carPlateBlue from '../assets/car-plate-empty-blue.png'
import guessLogo from '../assets/guess-logo.png'
import offWhiteLogo from '../assets/off-white-logo.png'
import michaelKorsLogo from '../assets/michael-kors-logo.png'
import appleLogo from '../assets/apple-logo.png'
import gShockLogo from '../assets/g-shock-logo.png'
import huaweiLogo from '../assets/huawei-logo.png'
import samsungLogo from '../assets/samsung-logo.png'
import xiaomiLogo from '../assets/xiaomi-logo.png'
import phoneNumberIcon from '../assets/phoneNumbers.png'
import diorLogo from '../assets/dior-logo.png'
import stcLogo from '../assets/stc-logo.png'
import mobilyLogo from '../assets/mobily-logo.png'
import zainLogo from '../assets/zain-logo.png'
import { useNavigate } from 'react-router-dom';
import { FaQuestion } from "react-icons/fa6";
import { IoMdMale } from "react-icons/io";
import { IoFemale } from "react-icons/io5";
import { FaTransgender } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa";
import { PiRectangleBold } from "react-icons/pi";
import { TbOval } from "react-icons/tb";
import { RiPentagonLine } from "react-icons/ri";
import { LuHexagon } from "react-icons/lu";
import { IoIosWatch } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import YearMade from '../components/YearMade';
import SelectableCondition from '../components/SelectableCondition';
import PostType from '../components/PostType';
import { BiSolidPurchaseTag } from "react-icons/bi";
import { PiGavelFill } from "react-icons/pi";
import { GiReceiveMoney } from "react-icons/gi";
import NextSevenDays from '../components/NextSevenDays';
import Datetime from 'react-datetime';
import moment from 'moment';
import "moment/locale/ar"; // Import Arabic locale
import "react-datetime/css/react-datetime.css";
import Product from '../components/Product';
import smsa from '../assets/smsa.png'
import { FaRegHandshake } from "react-icons/fa";
import rosaryIcon from '../assets/sebhah00C8B8.PNG'
import carPlateImageEmpty from '../assets/car-plate-empty.png'
import carPlateImageBlue from '../assets/car-plate-empty-blue.png'
import carPlateImageYellow from '../assets/car-plate-empty-yellow.png'
import CarPlate from '../components/CarPlate';
import { FaCarAlt } from "react-icons/fa";
import { FaMotorcycle } from "react-icons/fa6";
import { BsFillTaxiFrontFill } from "react-icons/bs";
import { FaTruckPickup } from "react-icons/fa6";
import { BsStopwatchFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import PostTypeSingle from '../components/PostTypeSingle';
import { Link } from 'react-router-dom';
import { RiShoppingBagLine } from "react-icons/ri";
import PhoneNumber from '../components/PhoneNumber';
import { FaShippingFast } from "react-icons/fa";
import { GoHomeFill, GoReport } from "react-icons/go";


function AddPostScreen() {
    const [postImages, setPostImages] = useState([]); // State to store the images for the post
    const [productName, setProductName] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productQty, setProductQty] = useState(1);
    const [productCondition, setProductCondition] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [productSubCategory, setProductSubCategory] = useState("");
    const [productExpiryDate, setProductExpiryDate] = useState("");
    const [productWeight, setProductWeight] = useState(0.5);
    const [passedDateSelected, setPassedDateSelected] = useState(false);
    const [passedOneWeekSelected, setPassedOneWeekSelected] = useState(false);
    const [startingPrice, setStartingPrice] = useState("");
    const [entryFee, setEntryFee] = useState("");
    const [bidIncrements, setBidIncrements] = useState("");
    const [gal, setGal] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [blobImgs, setBlobImgs] = useState([]);
    const [isAuction, setIsAuction] = useState(false);
    const navigate = useNavigate();

    const [postType, setPostType] = useState([]);

    const [addProductLoading, setAddProductLoading] = useState(false);

    const carPlateNumbersRef = useRef();

    const [productCompany, setProductCompany] = useState("")


    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);



    const [purseMaterial, setPurseMaterial] = useState("")
    const purseMaterialRef = useRef();
    const [watchInsideColor, setWatchInsideColor] = useState("")
    const [watchInsideColorSpecification, setWatchInsideColorSpecification] = useState("")

    const [watchOutsideColor, setWatchOutsideColor] = useState("")
    const [watchOutsideColorSpecification, setWatchOutsideColorSpecification] = useState("")

    const [watchDialSize, setWatchDialSize] = useState("")
    const [watchDialShape, setWatchDialShape] = useState("")
    const [watchNumbersColor, setWatchNumbersColor] = useState("")
    const [watchNumbersColorSpecification, setWatchNumbersColorSpecification] = useState("")
    const [watchHandlesColor, setWatchHandlesColor] = useState("")
    const [watchHandlesColorSpecification, setWatchHandlesColorSpecification] = useState("")
    const [watchNumbersLanguage, setWatchNumbersLanguage] = useState("")
    const [watchBandMaterial, setWatchBandMaterial] = useState("")
    const [watchBandColor, setWatchBandColor] = useState("")
    const [watchBandColorSpecification, setWatchBandColorSpecification] = useState("")
    const [watchHasOriginalPackaging, setWatchHasOriginalPackaging] = useState("")
    const [watchYearMade, setWatchYearMade] = useState("")
    const [watchIsWaterResistant, setWatchIsWaterResistant] = useState("")
    const [watchDepartment, setWatchDepartment] = useState("");

    const [carPlateTransferTime, setCarPlateTransferTime] = useState("")
    const [carPlateTransferFeeOn, setCarPlateTransferFeeOn] = useState("")
    const [carPlateKind, setCarPlateKind] = useState("")

    const [carPlateFirstArabicLetter, setCarPlateFirstArabicLetter] = useState("");
    const [carPlateSecondArabicLetter, setCarPlateSecondArabicLetter] = useState("");
    const [carPlateThirdArabicLetter, setCarPlateThirdArabicLetter] = useState("");
    const [carPlateFirstArabicNumber, setCarPlateFirstArabicNumber] = useState("");
    const [carPlateSecondArabicNumber, setCarPlateSecondArabicNumber] = useState("");
    const [carPlateThirdArabicNumber, setCarPlateThirdArabicNumber] = useState("");
    const [carPlateFourthArabicNumber, setCarPlateFourthArabicNumber] = useState("");

    const [carPlateFirstEnglishLetter, setCarPlateFirstEnglishLetter] = useState("");
    const [carPlateSecondEnglishLetter, setCarPlateSecondEnglishLetter] = useState("");
    const [carPlateThirdEnglishLetter, setCarPlateThirdEnglishLetter] = useState("");
    const [carPlateFirstEnglishNumber, setCarPlateFirstEnglishNumber] = useState("");
    const [carPlateSecondEnglishNumber, setCarPlateSecondEnglishNumber] = useState("");
    const [carPlateThirdEnglishNumber, setCarPlateThirdEnglishNumber] = useState("");
    const [carPlateFourthEnglishNumber, setCarPlateFourthEnglishNumber] = useState("");
    


    const [carPlateKindScrollTrigger, setCarPlateKindScrollTrigger] = useState(false)

    
    const [rosaryKind, setRosaryKind] = useState("")
    const [rosaryKindSpecification, setRosaryKindSpecification] = useState("")
    const [rosaryColor, setRosaryColor] = useState("")
    const [rosaryColorSpecification, setRosaryColorSpecification] = useState("")
    const [rosaryCount, setRosaryCount] = useState("")
    const [rosarySize, setRosarySize] = useState(0)
    const [rosaryWeight, setRosaryWeight] = useState(0)

    const [walletDepartment, setWalletDepartment] = useState("");
    
    const today = moment();
    const sixDaysAhead = moment().add(6, 'days');
  
    const isValidDate = (current) => {
      return current.isSameOrAfter(today, 'day') && current.isSameOrBefore(sixDaysAhead, 'day');
    };
  
    const customInputProps = {
      isValidDate: isValidDate,
      closeOnClickOutside: true,
      initialValue: today, // Set the initial value to today's date
      inputProps: {
        readOnly: true, // Disable keyboard input
        style: { outline: 'none', boxShadow: 'none', color: '#00C8B8', direction: 'rtl' } // Remove focus styles, set text color, and RTL
      },
    };
  
    // Set locale for moment.js and react-datetime to Arabic
    moment.locale('ar');
    
    
    const [phoneNumberCourier, setPhoneNumberCourier] = useState("");
    const [phoneNumberToSell, setPhoneNumberToSell] = useState("");
    
    
    const [purseOutsideColor, setPurseOutsideColor] = useState("");
    const [purseOutsideColorSpecification, setPurseOutsideColorSpecification] = useState("");
    const [purseInsideColor, setPurseInsideColor] = useState("");
    const [purseInsideColorSpecification, setPurseInsideColorSpecification] = useState("");
    // const [productMemorySize, setProductMemorySize] = useState("")
    // const [productScreenSize, setProductScreenSize] = useState("")

    const [productKind, setProductKind] = useState("");
    // const [productInkKind, setProductInkKind] = useState("");
    // const [productStorageSize, setProductStorageSize] = useState("");

    const [productImagesHasError, setProductImageHasError] = useState(false);
    const [productNameHasError, setProductNameHasError] = useState(false);
    const [productDescriptionHasError, setProductDescriptionHasError] = useState(false);
    const [productPriceHasError, setProductPriceHasError] = useState(false);
    const [productQtyHasError, setProductQtyHasError] = useState(false);
    const [entryFeeHasError, setEntryFeeHasError] = useState(false);
    const [productExpiryDateHasError, setProductExpiryDateHasError] = useState(false);
    const [productStartingPriceHasError, setProductStartingPriceHasError] = useState(false);
    const [productConditionHasError, setProductConditionHasError] = useState(false);
    const [productWeightHasError, setProductWeightHasError] = useState(false);
    const [productCategoryHasError, setProductCategoryHasError] = useState(false);
    const [productCompanyHasError, setProductCompanyHasError] = useState(false);
    const [bidIncrementHasError, setBidIncrementHasError] = useState(false);
    const [isAcutionHasError, setIsAuctionHasError] = useState(false);


    const [productCategoryScrollTrigger, setProductCategoryScrollTrigger] = useState(false);
    const [watchDepartmentScrollTrigger, setWatchDepartmentScrollTrigger] = useState(false);
    const [walletDepartmentScrollTrigger, setWalletDepartmentScrollTrigger] = useState(false);


    const [selectedDate, setSelectedDate] = useState(null);

    const handleSelectDay = (date) => {
        setSelectedDate(date);
        console.log("Selected Date:", selectedDate);
    };

    const purseCompanyRef = useRef();

    
    useEffect(() => {
      if (productCategoryScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        if(productCategory === "ساعات"){
          const watchDetailsDiv = document.getElementById('watch-details-div');
          if (watchDetailsDiv) {
            const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if(productCategory === "سبح"){
          const watchDetailsDiv = document.getElementById('watch-department-div');
          if (watchDetailsDiv) {
            const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if(productCategory === "اقلام"){
          const watchDetailsDiv = document.getElementById('watch-department-div');
          if (watchDetailsDiv) {
            const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if(productCategory === "ابواك"){
          const watchDetailsDiv = walletCompanyRef.current
          if (watchDetailsDiv) {
            const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if(productCategory === "لوح سيارات"){
          const watchDetailsDiv = carPlateKindRef.current;
          if (watchDetailsDiv) {
            const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if(productCategory === "حقائب نسائية"){
          const watchDetailsDiv = purseCompanyRef.current;
          if (watchDetailsDiv) {
            const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if(productCategory === "ارقام جوالات"){
          const watchDetailsDiv = phoneNumberCourierDivRef.current;
          if (watchDetailsDiv) {
            const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        setProductCategoryScrollTrigger(false);
      }
      setPostImages([])
    }, [productCategoryScrollTrigger]);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'instant' // Scroll behavior set to instant for instant scrolling
      });
    };
    

    const specifiedCompanies = ["Apple", "Huawei", "Xiaomi", "Samsung"];
    const isOtherCompany = !specifiedCompanies.includes(productCompany);
    

    useEffect(() => {
      if (watchDepartmentScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv = watchOutsideColorRef.current
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setWatchDepartmentScrollTrigger(false);
      }
    }, [watchDepartmentScrollTrigger]);
    
    const [phoneCourierScrollTrigger, setPhoneCourierScrollTrigger] = useState(false)
    const phoneNumberCourierDivRef = useRef()
    const phoneNumberToSellDivRef = useRef()
    useEffect(() => {
      if (phoneCourierScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv = phoneNumberToSellDivRef.current
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setPhoneCourierScrollTrigger(false);
      }
    }, [phoneCourierScrollTrigger]);


    const [phoneNumberToSellScrollTrigger, setPhoneNumberToSellScrollTrigger] = useState(false)
    useEffect(() => {
      if (phoneNumberToSellScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv = postTypeRef.current
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setPhoneNumberToSellScrollTrigger(false);
      }
    }, [phoneNumberToSellScrollTrigger]);

    const [carPlateNumbersScrollTrigger, setCarPlateNumbersScrollTrigger] = useState(false)
    useEffect(() => {
      if (carPlateNumbersScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv = postTypeRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setCarPlateNumbersScrollTrigger(false);
      }
    }, [carPlateNumbersScrollTrigger]);

    const [purseMaterialScrollTrigger, setPurseMaterialScrollTrigger] = useState(false)
    useEffect(() => {
      if (purseMaterialScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv = purseOutsideColorRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setPurseMaterialScrollTrigger(false);
      }
    }, [purseMaterialScrollTrigger]);
    
    const walletCompanyRef = useRef();

    useEffect(() => {
      if (walletDepartmentScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv =  walletColorRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setWalletDepartmentScrollTrigger(false);
      }
    }, [walletDepartmentScrollTrigger]);
    

    const [walletColor, setWalletColor] = useState("")
    const [walletColorScrollTrigger, setWalletColorScrollTrigger] = useState(false)
    

    useEffect(() => {
      if (walletColorScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv =  productConditionRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setWalletColorScrollTrigger(false);
      }
    }, [walletColorScrollTrigger]);

    const [carPlateTransferTimeScrollTrigger, setCarPlateTransferTimeScrollTrigger] = useState(false)

    useEffect(() => {
      if (carPlateTransferTimeScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDetailsDiv =  carPlateNumbersRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
        // Reset the trigger after scrolling
        setCarPlateTransferTimeScrollTrigger(false);
      }
    }, [carPlateTransferTimeScrollTrigger]);

    

const walletColorRef = useRef();

    const [productCompanyScrollTrigger, setProductCompanyScrollTrigger] = useState(false);

    useEffect(() => {
      
      if (productCompanyScrollTrigger) {
        if (
          productCategory === "ساعات"
        ) {
          if(productCompany !== "Apple" &&
          productCompany !== "Samsung" && 
          productCompany !== "Huawei" && 
          productCompany !== "Xiaomi"){

            const navbarHeight = 65; // Height of the navbar
            const watchDepartmentDiv = document.getElementById('watch-department-div');
            if (watchDepartmentDiv) {
              const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }
          else{
            const navbarHeight = 65; // Height of the navbar
            const watchDepartmentDiv = watchOutsideColorRef.current
            if (watchDepartmentDiv) {
              const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }

        }
        else if (
          productCategory === "اقلام"
        ) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productConditionRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if (
          productCategory === "ابواك"
        ) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = walletDepartmentRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else if (
          productCategory === "حقائب نسائية"
        ) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = purseMaterialRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        // Reset the trigger after scrolling
        setProductCompanyScrollTrigger(false);
      }
    }, [productCompanyScrollTrigger]);
    

    const [watchInsideColorScrollTrigger, setWatchInsideColorScrollTrigger] = useState(false);

    useEffect(() => {
      if (watchInsideColorScrollTrigger) {
        if (
          productCategory === "ساعات" && 
          productCompany !== "" &&
          productCompany !== "Apple" &&
          productCompany !== "Samsung" && 
          productCompany !== "Huawei" && 
          productCompany !== "Xiaomi" && 
          watchDepartment !== "" &&
          watchInsideColor !== ""
        ) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = watchDialShapeRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        // Reset the trigger after scrolling
        setWatchInsideColorScrollTrigger(false);
      }
    }, [watchInsideColorScrollTrigger]);


    const [postTypeScrollTrigger, setPostTypeScrollTrigger] = useState(false);

    useEffect(() => {
      if (postTypeScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productExpiryDateRef.current;
          const productPricingDiv = productPricingRef.current;
          if(postType.includes("مزاد")){
            if (watchDepartmentDiv) {
              const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }
          else{
            if (productPricingDiv) {
              const topOffset = productPricingDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }
        // Reset the trigger after scrolling
        setPostTypeScrollTrigger(false);
      }
    }, [postTypeScrollTrigger]);

    const rosaryColorDivRef = useRef();
    const rosaryCountDivRef = useRef();
    const rosarySizeDivRef = useRef();

    const [rosaryKindScrollTrigger, setRosaryKindScrollTrigger] = useState(false);
    
    useEffect(() => {
      if (rosaryKindScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = rosaryColorDivRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setRosaryKindScrollTrigger(false);
      }
    }, [rosaryKindScrollTrigger]);

    const [rosaryCountScrollTrigger, setRosaryCountScrollTrigger] = useState(false);
    useEffect(() => {
      if (rosaryCountScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = rosarySizeDivRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setRosaryCountScrollTrigger(false);
      }
    }, [rosaryCountScrollTrigger]);

    const [rosarySizeScrollTrigger, setRosarySizeScrollTrigger] = useState(false);
    useEffect(() => {
      if (rosarySizeScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productConditionRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setRosarySizeScrollTrigger(false);
      }
    }, [rosarySizeScrollTrigger]);

    const [rosaryColorScrollTrigger, setRosaryColorScrollTrigger] = useState(false);
    
    useEffect(() => {
      if (rosaryColorScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = rosaryCountDivRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setRosaryColorScrollTrigger(false);
      }
    }, [rosaryColorScrollTrigger]);
    
  const canvasRef = useRef(null);

    
    useEffect(() => {
      if (carPlateKindScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = carPlateTransferFeeOnRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setCarPlateKindScrollTrigger(false);
      }
    }, [carPlateKindScrollTrigger]);
    
    const [carPlateTransferFeeOnScrollTrigger, setCarPlateTransferFeeOnScrollTrigger] = useState(false)
    
    useEffect(() => {
      if (carPlateTransferFeeOnScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = carPlateTransferTimeRef.current;
        if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setCarPlateTransferFeeOnScrollTrigger(false);
      }
    }, [carPlateTransferFeeOnScrollTrigger]);


    const [productConditionScrollTrigger, setProductConditionScrollTrigger] = useState(false);
    const postTypeRef = useRef();
    useEffect(() => {
      if (productConditionScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = postTypeRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setProductConditionScrollTrigger(false);
      }
    }, [productConditionScrollTrigger]);
    

    const [watchOutsideColorScrollTrigger, setWatchOutsideColorScrollTrigger] = useState(false);

    useEffect(() => {
      if (watchOutsideColorScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = watchInsideColorRef.current;
        const watchDepartmentDivv = productConditionRef.current;
        if(productCompany !== "Apple" &&
        productCompany !== "Samsung" && 
        productCompany !== "Huawei" && 
        productCompany !== "Xiaomi"){
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else{
          if (watchDepartmentDivv) {
            const topOffset = watchDepartmentDivv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
          // Reset the trigger after scrolling
        }
        setWatchOutsideColorScrollTrigger(false);
      }
    }, [watchOutsideColorScrollTrigger]);
    
    const [watchBandMaterialScrollTrigger, setWatchBandMaterialScrollTrigger] = useState(false);


    useEffect(() => {
      if (watchBandMaterialScrollTrigger) {
        if (
          productCategory === "ساعات"
        ) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = watchHasOriginalPackagingRef.current;
          const watchBandColorDiv = watchBandColorRef.current;
    
          if (watchBandMaterial === "ستانلس ستيل") {
            if (watchDepartmentDiv) {
              const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          } else {
            if (watchBandColorDiv) {
              const topOffset = watchBandColorDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }
        }
        // Reset the trigger after scrolling
        setWatchBandMaterialScrollTrigger(false);
      }
    }, [watchBandMaterialScrollTrigger]);
    

    const handleSelectPostType = (categories) => {
      // Check if "عروض" should be removed when "شراء فوري" is not included
      if (!categories.includes("شراء فوري") && categories.includes("عروض")) {
        // Filter out "عروض" since "شراء فوري" is not selected
        categories = categories.filter(category => category !== "عروض");
        setProductPrice("")
      }
      
      if (!categories.includes("مزاد")) {
        setProductExpiryDate("")
        setEntryFee("")
        setStartingPrice("")
        setBidIncrements("")
      }
      
      // Update the state with the new categories
      setPostType(categories);
    };

    const handleSetPhoneNumber = (n) => {
      setPhoneNumberToSell(n);
    };
    const handleSetCarPlateFirstArabicLetter = (letter) => {
      setCarPlateFirstArabicLetter(letter);
    };
    const handleSetCarPlateFirstEnglishLetter = (letter) => {
      setCarPlateFirstEnglishLetter(letter);
    };
    const handleSetCarPlateSecondArabicLetter = (letter) => {
      setCarPlateSecondArabicLetter(letter);
    };
    const handleSetCarPlateSecondEnglishLetter = (letter) => {
      setCarPlateSecondEnglishLetter(letter);
    };
    const handleSetCarPlateThirdArabicLetter = (letter) => {
      setCarPlateThirdArabicLetter(letter);
    };
    const handleSetCarPlateThirdEnglishLetter = (letter) => {
      setCarPlateThirdEnglishLetter(letter);
    };
    const handleSetCarPlateFirstArabicNumber = (number) => {
      setCarPlateFirstArabicNumber(number);
    };
    const handleSetCarPlateFirstEnglishNumber = (number) => {
      setCarPlateFirstEnglishNumber(number);
    };
    const handleSetCarPlateSecondArabicNumber = (number) => {
      setCarPlateSecondArabicNumber(number);
    };
    const handleSetCarPlateSecondEnglishNumber = (number) => {
      setCarPlateSecondEnglishNumber(number);
    };
    const handleSetCarPlateThirdArabicNumber = (number) => {
      setCarPlateThirdArabicNumber(number);
    };
    const handleSetCarPlateThirdEnglishNumber = (number) => {
      setCarPlateThirdEnglishNumber(number);
    };
    const handleSetCarPlateFourthArabicNumber = (number) => {
      setCarPlateFourthArabicNumber(number);
    };
    const handleSetCarPlateFourthEnglishNumber = (number) => {
      setCarPlateFourthEnglishNumber(number);
    };
    const handleSetCarPlatePostImage = (image) => {
      setPostImages(image);
    };
    const handleSetPhoneNumberPostImage = (image) => {
      setPostImages(image);
    };

    const [shippingMethods, setShippingMethods] = useState([])
    
    const handleSelectShippingMethods = (categories) => {
      setShippingMethods(categories);
      
      // Check if "شحن" is included in categories
      if (!categories.includes("شحن")) {
        // Set productWeight to an empty string
        setProductWeight("");
      }
      else{
        setProductWeight(0.5)
      }
    };
    


      const [watchBandColorScrollTrigger, setWatchBandColorScrollTrigger] = useState(false);


    useEffect(() => {
      if (watchBandColorScrollTrigger) {
        if (
          productCategory === "ساعات" 
        ) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = watchHasOriginalPackagingRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        // Reset the trigger after scrolling
        setWatchBandColorScrollTrigger(false);
      }
    }, [watchBandColorScrollTrigger]);


    
    const [productExpiryDateScrollTrigger, setProductExpiryDateScrollTrigger] = useState(false);

    useEffect(() => {
      if (productExpiryDateScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productPricingRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setProductExpiryDateScrollTrigger(false);
      }
    }, [productExpiryDateScrollTrigger]);

    const [productPricingScrollTrigger, setProductPricingScrollTrigger] = useState(false);

    useEffect(() => {
      if (productPricingScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productQtyDivRef.current;
          const productInfoDiv = productInfoRef.current;

          if(productCategory === "لوح سيارات" || productCategory === "ارقام جوالات"){
            if (productInfoDiv) {
              const topOffset = productInfoDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          } else{
            if (watchDepartmentDiv) {
              const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }

        // Reset the trigger after scrolling
        setProductPricingScrollTrigger(false);
      }
    }, [productPricingScrollTrigger]);

    const [productQtyScrollTrigger, setProductQtyScrollTrigger] = useState(false);
    const productQtyDivRef = useRef(null);

    useEffect(() => {
      if (productQtyScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productImagesRef.current;
          const productInfoDiv = productInfoRef.current;

          if(productCategory === "لوح سيارات" || productCategory === "ارقام جوالات"){
            if (productInfoDiv) {
              const topOffset = productInfoDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          } else{
            if (watchDepartmentDiv) {
              const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }

        // Reset the trigger after scrolling
        setProductQtyScrollTrigger(false);
      }
    }, [productQtyScrollTrigger]);
    

    const productInfoRef = useRef();

    const [productImagesScrollTrigger, setProductImagesScrollTrigger] = useState(false);

    useEffect(() => {
      if (productImagesScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productInfoRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setProductImagesScrollTrigger(false);
      }
    }, [productImagesScrollTrigger]);

    const [purseOutsideColorScrollTrigger, setPurseOutsideColorScrollTrigger] = useState(false);

    useEffect(() => {
      if (purseOutsideColorScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = purseInsideColorRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setPurseOutsideColorScrollTrigger(false);
      }
    }, [purseOutsideColorScrollTrigger]);


    const [purseInsideScrollTrigger, setPurseInsideColorScrollTrigger] = useState(false);

    
    useEffect(() => {
      if (purseInsideScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productConditionRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setPurseInsideColorScrollTrigger(false);
      }
    }, [purseInsideScrollTrigger]);

    const [productInfoScrollTrigger, setProductInfoScrollTrigger] = useState(false);

    useEffect(() => {
      if (productInfoScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = shippingRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setProductInfoScrollTrigger(false);
      }
    }, [productInfoScrollTrigger]);

    const [productToCategoryScrollTrigger, setProductToCategoryScrollTrigger] = useState(false);

    const productCategoryDivRef = useRef();
    useEffect(() => {
      if (productToCategoryScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productCategoryDivRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setProductToCategoryScrollTrigger(false);
      }
    }, [productToCategoryScrollTrigger]);
    
    const shippingRef = useRef();

    const [shippingScrollTrigger, setShippingScrollTrigger] = useState(false);

    useEffect(() => {
      if (shippingScrollTrigger) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productInfoRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        // Reset the trigger after scrolling
        setShippingScrollTrigger(false);
      }
    }, [shippingScrollTrigger]);
    


    const [watchYearMadeScrollTrigger, setWatchYearMadeScrollTrigger] = useState(false);


    useEffect(() => {
      if (watchYearMadeScrollTrigger) {
        if (
          productCategory === "ساعات" 
        ) {
          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = productConditionRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        // Reset the trigger after scrolling
        setWatchYearMadeScrollTrigger(false);
      }
    }, [watchYearMadeScrollTrigger]);
    

const watchDialShapeRef = useRef();



const [watchHandlesColorScrollTrigger, setWatchHandlesColorScrollTrigger] = useState(false);

useEffect(() => {
  if (watchHandlesColorScrollTrigger) {
    if (
      productCategory === "ساعات"
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchNumbersColorRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchHandlesColorScrollTrigger(false);
  }
}, [watchHandlesColorScrollTrigger]);



const [watchHasOriginalPackagingScrollTrigger, setWatchHasOriginalPackagingScrollTrigger] = useState(false);


useEffect(() => {
  if (watchHasOriginalPackagingScrollTrigger) {
    if (productCategory === "ساعات") {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchIsWaterResistantRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchHasOriginalPackagingScrollTrigger(false);
  }
}, [watchHasOriginalPackagingScrollTrigger]);



const [watchIsWaterResistantScrollTrigger, setWatchIsWaterResistantScrollTrigger] = useState(false);


useEffect(() => {
  if (watchIsWaterResistantScrollTrigger) {
    if (productCategory === "ساعات") {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchYearMadeRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchIsWaterResistantScrollTrigger(false);
  }
}, [watchIsWaterResistantScrollTrigger]);



const [watchDialShapeScrollTrigger, setWatchDialShapeScrollTrigger] = useState(false);

useEffect(() => {
  if (watchDialShapeScrollTrigger) {
    if (
      productCategory === "ساعات"
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchDialSizeRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchDialShapeScrollTrigger(false);
  }
}, [watchDialShapeScrollTrigger]);






const [watchNumbersColorScrollTrigger, setWatchNumbersColorScrollTrigger] = useState(false);

useEffect(() => {
  if (watchNumbersColorScrollTrigger) {
    if (
      productCategory === "ساعات" && 
      productCompany !== "" &&
      productCompany !== "Apple" &&
      productCompany !== "Samsung" && 
      productCompany !== "Huawei" && 
      productCompany !== "Xiaomi"  
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchNumbersLanguageRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchNumbersColorScrollTrigger(false);
  }
}, [watchNumbersColorScrollTrigger]);


    
const [watchNumbersLanguageScrollTrigger, setWatchNumbersLanguageScrollTrigger] = useState(false);


useEffect(() => {
  if (watchNumbersLanguageScrollTrigger) {
    if (
      productCategory === "ساعات" && 
      productCompany !== "" &&
      productCompany !== "Apple" &&
      productCompany !== "Samsung" && 
      productCompany !== "Huawei" && 
      productCompany !== "Xiaomi"  
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchBandMaterialRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchNumbersLanguageScrollTrigger(false);
  }
}, [watchNumbersLanguageScrollTrigger]);


    

const [watchDialSizeScrollTrigger, setWatchDialSizeScrollTrigger] = useState(false);

useEffect(() => {
  if (watchDialSizeScrollTrigger) {
    if (
      productCategory === "ساعات"
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchHandlesColorRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchDialSizeScrollTrigger(false);
  }
}, [watchDialSizeScrollTrigger]);


    

    const [watchDepartmentHasError, setWatchDepartmentHasError] = useState(false);
    const [watchInsideColorHasError, setWatchInsideColorHasError] = useState(false);
    const [watchInsideColorSpecificationHasError, setWatchInsideColorSpecificationHasError] = useState(false);
    const [watchOutsideColorHasError, setWatchOutsideColorHasError] = useState(false);
    const [watchOutsideColorSpecificationHasError, setWatchOutsideColorSpecificationHasError] = useState(false);
    
    const [carPlateTransferTimeHasError, setCarPlateTransferTimeHasError] = useState(false);
    const [carPlateTransferFeeOnHasError, setCarPlateTransferFeeOnHasError] = useState(false);
    const [carPlateKindHasError, setCarPlateKindHasError] = useState(false);
    const [carPlateFirstArabicLetterHasError, setCarPlateFirstArabicLetterHasError] = useState(false);
    const [carPlateFirstArabicNumberHasError, setCarPlateFirstArabicNumberHasError] = useState(false);
    const [carPlateFirstEnglishLetterHasError, setCarPlateFirstEnglishLetterHasError] = useState(false);
    const [carPlateFirstEnglishNumberHasError, setCarPlateFirstEnglishNumberHasError] = useState(false);

    const [successAnimVisible, setSuccessAnimVisible] = useState(false);

    const [rosaryKindHasError, setRosaryKindHasError] = useState(false);
    const [rosaryKindSpecificationHasError, setRosaryKindSpecificationHasError] = useState(false);
    const [rosaryColorHasError, setRosaryColorHasError] = useState(false);
    const [rosaryColorSpecificationHasError, setRosaryColorSpecificationHasError] = useState(false);
    const [rosaryCountHasError, setRosaryCountHasError] = useState(false);
    const [rosarySizeHasError, setRosarySizeHasError] = useState(false);
    
    
    const [walletDepartmentHasError, setWalletDepartmentHasError] = useState(false);
    
    const [phoneNumberCourierHasError, setPhoneNumberCourierHasError] = useState(false);
    const [phoneNumberToSellHasError, setPhoneNumberToSellHasError] = useState(false);
    
    const [purseOutsideColorHasError, setPurseOutsideColorHasError] = useState(false);
    const [purseOutsideColorSpecificationHasError, setPurseOutsideColorSpecificationHasError] = useState(false);
    const [purseInsideColorHasError, setPurseInsideColorHasError] = useState(false);
    const [purseInsideColorSpecificationHasError, setPurseInsideColorSpecificationHasError] = useState(false);
    
    const [showSuccessAnim, setShowSuccessAnim] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

  
    const handleAnimationComplete = () => {
      setShowSuccessAnim(false); // Reset button state after animation completes
    };

    // const [productSubCategoryHasError, setProductSubCategoryHasError] = useState(false);
    // const [productCompanyHasError, setProductCompanyHasError] = useState(false);
    // const [productKindHasError, setProductKindHasError] = useState(false);
    // const [productMemorySizeHasError, setProductMemorySizeHasError] = useState(false);
    // const [productStorageSizeHasError, setProductStorageSizeHasError] = useState(false);
    // const [productScreenSizeHasError, setProductScreenSizeHasError] = useState(false);
    // const [productInkKindHasError, setProductInkKindHasError] = useState(false);

    const productNameRef = useRef();
    const productDescriptionRef = useRef();
    const productPriceRef = useRef();
    const productQtyRef = useRef();
    const productWeightRef = useRef();
    const startingPriceRef = useRef();
    const entryFeeRef = useRef();
    const productConditionRef = useRef();
    const productCategoryRef = useRef();
    const productSubCategoryRef = useRef();
    const bidIncrementRef = useRef();

    const productCompanyRef = useRef();
    const productKindRef = useRef();

    
    const watchDepartmentRef = useRef();
    const watchInsideColorRef = useRef();
    const watchInsideColorSpecificationRef = useRef();

    const watchOutsideColorRef = useRef();
    const watchOutsideColorSpecificationRef = useRef();
    const watchDialSizeRef = useRef();
    const watchNumbersColorRef = useRef();
    const watchNumbersColorSpecificationRef = useRef();
    const watchHandlesColorRef = useRef();
    const watchHandlesSpecificationRef = useRef();
    const watchNumbersLanguageRef = useRef();
    const watchBandMaterialRef = useRef();
    const watchBandColorRef = useRef();
    const watchBandColorSpecificationRef = useRef();
    const watchHasOriginalPackagingRef = useRef();
    const watchYearMadeRef= useRef();
    const watchIsWaterResistantRef= useRef();

    const carPlateTransferTimeRef = useRef();
    const carPlateTransferFeeOnRef = useRef();
    const carPlateKindRef = useRef();
    const productPricingRef = useRef();

    const carPlateFirstArabicLetterRef = useRef();
    const carPlateSecondArabicLetterRef = useRef();
    const carPlateThirdArabicLetterRef = useRef();
    const carPlateFirstArabicNumberRef = useRef();
    const carPlateSecondArabicNumberRef = useRef();
    const carPlateThirdArabicNumberRef = useRef();
    const carPlateFourthArabicNumberRef = useRef();
    const carPlateFirstEnglishLetterRef = useRef();
    const carPlateSecondEnglishLetterRef = useRef();
    const carPlateThirdEnglishLetterRef = useRef();
    const carPlateFirstEnglishNumberRef = useRef();
    const carPlateSecondEnglishNumberRef = useRef();
    const carPlateThirdEnglishNumberRef = useRef();
    const carPlateFourthEnglishNumberRef = useRef();

    const rosaryKindRef = useRef();
    const rosaryKindSpecificationRef = useRef();
    const rosaryColorRef = useRef();
    const rosaryColorSpecificationRef = useRef();
    const rosaryCountRef = useRef();
    const rosarySizeRef = useRef();

    const walletDepartmentRef = useRef();
    
    
    const phoneNumberCourierRef = useRef();
    const phoneNumberToSellRef = useRef();

    const purseOutsideColorRef = useRef();
    const purseOutsideColorSpecificationRef = useRef();
    const purseInsideColorRef = useRef();
    const purseInsideColorSpecificationRef = useRef();


    // const productInkKindRef = useRef();
    const productStorageSizeRef = useRef();
    // const productMemorySizeRef = useRef();
    const productExpiryDateRef = useRef();
    const productImagesRef = useRef();
    const isAuctionRef = useRef();
    const productScreenSizeRef = useRef();

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [successText, setSuccessText] = useState("")

    const formattedPrice = accounting.formatMoney(productPrice, {
      symbol: '', // No currency symbol
      thousand: ',', // Thousand separator
      precision: 0, // No decimal places
    })

    // Function to get the current date and time in the required format
    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
  };


    const handleImagesChange = (newImages) => {
      setProductImageHasError(false);
      setPostImages(newImages);
    };

    const handleAddProduct = async () => {

      if(
        // main fields
        (postImages.length < 3 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") ||
        productName.length < 1 ||
        // productDescription.length < 50 ||
        postType.length === 0 ||
        (postType.includes("شراء فوري") && productPrice === "") ||
        // productQty === "" ||
        productQty <= 0 ||
        (shippingMethods.length === 0 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") || 
        (shippingMethods.includes("شحن") && (productWeight === "" || isNaN(productWeight))) ||
        (postType.includes("مزاد") && productExpiryDate === "") ||
        (postType.includes("مزاد") && (postType.includes("شراء فوري") && (parseFloat(startingPrice) >= parseFloat(productPrice)))) ||
        (postType.includes("مزاد") && (bidIncrements < 10 || isNaN(bidIncrements) || bidIncrements === "")) ||
        (postType.includes("مزاد") && (startingPrice < 10 || isNaN(startingPrice) || startingPrice === "")) ||
        (productCondition === "" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") ||
        productCategory === "" ||

        // specific fields
        (productCategory === "لوح سيارات" &&( 
        carPlateFirstArabicLetter === "" || carPlateFirstArabicNumber === "" ||
        carPlateTransferTime === "" ||
        carPlateKind === "" ||
        carPlateTransferFeeOn === ""
        )) ||


        (productCategory === "ساعات" &&(
          (watchDepartment === "" && (productCompany !== "Apple" &&
          productCompany !== "Samsung" && 
          productCompany !== "Huawei" && 
          productCompany !== "Xiaomi")) ||
        productCompany === "" ||
        (watchInsideColor === "" && (productCompany !== "Apple" &&
        productCompany !== "Samsung" && 
        productCompany !== "Huawei" && 
        productCompany !== "Xiaomi"))||
        watchOutsideColor === "" 
        )) ||


        (productCategory === "سبح" &&(
        rosaryKind === "" ||
        rosaryColor === "" ||
        rosaryCount === ""
        )) ||


        (productCategory === "اقلام" &&(
        productCompany === ""
        )) ||


        (productCategory === "ابواك" &&(
        walletDepartment === "" ||
        productCompany === ""
        )) ||



        (productCategory === "ارقام جوالات" && (
        phoneNumberCourier === "" || phoneNumberToSell === "" ||
        phoneNumberToSell.length < 10
        )) ||

        (productCategory === "حقائب نسائية" && (
        productCompany === "" || purseOutsideColor === ""
        // (purseOutsideColor === "آخر" && purseOutsideColorSpecification === "") || 
        // purseInsideColor === "" ||
        // (purseInsideColor === "آخر" && purseInsideColorSpecification === "")
        ))

         ){

          // main fields
          if(productCategory === "") {setProductCategoryHasError(true); console.log("setProductCategoryHasError"); alert("يجب ادخال تصنيف المنتج"); setProductToCategoryScrollTrigger(true); return;}

          // if(productDescription.length < 50) {setProductDescriptionHasError(true); console.log("setProductDescriptionHasError")} 
          // if(productQty === "") {setProductQtyHasError(true); console.log("setProductQtyHasError")}
          
          if(productQty <= 0) {setProductQtyHasError(true); console.log("setProductQtyHasError"); setProductPricingScrollTrigger(true)}
          
          // entry fee
          if(postType.includes("مزاد") && (isNaN(entryFee) || entryFee === "")) {setEntryFeeHasError(true); console.log("setEntryFeeHasError"); alert("يرجى ادخال مبلغ عربون المزاد"); setProductExpiryDateScrollTrigger(true);  return;}
          if(postType.includes("مزاد") && entryFee < 10) {setEntryFeeHasError(true); console.log("setEntryFeeHasError"); alert("اقل مبلغ للعربون هو 10 ريال"); setProductExpiryDateScrollTrigger(true);  return;}
          
          // starting price
          if(postType.includes("مزاد") && (isNaN(startingPrice) || startingPrice === "")) {setProductStartingPriceHasError(true); console.log("setProductStartingPriceHasError"); alert("يرجى ادخال مبلغ افتتاح المزاد"); setProductExpiryDateScrollTrigger(true); return;}
          if(postType.includes("مزاد") && startingPrice < 10) {setProductStartingPriceHasError(true); console.log("setProductStartingPriceHasError"); alert("اقل مبلغ لافتتاح المزاد هو 10 ريال"); setProductExpiryDateScrollTrigger(true); return;}
          
          // bid increments
          if(postType.includes("مزاد") && (isNaN(bidIncrements) || bidIncrements === "")) {setBidIncrementHasError(true); console.log("setBidIncrementHasError"); alert("يرجى ادخال مبلغ مضاعفات المزايدة"); setProductExpiryDateScrollTrigger(true); return;}
          if(postType.includes("مزاد") && bidIncrements < 10) {setBidIncrementHasError(true); console.log("setBidIncrementHasError"); alert("اقل مبلغ لمضاعفات المزايدة هو 10 ريال"); setProductExpiryDateScrollTrigger(true); return;}
          
          if((postType.includes("مزاد") && (postType.includes("شراء فوري") && (parseInt(entryFee) >= parseFloat(productPrice))))){
            setProductPriceHasError(true);
            setEntryFeeHasError(true);
            console.log("setProductPriceHasError")
            console.log("setEntryFeeHasError")
            setProductExpiryDateScrollTrigger(true);
            alert("مبلغ عربون دخول المزاد يجب ان يكون اقل من سعر الشراء الفوري");
            return;
          }
          if((postType.includes("مزاد") && (postType.includes("شراء فوري") && (parseInt(startingPrice) >= parseFloat(productPrice))))){
            setProductPriceHasError(true);
            setProductStartingPriceHasError(true);
            console.log("setProductPriceHasError")
            console.log("setProductStartingPriceHasError")
            alert("مبلغ افتتاح المزاد يجب ان يكون اقل من سعر الشراء الفوري");
            setProductExpiryDateScrollTrigger(true);
            return;
          }
          
          
        
          
          // specific fields
          if(productCategory === "لوح سيارات"){
            if(carPlateKind === "") { setCarPlateKindHasError(true); alert("يجب تحديد نوع اللوحة"); setProductCategoryScrollTrigger(true); return;}
            if(carPlateTransferFeeOn === "") { setCarPlateTransferFeeOnHasError(true) ; alert("يجب تحديد من سيدفع تكلفة نقل ملكية اللوحة"); setCarPlateKindScrollTrigger(true); return;}
            if(carPlateTransferTime === "") { setCarPlateTransferTimeHasError(true); alert("يجب تحديد فترة نقل الملكية"); setCarPlateTransferFeeOnScrollTrigger(true); return;}
            if(carPlateFirstArabicLetter === "")  {setCarPlateFirstArabicLetterHasError(true); alert("يجب ادخال اول حرف من حروف اللوحة"); setCarPlateTransferTimeScrollTrigger(true); return;}
            if(carPlateFirstArabicNumber === "")  {setCarPlateFirstArabicNumberHasError(true); alert("يجب ادخال اول رقم من ارقام اللوحة"); setCarPlateTransferTimeScrollTrigger(true); return;}
          } 
          
          else if(productCategory === "ساعات"){
            if(productCompany === "") {setProductCompanyHasError(true); console.log("setProductCompanyHasError"); alert("يجب تحدبد الشركة المصنعة للساعة"); setWatchDepartmentScrollTrigger(true); return;}
            if((watchDepartment === "" && (productCompany !== "Apple" &&
            productCompany !== "Samsung" && 
            productCompany !== "Huawei" && 
            productCompany !== "Xiaomi"))) {setWatchDepartmentHasError(true); console.log("setWatchDepartmentHasError"); alert("يجب تحديد قسم الساعات"); setProductCategoryScrollTrigger(true); return;}
            if((watchInsideColor === "" && (productCompany !== "Apple" &&
            productCompany !== "Samsung" && 
            productCompany !== "Huawei" && 
            productCompany !== "Xiaomi"))) {setWatchInsideColorHasError(true); console.log("setWatchInsideColorHasError"); alert("يجب تحديد لون قرص الساعة الداخلي"); setProductCompanyScrollTrigger(true); return;}
            if(watchOutsideColor === "" ) {setWatchOutsideColorHasError(true); console.log("setWatchOutsideColorHasError"); alert("يجب تحديد لون قرص الساعة الخارجي"); setWatchInsideColorScrollTrigger(true); return}
          }

          else if(productCategory === "سبح"){
            if(rosaryKind === "") {setRosaryKindHasError(true); alert("يجب تحديد نوع الخرز"); setProductCategoryScrollTrigger(true); return;}
            if(rosaryColor === "") {setRosaryColorHasError(true); alert("يجب تحديد لون الخرز"); setRosaryKindScrollTrigger(true); return;}
            if(rosaryCount === "") {setRosaryCountHasError(true); alert("يجب تحديد عدد الخرز"); setRosaryColorScrollTrigger(true); return;}
          }


          else if(productCategory === "اقلام"){
            if(productCompany === "") {setProductCompanyHasError(true); alert("يجب تحديد الشركة المصنعة للقلم"); setProductCategoryScrollTrigger(true); return;}
          }


          else if(productCategory === "ابواك"){
            if(walletDepartment === "") {setWalletDepartmentHasError(true); alert("يجب تحديد قسم الأبواك"); setProductCategoryScrollTrigger(true); return;}
            if(productCompany === "") {setProductCompanyHasError(true); alert("يجب تحديد الشركة المصنعة للبوك"); setWalletDepartmentScrollTrigger(true); return;}
            if(walletColor === "") {alert("يجب تحديد اللون الخارجي للبوك"); setProductCompanyScrollTrigger(true); return;}
          }

          else if(productCategory === "ارقام جوالات"){
            if(phoneNumberCourier === "") {setPhoneNumberCourierHasError(true); alert("يجب تحديد مشغل الخدمة"); setProductCategoryScrollTrigger(true); return;}
            if(phoneNumberToSell === "") {setPhoneNumberToSellHasError(true); console.log("setPhoneNumberToSellHasError"); alert("يجب ادخال رقم الجوال"); setPhoneCourierScrollTrigger(true); return;}
            if(phoneNumberToSell.length < 10) {setPhoneNumberToSellHasError(true); console.log("setPhoneNumberToSellHasError"); alert("يجب ان يتكون رقم الجوال من 10 ارقام و يبدأ بـ05"); setPhoneCourierScrollTrigger(true); return;}
          }

          else if(productCategory === "حقائب نسائية"){

        if(productCompany === "") {setProductCompanyHasError(true); alert("يجب تحديد الشركة المصنعة للحقيبة"); setProductCategoryScrollTrigger(true); return;}
        if(purseMaterial === "") {alert("يجب تحديد المادة المصنوع منها الحقيبة"); setProductCompanyScrollTrigger(true); return;}
        if(purseOutsideColor === "") {setPurseOutsideColorHasError(true); alert("يجب تحديد اللون الخارجي للحقيبة"); setPurseMaterialScrollTrigger(true); return;}
        // if(purseOutsideColor === "آخر" && purseOutsideColorSpecification === "") setPurseOutsideColorSpecificationHasError(true);
        // if(purseInsideColor === "آخر" && purseInsideColorSpecification === "") setPurseInsideColorSpecificationHasError(true);               
        
        }
        if(productCondition === "" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" ) {setProductConditionHasError(true); console.log("setProductConditionHasError"); alert("يجب ادخال حالة المنتج");
        if(productCategory === "ساعات") setWatchYearMadeScrollTrigger(true);
        else if(productCategory === "سبح") setRosarySizeScrollTrigger(true)
          else if(productCategory === "اقلام") setProductCompanyScrollTrigger(true)
        else if(productCategory === "ابواك") setWalletColorScrollTrigger(true)
      else if(productCategory === "حقائب نسائية") setPurseInsideColorScrollTrigger(true)
    return;
}
if(postType.length === 0) {alert("يجب تحديد طريقة عرض المنتج"); setProductConditionScrollTrigger(true); return;}
if(postType.includes("شراء فوري") && productPrice === "") {setProductPriceHasError(true); console.log("setProductPriceHasError"); alert("يجب تحديد سعر المنتج"); if(!postType.includes("مزاد")){setPostTypeScrollTrigger(true);} else{setProductExpiryDateScrollTrigger(true)} return;}
if((postImages.length < 3 || postImages.length > 10) && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") {setProductImageHasError(true); console.log("setProductImageHasError"); alert("يرجى اضافة من 3 الى 10 صور"); setProductPricingScrollTrigger(true); return}
if(productName.length < 1) {setProductNameHasError(true); console.log("setProductNameHasError"); alert("يرجى كتابة اسم للمنتج"); setProductImagesScrollTrigger(true); return;}


        
          
        if(shippingMethods.length === 0 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") {console.log("setProductShippingMethodsHasError"); alert("يرجى تحديد طريقة التوصيل"); setProductInfoScrollTrigger(true); return;}
          
        if(shippingMethods.includes("شحن") && (productWeight === "" || isNaN(productWeight))) {setProductWeightHasError(true); console.log("setProductWeightHasError"); alert("يرجى ادخال وزن المنتج"); setProductInfoScrollTrigger(true); return;}

          return;
      }

      setAddProductLoading(true)

const keywords = [];
const productCon = productCondition.trim();

if(productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات"){
  keywords.push(productCon)
}

// only if applicable
const companyStr = productCompany.trim().split(" ");
const productNameStr = productName.trim().split(" ");
const descriptionStr = productDescription.trim().split(/\s+/);
//#region 
// const subCategoryStr = productSubCategory.trim().split(" ");

for(var i = 0 ; i < productNameStr.length; i ++){
if(!keywords.includes(productNameStr[i])){
keywords.push(productNameStr[i].trim())
}
}


if(productCategory === "ساعات" || productCategory === "اقلام" || productCategory === "ابواك" || productCategory === "حقائب نسائية" ){

  for(var i = 0 ; i < companyStr.length; i ++){
    if(!keywords.includes(companyStr[i])){
    keywords.push(companyStr[i].trim())
    }
  }

  if (productCompany === "Aigner" && !keywords.includes("آغنر")) {
    keywords.push("آغنر");
  }
  if (productCompany === "Aldo" && !keywords.includes("آلدو")) {
    keywords.push("آلدو");
  }
  if (productCompany === "Aldo" && !keywords.includes("ألدو")) {
    keywords.push("ألدو");
  }
  if (productCompany === "Aldo" && !keywords.includes("الدو")) {
    keywords.push("الدو");
  }
  if (productCompany === "Cartier" && !keywords.includes("كارتييه")) {
    keywords.push("كارتييه");
  }
  if (productCompany === "Cartier" && !keywords.includes("كارتيير")) {
    keywords.push("كارتيير");
  }
  if (productCompany === "Cartier" && !keywords.includes("كارتير")) {
    keywords.push("كارتير");
  }
  if (productCompany === "Chanel" && !keywords.includes("شانيل")) {
    keywords.push("شانيل");
  }
  if (productCompany === "Chopard" && !keywords.includes("شوبارد")) {
    keywords.push("شوبارد");
  }
  if (productCompany === "Dior" && !keywords.includes("ديور")) {
    keywords.push("ديور");
  }
  if (productCompany === "Dolce & Gabbana" && !keywords.includes("دولتشي اند غابانا")) {
    keywords.push("دولتشي اند غابانا");
  }
  if (productCompany === "Dolce & Gabbana" && !keywords.includes("دولتشي اند قابانا")) {
    keywords.push("دولتشي اند قابانا");
  }
  if (productCompany === "Fred Perry" && !keywords.includes("فريد بيري")) {
    keywords.push("فريد بيري");
  }
  if (productCompany === "Givenchy" && !keywords.includes("جيفينشي")) {
    keywords.push("جيفينشي");
  }
  if (productCompany === "Gucci" && !keywords.includes("قوتشي")) {
    keywords.push("قوتشي");
  }
  if (productCompany === "Louis Vuitton" && !keywords.includes("لويس فيتون")) {
    keywords.push("لويس فيتون");
  }
  if (productCompany === "Louis Vuitton" && !keywords.includes("لوي فيتون")) {
    keywords.push("لوي فيتون");
  }
  if (productCompany === "Montblanc" && !keywords.includes("مونت بلانك")) {
    keywords.push("مونت بلانك");
  }
  if (productCompany === "Montblanc" && !keywords.includes("مون بلان")) {
    keywords.push("مون بلان");
  }
  if (productCompany === "Omega" && !keywords.includes("أوميغا")) {
    keywords.push("أوميغا");
  }
  if (productCompany === "Omega" && !keywords.includes("اوميغا")) {
    keywords.push("اوميغا");
  }
  if (productCompany === "Rolex" && !keywords.includes("رولكس")) {
    keywords.push("رولكس");
  }
  if (productCompany === "Saint Laurent" && !keywords.includes("سان لوران")) {
    keywords.push("سان لوران");
  }
  if (productCompany === "Saint Laurent" && !keywords.includes("سانت لوران")) {
    keywords.push("سانت لوران");
  }
  if (productCompany === "Tom Ford" && !keywords.includes("توم فورد")) {
    keywords.push("توم فورد");
  }
  if (productCompany === "Tory Burch" && !keywords.includes("توري بورش")) {
    keywords.push("توري بورش");
  }
  if (productCompany === "Versace" && !keywords.includes("فيرزاتشي")) {
    keywords.push("فيرزاتشي");
  }
  if (productCompany === "Rado" && !keywords.includes("رادو")) {
    keywords.push("رادو");
  }
  if (productCompany === "Tissot" && !keywords.includes("تيسوت")) {
    keywords.push("تيسوت");
  }
  if (productCompany === "Tissot" && !keywords.includes("تيسو")) {
    keywords.push("تيسو");
  }
  if (productCompany === "Patek Philippe" && !keywords.includes("باتيك فيليب")) {
    keywords.push("باتيك فيليب");
  }
  if (productCompany === "Armani Exchange" && !keywords.includes("آرماني اكستشينج")) {
    keywords.push("آرماني اكستشينج");
  }
  if (productCompany === "Armani Exchange" && !keywords.includes("أرماني اكستشينج")) {
    keywords.push("أرماني اكستشينج");
  }
  if (productCompany === "Armani Exchange" && !keywords.includes("ارماني اكستشينج")) {
    keywords.push("ارماني اكستشينج");
  }
  if (productCompany === "Armani Exchange" && !keywords.includes("آرماني")) {
    keywords.push("آرماني");
  }
  if (productCompany === "Armani Exchange" && !keywords.includes("أرماني")) {
    keywords.push("أرماني");
  }
  if (productCompany === "Armani Exchange" && !keywords.includes("ارماني")) {
    keywords.push("ارماني");
  }
  if (productCompany === "G-Shock" && !keywords.includes("جي شوك")) {
    keywords.push("جي شوك");
  }
  if (productCompany === "Apple" && !keywords.includes("أبل")) {
    keywords.push("أبل");
  }
  if (productCompany === "Apple" && !keywords.includes("ابل")) {
    keywords.push("ابل");
  }
  if (productCompany === "Huawei" && !keywords.includes("Huawei")) {
    keywords.push("Huawei");
  }
  if (productCompany === "Samsung" && !keywords.includes("Samsung")) {
    keywords.push("Samsung");
  }
  if (productCompany === "Samsung" && !keywords.includes("سامسونق")) {
    keywords.push("سامسونق");
  }
  if (productCompany === "Xiaomi" && !keywords.includes("Xiaomi")) {
    keywords.push("Xiaomi");
  }
  if (productCompany === "Balenciaga" && !keywords.includes("بالنسياقا")) {
    keywords.push("بالنسياقا");
  }
  if (productCompany === "Balenciaga" && !keywords.includes("بالنسياغا")) {
    keywords.push("بالنسياغا");
  }
  if (productCompany === "Prada" && !keywords.includes("برادا")) {
    keywords.push("برادا");
  }
  if (productCompany === "Coach" && !keywords.includes("كوتش")) {
    keywords.push("كوتش");
  }
  if (productCompany === "Ted Baker" && !keywords.includes("تيد بيكر")) {
    keywords.push("تيد بيكر");
  }
  if (productCompany === "Charles & Keith" && !keywords.includes("تشارلز اند كيث")) {
    keywords.push("تشارلز اند كيث");
  }
  if (productCompany === "Guess" && !keywords.includes("جيس")) {
    keywords.push("جيس");
  }
  if (productCompany === "Off-White" && !keywords.includes("اوف وايت")) {
    keywords.push("اوف وايت");
  }
  if (productCompany === "Michael Kors" && !keywords.includes("مايكل كورس")) {
    keywords.push("مايكل كورس");
  }
  
}
// for(var i = 0 ; i < productKindStr.length; i ++){
//   if(!keywords.includes(productKindStr[i])){
//     keywords.push(productKindStr[i].trim())
//   }
// }
//#endregion

if(productDescription.length > 0){
  for(var i = 0 ; i < descriptionStr.length; i ++){
    if(!descriptionStr[i].includes("\n") && !keywords.includes(descriptionStr[i])){
      keywords.push(descriptionStr[i].trim())
    }
    }
}

//#region 
// if(productCategory == 'الكترونيات'){
//   if(productSubCategory == "جوال"){
  //     keywords.push("قيقا".trim())
  //     const productStorageSizeStr = productStorageSize.split(" ");
  //     for(var i = 0 ; i < productStorageSizeStr.length; i ++){
    //       keywords.push(productStorageSizeStr[i].trim())
    //     }
    //     if(productCompany == 'Samsung'){
      //       keywords.push("سامسونغ")
      //       keywords.push("سامسونق")
      //       keywords.push("جلكسي")
      //       keywords.push("جالكسي")
      //       if(productKind == 'Galaxy S23 Ultra'){
        //         keywords.push("s")
        //         keywords.push("23")
        //         keywords.push("اس")
        //         keywords.push("إس")
        //         keywords.push("٢٣")
        //         keywords.push("الترا")
        //         keywords.push("ألترا")
        //       }
        //       else if(productKind == 'Galaxy S23+'){
          //         keywords.push("s")
          //         keywords.push("23")
          //         keywords.push("اس")
          //         keywords.push("إس")
          //         keywords.push("٢٣")
          //         keywords.push("بلس")
          //         keywords.push("+")
          //       }
          //       else if(productKind == 'Galaxy S23'){
            //         keywords.push("s")
            //         keywords.push("23")
            //         keywords.push("اس")
            //         keywords.push("إس")
            //         keywords.push("٢٣")
            //       }
            //       else if(productKind == 'Galaxy S22 Ultra'){
              //         keywords.push("s")
              //         keywords.push("22")
              //         keywords.push("اس")
              //         keywords.push("إس")
              //         keywords.push("٢٢")
              //         keywords.push("الترا")
              //         keywords.push("ألترا")
              //       }
              //       else if(productKind == 'Galaxy S22+'){
                //         keywords.push("s")
                //         keywords.push("22")
                //         keywords.push("اس")
                //         keywords.push("إس")
                //         keywords.push("٢٢")
                //         keywords.push("بلس")
                //         keywords.push("+")
                //       }
                //       else if(productKind == 'Galaxy S22'){
                  //         keywords.push("s")
                  //         keywords.push("22")
                  //         keywords.push("اس")
                  //         keywords.push("إس")
                  //         keywords.push("٢٢")
                  //       }
                  //       else if(productKind == 'Galaxy S21 FE'){
                    //         keywords.push("s")
                    //         keywords.push("21")
                    //         keywords.push("اف")
//         keywords.push("إف")
//         keywords.push("اي")
//         keywords.push("إي")
//         keywords.push("٢١")
//       }
//       else if(productKind == 'Galaxy S21 Ultra'){
//         keywords.push("s")
//         keywords.push("21")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٢١")
//         keywords.push("الترا")
//         keywords.push("ألترا")
//       }
//       else if(productKind == 'Galaxy S21+'){
//         keywords.push("s")
//         keywords.push("21")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٢١")
//         keywords.push("بلس")
//         keywords.push("+")
//       }
//       else if(productKind == 'Galaxy S21'){
//         keywords.push("s")
//         keywords.push("21")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٢٢")
//       }
//       else if(productKind == 'Galaxy S20 FE'){
//         keywords.push("s")
//         keywords.push("20")
//         keywords.push("اف")
//         keywords.push("إف")
//         keywords.push("إي")
//         keywords.push("اي")
//         keywords.push("٢٠")
//       }
//       else if(productKind == 'Galaxy S20 Ultra 5G'){
  //         keywords.push("s")
  //         keywords.push("20")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("الترا")
  //         keywords.push("ألترا")
  //         keywords.push("فايف")
//         keywords.push("جي")
//         keywords.push("٢٠")
//       }
//       else if(productKind == 'Galaxy S20+'){
//         keywords.push("s")
//         keywords.push("20")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("بلس")
//         keywords.push("+")
//         keywords.push("٢٠")
//       }
//       else if(productKind == 'Galaxy S20'){
//         keywords.push("s")
//         keywords.push("20")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٢٠")
//       }
//       else if(productKind == 'Galaxy S10 Lite'){
//         keywords.push("s")
//         keywords.push("10")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("١٠")
//         keywords.push("لايت")
//       }
//       else if(productKind == 'Galaxy S10 5G'){
//         keywords.push("s")
//         keywords.push("10")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("١٠")
//         keywords.push("فايف")
//         keywords.push("جي")
//       }
//       else if(productKind == 'Galaxy S10+'){
  //         keywords.push("s")
  //         keywords.push("10")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("١٠")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S10e'){
    //         keywords.push("s")
//         keywords.push("10")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("١٠")
//         keywords.push("بلس")
//         keywords.push("+")
//       }
//       else if(productKind == 'Galaxy S10'){
//         keywords.push("s")
//         keywords.push("10")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("١٠")
//       }
//       else if(productKind == 'Galaxy S9+'){
//         keywords.push("s")
//         keywords.push("9")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٩")
//         keywords.push("بلس")
//         keywords.push("+")
//       }
//       else if(productKind == 'Galaxy S9'){
//         keywords.push("s")
//         keywords.push("9")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٩")
//       }
//       else if(productKind == 'Galaxy S8+'){
//         keywords.push("s")
//         keywords.push("8")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٨")
//         keywords.push("بلس")
//         keywords.push("+")
//       }
//       else if(productKind == 'Galaxy S7 Active'){
//         keywords.push("s")
//         keywords.push("7")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٧")
//         keywords.push("اكتف")
//         keywords.push("اكتيف")
//         keywords.push("active")
//       }
//       else if(productKind == 'Galaxy S7 edge'){
//         keywords.push("s")
//         keywords.push("7")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٧")
//         keywords.push("ادج")
//         keywords.push("ايدج")
//         keywords.push("edge")
//       }
//       else if(productKind == 'Galaxy S7'){
//         keywords.push("s")
//         keywords.push("7")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٧")
//       }
//       else if(productKind == 'Galaxy S6 Edge+'){
//         keywords.push("s")
//         keywords.push("6")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٦")
//         keywords.push("ادج")
//         keywords.push("ايدج")
//         keywords.push("edge")
//         keywords.push("بلس")
//         keywords.push("+")
//       }
//       else if(productKind == 'Galaxy S6 Edge'){
//         keywords.push("s")
//         keywords.push("6")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٦")
//         keywords.push("ادج")
//         keywords.push("ايدج")
//         keywords.push("edge")
//       }
//       else if(productKind == 'Galaxy S6 Edge'){
//         keywords.push("s")
//         keywords.push("6")
//         keywords.push("اس")
//         keywords.push("إس")
//         keywords.push("٦")
//       }
//       else if(productKind == 'Galaxy Z Flip 5'){
//         keywords.push("z")
//         keywords.push("5")
//         keywords.push("فلب")
//         keywords.push("فليب")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("زد")
//         keywords.push("٥")
//       }
//       else if(productKind == 'Galaxy Z Fold 5'){
//         keywords.push("z")
//         keywords.push("5")
//         keywords.push("فولد")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("٥")
//         keywords.push("زد")
//       }
//       else if(productKind == 'Galaxy Z Flip 4'){
//         keywords.push("z")
//         keywords.push("4")
//         keywords.push("فلب")
//         keywords.push("فليب")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("٤")
//         keywords.push("زد")
//       }
//       else if(productKind == 'Galaxy Z Fold 4'){
//         keywords.push("z")
//         keywords.push("4")
//         keywords.push("فولد")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("٤")
//         keywords.push("زد")
//       }
//       else if(productKind == 'Galaxy Z Flip 3'){
//         keywords.push("z")
//         keywords.push("3")
//         keywords.push("فلب")
//         keywords.push("فليب")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("٣")
//         keywords.push("زد")
//       }
//       else if(productKind == 'Galaxy Z Fold 3'){
//         keywords.push("z")
//         keywords.push("3")
//         keywords.push("فولد")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("٣")
//         keywords.push("زد")
//       }
//       else if(productKind == 'Galaxy Z Flip'){
//         keywords.push("z")
//         keywords.push("فلب")
//         keywords.push("فليب")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("زد")
//       }
//       else if(productKind == 'Galaxy Z Fold'){
//         keywords.push("z")
//         keywords.push("فولد")
//         keywords.push("زي")
//         keywords.push("زيد")
//         keywords.push("زد")
//       }
//       else if(productKind == 'Galaxy Fold'){
//         keywords.push("فولد")
//       }
//       else if(productKind == 'Galaxy Note 20 Ultra'){
//         keywords.push("note")
//         keywords.push("20")
//         keywords.push("نوت")
//         keywords.push("٢٠")
//         keywords.push("الترا")
//         keywords.push("ألترا")
//       }
//       else if(productKind == 'Galaxy Note 20'){
//         keywords.push("note")
//         keywords.push("20")
//         keywords.push("نوت")
//         keywords.push("٢٠")
//       }
//       else if(productKind == 'Galaxy Note 10+'){
//         keywords.push("note")
//         keywords.push("10")
//         keywords.push("نوت")
//         keywords.push("١٠")
//         keywords.push("بلس")
//         keywords.push("+")
//       }
//       else if(productKind == 'Galaxy Note 10'){
//         keywords.push("note")
//         keywords.push("10")
//         keywords.push("نوت")
//         keywords.push("١٠")
//       }
//       else if(productKind == 'Galaxy Note 9'){
//         keywords.push("note")
//         keywords.push("9")
//         keywords.push("نوت")
//         keywords.push("٩")
//       }
//       else if(productKind == 'Galaxy Note 8'){
//         keywords.push("note")
//         keywords.push("8")
//         keywords.push("نوت")
//         keywords.push("٨")
//       }
//       else if(productKind == 'Galaxy Note 7'){
//         keywords.push("note")
//         keywords.push("7")
//         keywords.push("نوت")
//         keywords.push("٧")
//       }
//       else if(productKind == 'Galaxy Note 5'){
//         keywords.push("note")
//         keywords.push("5")
//         keywords.push("نوت")
//         keywords.push("٥")
//       }
//       else if(productKind == 'Galaxy A6+ (2018)'){
//         keywords.push("A")
//         keywords.push("6")
//         keywords.push("بلس")
//         keywords.push("٦")
//       }
//     }
//     else if(productCompany == 'ابل'){
//       if(productKind.includes("15")){keywords.push("١٥")}
//       else if(productKind.includes("14")){keywords.push("١٤")}
//       else if(productKind.includes("13")){keywords.push("١٣")}
//       else if(productKind.includes("12")){keywords.push("١٢")}
//       else if(productKind.includes("11")){keywords.push("١١")}
//       else if(productKind == 'ايفون XS Max'){
//         keywords.push("اكس اس ماكس")
//         keywords.push("إكس اس ماكس")
//         keywords.push("إكس إس ماكس")
//         keywords.push("اكس إس ماكس")
//       }
//       else if(productKind == 'ايفون XS'){
//         keywords.push("اكس اس")
//         keywords.push("إكس اس")
//         keywords.push("إكس إس")
//         keywords.push("اكس إس")
//       }
//       else if(productKind == 'ايفون XR'){
//         keywords.push("اكس ار")
//         keywords.push("إكس ار")
//         keywords.push("إكس أر")
//       }
//       else if(productKind == 'ايفون X'){
//         keywords.push("اكس")
//         keywords.push("إكس")
//       }
//       else if(productKind.includes("8")){keywords.push("٨")}
//       else if(productKind.includes("7")){keywords.push("٧")}
//       else if(productKind.includes("6")){keywords.push("٦")}
//     }
//   }
//   else if(productSubCategory == 'شاشة'){
//       keywords.push('شاشة')
//       keywords.push('شاشه')
//       keywords.push('تلفزيون')
//       keywords.push('انش')
//       const productScreenSizeStr = productScreenSize.split(" ");
//       for(var i = 0 ; i < productScreenSizeStr.length; i ++){
//         keywords.push(productScreenSizeStr[i])
//       }
//       if(productCompany == 'Samsung'){
//         keywords.push("سامسونغ")
//         keywords.push("سامسونق")
//       }
//       else if(productCompany == 'TCL'){
//         keywords.push("تي سي ال")
//         keywords.push("تي")
//         keywords.push("سي")
//         keywords.push("ال")
//       }
//       else if(productCompany == 'دانسات'){
//         keywords.push("danast")
//       }
//       else if(productCompany == 'باناسونيك'){
//         keywords.push("panasonic")
//       }
//       else if(productCompany == 'ال جي'){
//         keywords.push("إل جي")
//         keywords.push("إل")
//         keywords.push("جي")
//         keywords.push("ال")
//         keywords.push("LG")
//       }
//       else if(productCompany == 'اسوس'){
//         keywords.push("ايسوس")
//         keywords.push("asus")
//       }
//       else if(productCompany == 'BenQ'){
//         keywords.push("بن كيو")
//         keywords.push("بين كيو")
//         keywords.push("بن")
//         keywords.push("بين")
//         keywords.push("كيو")
//         keywords.push("benq")
//         keywords.push("ben")
//         keywords.push("q")
//       }

//       // screen size
//       if(productScreenSize == '16 بوصة'){
//         keywords.push('١٦')
//       }
//       else if(productScreenSize == '18 بوصة'){
//         keywords.push('١٨')
//       }
//       else if(productScreenSize == '20 بوصة'){
//         keywords.push('٢٠')
//       }
//       else if(productScreenSize == '22 بوصة'){
//         keywords.push('٢٢')
//       }
//       else if(productScreenSize == '24 بوصة'){
//         keywords.push('٢٤')
//       }
//       else if(productScreenSize == '26 بوصة'){
//         keywords.push('٢٦')
//       }
//       else if(productScreenSize == '28 بوصة'){
//         keywords.push('٢٨')
//       }
//       else if(productScreenSize == '30 بوصة'){
//         keywords.push('٣٠')
//       }
//       else if(productScreenSize == '32 بوصة'){
//         keywords.push('٣٢')
//       }
//       else if(productScreenSize == '34 بوصة'){
//         keywords.push('٣٤')
//       }
//       else if(productScreenSize == '36 بوصة'){
//         keywords.push('٣٦')
//       }
//       else if(productScreenSize == '38 بوصة'){
//         keywords.push('٣٨')
//       }
//       else if(productScreenSize == '40 بوصة'){
//         keywords.push('٤٠')
//       }
//       else if(productScreenSize == '45 بوصة'){
//         keywords.push('٤٥')
//       }
//       else if(productScreenSize == '50 بوصة'){
//         keywords.push('٥٠')
//       }
//       else if(productScreenSize == '55 بوصة'){
//         keywords.push('٥٥')
//       }
//       else if(productScreenSize == '60 بوصة'){
//         keywords.push('٦٠')
//       }
//       else if(productScreenSize == '65 بوصة'){
//         keywords.push('٦٥')
//       }
//       else if(productScreenSize == '70 بوصة'){
//         keywords.push('٧٠')
//       }
//       else if(productScreenSize == '75 بوصة'){
//         keywords.push('٧٥')
//       }
//       else if(productScreenSize == '80 بوصة'){
//         keywords.push('٨٠')
//       }
//       else if(productScreenSize == '85 بوصة'){
//         keywords.push('٨٥')
//       }
//       else if(productScreenSize == '100 بوصة'){
//         keywords.push('١٠٠')
//       }
//   }
//   else if(productSubCategory == 'لابتوب'){
//     keywords.push("ram")
//     const productMemorySizeStr = productMemorySize.split(" ");
//     for(var i = 0 ; i < productMemorySizeStr.length; i ++){
//       keywords.push(productMemorySizeStr[i])
//     }

//     if(productCompany == 'ابل'){
//       keywords.push("ماك")
//       keywords.push("ماك بوك")
//     }
//     else if(productCompany == 'اتش بي'){
//       keywords.push("hp")
//       keywords.push("اتش")
//       keywords.push("بي")
//     }
//     else if(productCompany == 'ثينك باد'){
//       keywords.push("thinkpad")
//     }
//     else if(productCompany == 'مايكروسوفت'){
//       keywords.push("microsoft")
//     }
//     else if(productCompany == 'ديل'){
//       keywords.push("dell")
//     }
//     else if(productCompany == 'توشيبا'){
//       keywords.push("toshiba")
//     }
//     else if(productCompany == 'اسوس'){
//       keywords.push("asus")
//     }
//     else if(productCompany == 'لينوفو'){
//       keywords.push("lenovo")
//     }

//     if(productMemorySize == '4 رام'){
//       keywords.push('٤')
//     }
//     else if(productMemorySize == '8 رام'){
//       keywords.push('٨')
//     }
//     else if(productMemorySize == '16 رام'){
//       keywords.push('١٦')
//     }
//     else if(productMemorySize == '32 رام'){
//       keywords.push('٣٢')
//     }
//   }
//   else if(productSubCategory == 'جهاز لوحي'){
//     const productMemorySizeStr = productMemorySize.split(" ");
//     for(var i = 0 ; i < productMemorySizeStr.length; i ++){
//       keywords.push(productMemorySizeStr[i])
//     }

//     if(productCompany == 'ابل'){
//       keywords.push("ايباد")
//     }

//     if(productMemorySize == '4 رام'){
//       keywords.push('٤')
//     }
//     else if(productMemorySize == '8 رام'){
//       keywords.push('٨')
//     }
//     else if(productMemorySize == '16 رام'){
//       keywords.push('١٦')
//     }
//     else if(productMemorySize == '32 رام'){
//       keywords.push('٣٢')
//     }
//   }
//   else if(productSubCategory == 'طابعة'){
//     keywords.push("طابعه")
//     keywords.push("حبر")
//     const productInkKindStr = productInkKind.split(" ");
//     for(var i = 0 ; i < productInkKindStr.length; i ++){
//       keywords.push(productInkKindStr[i])
//     }

//     if(productCompany == 'اتش بي'){
//       keywords.push("hp")
//     }
//     else if(productCompany == 'كانون'){
//       keywords.push("canon")
//     }
//     else if(productCompany == 'ايبسون'){
//       keywords.push("epson")
//     }
//   }
//   else if(productSubCategory == 'سماعة'){
//     keywords.push('سماعه')
//     keywords.push('سماعات')
//     keywords.push('ستيريو')
//     keywords.push('استريو')
//     keywords.push('سبيكر')
//     keywords.push('اسبيكر')
//     keywords.push('سبيكرات')
//   }
//   else if(productSubCategory == 'جهاز العاب'){
//     if(productCompany == 'بلايستيشن'){
//       keywords.push("سوني")
//       if(productKind == 'بلايستيشن 1'){
//         keywords.push("١")
//       }
//       else if(productKind == 'بلايستيشن 2'){
//         keywords.push("٢")

//       }
//       else if(productKind == 'بلايستيشن 3'){
//         keywords.push("٣")

//       }
//       else if(productKind == 'بلايستيشن 4'){
//         keywords.push("٤")

//       }
//       else if(productKind == 'بلايستيشن 5'){
//         keywords.push("٥")
//       }
//     }
//     else if(productCompany == 'مايكروسوفت'){
//       keywords.push("اكس بوكس")

//       if(productKind == 'اكسبوكس 360'){
//         keywords.push("٣٦٠")
//       }
//       else if (productKind == 'اكسبوكس 1'){
//         keywords.push("١")
//       }
//     }
//   }
// }

// else if(productSubCategory == 'جهاز العاب'){
//   keywords.push('جهاز العاب')
//   keywords.push('سوني')
//   keywords.push('سوني 1')
//   keywords.push('سوني ون')
//   keywords.push('سوني 2')
//   keywords.push('سوني تو')
//   keywords.push('سوني 3')
//   keywords.push('سوني ثري')
//   keywords.push('سوني 4')
//   keywords.push('سوني فور')
//   keywords.push('سوني 5')
//   keywords.push('سوني فايف')
//   keywords.push('بلايستيشن')
//   keywords.push('بلايستيشن 1')
//   keywords.push('بلايستيشن ون')
//   keywords.push('بلايستيشن 2')
//   keywords.push('بلايستيشن تو')
//   keywords.push('بلايستيشن 3')
//   keywords.push('بلايستيشن ثري')
//   keywords.push('بلايستيشن 4')
//   keywords.push('بلايستيشن فور')
//   keywords.push('بلايستيشن 5')
//   keywords.push('بلايستيشن فايف')
//   keywords.push('اكسبوكس')
//   keywords.push('اكسبوكس 360')
//   keywords.push('اكسبوكس 1')
//   keywords.push('اكسبوكس ون')
// }

// else if(productSubCategory == 'اثاث'){
//   keywords.push('اثاث')
//   keywords.push('أثاث')
//   keywords.push('منزلي')
//   keywords.push('مكتبي')
//   keywords.push('مدرسي')
//   keywords.push('خارجي')
// }
// else if(productSubCategory == 'كرسي'){
//   keywords.push('كرسي')
//   keywords.push('كراسي')
//   keywords.push('منزلي')
//   keywords.push('مكتبي')
//   keywords.push('مدرسي')
//   keywords.push('خارجي')
//   keywords.push('قيمنق')
//   keywords.push('العاب')
// }
// else if(productSubCategory == 'كنب'){
//   keywords.push('كنب')
//   keywords.push('كنبة')
//   keywords.push('كنبه')
//   keywords.push('كنبات')
//   keywords.push('منزلي')
//   keywords.push('مكتبي')
//   keywords.push('مدرسي')
//   keywords.push('خارجي')
//   keywords.push('ثنائي')
//   keywords.push('ثلاثي')
//   keywords.push('رباعي')
//   keywords.push('زاوية')
// }
// else if(productSubCategory == 'طاولة'){
//   keywords.push('طاولة')
//   keywords.push('طاوله')
//   keywords.push('طاولات')
//   keywords.push('مكتب')
//   keywords.push('منزلي')
//   keywords.push('منزلية')
//   keywords.push('مكتبي')
//   keywords.push('مكتبية')
//   keywords.push('مدرسي')
//   keywords.push('مدرسية')
//   keywords.push('خارجي')
//   keywords.push('خارجية')
//   keywords.push('زاوية')
//   keywords.push('خشب')
//   keywords.push('خشبية')
//   keywords.push('خشبيه')
// }
// else if(productSubCategory == 'ارفف'){
//   keywords.push('ارفف')
//   keywords.push('رف')
//   keywords.push('منزلي')
//   keywords.push('منزلية')
//   keywords.push('مكتبي')
//   keywords.push('مكتبية')
//   keywords.push('مدرسي')
//   keywords.push('مدرسية')
//   keywords.push('خارجي')
//   keywords.push('خارجية')
//   keywords.push('خشب')
//   keywords.push('خشبية')
//   keywords.push('خشبيه')
// }
// else if(productSubCategory == 'دواليب و ادراج'){
//   keywords.push('دولاب')
//   keywords.push('دواليب')
//   keywords.push('ادراج')
//   keywords.push('درج')
//   keywords.push('منزلية')
//   keywords.push('منزليه')
//   keywords.push('منزلي')
//   keywords.push('مكتبي')
//   keywords.push('مكتبية')
//   keywords.push('مكتبيه')
//   keywords.push('مدرسي')
//   keywords.push('مدرسية')
//   keywords.push('مدرسيه')
//   keywords.push('خارجي')
//   keywords.push('خارجية')
//   keywords.push('خارجيه')
//   keywords.push('خشب')
//   keywords.push('خشبية')
//   keywords.push('خشبيه')
// }
// else if(productSubCategory == 'لوحات فنية'){
//   keywords.push('لوحة')
//   keywords.push('لوحات')
//   keywords.push('فنية')
//   keywords.push('فنيه')
//   keywords.push('جدارية')
//   keywords.push('جداريه')
//   keywords.push('جداريات')
//   keywords.push('اثاث')
//   keywords.push('منزلي')
//   keywords.push('منزلية')
//   keywords.push('منزليه')
//   keywords.push('مكتبي')
//   keywords.push('مكتبية')
//   keywords.push('مكتبيه')
//   keywords.push('داخلية')
//   keywords.push('داخليه')
// }
// else if(productSubCategory == 'قطع غيار سيارات'){
//   keywords.push('قطع غيار سيارات')
//   keywords.push('قطع غيار')
//   keywords.push('سيارة')
//   keywords.push('سياره')
// }
// else if(productSubCategory == 'الات موسيقية'){
//   keywords.push('الات موسيقية')
//   keywords.push('الات موسيقيه')
//   keywords.push('موسيقية')
//   keywords.push('الة')
//   keywords.push('اله')
// }
// #endregion


if(productCategory == "ساعات"){
  if(productCompany === "Apple" ||
  productCompany === "Samsung" ||
  productCompany === "Huawei" || 
  productCompany === "Xiaomi"){
    setWatchDepartment("للجنسين")
  }

  if(!keywords.includes("ساعة")){
    keywords.push("ساعة")
  }
  if(!keywords.includes("ساعه")){
    keywords.push("ساعه")
  }
  if(!keywords.includes("ساعات")){
    keywords.push("ساعات")
  }
  



  keywords.push(watchDepartment);

  if(watchDepartment === "رجالي"){
    if(!keywords.includes("رجالية")){
      keywords.push("رجالية")
    }
    if(!keywords.includes("رجاليه")){
      keywords.push("رجاليه")
    }
  }
  else if(watchDepartment === "نسائي"){
    if(!keywords.includes("نسائية")){
      keywords.push("نسائية")
    }
    if(!keywords.includes("نسائيه")){
      keywords.push("نسائيه")
    }
  }





  keywords.push(watchInsideColor);

  if(watchInsideColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchInsideColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchInsideColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchInsideColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchInsideColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchInsideColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchInsideColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchInsideColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchInsideColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchInsideColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchInsideColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchInsideColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchInsideColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchInsideColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchInsideColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }


// if(watchInsideColorSpecification){
// if(!keywords.includes(watchInsideColorSpecification)){
// keywords.push(watchInsideColorSpecification);
// if(watchInsideColorSpecification.includes("أ") && !keywords.includes(watchInsideColorSpecification.replace("أ", "ا"))){
// keywords.push(watchInsideColorSpecification.replace("أ", "ا"));
// }
// }
// }

  keywords.push(watchOutsideColor);

  if(watchOutsideColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchOutsideColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchOutsideColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchOutsideColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchOutsideColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchOutsideColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchOutsideColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchOutsideColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchOutsideColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchOutsideColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchOutsideColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchOutsideColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchOutsideColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchOutsideColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchOutsideColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }



// if(watchOutsideColorSpecification){
//   if(!keywords.includes(watchOutsideColorSpecification)){
//     keywords.push(watchOutsideColorSpecification);
//   if(watchOutsideColorSpecification.includes("أ") && !keywords.includes(watchOutsideColorSpecification.replace("أ", "ا"))){
//     keywords.push(watchOutsideColorSpecification.replace("أ", "ا"));
//   }
//   }
// }

  if(watchDialShape && !keywords.includes(watchDialShape)){
  keywords.push(watchDialShape)
  if(watchDialShape === "دائري"){
    if(!keywords.includes("دائرية")) keywords.push("دائرية")
    if(!keywords.includes("دائريه")) keywords.push("دائريه")
  }
  else if(watchDialShape === "مربع"){
    if(!keywords.includes("مربعة")) keywords.push("مربعة")
    if(!keywords.includes("مربعه")) keywords.push("مربعه")
  }
  else if(watchDialShape === "مستطيل"){
    if(!keywords.includes("مستطيلة")) keywords.push("مستطيلة")
    if(!keywords.includes("مستطيله")) keywords.push("مستطيله")
  }
  else if(watchDialShape === "بيضاوي"){
    if(!keywords.includes("بيضاوية")) keywords.push("بيضاوية")
    if(!keywords.includes("بيضاويه")) keywords.push("بيضاويه")
  }
  else if(watchDialShape === "خماسي"){
    if(!keywords.includes("خماسية")) keywords.push("خماسية")
    if(!keywords.includes("خماسيه")) keywords.push("خماسيه")
  }
  else if(watchDialShape === "سداسي"){
    if(!keywords.includes("سداسية")) keywords.push("سداسية")
    if(!keywords.includes("سداسيه")) keywords.push("سداسيه")
  }
}

  if(watchDialSize && !keywords.includes(watchDialSize)){
  keywords.push(watchDialSize)
  }

  if(watchHandlesColor && !keywords.includes(watchHandlesColor)){
  keywords.push(watchHandlesColor)
  
  if(watchHandlesColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchHandlesColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchHandlesColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchHandlesColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchHandlesColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchHandlesColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchHandlesColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchHandlesColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchHandlesColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchHandlesColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchHandlesColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchHandlesColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchHandlesColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchHandlesColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchHandlesColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
  }

  // if(watchHandlesColorSpecification){
  //   if(!keywords.includes(watchHandlesColorSpecification)){
  //     keywords.push(watchHandlesColorSpecification);
  //   if(watchHandlesColorSpecification.includes("أ") && !keywords.includes(watchHandlesColorSpecification.replace("أ", "ا"))){
  //     keywords.push(watchHandlesColorSpecification.replace("أ", "ا"));
  //   }
  //   }
  // }

  if(watchNumbersColor && !keywords.includes(watchNumbersColor)){
    keywords.push(watchNumbersColor)
    
  if(watchNumbersColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchNumbersColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchNumbersColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchNumbersColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchNumbersColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchNumbersColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchNumbersColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchNumbersColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchNumbersColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchNumbersColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchNumbersColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchNumbersColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchNumbersColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchNumbersColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchNumbersColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
}

// if(watchNumbersColor){
// if(!keywords.includes(watchNumbersColorSpecification)){
// keywords.push(watchNumbersColorSpecification);
// if(watchHandlesColorSpecification.includes("أ") && !keywords.includes(watchHandlesColorSpecification.replace("أ", "ا"))){
// keywords.push(watchHandlesColorSpecification.replace("أ", "ا"));
// }
// }
// }


  if(watchNumbersLanguage && !keywords.includes(watchNumbersLanguage)){
  if(!keywords.includes("ارقام"))
  keywords.push("ارقام")

  if(!keywords.includes("أرقام"))
  keywords.push("أرقام")

  if(!keywords.includes(watchNumbersLanguage))
  keywords.push(watchNumbersLanguage)

  if(watchNumbersLanguage === "العربية"){
  if(!keywords.includes("العربية")){
  keywords.push("العربية")
  }
  if(!keywords.includes("العربيه")){
  keywords.push("العربيه")
  }
  if(!keywords.includes("عربية")){
  keywords.push("عربية")
  }
  if(!keywords.includes("عربيه")){
  keywords.push("عربيه")
  }
  }
  else if(watchNumbersLanguage === "الإنجليزية"){
  if(!keywords.includes("الإنجليزية")){
  keywords.push("الإنجليزية")
  }
  if(!keywords.includes("الإنجليزيه")){
  keywords.push("الإنجليزيه")
  }
  if(!keywords.includes("انجليزية")){
  keywords.push("انجليزية")
  }
  if(!keywords.includes("انجليزيه")){
  keywords.push("انجليزيه")
  }
  if(!keywords.includes("إنجليزية")){
  keywords.push("إنجليزية")
  }
  if(!keywords.includes("إنجليزيه")){
  keywords.push("إنجليزيه")
  }
  }
  else if(watchNumbersLanguage === "الرومانية"){
  if(!keywords.includes("الرومانية")){
  keywords.push("الرومانية")
  }
  if(!keywords.includes("الرومانيه")){
  keywords.push("الرومانيه")
  }
  if(!keywords.includes("رومانية")){
  keywords.push("رومانية")
  keywords.push("رومانيه")
  }
  }
  else if(watchNumbersLanguage === "بدون أرقام"){
  if(!keywords.includes("بدون ارقام")){
  keywords.push("بدون ارقام")
  }
  if(!keywords.includes("بدون أرقام")){
  keywords.push("بدون أرقام")
  }
  }
}

// if(watchBandColorSpecification){
// if(!keywords.includes(watchBandColorSpecification)){
// keywords.push(watchBandColorSpecification);
// if(watchBandColorSpecification.includes("أ") && !keywords.includes(watchBandColorSpecification.replace("أ", "ا"))){
// keywords.push(watchBandColorSpecification.replace("أ", "ا"));
// }
// }
// }


if(watchBandMaterial === "ستانلس ستيل") setWatchBandColor("ستانلس ستيل");

if(!keywords.includes(watchBandColor)){
  keywords.push(watchBandColor);
  
  if(watchBandColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchBandColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchBandColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchBandColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchBandColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchBandColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchBandColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchBandColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchBandColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchBandColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchBandColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchBandColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchBandColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchBandColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchBandColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
  }



  if(watchYearMade){
    if(!keywords.includes(watchYearMade)){
      keywords.push(watchYearMade)
    }
  }

  if(watchIsWaterResistant){
    if(watchIsWaterResistant === true){
    if(!keywords.includes("مقاومة للماء")){
    keywords.push("مقاومة للماء")
    }
    if(!keywords.includes("مقاومه للماء")){
    keywords.push("مقاومه للماء")
    }
    if(!keywords.includes("مقاومة")){
    keywords.push("مقاومة")
    }
    if(!keywords.includes("مقاومه")){
    keywords.push("مقاومه")
    }
    }
      
    else{
      if(!keywords.includes("غير مقاومة للماء")){
      keywords.push("غير مقاومة للماء")
      }
      if(!keywords.includes("غير مقاومه للماء")){
      keywords.push("مقاومه للماء")
      }
      if(!keywords.includes("غير")){
      keywords.push("غير")
      }
      if(!keywords.includes("مقاومة")){
      keywords.push("مقاومة")
      }
      if(!keywords.includes("مقاومه")){
      keywords.push("مقاومه")
      }
    }
  }

  if(watchHasOriginalPackaging){
    if(watchHasOriginalPackaging === true){
      if(!keywords.includes("مع صندوق")) keywords.push("مع صندوق")
    } else{
      if(!keywords.includes("بدون صندوق")) keywords.push("بدون صندوق")
  }
  }
}




else if(productCategory == "سبح"){
  if(!keywords.includes("سبحة")){
    keywords.push("سبحة")
  }
  if(!keywords.includes("سبحه")){
    keywords.push("سبحه")
  }
  if(!keywords.includes("مسباح")){
    keywords.push("مسباح")
  }

  if(!keywords.includes(rosaryKind)){
    keywords.push(rosaryKind)
  }

  if(!keywords.includes(rosaryColor)){
  keywords.push(rosaryColor)

  if(rosaryColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(rosaryColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(rosaryColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(rosaryColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(rosaryColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(rosaryColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(rosaryColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(rosaryColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(rosaryColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(rosaryColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(rosaryColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(rosaryColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(rosaryColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(rosaryColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
}
  if(!keywords.includes(rosaryCount)){
  keywords.push(rosaryCount)
  }

  if(rosarySize && !keywords.includes(rosarySize)){
    keywords.push(rosarySize)
    keywords.push("ملم")
  }

  if(!keywords.includes("خرز")){
  keywords.push("خرز")
  }
  if(!keywords.includes("خرزة")){
  keywords.push("خرزة")
  }
  if(!keywords.includes("خرزه")){
  keywords.push("خرزه")
  }




}


else if(productCategory == "اقلام"){
if(!keywords.includes("قلم")){
keywords.push("قلم")
}
if(!keywords.includes("أقلام")){
keywords.push("أقلام")
}
if(!keywords.includes("اقلام")){
keywords.push("اقلام")
}
}

else if(productCategory == "ابواك"){

if(!keywords.includes("بوك")){
keywords.push("بوك")
}
if(!keywords.includes("محفظة")){
keywords.push("محفظة")
}
if(!keywords.includes("محفظه")){
keywords.push("محفظه")
}

keywords.push(walletDepartment);

if(walletDepartment === "رجالي"){
  if(!keywords.includes("رجالية")) keywords.push("رجالية")
  if(!keywords.includes("رجاليه")) keywords.push("رجاليه")
}

if(walletDepartment === "نسائي"){
  if(!keywords.includes("نسائية")) keywords.push("نسائية")
  if(!keywords.includes("نسائيه")) keywords.push("نسائيه")
}



if(walletColor === "أسود"){
  if(!keywords.includes("اسود")){
    keywords.push("اسود")
  }
  if(!keywords.includes("سوداء")){
    keywords.push("سوداء")
  }
  if(!keywords.includes("سودا")){
    keywords.push("سودا")
  }
}


if(walletColor === "أبيض"){
  if(!keywords.includes("ابيض")){
    keywords.push("ابيض")
  }
  if(!keywords.includes("بيضاء")){
    keywords.push("بيضاء")
  }
  if(!keywords.includes("بيضا")){
    keywords.push("بيضا")
  }
}


if(walletColor === "أحمر"){
  if(!keywords.includes("أحمر")){
    keywords.push("أحمر")
  }
  if(!keywords.includes("احمر")){
    keywords.push("احمر")
  }
  if(!keywords.includes("حمراء")){
    keywords.push("حمراء")
  }
  if(!keywords.includes("حمرا")){
    keywords.push("حمرا")
  }
}

if(walletColor === "أزرق"){
  if(!keywords.includes("أزرق")){
  keywords.push("أزرق")
  }
  if(!keywords.includes("ازرق")){
  keywords.push("ازرق")
  }
  if(!keywords.includes("زرقاء")){
  keywords.push("زرقاء")
  }
}

if(walletColor === "أخضر"){
  if(!keywords.includes("أخضر")){
  keywords.push("أخضر")
  }
  if(!keywords.includes("اخضر")){
  keywords.push("اخضر")
  }
  if(!keywords.includes("خضراء")){
  keywords.push("خضراء")
  }
  if(!keywords.includes("خضرا")){
  keywords.push("خضرا")
  }
}

if(walletColor === "أصفر"){
  if(!keywords.includes("أصفر")){
  keywords.push("أصفر")
  }
  if(!keywords.includes("اصفر")){
  keywords.push("اصفر")
  }
  if(!keywords.includes("صفراء")){
  keywords.push("صفراء")
  }
  if(!keywords.includes("صفرا")){
  keywords.push("صفراء")
  }
}

if(walletColor === "برتقالي"){
  if(!keywords.includes("برتقالي")){
    keywords.push("برتقالي")
  }
  if(!keywords.includes("برتقالية")){
    keywords.push("برتقالية")
  }
  if(!keywords.includes("برتقاليه")){
    keywords.push("برتقاليه")
  }
}

if(walletColor === "بنفسجي"){
  if(!keywords.includes("بنفسجي")){
    keywords.push("بنفسجي")
  }
  if(!keywords.includes("بنفسجية")){
    keywords.push("بنفسجية")
  }
  if(!keywords.includes("بنفسجيه")){
    keywords.push("بنفسجيه")
  }
}

if(walletColor === "وردي"){
  if(!keywords.includes("وردي")){
    keywords.push("وردي")
  }
  if(!keywords.includes("وردية")){
    keywords.push("وردية")
  }
  if(!keywords.includes("ورديه")){
    keywords.push("ورديه")
  }
}

if(walletColor === "بني"){
  if(!keywords.includes("بني")){
    keywords.push("بني")
  }
  if(!keywords.includes("بنية")){
    keywords.push("بنية")
  }
  if(!keywords.includes("بنيه")){
    keywords.push("بنيه")
  }
}

if(walletColor === "رمادي"){
  if(!keywords.includes("رمادي")){
    keywords.push("رمادي")
  }
  if(!keywords.includes("رمادية")){
    keywords.push("رمادية")
  }
  if(!keywords.includes("رماديه")){
    keywords.push("رماديه")
  }
}

if(walletColor === "زيتي"){
  if(!keywords.includes("زيتي")){
    keywords.push("زيتي")
  }
  if(!keywords.includes("زيتية")){
    keywords.push("زيتية")
  }
  if(!keywords.includes("زيتيه")){
    keywords.push("زيتيه")
  }
}

if(walletColor === "سماوي"){
  if(!keywords.includes("سماوي")){
    keywords.push("سماوي")
  }
  if(!keywords.includes("سماوية")){
    keywords.push("سماوية")
  }
  if(!keywords.includes("سماويه")){
    keywords.push("سماويه")
  }
}

if(walletColor === "كحلي"){
  if(!keywords.includes("كحلي")){
    keywords.push("كحلي")
  }
  if(!keywords.includes("كحلية")){
    keywords.push("كحلية")
  }
  if(!keywords.includes("كحليه")){
    keywords.push("كحليه")
  }
}

}


else if(productCategory == "لوح سيارات"){
if(!keywords.includes("لوحة")){
keywords.push("لوحة")
}
if(!keywords.includes("لوحه")){
keywords.push("لوحه")
}
if(!keywords.includes("لوحة سيارة")){
keywords.push("لوحة سيارة")
}
if(!keywords.includes("لوحه سيارة")){
keywords.push("لوحه سيارة")
}
if(!keywords.includes("لوحه سياره")){
keywords.push("لوحه سياره")
}
if(!keywords.includes("لوحة سياره")){
keywords.push("لوحة سياره")
}



// Collect Car Plate Arabic letters
if (carPlateFirstArabicLetter) {
  keywords.push(carPlateFirstArabicLetter);
}
if (carPlateSecondArabicLetter) {
  keywords.push(carPlateSecondArabicLetter);
}
if (carPlateThirdArabicLetter) {
  keywords.push(carPlateThirdArabicLetter);
}

// Collect Car Plate Arabic numbers
if (carPlateFirstArabicNumber) {
  keywords.push(carPlateFirstArabicNumber);
}
if (carPlateSecondArabicNumber) {
  keywords.push(carPlateSecondArabicNumber);
}
if (carPlateThirdArabicNumber) {
  keywords.push(carPlateThirdArabicNumber);
}
if (carPlateFourthArabicNumber) {
  keywords.push(carPlateFourthArabicNumber);
}

// Collect Car Plate English letters
if (carPlateFirstEnglishLetter) {
  keywords.push(carPlateFirstEnglishLetter);
}
if (carPlateSecondEnglishLetter) {
  keywords.push(carPlateSecondEnglishLetter);
}
if (carPlateThirdEnglishLetter) {
  keywords.push(carPlateThirdEnglishLetter);
}

// Collect Car Plate English numbers
if (carPlateFirstEnglishNumber) {
  keywords.push(carPlateFirstEnglishNumber);
}
if (carPlateSecondEnglishNumber) {
  keywords.push(carPlateSecondEnglishNumber);
}
if (carPlateThirdEnglishNumber) {
  keywords.push(carPlateThirdEnglishNumber);
}
if (carPlateFourthEnglishNumber) {
  keywords.push(carPlateFourthEnglishNumber);
}

}

else if(productCategory == "ارقام جوالات"){
if(!keywords.includes("رقم")){
keywords.push("رقم")
}
if(!keywords.includes("رقم جوال")){
keywords.push("رقم جوال")
}
if(phoneNumberCourier === "STC"){
  if(!keywords.includes("الاتصالات السعودية")) keywords.push("الاتصالات")
}
if(!keywords.includes(phoneNumberCourier)){
keywords.push(phoneNumberCourier)
if(!keywords.includes(phoneNumberToSell)){
keywords.push(phoneNumberToSell)
}
}


}

else if(productCategory == "حقائب نسائية"){

if(!keywords.includes(purseOutsideColor)){
keywords.push(purseOutsideColor)

if(purseOutsideColor === "أسود"){
  if(!keywords.includes("اسود")){
    keywords.push("اسود")
  }
  if(!keywords.includes("سوداء")){
    keywords.push("سوداء")
  }
  if(!keywords.includes("سودا")){
    keywords.push("سودا")
  }
}

if(purseOutsideColor === "أبيض"){
  if(!keywords.includes("ابيض")){
    keywords.push("ابيض")
  }
  if(!keywords.includes("بيضاء")){
    keywords.push("بيضاء")
  }
  if(!keywords.includes("بيضا")){
    keywords.push("بيضا")
  }
}


if(purseOutsideColor === "أحمر"){
  if(!keywords.includes("أحمر")){
    keywords.push("أحمر")
  }
  if(!keywords.includes("احمر")){
    keywords.push("احمر")
  }
  if(!keywords.includes("حمراء")){
    keywords.push("حمراء")
  }
  if(!keywords.includes("حمرا")){
    keywords.push("حمرا")
  }
}

if(purseOutsideColor === "أزرق"){
  if(!keywords.includes("أزرق")){
  keywords.push("أزرق")
  }
  if(!keywords.includes("ازرق")){
  keywords.push("ازرق")
  }
  if(!keywords.includes("زرقاء")){
  keywords.push("زرقاء")
  }
}

if(purseOutsideColor === "أخضر"){
  if(!keywords.includes("أخضر")){
  keywords.push("أخضر")
  }
  if(!keywords.includes("اخضر")){
  keywords.push("اخضر")
  }
  if(!keywords.includes("خضراء")){
  keywords.push("خضراء")
  }
  if(!keywords.includes("خضرا")){
  keywords.push("خضرا")
  }
}

if(purseOutsideColor === "أصفر"){
  if(!keywords.includes("أصفر")){
  keywords.push("أصفر")
  }
  if(!keywords.includes("اصفر")){
  keywords.push("اصفر")
  }
  if(!keywords.includes("صفراء")){
  keywords.push("صفراء")
  }
  if(!keywords.includes("صفرا")){
  keywords.push("صفراء")
  }
}

if(purseOutsideColor === "برتقالي"){
  if(!keywords.includes("برتقالي")){
    keywords.push("برتقالي")
  }
  if(!keywords.includes("برتقالية")){
    keywords.push("برتقالية")
  }
  if(!keywords.includes("برتقاليه")){
    keywords.push("برتقاليه")
  }
}

if(purseOutsideColor === "بنفسجي"){
  if(!keywords.includes("بنفسجي")){
    keywords.push("بنفسجي")
  }
  if(!keywords.includes("بنفسجية")){
    keywords.push("بنفسجية")
  }
  if(!keywords.includes("بنفسجيه")){
    keywords.push("بنفسجيه")
  }
}

if(purseOutsideColor === "وردي"){
  if(!keywords.includes("وردي")){
    keywords.push("وردي")
  }
  if(!keywords.includes("وردية")){
    keywords.push("وردية")
  }
  if(!keywords.includes("ورديه")){
    keywords.push("ورديه")
  }
}

if(purseOutsideColor === "بني"){
  if(!keywords.includes("بني")){
    keywords.push("بني")
  }
  if(!keywords.includes("بنية")){
    keywords.push("بنية")
  }
  if(!keywords.includes("بنيه")){
    keywords.push("بنيه")
  }
}

if(purseOutsideColor === "رمادي"){
  if(!keywords.includes("رمادي")){
    keywords.push("رمادي")
  }
  if(!keywords.includes("رمادية")){
    keywords.push("رمادية")
  }
  if(!keywords.includes("رماديه")){
    keywords.push("رماديه")
  }
}

if(purseOutsideColor === "زيتي"){
  if(!keywords.includes("زيتي")){
    keywords.push("زيتي")
  }
  if(!keywords.includes("زيتية")){
    keywords.push("زيتية")
  }
  if(!keywords.includes("زيتيه")){
    keywords.push("زيتيه")
  }
}

if(purseOutsideColor === "سماوي"){
  if(!keywords.includes("سماوي")){
    keywords.push("سماوي")
  }
  if(!keywords.includes("سماوية")){
    keywords.push("سماوية")
  }
  if(!keywords.includes("سماويه")){
    keywords.push("سماويه")
  }
}

if(purseOutsideColor === "كحلي"){
  if(!keywords.includes("كحلي")){
    keywords.push("كحلي")
  }
  if(!keywords.includes("كحلية")){
    keywords.push("كحلية")
  }
  if(!keywords.includes("كحليه")){
    keywords.push("كحليه")
  }
}
}

if(purseInsideColor !== "" && !keywords.includes(purseInsideColor)){
keywords.push(purseInsideColor)

if(purseInsideColor === "أسود"){
  if(!keywords.includes("اسود")){
    keywords.push("اسود")
  }
  if(!keywords.includes("سوداء")){
    keywords.push("سوداء")
  }
  if(!keywords.includes("سودا")){
    keywords.push("سودا")
  }
}

if(purseInsideColor === "أبيض"){
  if(!keywords.includes("ابيض")){
    keywords.push("ابيض")
  }
  if(!keywords.includes("بيضاء")){
    keywords.push("بيضاء")
  }
  if(!keywords.includes("بيضا")){
    keywords.push("بيضا")
  }
}


if(purseInsideColor === "أحمر"){
  if(!keywords.includes("أحمر")){
    keywords.push("أحمر")
  }
  if(!keywords.includes("احمر")){
    keywords.push("احمر")
  }
  if(!keywords.includes("حمراء")){
    keywords.push("حمراء")
  }
  if(!keywords.includes("حمرا")){
    keywords.push("حمرا")
  }
}

if(purseInsideColor === "أزرق"){
  if(!keywords.includes("أزرق")){
  keywords.push("أزرق")
  }
  if(!keywords.includes("ازرق")){
  keywords.push("ازرق")
  }
  if(!keywords.includes("زرقاء")){
  keywords.push("زرقاء")
  }
}

if(purseInsideColor === "أخضر"){
  if(!keywords.includes("أخضر")){
  keywords.push("أخضر")
  }
  if(!keywords.includes("اخضر")){
  keywords.push("اخضر")
  }
  if(!keywords.includes("خضراء")){
  keywords.push("خضراء")
  }
  if(!keywords.includes("خضرا")){
  keywords.push("خضرا")
  }
}

if(purseInsideColor === "أصفر"){
  if(!keywords.includes("أصفر")){
  keywords.push("أصفر")
  }
  if(!keywords.includes("اصفر")){
  keywords.push("اصفر")
  }
  if(!keywords.includes("صفراء")){
  keywords.push("صفراء")
  }
  if(!keywords.includes("صفرا")){
  keywords.push("صفراء")
  }
}

if(purseInsideColor === "برتقالي"){
  if(!keywords.includes("برتقالي")){
    keywords.push("برتقالي")
  }
  if(!keywords.includes("برتقالية")){
    keywords.push("برتقالية")
  }
  if(!keywords.includes("برتقاليه")){
    keywords.push("برتقاليه")
  }
}

if(purseInsideColor === "بنفسجي"){
  if(!keywords.includes("بنفسجي")){
    keywords.push("بنفسجي")
  }
  if(!keywords.includes("بنفسجية")){
    keywords.push("بنفسجية")
  }
  if(!keywords.includes("بنفسجيه")){
    keywords.push("بنفسجيه")
  }
}

if(purseInsideColor === "وردي"){
  if(!keywords.includes("وردي")){
    keywords.push("وردي")
  }
  if(!keywords.includes("وردية")){
    keywords.push("وردية")
  }
  if(!keywords.includes("ورديه")){
    keywords.push("ورديه")
  }
}

if(purseInsideColor === "بني"){
  if(!keywords.includes("بني")){
    keywords.push("بني")
  }
  if(!keywords.includes("بنية")){
    keywords.push("بنية")
  }
  if(!keywords.includes("بنيه")){
    keywords.push("بنيه")
  }
}

if(purseInsideColor === "رمادي"){
  if(!keywords.includes("رمادي")){
    keywords.push("رمادي")
  }
  if(!keywords.includes("رمادية")){
    keywords.push("رمادية")
  }
  if(!keywords.includes("رماديه")){
    keywords.push("رماديه")
  }
}

if(purseInsideColor === "زيتي"){
  if(!keywords.includes("زيتي")){
    keywords.push("زيتي")
  }
  if(!keywords.includes("زيتية")){
    keywords.push("زيتية")
  }
  if(!keywords.includes("زيتيه")){
    keywords.push("زيتيه")
  }
}

if(purseInsideColor === "سماوي"){
  if(!keywords.includes("سماوي")){
    keywords.push("سماوي")
  }
  if(!keywords.includes("سماوية")){
    keywords.push("سماوية")
  }
  if(!keywords.includes("سماويه")){
    keywords.push("سماويه")
  }
}

if(purseInsideColor === "كحلي"){
  if(!keywords.includes("كحلي")){
    keywords.push("كحلي")
  }
  if(!keywords.includes("كحلية")){
    keywords.push("كحلية")
  }
  if(!keywords.includes("كحليه")){
    keywords.push("كحليه")
  }
}
}


if(purseInsideColor === "أسود"){
if(!keywords.includes("سوداء")){
keywords.push("سوداء")
}
if(!keywords.includes("سودا")){
keywords.push("سودا")
}
}
else if(purseInsideColor === "أبيض"){
if(!keywords.includes("بيضاء")){
keywords.push("بيضاء")
}
if(!keywords.includes("بيضا")){
keywords.push("بيضا")
}
}
else if(purseInsideColor === "وردي"){
if(!keywords.includes("وردية")){
keywords.push("وردية")
}
if(!keywords.includes("ورديه")){
keywords.push("ورديه")
}
}
else if(purseInsideColor === "ذهبي"){
if(!keywords.includes("ذهبية")){
keywords.push("ذهبية")
}
if(!keywords.includes("ذهبيه")){
keywords.push("ذهبيه")
}
}
else if(purseInsideColor === "زيتي"){
if(!keywords.includes("زيتية")){
keywords.push("زيتية")
}
if(!keywords.includes("زيتيه")){
keywords.push("زيتيه")
}
}
else if(purseInsideColor === "أخضر"){
if(!keywords.includes("خضراء")){
keywords.push("خضراء")
}
if(!keywords.includes("خضرا")){
keywords.push("خضرا")
}
}
else if(purseInsideColor === "بني"){
if(!keywords.includes("بنية")){
keywords.push("بنية")
}
if(!keywords.includes("بنيه")){
keywords.push("بنيه")
}
}
else if(purseInsideColor === "كحلي"){
if(!keywords.includes("كحلية")){
keywords.push("كحلية")
}
if(!keywords.includes("كحليه")){
keywords.push("كحليه")
}
}
else if(purseInsideColor === "سماوي"){
if(!keywords.includes("سماوية")){
keywords.push("سماوية")
}
if(!keywords.includes("سماويه")){
keywords.push("سماويه")
}
}
else if(purseInsideColor === "بنفسجي"){
if(!keywords.includes("بنفسجية")){
keywords.push("بنفسجية")
}
if(!keywords.includes("بنفسجيه")){
keywords.push("بنفسجيه")
}
}
else{
// if(!keywords.includes(purseOutsideColorSpecification)){
// keywords.push(purseOutsideColorSpecification)
// }
}

if(!keywords.includes("شنطة")){
keywords.push("شنطة")
}
if(!keywords.includes("شنطه")){
keywords.push("شنطه")
}
if(!keywords.includes("حقيبه")){
keywords.push("حقيبه")
}
}

const coll = collection(db, "Posts");
const snapshot = await getCountFromServer(coll);
const docRef = doc(db, `Users/${auth.currentUser.uid}`)
const docSnap = await getDoc(docRef)
var newId = ++snapshot.data().count;

if(docSnap.data()['city'] !== "")
  keywords.push(docSnap.data()['city'])
keywords.push(newId.toString())

const d = [];

// #region
// if(productSubCategory == 'جوال'){
//   d.push({
//     productName: productName,
//     productDescription: productDescription,
//     category: productCategory,
//     subCategory: productSubCategory,
//     productPrice: parseFloat(productPrice),
//     condition: productCondition,
//     imgs: urls,
//     expiryDate: productExpiryDate,
//     id: newId.toString(),
//     numBidders: 0,
//     seller: {
//       sellerImg: docSnap.data()['profileImgUrl'],
//       sellerRating: parseFloat(docSnap.data()['rating']), 
//       sellerName: docSnap.data()['fullName'],
//       sellerId: auth.currentUser.uid,
//       sellerNumRaters: docSnap.data()['numRaters']
//     },
//     createdAt: serverTimestamp(),
//     currentBid: 0,
//     isAuction: isAuction,
//     startingPrice: parseInt(startingPrice),
//     productCompany: productCompany,
//     heighestBidder: "",
//     productKind: productKind,
//     productStorageSize: productStorageSize,
//     keywords: keywords,
//     isDeleted: false, 
//     deletedDate : "",
//     isUpdated: false,
//     lastUpdated: "",
//     isSold: false,
//     startingPrice: startingPrice,
//     bidders: {},
//     isReceived: false,
//     receivedDate: "",
//     soldDate: ""
//   })
// }

// else if(productSubCategory == 'شاشة'){
//   d.push(
//     {
//       productName: productName,
//       productDescription: productDescription,
//       category: productCategory,
//       subCategory: productSubCategory,
//       productPrice: parseFloat(productPrice),
//       condition: productCondition,
//       imgs: urls,
//       expiryDate: productExpiryDate,
//       id: newId.toString(),
//       numBidders: 0,
//       seller: {
//         sellerImg: docSnap.data()['profileImgUrl'],
//         sellerRating: parseFloat(docSnap.data()['rating']), 
//         sellerName: docSnap.data()['fullName'],
//         sellerId: auth.currentUser.uid,
//         sellerNumRaters: docSnap.data()['numRaters']
//       },
//       createdAt: serverTimestamp(),
//       currentBid: 0,
//       isAuction: isAuction,
//       startingPrice: parseInt(startingPrice),
//       productCompany: productCompany,
//       productKind: productKind,
//       heighestBidder: "",
//       productScreenSize: productScreenSize,
//       keywords: keywords,
//       isDeleted: false, 
//       isUpdated: false,
//       isSold: false,
//       bidders: {},
//       isReceived: false,
//       receivedDate: "",
//       soldDate: ""
//     }
//   )
// }





// else if(productSubCategory == 'لابتوب'){
//   d.push(
//     {
//       productName: productName,
//       productDescription: productDescription,
//       category: productCategory,
//       subCategory: productSubCategory,
//       productPrice: parseFloat(productPrice),
//       condition: productCondition,
//       imgs: urls,
//       expiryDate: productExpiryDate,
//       id: newId.toString(),
//       numBidders: 0,
//       seller: {
//         sellerImg: docSnap.data()['profileImgUrl'],
//         sellerRating: parseFloat(docSnap.data()['rating']), 
//         sellerName: docSnap.data()['fullName'],
//         sellerCity: docSnap.data()['city'] != "" ? docSnap.data()['city'] : "", 
//         sellerId: auth.currentUser.uid
//       },
//       createdAt: serverTimestamp(),
//       currentBid: 0,
//       isAuction: isAuction,
//       startingPrice: parseInt(startingPrice),
//       productCompany: productCompany,
//       productKind: productKind,
//       productScreenSize: productScreenSize,
//       productMemorySize: productMemorySize,
//       keywords: keywords,
//       isDeleted: false, 
//       isUpdated: false,
//       bidders: {},
//       isSold: false,
//       isReceived: false,
//       receivedDate: "",
//       soldDate: ""

//     }
//   )
// }
// else if(productSubCategory == 'جهاز لوحي'){
//   d.push(
//     {
//       productName: productName,
//       productDescription: productDescription,
//       category: productCategory,
//       subCategory: productSubCategory,
//       productPrice: parseFloat(productPrice),
//       condition: productCondition,
//       imgs: urls,
//       expiryDate: productExpiryDate,
//       id: newId.toString(),
//       numBidders: 0,
//       seller: {
//         sellerImg: docSnap.data()['profileImgUrl'],
//         sellerRating: parseFloat(docSnap.data()['rating']), 
//         sellerName: docSnap.data()['fullName'],
//         sellerCity: docSnap.data()['city'] != "" ? docSnap.data()['city'] : "",
//         sellerId: auth.currentUser.uid
//       },
//       createdAt: serverTimestamp(),
//       currentBid: 0,
//       isAuction: isAuction,
//       startingPrice: parseInt(startingPrice),
//       productCompany: productCompany,
//       productKind: productKind,
//       productMemorySize: productMemorySize,
//       keywords: keywords,
//       isDeleted: false, 
//       isUpdated: false,
//       bidders: {},
//       isSold: false,
//       isReceived: false,
//       receivedDate: "",
//       soldDate: ""
//     }
//   )
// }
// else if(productSubCategory == 'طابعة'){
//   d.push(
//      {
//       productName: productName,
//       productDescription: productDescription,
//       category: productCategory,
//       subCategory: productSubCategory,
//       productPrice: parseFloat(productPrice),
//       condition: productCondition,
//       imgs: urls,
//       expiryDate: productExpiryDate,
//       id: newId.toString(),
//       numBidders: 0,
//       seller: {
//         sellerImg: docSnap.data()['profileImgUrl'],
//         sellerRating: parseFloat(docSnap.data()['rating']), 
//         sellerName: docSnap.data()['fullName'],
//         sellerNumRaters: docSnap.data()['numRaters'],
//         sellerId: auth.currentUser.uid
//       },
//       createdAt: serverTimestamp(),
//       currentBid: 0,
//       isAuction: isAuction,
//       startingPrice: parseInt(startingPrice),
//       productCompany: productCompany,
//       productKind: productKind,
//       productInkKind: productInkKind,
//       keywords: keywords,
//       isDeleted: false, 
//       isUpdated: false,
//       bidders: {},
//       isSold: false,
//       isReceived: false,
//       receivedDate: "",
//       soldDate: ""
//     }
//   )
// }
//#endregion
// else{
// await uploadImagesToStorageAndFirestore(images, newId)
d.push(
{
productName: productName,
productDescription: productDescription,
category: productCategory,
subCategory: productSubCategory,
productPrice: parseFloat(productPrice) ? parseFloat(productPrice) : "",
condition: productCondition,
expiryDate: productExpiryDate != "" ? Timestamp.fromDate(new Date(productExpiryDate)) : "",
id: parseFloat(newId),
numBidders: 0,
sellerId: docSnap.data()['id'],
createdAt: serverTimestamp(),
currentBid: 0,
entryFee: parseFloat(entryFee) || 0,
heighestBidder: "",
postType: postType,
startingPrice: parseInt(startingPrice) ||  0,
productCompany: productCompany,
productKind: productKind,
keywords: keywords,
carPlateTransferTime: carPlateTransferTime,
watchInsideColor: watchInsideColor,
watchInsideColorSpecification: watchInsideColorSpecification,
watchOutsideColor: watchOutsideColor,
watchOutsideColorSpecification: watchOutsideColorSpecification,
watchDialSize: parseFloat(watchDialSize),
walletOutsideColor: walletColor,
watchDialShape: watchDialShape,
watchHandlesColor: watchHandlesColor,
watchHandlesColorSpecification: watchHandlesColorSpecification,
watchNumbersColor: watchNumbersColor,
watchNumbersColorSpecification: watchNumbersColorSpecification,
watchNumbersLanguage: watchNumbersLanguage,
watchBandColor: watchBandMaterial === "ستانلس ستيل" ? "ستانلس ستيل" : watchBandColor,
watchBandColorSpecification: watchBandColorSpecification,
watchBandMaterial: watchBandMaterial,
watchHasOriginalPackaging: watchHasOriginalPackaging === true,
watchYearMade: parseFloat(watchYearMade),
watchIsWaterResistant: watchIsWaterResistant === true,
rosaryKind: rosaryKind,
walletDepartment: walletDepartment,
rosaryKindSpecification: rosaryKindSpecification,
rosaryColor: rosaryColor,
rosaryColorSpecification: rosaryColorSpecification,
rosaryCount: parseFloat(rosaryCount) || 0,
rosarySize: parseFloat(rosarySize) || 0,
carPlateFirstArabicLetter: carPlateFirstArabicLetter,
carPlateSecondArabicLetter: carPlateSecondArabicLetter,
carPlateThirdArabicLetter: carPlateThirdArabicLetter,
carPlateFirstArabicNumber: carPlateFirstArabicNumber,
carPlateSecondArabicNumber: carPlateSecondArabicNumber,
carPlateThirdArabicNumber: carPlateThirdArabicNumber,
carPlateFourthArabicNumber: carPlateFourthArabicNumber,
carPlateFirstEnglishLetter: carPlateFirstEnglishLetter,
carPlateSecondEnglishLetter: carPlateSecondEnglishLetter,
carPlateThirdEnglishLetter: carPlateThirdEnglishLetter,
carPlateFirstEnglishNumber: carPlateFirstEnglishNumber,
carPlateSecondEnglishNumber: carPlateSecondEnglishNumber,
carPlateThirdEnglishNumber: carPlateThirdEnglishNumber,
carPlateFourthEnglishNumber: carPlateFourthEnglishNumber,
carPlateTransferTime: carPlateTransferTime,
carPlateTransferFeeOn: carPlateTransferFeeOn,
carPlateKind: carPlateKind,
productQty: parseFloat(productQty),
phoneNumberCourier: phoneNumberCourier,
phoneNumberToSell: phoneNumberToSell,
purseOutsideColor: purseOutsideColor,
purseMaterial: purseMaterial,
purseOutsideColorSpecification: purseOutsideColorSpecification,
purseInsideColor: purseInsideColor,
purseInsideColorSpecification,
isDeleted: false, 
isUpdated: false,
bidders: {},
isSold: false,
isReceived: false,
receivedDate: "",
soldDate: "",
autoReceiveDate: "",
views: 0,
productWeight: parseFloat(productWeight) || 0.5,
imgs: await uploadImagesToStorageAndFirestore(postImages, newId),
isRejected: false,
lastBidDate: "",
updatedDate: "",
bidIncrements: parseInt(bidIncrements) || 0,
numBids: 0,
shippingMethods: shippingMethods,
watchDepartment: watchDepartment
}
)
// }

// await uploadImagesToStorageAndFirestore(images, newId).then(async ()=>{
// uploadImagesToStorageAndFirestore(images, newId)
setDoc(doc(db, "Posts", `post${newId.toString()}`), d[0])


.then(()=>{

setSuccessMessage("!تم إضافة المنتج بنجاح")
setShowSuccessAnim(true);


// // Call scrollToTop function to scroll to the top of the page instantly
scrollToTop();
productNameRef.current.value = "";
productDescriptionRef.current.value = "";
setPostImages([]);
setShippingMethods([])
setProductName("");
setProductDescription("");
setProductPrice("");
setProductCondition("");
setProductCategory("");
setProductSubCategory("");
setProductExpiryDate("");
setProductWeight(0.5);
// setPassedDateSelected(false);
setStartingPrice("");
setBidIncrements("");
setGal([]);
setIsLoading(false);
setAddProductLoading(false)
setBlobImgs([]);
// setIsAuction(false);
setPostType([])

setProductCompany("");
setWatchInsideColor("");
setWatchInsideColorSpecification("");
setWatchOutsideColor("");
setWatchOutsideColorSpecification("");
setWatchDialSize("");
setWatchDialShape("");
setWatchNumbersColor("");
setWatchNumbersColorSpecification("");
setWatchHandlesColor("");
setWatchHandlesColorSpecification("");
setWatchNumbersLanguage("");
setWatchBandMaterial("");
setWatchBandColor("");
setWatchBandColorSpecification("");
setWatchHasOriginalPackaging(false);
setWatchYearMade("");
setWatchIsWaterResistant(false);
setWatchDepartment("");

setCarPlateTransferTime("");
setCarPlateFirstArabicLetter("");
setCarPlateSecondArabicLetter("");
setCarPlateThirdArabicLetter("");
setCarPlateFirstArabicNumber("");
setCarPlateSecondArabicNumber("");
setCarPlateThirdArabicNumber("");
setCarPlateFourthArabicNumber("");
setCarPlateFirstEnglishLetter("");
setCarPlateSecondEnglishLetter("");
setCarPlateThirdEnglishLetter("");
setCarPlateFirstEnglishNumber("");
setCarPlateSecondEnglishNumber("");
setCarPlateThirdEnglishNumber("");
setCarPlateFourthEnglishNumber("");

setRosaryKind("");
setRosaryKindSpecification("");
setRosaryColor("");
setRosaryColorSpecification("");
setRosaryCount(0);
setRosarySize(0);
setRosaryWeight(0);

setWalletDepartment("");
setPhoneNumberCourier("");
setPhoneNumberToSell("");
setPurseOutsideColor("");
setPurseOutsideColorSpecification("");
setPurseInsideColor("");
setPurseInsideColorSpecification("");

setProductKind("");

// Resetting all error states to false
setProductImageHasError(false);
setProductNameHasError(false);
setProductDescriptionHasError(false);
setProductPriceHasError(false);
setProductExpiryDateHasError(false);
setProductStartingPriceHasError(false);
setProductConditionHasError(false);
setProductWeightHasError(false);
setProductCategoryHasError(false);
setProductCompanyHasError(false);
setBidIncrementHasError(false);
setIsAuctionHasError(false);

setWatchDepartmentHasError(false);
setWatchInsideColorHasError(false);
setWatchInsideColorSpecificationHasError(false);
setWatchOutsideColorHasError(false);
setWatchOutsideColorSpecificationHasError(false);

setCarPlateTransferTimeHasError(false);
setCarPlateFirstArabicLetterHasError(false);
setCarPlateFirstArabicNumberHasError(false);
setCarPlateFirstEnglishLetterHasError(false);
setCarPlateFirstEnglishNumberHasError(false);

setRosaryKindHasError(false);
setRosaryKindSpecificationHasError(false);
setRosaryColorHasError(false);
setRosaryColorSpecificationHasError(false);
setRosaryCountHasError(false);
setRosarySizeHasError(false);

setWalletDepartmentHasError(false);
setPhoneNumberCourierHasError(false);
setPhoneNumberToSellHasError(false);

setPurseOutsideColorHasError(false);
setPurseOutsideColorSpecificationHasError(false);
setPurseInsideColorHasError(false);
setPurseInsideColorSpecificationHasError(false);

})
.catch(()=>{
// error animation
})
// })

//#region 
// await setDoc(doc(db, "Posts", `post${newId.toString()}`), d[0]).then(()=>{
//   uploadImagesToStorageAndFirestore(images, newId)
//   .then((downloadURLs) => {
//     // Handle success

//     console.log('Images uploaded successfully:', downloadURLs);
//   })
//   .catch((error) => {
//     // Handle error
//     console.error('Error uploading images:', error);
//   });
// });

// ####################################################################

// blobs

//   const docData = {
//   productName: productName,
//   productDescription: productDescription,
//   category: productCategory,
//   subCategory: productSubCategory,
//   productPrice: productPrice,
//   productCondition: productCondition,
//   imgs: blobImgs,
//   expiryDate: productExpiryDate,
//   id: "testPost",
//   currentBid: "0"
// };
// setDoc(doc(db, "Posts", "testPost2"), docData);



// ############################################################

// const base64urls =[];
// for(var i = 0 ; i < images.length ; i++){
//   await getBase64(images[i]) // `file` your img file
//   .then(res => {
//     base64urls.push(res);
//     console.log(res)
//     console.log(res.length)
//   }) // `res` base64 of img file
//   .catch(err => console.log(err))
// }


//   const docData = {
//   productName: productName,
//   productDescription: productDescription,
//   category: productCategory,
//   subCategory: productSubCategory,
//   productPrice: productPrice,
//   productCondition: productCondition,
//   imgs: base64urls,
//   expiryDate: productExpiryDate,
//   id: "testPost",
//   currentBid: "0"
// };
// await setDoc(doc(db, "Posts", "testPost2"), docData);
//#endregion
setIsLoading(false);
    };

    const watchYearMadeOptions = [];

  for (let year = 1990; year <= 2024; year++) {
    watchYearMadeOptions.push(<option style={{backgroundColor:"white", textAlign:"right"}} key={year} value={year}>{year}</option>);
  }

  const rosarySizes = [];

  for (let size = 5; size <= 16; size += .5){
    rosarySizes.push(<option style={{backgroundColor:"white", textAlign:"right"}} key={size} value={size}>{size} ملم</option>)
  }

  useEffect(() => {
    window.scrollTo({
top: 0,
left: 0,
behavior: 'instant'
});
}, []);


    useEffect(() => {
      auth.onAuthStateChanged(async function (user) {
        if (user != null) {
          if (postImages.length < 1) {
            setIsLoading(false);
            return;
          }

          if(productNameHasError){
            setIsLoading(false);
            return;
          }
    
          const temp = [];
          const tempGal = [];
    
          setBlobImgs(temp);
          setGal(tempGal);
        } else {
          // Handle the case when user is null (optional, add your logic if needed)
        }
    
        setIsLoading(false);
      });
    }, [postImages]);

        
        if(isLoading){
          return <LoadingScreen/>
        }

        if(auth.currentUser === null ){
          return <SignInOrSignUpScreen prompt={"الرجاء التسجيل او تسجيل الدخول لإضافة منتج"} />
         }




    

// Helper function to convert base64 to Blob
const b64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};




const uploadImagesToStorageAndFirestore = async (images, postId) => {
  try {
      const uploadPromises = images.map(async (image, index) => {
          try {
              if (image && image.src && image.src.trim()) {
                  const base64Data = image.src.split(',')[1]; // Get the base64 data
                  const blob = b64toBlob(base64Data, 'image/jpeg'); // Convert base64 to Blob
                  const storageRef = ref(storage, `Posts/post${postId}/${index}`); // Create a reference to the storage path
                  await uploadBytes(storageRef, blob); // Upload the Blob to Firebase Storage
                  const downloadURL = await getDownloadURL(storageRef); // Get the download URL
                  return downloadURL; // Return the download URL
              } else {
                  console.error(`Image data is missing or empty for image at index ${index}`);
                  return null;
              }
          } catch (error) {
              console.error(`Error uploading image ${index + 1}:`, error);
              return null;
          }
      });

      const imageURLs = await Promise.all(uploadPromises); // Wait for all uploads to finish
      return imageURLs.filter(url => url !== null); // Filter out any null values (failed uploads)
  } catch (error) {
      console.error('Error uploading images to storage and updating Firestore:', error);
      return [];
  }
};
        

        const handleImageCropped = (index, croppedImage) => {
          // Update the cropped image in the postImages state
          const updatedPostImages = [...postImages];
          updatedPostImages[index] = { ...updatedPostImages[index], croppedImage };
          setPostImages(updatedPostImages);
      
          // Upload the cropped image to Firestore (implement this logic)
          // You can use Firebase Firestore here to upload the cropped image
          // Example code to upload to Firestore:
          // const uploadImageToFirestore = async (imageData) => {
          //   // Implement the Firestore upload logic here
          //   // ...
          //   // After successful upload, update the uploadedImages state
          //   const updatedUploadedImages = [...uploadedImages];
          //   updatedUploadedImages[index] = { /* ... */ };
          //   setUploadedImages(updatedUploadedImages);
          // };
      
          // Call the uploadImageToFirestore function with croppedImage data
          // uploadImageToFirestore(croppedImage);
        };


    // async function getBase64(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(file)
    //     reader.onload = () => {
    //       resolve(reader.result)
    //     }
    //     reader.onerror = reject
    //   })
    // }

    function calculateShippingCost(weight) {
      const basePrice = 23; // تكلفة الشحن لأول 15 كيلوجرام
      const additionalPricePerKilo = 2; // تكلفة الشحن لكل كيلوجرام إضافي
      const baseWeight = 15; // الوزن الأساسي
  
      if (weight <= baseWeight) {
          return basePrice;
      } else {
          const additionalWeight = weight - baseWeight;
          const additionalCost = additionalWeight * additionalPricePerKilo;
          return basePrice + additionalCost;
      }
  }

  function formatAccountingArabic(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with commas and two decimal places
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits: number > 100 ? 0 : 2,
      maximumFractionDigits: number > 100 ? 0 : 2,
    });
  
    // Add the Arabic currency symbol manually
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
  
    return arabicFormattedNumber;
}

const handleChange = (e) => {
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  const oneWeekFromNow = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
  const fiveSecondsAgo = new Date(currentDate.getTime() - 20 * 1000); // 5 seconds ago

  setPassedDateSelected(false)
  setPassedOneWeekSelected(false)
    if (selectedDate <= fiveSecondsAgo) {
      alert("يجب ان يكون تاريخ الانتهاء في المستقبل");
      setProductExpiryDateHasError(true);
      setProductExpiryDate("")
      setPassedDateSelected(true);
    } else if (selectedDate > oneWeekFromNow) {
      alert("لا يمكن أن يكون التاريخ المُحدد أكثر من أسبوع من الآن");
      setProductExpiryDate("")
      setProductExpiryDateHasError(true);
      setPassedOneWeekSelected(true);
    } else {
      setProductExpiryDateHasError(false);
      setPassedDateSelected(false);
      setPassedOneWeekSelected(false);
      setProductExpiryDate(e.target.value);
    }
};



const uploadCroppedImagesToFirebase = async (croppedImages) => {
  try {
    // Iterate through croppedImages and upload each one to Firebase Storage
    for (const croppedImage of croppedImages) {
      // Implement Firebase Storage upload logic here
      // You can use Firebase SDK to upload images
      // Example: uploadImageToFirebaseStorage(croppedImage);
    }
  } catch (error) {
    console.error('Error uploading cropped images:', error);
  }
};


const handleSelectCategory = (category) => {
  if(category !== productCategory){
    setWatchDepartment("")
    setProductCompany("")
    setWatchInsideColor("")
    setWatchOutsideColor("")
    setWatchDialShape("")
    setWatchDialSize("")
    setWatchHandlesColor("")
    setWatchNumbersColor("")
    setWatchNumbersLanguage("")
    setWatchBandMaterial("")
    setWatchBandColor("")
    setWatchHasOriginalPackaging("")
    setWatchIsWaterResistant("")
    setWatchYearMade("")

    setRosaryKind("")
    setRosaryColor("")
    setRosaryCount("")
    setRosarySize("")

    setWalletDepartment("")
    setWalletColor("")

    setCarPlateKind("")
    setCarPlateTransferFeeOn("")
    setCarPlateTransferTime("")

    setCarPlateFirstArabicLetter("")
    setCarPlateSecondArabicLetter("")
    setCarPlateThirdArabicLetter("")

    setCarPlateFirstArabicNumber("")
    setCarPlateSecondArabicNumber("")
    setCarPlateThirdArabicNumber("")
    setCarPlateFourthArabicNumber("")

    setCarPlateFirstEnglishLetter("")
    setCarPlateSecondEnglishLetter("")
    setCarPlateThirdEnglishLetter("")

    setCarPlateFirstEnglishNumber("")
    setCarPlateSecondEnglishNumber("")
    setCarPlateThirdEnglishNumber("")
    setCarPlateFourthEnglishNumber("")


    // continue after finishing categoreies
  }
    setProductCategory(category);
    setProductCategoryScrollTrigger(true);
};

const handleSelectWatchProductCompany = (company) => {
  setProductCompany(company); 
  if(company === "Apple" || company === "Samsung" || company === "Huawei" || company === "Xiaomi")
  setWatchDepartment("")
  setProductCompanyScrollTrigger(true)
};
const handleSelectPenProductCompany = (company) => {
  setProductCompany(company); 
  setProductCompanyScrollTrigger(true)
};
const handleSelectPhoneCourier = (company) => {
  setPhoneNumberCourier(company); 
  setPhoneCourierScrollTrigger(true)
};
const handleSelectPurseMaterial = (company) => {
  setPurseMaterial(company); 
  setPurseMaterialScrollTrigger(true)
};
const handleSelectWatchDepartment = (company) => {
  setWatchDepartment(company);
  setWatchDepartmentScrollTrigger(true)
};
const handleSelectWalletDepartment = (company) => {
  setWalletDepartment(company);
  setWalletDepartmentScrollTrigger(true)
};
const handleSelectRosaryKind = (company) => {
  setRosaryKind(company);
  setRosaryKindScrollTrigger(true)
};
const handleSelectWatchInsideColor = (company) => {
  setWatchInsideColor(company);
  setWatchInsideColorScrollTrigger(true);
};
const handleSelectWalletColor = (company) => {
  setWalletColor(company);
  setWalletColorScrollTrigger(true);
};
const handleSelectCarPlateKind = (company) => {
  setCarPlateKind(company);
  setCarPlateKindScrollTrigger(true);
};
const handleSelectCarPlateTransferFeeOn = (company) => {
  setCarPlateTransferFeeOn(company);
  setCarPlateTransferFeeOnScrollTrigger(true);
};
const handleSelectCarPlateTransferTime = (company) => {
  setCarPlateTransferTime(company);
  setCarPlateTransferTimeScrollTrigger(true);
};
const handleSelectWatchOutsideColor = (company) => {
  setWatchOutsideColor(company);
  setWatchOutsideColorScrollTrigger(true);
};
const handleSelectPurseOutsideColor = (company) => {
  setPurseOutsideColor(company);
  setPurseOutsideColorScrollTrigger(true);
};
const handleSelectPurseInsideColor = (company) => {
  setPurseInsideColor(company);
  setPurseInsideColorScrollTrigger(true);
};
const handleSelectWatchDialShape = (company) => {
  setWatchDialShape(company);
  setWatchDialShapeScrollTrigger(true);

};
const handleSelectWatchDialSize = (company) => {
  setWatchDialSize(company);
  setWatchDialSizeScrollTrigger(true);

};
const handleSelectWatchHandlesColor = (company) => {
  setWatchHandlesColor(company);
  setWatchHandlesColorScrollTrigger(true);
  
};

const handleSelectRosaryColor = (company) => {
  setRosaryColor(company);
  setRosaryColorScrollTrigger(true);
  
};
const handleSelectWatchNumbersLanguage = (company) => {
  setWatchNumbersLanguage(company);
  setWatchNumbersLanguageScrollTrigger(true);
  
};
const handleSelectWatchNumbersColor = (company) => {
  setWatchNumbersColor(company);
  setWatchNumbersColorScrollTrigger(true);
  
};
const handleSelectWatchBandMaterial = (company) => {
  setWatchBandMaterial(company);
  setWatchBandMaterialScrollTrigger(true);
};
const handleSelectWatchBandColor = (company) => {
  setWatchBandColor(company);
  setWatchBandColorScrollTrigger(true);
  
};
const handleSelectWatchHasOriginalPackaging = (company) => {
  setWatchHasOriginalPackaging(company);
  setWatchHasOriginalPackagingScrollTrigger(true);

};
const handleSelectWatchIsWaterResistant = (company) => {
  setWatchIsWaterResistant(company);
  setWatchIsWaterResistantScrollTrigger(true);
  
};
const handleSelectWatchYearMade = (company) => {
  setWatchYearMade(company);
  setWatchYearMadeScrollTrigger(true)
};

// const handleSelectIsAuction = (company) => {
//   setIsAuction(company);
//   setIsAuctionScrollTrigger(true)
// };

const handleSelectProductCondition = (company) => {
  setProductCondition(company);
  setProductConditionScrollTrigger(true)
};


  return ( 
    <>
{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}

<div ref={productCategoryDivRef} 
style={{
  width: window.innerWidth > window.innerHeight ? "80%" : "100%", 
  height:"fit-content", 
  marginLeft: "auto", 
  marginRight:"auto", 
  direction:"rtl", 
  paddingBottom:"32px", 
  padding: window.innerWidth > window.innerHeight ?  "auto" : "0px 16px", 
  minHeight:"100vh",
  }}>


          {/* path */}
          <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width:"100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>إضافة منتج</h6>
        </div>
      </div>


<h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px", marginTop:"16px"}}>تفاصيل المنتج</h1>

<h5 style={{fontWeight: "500", color: "#00A49B" }}><span style={{color:"red"}}>*</span>اختر التصنيف:</h5>


<SelectableCategory selectedCategory={productCategory} onSelect={handleSelectCategory}>
  <div category="ساعات" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
    <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", padding: "8px", backgroundColor: "#f7f7f7"}}
              src={watch}  />
    <p id='categoryNames' style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>ساعات</p>
  </div>
  <div category="سبح">
  <img id='landing-screen-img-size' style={{borderRadius: "1000px", backgroundColor:"#F7F7F7"}} src={sbhah} />
    <p id='categoryNames' style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>سبح</p>
  </div>
  <div category="اقلام">
  <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", padding: "8px", backgroundColor:"#FAFAFA"}} src={pen} />

    <p id='categoryNames' style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أقلام</p>
  </div>
  <div category="ابواك">
  <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} src={wallet} />
    <p id='categoryNames' style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أبواك</p>
  </div>
  <div category="حقائب نسائية">
    <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} src={purses} />
    <p id='categoryNames' style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>حقائب نسائية</p>
  </div>
  <div category="لوح سيارات">
    <img id='landing-screen-img-size' src={plateIcon} style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} alt="لوح سيارات" />
    <p id='categoryNames' style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوح سيارات</p>
  </div>
  <div category="ارقام جوالات">
    <img id='landing-screen-img-size' src={phoneNumberIcon} style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} alt="ارقام جوالات" />
    <p id='categoryNames' style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أرقام جوالات</p>
  </div>
</SelectableCategory>

<p style={{marginBottom:"0px", fontWeight:"500", marginTop:"32px"}}>لا ترى تصنيف منتجك؟ <Link 
    style={{ borderBottom : "1px solid"
    }} to="/contact_us">تواصل معنا</Link></p>
</div>


{
  productCategory === "ساعات" && (

    <>


<div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", paddingBottom: "32px", padding: window.innerWidth > window.innerHeight ? "auto" : "0px 16px" }}>


      <div id='watch-details-div' style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
        <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectWatchProductCompany}>
  <div category="Rolex">
    <img id='landing-screen-img-size' src={rolexLogo} style={{objectFit:"cover"}} alt="Rolex" />
  </div>
  <div category="Omega">
    <img id='landing-screen-img-size' src={omegaLogo} style={{objectFit:"scale-down"}} alt="Omega" />
  </div>
  <div category="Cartier">
    <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
  </div>
  <div category="Versace">
    <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
  </div>
  <div category="Montblanc">
    <img id='landing-screen-img-size' src={montBlancLogo} style={{objectFit:"scale-down"}} alt="Montblanc" />
  </div>
  <div category="Aigner">
    <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
  </div>
  <div category="Rado">
    <img id='landing-screen-img-size' src={radoLogo} style={{objectFit:"scale-down"}} alt="Rado" />
  </div>
  <div category="Givenchy">
    <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
  </div>
  <div category="Chopard">
    <img id='landing-screen-img-size' src={chopardLogo} style={{objectFit:"scale-down"}} alt="Chopard" />
  </div>
  <div category="Tissot">
    <img id='landing-screen-img-size' src={tissotLogo} style={{objectFit:"scale-down"}} alt="Tissot" />
  </div>
  <div category="Patek Philippe">
    <img id='landing-screen-img-size' src={patekPhilippeLogo} style={{objectFit:"scale-down"}} alt="Patek Philippe" />
  </div>
  <div category="Armani Exchange">
    <img id='landing-screen-img-size' src={armaniExchangeLogo} style={{objectFit:"scale-down"}} alt="Armani Exchange" />
  </div>
  <div category="G-Shock">
    <img id='landing-screen-img-size' src={gShockLogo} style={{objectFit:"scale-down"}} alt="G-Shock" />
  </div>
  <div category="Apple">
    <img id='landing-screen-img-size' src={appleLogo} style={{objectFit:"scale-down"}} alt="Apple" />
  </div>
  <div category="Huawei">
    <img id='landing-screen-img-size' src={huaweiLogo} style={{objectFit:"scale-down"}} alt="Huawei" />
  </div>
  <div category="Samsung">
    <img id='landing-screen-img-size' src={samsungLogo} style={{objectFit:"scale-down"}} alt="Samsung" />
  </div>
  <div category="Xiaomi">
    <img id='landing-screen-img-size' src={xiaomiLogo} style={{objectFit:"scale-down"}} alt="Xiaomi" />
  </div>
  <div category="Other" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أخرى</p>
  </div>
</SelectableWatchCompany>

      </div>

{
  productCompany !== "Apple" &&
  productCompany !== "Samsung" && 
  productCompany !== "Huawei" && 
  productCompany !== "Xiaomi" && (
    <>
    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight: "100vh"}}>
            
            
            
            
            <div ref={watchDepartmentRef} id='watch-department-div' style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>قسم الساعات:</h5>
              <SelectableWatchCompany selectedCategory={watchDepartment} onSelect={handleSelectWatchDepartment}>
              
              <div  category="رجالي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <IoMdMale color='#00C8B8' style={{width:"80px", height:"80px"}}/>
                </div>
                <p style={{marginBottom:"0px", marginTop:"4px", color:"#01635c"}}>رجالي</p>
              </div>

              <div  category="نسائي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <IoFemale color='#F00057' style={{width:"80px", height:"80px"}} />
                </div>
                <p style={{marginBottom:"0px", marginTop:"4px", color:"#01635c"}}>نسائي</p>
              </div>


              <div  category="للجنسين" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <FaTransgender color='gray' style={{width:"80px", height:"80px"}} />
                </div>
                <p style={{marginBottom:"0px", marginTop:"4px", color:"#01635c"}}>للجنسين</p>
              </div>
              </SelectableWatchCompany>
            </div>
          </div>

    </>
  )
}

      <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",  minHeight:"100vh" }}>
            <div ref={watchOutsideColorRef} id='watch-outsideColor-div' style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون قرص الساعة الخارجي:</h5>
              <SelectableWatchCompany selectedCategory={watchOutsideColor} onSelect={handleSelectWatchOutsideColor}>
              
              <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                  <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>

                  </div>
                  <p style={{marginBottom:"0px", marginTop:"4px", color:"#01635c"}}>ستانلس ستيل</p>
                </div>

                <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px", color:"#01635c" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>زيتي</p>
  </div>


  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c"}}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>آخر</p>
    </div>


              </SelectableWatchCompany>
            </div>
          </div>


      {
        isOtherCompany && (
          <>
         
        
          <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight:"100vh" }}>
            <div ref={watchInsideColorRef} id='watch-insideColor-div' style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون قرص الساعة الداخلي:</h5>
              <SelectableWatchCompany selectedCategory={watchInsideColor} onSelect={handleSelectWatchInsideColor}>
              
                <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                  <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>

                  </div>
                  <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>ستانلس ستيل</p>
                </div>

                <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>زيتي</p>
  </div>


  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>آخر</p>
    </div>


              </SelectableWatchCompany>
            </div>
          </div>

{/* dial shape */}

<div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={watchDialShapeRef} id='watch-dialShape-div' style={{paddingTop:"8px"}}>
              <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>شكل قرص الساعة:</h5>
                <div>
                  {
                    watchDialShape && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchDialShape("")}}>إعادة تعيين</button>
                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchDialShapeScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchDialShape} onSelect={handleSelectWatchDialShape}>
              
              <div  category="دائري" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <FaRegCircle color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>دائري</p>
  </div>



  <div  category="مربع" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <FaRegSquare color='#00C8B8' style={{width:"80px", height:"80px"}}/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>مربع</p>
  </div>


              <div  category="مستطيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <PiRectangleBold color='#00C8B8' style={{width:"80px", height:"80px"}}/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>مستطيل</p>
  </div>



  <div  category="بيضاوي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <TbOval color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>بيضاوي</p>
  </div>


  <div  category="خماسي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <RiPentagonLine color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>خماسي</p>
  </div>

  <div  category="سداسي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <LuHexagon color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>سداسي</p>
  </div>



  



              </SelectableWatchCompany>
            </div>
          </div>
        







{/* dial size  */}
<div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={watchDialSizeRef} id='watch-dialSize-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>حجم قرص الساعة:</h5>
                <div>
                  {
                    watchDialSize && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchDialSize("")}}>إعادة تعيين</button>

                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchDialSizeScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchDialSize} onSelect={handleSelectWatchDialSize}>
              
    <div  category="32" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>32</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>32 ملم</p>
    </div>

    <div  category="34" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>34</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>34 ملم</p>
    </div>

    <div  category="36" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>36</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>36 ملم</p>
    </div>

    <div  category="38" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>38</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>38 ملم</p>
    </div>


    <div  category="40" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>40</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>40 ملم</p>
    </div>


    <div  category="42" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>42</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>42 ملم</p>
    </div>


    <div  category="44" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>44</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>44 ملم</p>
    </div>



    <div  category="46" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>46</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>46 ملم</p>
    </div>



    <div  category="48" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>48</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>48 ملم</p>
    </div>



    <div  category="50" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>50</h1>
        </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>50 ملم</p>
    </div>


              </SelectableWatchCompany>
            </div>
          </div>






    {/* watch handles color */}
    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={watchHandlesColorRef} id='watch-handlesColor-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون عقارب الساعة:</h5>
                <div>
                  {
                    watchHandlesColor && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchHandlesColor("")}}>إعادة تعيين</button>
                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchHandlesColorScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchHandlesColor} onSelect={handleSelectWatchHandlesColor}>
              
              <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                  <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>

                  </div>
                  <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>ستانلس ستيل</p>
                </div>

                <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>زيتي</p>
  </div>


  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c" , color:"#01635c"}}>آخر</p>
    </div>


              </SelectableWatchCompany>
            </div>
          </div>
    
    


    
    
    {/* watch numbers color */}
    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={watchNumbersColorRef} id='watch-numbersColor-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون أرقام الساعة:</h5>
                <div>
                  {
                    watchNumbersColor && (
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchNumbersColor("")}}>إعادة تعيين</button>

                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchNumbersColorScrollTrigger(true)}}>التالي</button>

                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchNumbersColor} onSelect={handleSelectWatchNumbersColor}>
              
              <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                  <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>

                  </div>
                  <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>ستانلس ستيل</p>
                </div>

                <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>زيتي</p>
  </div>


  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c" , color:"#01635c"}}>آخر</p>
    </div>


              </SelectableWatchCompany>
            </div>
          </div>






    {/* watch numbers language */}
    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",minHeight:"100vh" }}>
            <div ref={watchNumbersLanguageRef} id='watch-handlesColor-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لغة أرقام الساعة:</h5>
                <div>
                  {
                    watchNumbersLanguage && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchNumbersLanguage("")}}>إعادة تعيين</button>
                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchNumbersLanguageScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchNumbersLanguage} onSelect={handleSelectWatchNumbersLanguage}>
              
              <div  category="الانجليزية" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                   <h1 style={{fontSize:"48px", fontWeight:"bold", color:"#00C8B8"}}>123</h1>
                
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>الانجليزية</p>
              </div>
              
              
              <div  category="العربية" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                   <h1 style={{fontSize:"48px", fontWeight:"bold", color:"#00C8B8"}}>١٢٣</h1>
                
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>العربية</p>
              </div>

              <div  category="الرومانية" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                   <h1 style={{fontSize:"48px", fontWeight:"bold", color:"#00C8B8", fontFamily: "serif", marginBottom:"0px"}}>XVII</h1>
                
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>الرومانية</p>
              </div>

              <div  category="بدون أرقام" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>بدون أرقام</p>
              </div>


              </SelectableWatchCompany>
            </div>
          </div>







{/* watch band material */}
<div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={watchBandMaterialRef} id='watch-bandMaterial-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", width:"100%", marginBottom:"16px", justifyContent:"space-between"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px", whiteSpace:"break-spaces", wordWrap:"break-word", width:window.innerWidth > window.innerHeight ? "100%" : "50%" }}>المادة المصنوع منها السوار:</h5>
                <div style={{display:"flex", width:"fit-content",justifyContent:"flex-end"}}>
                  {
                    watchBandMaterial && (
                      <button id='my-button' style={{padding:"0px 16px", backgroundColor:"#F00057", whiteSpace:"nowrap", width:"fit-content" }} onClick={()=>{setWatchBandMaterial("")}}>إعادة تعيين</button>
                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchBandMaterialScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchBandMaterial} onSelect={handleSelectWatchBandMaterial}>
              
              <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
              <IoIosWatch color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
              </div>
              <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>ستانلس ستيل</p>
              </div>


              <div  category="جلد" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
              <IoIosWatch color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
              </div>
              <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>جلد</p>
              </div>


              <div  category="ربل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
              <IoIosWatch color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
              </div>
              <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>ربل</p>
              </div>



              </SelectableWatchCompany>
            </div>
          </div>



    {/* watch band color */}
    {
      watchBandMaterial !== "ستانلس ستيل" && (


    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",minHeight:"100vh" }}>
            <div ref={watchBandColorRef} id='watch-bandColor-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون سوار الساعة:</h5>
                <div style={{display:"flex"}}>
                  {
                    watchBandColor && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchBandColor("")}}>إعادة تعيين</button>

                    )
                  }

                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchBandColorScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchBandColor} onSelect={handleSelectWatchBandColor}>
              
                <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
  </div>


  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>آخر</p>
    </div>


              </SelectableWatchCompany>
            </div>
          </div>
      )
    }

    
    {/* watch has box */}
    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",  minHeight:"100vh" }}>
            <div ref={watchHasOriginalPackagingRef} id='watch-hasOriginalPackaging-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>يوجد صندوق الساعة:</h5>
                <div style={{display:"flex"}}>
                  {
                    watchHasOriginalPackaging !== "" && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchHasOriginalPackaging("")}}>إعادة تعيين</button>

                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchHasOriginalPackagingScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchHasOriginalPackaging} onSelect={handleSelectWatchHasOriginalPackaging}>
              
              <div  category="true" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <IoMdCheckmark color='#00C8B8' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>نعم</p>
  </div>


              <div  category="false" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <IoCloseSharp color='#F00057' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لا</p>
  </div>

              </SelectableWatchCompany>
            </div>
          </div>


    {/* watch is water resistant */}
    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={watchIsWaterResistantRef} id='watch-isWaterResistant-div' style={{paddingTop:"8px"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>الساعة مقاومة للماء:</h5>
                <div style={{display:"flex"}}>
                  {
                    watchIsWaterResistant !== "" && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchIsWaterResistant("")}}>إعادة تعيين</button>
                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchIsWaterResistantScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
              <SelectableWatchCompany selectedCategory={watchIsWaterResistant} onSelect={handleSelectWatchIsWaterResistant}>
              
              <div  category="true" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <IoMdCheckmark color='#00C8B8' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>نعم</p>
  </div>


              <div  category="false" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <IoCloseSharp color='#F00057' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لا</p>
  </div>

              </SelectableWatchCompany>
            </div>
          </div>
    
    
    








    {/* watch year made */}
    <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={watchYearMadeRef} id='watch-handlesColor-div' style={{paddingTop:"8px", display:"flex", flexDirection:"column"}}>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>سنة الصنع:</h5>
                <div style={{display:"flex"}}>
                  {
                    watchYearMade && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchYearMade("")}}>إعادة تعيين</button>
                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchYearMadeScrollTrigger(true)}}>التالي</button>
                </div>
            </div>
            <div style={{marginTop:"16px"}}>
            <YearMade onSelect={handleSelectWatchYearMade} selectedYear={watchYearMade} />
            </div>
            </div>
          </div>





        </>
      )}
    </div>


      </>

    
  )
}


{
  productCategory === "سبح" && (
    <>

    <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", paddingBottom: "32px", padding: window.innerWidth > window.innerHeight ? "auto" : "0px 16px" }}>
<div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight: "100vh"}}>
            <div ref={rosaryKindRef} id='watch-department-div' style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>نوع الخرز:</h5>
              <SelectableWatchCompany selectedCategory={rosaryKind} onSelect={handleSelectRosaryKind}>
              
              <div  category="بكلايت" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>بكلايت</p>
              </div>
              
              <div  category="كهرمان" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>كهرمان</p>
              </div>

              <div  category="فاتوران" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>فاتوران</p>
              </div>


              <div  category="عاج" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>عاج</p>
              </div>

              <div  category="خشب" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>خشب</p>
              </div>

              <div  category="حجر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>حجر</p>
              </div>
              </SelectableWatchCompany>
            </div>
          </div>
</div>

{
  rosaryKind && (
    <div style={{ width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
    <div ref={rosaryColorDivRef} id='watch-handlesColor-div' style={{paddingTop:"4px"}}>
    <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون الخرز:</h5>
      </div>
      <SelectableWatchCompany selectedCategory={rosaryColor} onSelect={handleSelectRosaryColor}>

                <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>زيتي</p>
  </div>

  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" , color:"#01635c" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c" , color:"#01635c"}}>آخر</p>
    </div>


      </SelectableWatchCompany>
    </div>
    {
      rosaryColor && (
<div style={{minHeight:"100vh"}}>
            <div ref={rosaryCountDivRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <div>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>عدد الخرز:</h5>
                </div>
                {
                  rosaryCount !== "" && (
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px",marginTop:"8px"}} 
                    onClick={()=>{setRosaryCountScrollTrigger(true)}}
                    >التالي</button>
                  )
                }
            </div>
          <div style={{display:"flex", alignItems:"center"}}>
            <input ref={rosaryCountRef} 
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setRosaryCount(""); // Clear the state
    return;
  }

  // Check if the input value contains non-numeric characters
  if (!/^\d*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط.");
    rosaryCountRef.current.value = ""; // Clear the input field
    return;
  }

  // Update the state with the valid number
  setRosaryCount(inputValue);
}} type='tel' lang='en' placeholder='عدد الخرز' style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right", marginTop:"8px"}}/>
            <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>خرزة</p>
          </div>
</div>
      )
    }


    {
      rosaryCount && (
<div style={{minHeight:"100vh"}}>
            <div ref={rosarySizeDivRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <div>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}>حجم الخرز:</h5>
                </div>
                {
                  rosaryCount && (
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginTop:"8px"}} 
                    onClick={()=>{setRosarySizeScrollTrigger(true)}}
                    >التالي</button>
                  )
                }
            </div>
          <div style={{display:"flex", alignItems:"center",  marginTop:"8px"}}>
            <input ref={rosarySizeRef} onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setRosarySize(""); // Clear the state
    return;
  }

  // Check if the input value contains non-numeric characters
  if (!/^\d*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط.");
    rosarySizeRef.current.value = ""; // Clear the input field
    return;
  }

  // Update the state with the valid number
  setRosarySize(inputValue);
}} type='tel' lang='en' placeholder='حجم الخرز' style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" :"100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
            <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ملم</p>
          </div>
</div>
      )
    }
  </div>
  )
}


</>
  )
}


{
  productCategory === "اقلام" && (
    <div id='watch-department-div' style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
        <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectPenProductCompany}>
  <div category="Aigner">
    <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
  </div>
  <div category="Cartier">
    <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
  </div>
  <div category="Chopard">
    <img id='landing-screen-img-size' src={chopardLogo} style={{objectFit:"scale-down"}} alt="Chopard" />
  </div>
  <div category="Givenchy">
    <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
  </div>
  <div category="Montblanc">
    <img id='landing-screen-img-size' src={montBlancLogo} style={{objectFit:"scale-down"}} alt="Montblanc" />
  </div>
  <div category="Omega">
    <img id='landing-screen-img-size' src={omegaLogo} style={{objectFit:"scale-down"}} alt="Omega" />
  </div>
  <div category="Patek Philippe">
    <img id='landing-screen-img-size' src={patekPhilippeLogo} style={{objectFit:"scale-down"}} alt="Patek Philippe" />
  </div>
  <div category="Rado">
    <img id='landing-screen-img-size' src={radoLogo} style={{objectFit:"scale-down"}} alt="Rado" />
  </div>
  <div category="Rolex">
    <img id='landing-screen-img-size' src={rolexLogo} style={{objectFit:"cover"}} alt="Rolex" />
  </div>
  <div category="Tissot">
    <img id='landing-screen-img-size' src={tissotLogo} style={{objectFit:"scale-down"}} alt="Tissot" />
  </div>
  <div category="Versace">
    <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
  </div>
</SelectableWatchCompany>

      </div>
    </div>
  )
}

{
  productCategory === "ابواك" && (
    <>

          <div ref={walletCompanyRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
        <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectPenProductCompany}>
  <div category="Aigner">
    <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
  </div>
  <div category="Aldo">
    <img id='landing-screen-img-size' src={aldoLogo} style={{objectFit:"scale-down"}} alt="Aldo" />
  </div>
  <div category="Cartier">
    <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
  </div>
  <div category="Chanel">
    <img id='landing-screen-img-size' src={chanelLogo} style={{objectFit:"scale-down"}} alt="Chanel" />
  </div>
  <div category="Chopard">
    <img id='landing-screen-img-size' src={chopardLogo} style={{objectFit:"scale-down"}} alt="Chopard" />
  </div>
  <div category="Dior">
    <img id='landing-screen-img-size' src={diorLogo} style={{objectFit:"scale-down"}} alt="Dior" />
  </div>
  <div category="Dolce & Gabbana">
    <img id='landing-screen-img-size' src={DGLogo} style={{objectFit:"scale-down"}} alt="Dolce & Gabbana" />
  </div>
  <div category="Fred Perry">
    <img id='landing-screen-img-size' src={fredPerryLogo} style={{objectFit:"scale-down"}} alt="Fred Perry" />
  </div>
  <div category="Givenchy">
    <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
  </div>
  <div category="Gucci">
    <img id='landing-screen-img-size' src={gucciLogo} style={{objectFit:"scale-down"}} alt="Gucci" />
  </div>
  <div category="Louis Vuitton">
    <img id='landing-screen-img-size' src={lvLogo} style={{objectFit:"scale-down"}} alt="Louis Vuitton" />
  </div>
  <div category="Montblanc">
    <img id='landing-screen-img-size' src={montBlancLogo} style={{objectFit:"scale-down"}} alt="Montblanc" />
  </div>
  <div category="Omega">
    <img id='landing-screen-img-size' src={omegaLogo} style={{objectFit:"scale-down"}} alt="Omega" />
  </div>
  <div category="Rolex">
    <img id='landing-screen-img-size' src={rolexLogo} style={{objectFit:"cover"}} alt="Rolex" />
  </div>
  <div category="Saint Laurent">
    <img id='landing-screen-img-size' src={yslLogo} style={{objectFit:"scale-down"}} alt="Saint Laurent" />
  </div>
  <div category="Tom Ford">
    <img id='landing-screen-img-size' src={tomFordLogo} style={{objectFit:"scale-down"}} alt="Tom Ford" />
  </div>
  <div category="Tory Burch">
    <img id='landing-screen-img-size' src={toryBurchLogo} style={{objectFit:"scale-down"}} alt="Tory Burch" />
  </div>
  <div category="Versace">
    <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
  </div>
  <div category="Other" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أخرى</p>
  </div>
</SelectableWatchCompany>


      </div>
    </div>



    <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", paddingBottom: "32px", padding: window.innerWidth > window.innerHeight ? "auto" : "0px 16px" }}>
<div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight: "100vh"}}>
            <div ref={walletDepartmentRef} id='watch-department-div' style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>قسم البوك:</h5>
              <SelectableWatchCompany selectedCategory={walletDepartment} onSelect={handleSelectWalletDepartment}>
              
              <div  category="رجالي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <IoMdMale color='#00C8B8' style={{width:"80px", height:"80px"}}/>
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>رجالي</p>
              </div>

              <div  category="نسائي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <IoFemale color='#F00057' style={{width:"80px", height:"80px"}} />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>نسائي</p>
              </div>


              <div  category="للجنسين" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                <FaTransgender color='gray' style={{width:"80px", height:"80px"}} />
                </div>
                <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>للجنسين</p>
              </div>
              </SelectableWatchCompany>
            </div>
          </div>
          </div>




    <div style={{ width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px", minHeight:"100vh" }}>
            <div ref={walletColorRef} id='watch-insideColor-div' style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون البوك الخارجي:</h5>
              <SelectableWatchCompany selectedCategory={walletColor} onSelect={handleSelectWalletColor}>

      
              <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
  </div>

  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>آخر</p>
    </div>


      </SelectableWatchCompany>
            </div>
          </div>




    </>
  )
}


{
  productCategory === "لوح سيارات" && (
    <>
    <div ref={carPlateKindRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>نوع اللوحة:</h5>
<SelectableWatchCompany selectedCategory={carPlateKind} onSelect={handleSelectCarPlateKind}>
  
  
  <div category="لوحة سيارة خاصة" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
    <div  id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000, padding:"10px"}}>
      <img id='car-plate-img-size' src={carPlateWhite} alt="" />
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة سيارة خاصة</p>
  </div>



  <div category="لوحة دراجة نارية" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000}}>
  <img id='bike-plate-img-size' src={carPlateWhite} alt="" />
  </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة دراجة نارية</p>
  </div>
  <div category="لوحة سيارة نقل" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000, padding:"10px"}}>
  <img id='car-plate-img-size' src={carPlateYellow} alt="" />
  </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة سيارة نقل عام</p>
  </div>
  <div category="لوحة سيارة تجارية" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000, padding:"10px"}}>
  <img id='car-plate-img-size' src={carPlateBlue} alt="" />
  </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة سيارة تجارية</p>
  </div>

</SelectableWatchCompany>
      </div>
    </div>




    <div ref={carPlateTransferFeeOnRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>رسوم نقل الملكية:</h5>
        <SelectableCondition selectedCategory={carPlateTransferFeeOn} onSelect={handleSelectCarPlateTransferFeeOn}>
        <div category="البائع" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>على البائع<span style={{fontWeight:"400"}}>: عند بيع اللوحة, ستتكفل بدفع رسوم نقل الملكية للمشتري.</span></p>
            </div>
            <div category="المشتري" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>على المشتري<span style={{fontWeight:"400"}}>: عند شرائه للوحة, سيتكفل المشتري بدفع رسوم نقل الملكية اليه.</span></p>
            </div>
              </SelectableCondition>    
      </div>
    </div>



    <div ref={carPlateTransferTimeRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>أتعهد بنقل الملكية خلال:</h5>
<SelectableWatchCompany selectedCategory={carPlateTransferTime} onSelect={handleSelectCarPlateTransferTime}>
  <div category="خلال يومين عمل" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <BsStopwatchFill color='#00C8B8' style={{width:"60px", height:"60px"}}/>
  </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>خلال يومين عمل</p>
  </div>
  <div category="خلال 5 أيام عمل" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaCalendarAlt color='#00C8B8' style={{width:"60px", height:"60px"}}/>
  </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>خلال 5 أيام عمل</p>
  </div>
</SelectableWatchCompany>
      </div>
    </div>






    <div ref={carPlateNumbersRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "8px px" : "8px 16px" }}>

    <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <div>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>حروف و أرقام اللوحة:</h5>
                </div>
                {
                  carPlateFirstArabicLetter && carPlateFirstArabicNumber && (
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginTop:"8px"}} 
                    onClick={()=>{setCarPlateNumbersScrollTrigger(true)}}
                    >التالي</button>
                  )
                }
            </div>

      <div style={{width: window.innerWidth > window.innerHeight ? "60%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", }}>
    <CarPlate kind={carPlateKind}
        firstArabicLetter={carPlateFirstArabicLetter}
        secondArabicLetter={carPlateSecondArabicLetter}
        thirdArabicLetter={carPlateThirdArabicLetter}
        firstArabicNumber={carPlateFirstArabicNumber}
        secondArabicNumber={carPlateSecondArabicNumber}
        thirdArabicNumber={carPlateThirdArabicNumber}
        fourthArabicNumber={carPlateFourthArabicNumber}
        firstEnglishLetter={carPlateFirstEnglishLetter}
        secondEnglishLetter={carPlateSecondEnglishLetter}
        thirdEnglishLetter={carPlateThirdEnglishLetter}
        firstEnglishNumber={carPlateFirstEnglishNumber}
        secondEnglishNumber={carPlateSecondEnglishNumber}
        thirdEnglishNumber={carPlateThirdEnglishNumber}
        fourthEnglishNumber={carPlateFourthEnglishNumber}
    setFirstArabicLetter={handleSetCarPlateFirstArabicLetter}
    setFirstEnglishLetter={handleSetCarPlateFirstEnglishLetter}
    setSecondArabicLetter={handleSetCarPlateSecondArabicLetter}
    setSecondEnglishLetter={handleSetCarPlateSecondEnglishLetter}
    setThirdArabicLetter={handleSetCarPlateThirdArabicLetter}
    setThirdEnglishLetter={handleSetCarPlateThirdEnglishLetter}
    setFirstArabicNumber={handleSetCarPlateFirstArabicNumber}
    setFirstEnglishNumber={handleSetCarPlateFirstEnglishNumber}
    setSecondArabicNumber={handleSetCarPlateSecondArabicNumber}
    setSecondEnglishNumber={handleSetCarPlateSecondEnglishNumber}
    setThirdArabicNumber={handleSetCarPlateThirdArabicNumber}
    setThirdEnglishNumber={handleSetCarPlateThirdEnglishNumber}  
    setFourthArabicNumber={handleSetCarPlateFourthArabicNumber}
    setFourthEnglishNumber={handleSetCarPlateFourthEnglishNumber}
    setPostImageUrl={handleSetCarPlatePostImage}
     />
    </div>

</div>

    </>
  )
}

{
  productCategory === "حقائب نسائية" && (
    <>

    
<div ref={purseCompanyRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
        <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectPenProductCompany}>
  
  <div category="Aigner">
    <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
  </div>
  <div category="Aldo">
    <img id='landing-screen-img-size' src={aldoLogo} style={{objectFit:"scale-down"}} alt="Aldo" />
  </div>
  <div category="Balenciaga">
    <img id='landing-screen-img-size' src={balenciagaLogo} style={{objectFit:"scale-down"}} alt="Balenciaga" />
  </div>
  <div category="Cartier">
    <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
  </div>
  <div category="Chanel">
    <img id='landing-screen-img-size' src={chanelLogo} style={{objectFit:"scale-down"}} alt="Chanel" />
  </div>
  <div category="Charles & Keith">
    <img id='landing-screen-img-size' src={charlesKeithLogo} style={{objectFit:"scale-down"}} alt="Charles & Keith" />
  </div>
  <div category="Coach">
    <img id='landing-screen-img-size' src={coachLogo} style={{objectFit:"scale-down"}} alt="Coach" />
  </div>
  <div category="Dior">
    <img id='landing-screen-img-size' src={diorLogo} style={{objectFit:"scale-down"}} alt="Dior" />
  </div>
  <div category="Dolce & Gabbana">
    <img id='landing-screen-img-size' src={DGLogo} style={{objectFit:"scale-down"}} alt="Dolce & Gabbana" />
  </div>
  <div category="Givenchy">
    <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
  </div>
  <div category="Gucci">
    <img id='landing-screen-img-size' src={gucciLogo} style={{objectFit:"scale-down"}} alt="Gucci" />
  </div>
  <div category="Guess">
    <img id='landing-screen-img-size' src={guessLogo} style={{objectFit:"scale-down"}} alt="Guess" />
  </div>
  <div category="Louis Vuitton">
    <img id='landing-screen-img-size' src={lvLogo} style={{objectFit:"scale-down"}} alt="Louis Vuitton" />
  </div>
  <div category="Michael Kors">
    <img id='landing-screen-img-size' src={michaelKorsLogo} style={{objectFit:"scale-down"}} alt="Michael Kors" />
  </div>
  <div category="Off-White">
    <img id='landing-screen-img-size' src={offWhiteLogo} style={{objectFit:"scale-down"}} alt="Off-White" />
  </div>
  <div category="Prada">
    <img id='landing-screen-img-size' src={pradaLogo} style={{objectFit:"scale-down"}} alt="Prada" />
  </div>
  <div category="Saint Laurent">
    <img id='landing-screen-img-size' src={yslLogo} style={{objectFit:"scale-down"}} alt="Saint Laurent" />
  </div>
  <div category="Ted Baker">
    <img id='landing-screen-img-size' src={tedBakerLogo} style={{objectFit:"scale-down"}} alt="Ted Baker" />
  </div>
  <div category="Tory Burch">
    <img id='landing-screen-img-size' src={toryBurchLogo} style={{objectFit:"scale-down"}} alt="Tory Burch" />
  </div>
  <div category="Versace">
    <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
  </div>
  <div category="Other" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أخرى</p>
  </div>
</SelectableWatchCompany>

      </div>
    </div>






    <div ref={purseMaterialRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>المادة المصنوعة منها الحقيبة:</h5>
<SelectableWatchCompany selectedCategory={purseMaterial} onSelect={handleSelectPurseMaterial}>
<div  category="جلد" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <RiShoppingBagLine color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>جلد</p>
  </div>
<div  category="مخمل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <RiShoppingBagLine color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>مخمل</p>
  </div>
</SelectableWatchCompany>
      </div>
    </div>


    <div ref={purseOutsideColorRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون الحقيبة الخارجي:</h5>
        <SelectableWatchCompany selectedCategory={purseOutsideColor} onSelect={handleSelectPurseOutsideColor}>
        
        
        <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
  </div>



  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>آخر</p>
    </div>



              </SelectableWatchCompany>
      </div>
    </div>




    <div ref={purseInsideColorRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
 <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون الحقيبة الداخلي:</h5>
                <div style={{display:"flex"}}>
                  {
                    purseInsideColor !== "" && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setPurseInsideColor("")}}>إعادة تعيين</button>
                    )
                  }
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setPurseInsideColorScrollTrigger(true)}}>التالي</button>
                </div>
              </div>
        <SelectableWatchCompany selectedCategory={purseInsideColor} onSelect={handleSelectPurseInsideColor}>
              

              
        <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
  </div>


  <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
  </div>


  <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
  </div>

  <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
  </div>

  <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
  </div>

  <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
  </div>

  <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
  </div>

  <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
  </div>

  <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
  </div>


  <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
  </div>

  <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
  </div>

  <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
  </div>


  <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
  </div>

  <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
  </div>

  <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
          <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
      </div>
      <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
  </div>

                <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
      <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
      </div>
      <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>آخر</p>
    </div>



              </SelectableWatchCompany>
      </div>
    </div>




    </>
  )
}

{
  productCategory === "ارقام جوالات" && (
    <>
    
<div ref={phoneNumberCourierDivRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
 <div style={{paddingTop:"4px"}}>
        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>مشغل الخدمة:</h5>
<SelectableWatchCompany selectedCategory={phoneNumberCourier} onSelect={handleSelectPhoneCourier}>
  <div category="STC">
    <img id='landing-screen-img-size' src={stcLogo} style={{objectFit:"scale-down"}} alt="الاتصالات السعودية STC" />
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>الاتصالات السعودية STC</p>
  </div>
  <div category="موبايلي">
    <img id='landing-screen-img-size' src={mobilyLogo} style={{objectFit:"scale-down"}} alt="موبايلي" />
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>موبايلي</p>
  </div>
  <div category="زين">
    <img id='landing-screen-img-size' src={zainLogo} style={{objectFit:"scale-down"}} alt="زين" />
    <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>زين</p>
  </div>
</SelectableWatchCompany>
      </div>
    </div>
    

<div ref={phoneNumberToSellDivRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
<div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px", paddingTop:"8px"}}>
                <h5 style={{ fontWeight: "500", color: "#00A49B" }}>رقم الجوال:</h5>
                <div>
                  {
                    phoneNumberToSell.length === 10 && (
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} onClick={()=>{setPhoneNumberToSellScrollTrigger(true)}}>التالي</button>
                    )
                  }
                </div>
              </div>

      <PhoneNumber 
    setPostImageUrl={handleSetPhoneNumberPostImage}
 phoneNumber={phoneNumberToSell} setPhoneNumber={handleSetPhoneNumber} />

    </div>


    </>
  )
}

{
  productCategory !== "" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" &&  (
    
<div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
            <div ref={productConditionRef} style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>حالة المنتج:</h5>
              <SelectableCondition selectedCategory={productCondition} onSelect={handleSelectProductCondition}>
                <div category="جديد" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>جديد<span style={{fontWeight:"400", lineHeight:1.7}}>: المنتج لم يتم استخدامه من قبل و
 يأتي مع كافة الملحقات والمكونات اللازمة و التغليف الاصلي من الشركة المصنعة
</span></p>
                </div>
                <div category="مستعمل" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>مستعمل<span style={{fontWeight:"400"}}>: المنتج تم استخدامه من قبل و قد يكون مزودا بملحقاته الاساسية مثل التغليف الاصلي ولا يحتوي على خدوش او اي عيوب تمنع المنتج من استخدامه 
بشكل سليم
</span></p>
                </div>
                <div category="ممتازة" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>ممتازة<span style={{fontWeight:"400"}}>: المنتج يعمل بشكل سليم و قد يحتوي على بعض الخدوش التي لا تعيق استخدام المنتج</span></p>
                </div>
                <div category="جيدة جدا" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>جيدة جدا<span style={{fontWeight:"400"}}>: يحتوي المنتج على بعض الخدوش و لكنه نظيف و قابل للاستخدام بشكل سليم </span></p>
                </div>
                <div category="جيدة" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>جيدة<span style={{fontWeight:"400"}}>: يحتوي المنتج على خدوش و بعض الكسور التي لا تعيق استخدامه </span></p>
                </div>
                <div category="مقبولة" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>مقبولة<span style={{fontWeight:"400"}}>: يحتوي المنتج على خدوش و كسور التي قد تعيق استخدام المنتج ولكنه يعمل </span></p>
                </div>
              </SelectableCondition>           
            </div>
          </div>

  )
}

    <div style={{padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>



<div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={postTypeRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <div>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>طريقة عرض المنتج:</h5>
                  <h6 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}>يمكنك اختيار أكثر من طريقة لعرض منتجك</h6>
                </div>
                {
                  postType.length > 0 && (
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginTop:"8px"}} 
                    onClick={()=>{setPostTypeScrollTrigger(true)}}
                    >التالي</button>
                  )
                }
            </div>
            {/* <div ref={isAuctionRef} style={{paddingTop:"4px"}}>
              <SelectableWatchCompany selectedCategory={isAuction} onSelect={handleSelectIsAuction}>
              
              <div  category="true" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <IoMdCheckmark color='#00C8B8' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop:"4px"}}>نعم</p>
  </div>


              <div  category="false" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
    <IoCloseSharp color='#F00057' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
    </div>
    <p style={{marginBottom:"0px", marginTop:"4px"}}>لا</p>
  </div>

              </SelectableWatchCompany>
            </div> */}


            <PostType onSelect={handleSelectPostType} selectedCategories={postType}>
            <div category="شراء فوري" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>شراء فوري<span style={{fontWeight:"400"}}>: سيكون هناك سعر لشراء منتجك بشكل فوري حتى لو عرضته للمزاد. اذا اخترت ان تعرض منتجك للمزاد, سيكون هذا السعر هو سقف المزاد و سينتهي عند وصوله لهذا السعر. </span></p>
            </div>
            <div category="مزاد" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>مزاد<span style={{fontWeight:"400"}}>: سيتم عرض المنتج للمزاد و يمكن لمن يدفع عربون دخول المزاد أن يقوم بالمزايدة عليه.</span></p>
            </div>
            {
              postType.includes("شراء فوري") && (
            <div category="عروض" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                <p style={{marginBottom:"0px", fontWeight:"500"}}>قابل للتفاوض<span style={{fontWeight:"400"}}>: ستتلقى عروض سعر من قبل مشترين محتملين و ستتمكن من قبول أو رفض العروض.</span></p>
          </div>
              )
            }
            </PostType>
          </div>

            {
              postType.includes("مزاد") && (
                <>
                  <div ref={productExpiryDateRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={productExpiryDateRef} style={{display:"flex", justifyContent:"space-between", width:"100%", paddingTop:"8px"}}>
                <div>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>تاريخ انتهاء المزاد:</h5>
                </div>
                {
                  productExpiryDate && ( 
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                    onClick={()=>{setProductExpiryDateScrollTrigger(true)}}
                    >التالي</button>
                  )
                }
            </div>

            <Datetime
        className='custom-datetime-picker'
        {...customInputProps}
        locale="ar" // Set locale to Arabic for react-datetime
        inputProps={{ ...customInputProps.inputProps, placeholder: 'تاريخ' }} // Add placeholder in Arabic
        dateFormat="DD/MM/YYYY" // Set date format
        timeFormat="hh:mm A" // Set time format in 12-hour format with AM/PM
        onChange={(e)=>{setProductExpiryDate(e._d)}}
        initialViewDate=""
        initialValue=""
      />
            </div>
                </>
              )
            }


{
postType.length > 0 && (
<>


          <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
            <div ref={productPricingRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div style={{marginTop:"8px"}}>
                <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>تسعير المنتج</h1>
              </div>
             
            
            {
              postType.length === 1 && postType.includes("شراء فوري") && productPrice !== "" ? (
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px",marginTop:"8px"}} 
                    onClick={()=>{setProductPricingScrollTrigger(true)}}
                    >التالي</button>
              ) : ""
            }
             
            {
              postType.length === 1 && postType.includes("مزاد") && entryFee !== "" && startingPrice !== "" && bidIncrements !== "" ? (
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                    onClick={()=>{setProductPricingScrollTrigger(true)}}
                    >التالي</button>
              ) : ""
            }
            {
              postType.length === 2 && postType.includes("شراء فوري") && postType.includes("عروض") && productPrice !== ""  ? (
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                    onClick={()=>{setProductPricingScrollTrigger(true)}}
                    >التالي</button>
              ) : ""
            }
             
            
            {
              postType.length > 1 && postType.includes("مزاد") && postType.includes("شراء فوري")  && productPrice && entryFee && startingPrice && bidIncrements ? (
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                    onClick={()=>{setProductPricingScrollTrigger(true)}}
                    >التالي</button>
              ) : ""
            }

            </div>

            {
              postType.length === 1 && postType.includes("شراء فوري") && (
              <>
              <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> سعر المنتج:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                <input
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setProductPrice(""); // Clear the state
    return;
  }

  if(parseFloat(inputValue) === 0){
    alert("يجب ان تكون القيمة اعلى من 0")
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Check for non-numeric characters (excluding decimal point)
  if (!/^[0-9.]*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Check if the input is a valid number with optional decimal
  if (isNaN(parseFloat(inputValue))) {
    alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Round the weight to two decimal places using toFixed
  const roundedPrice = parseFloat(inputValue).toFixed(2);

  // Update the state with the rounded weight
  setProductPrice(roundedPrice);
}}

  type='tel'
  lang='en'
  ref={productPriceRef}
  placeholder='سعر المنتج'
  style={{
    backgroundColor: "#F0F0F0",
    border: "none",
    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
    height: "50px",
    borderRadius: "8px",
    outline: "none",
    padding: "8px 16px",
    textAlign: "right"
  }}
/>

                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                </div>
              </>
              )
            }

            {
              postType.length === 2 && postType.includes("شراء فوري") && postType.includes("عروض") &&(
              <>
              <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> سعر المنتج:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                <input
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setProductPrice(""); // Clear the state
    return;
  }

  if(parseFloat(inputValue) === 0){
    alert("يجب ان تكون القيمة اعلى من 0")
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Check for non-numeric characters (excluding decimal point)
  if (!/^[0-9.]*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    
    return;
  }

  // Check if the input is a valid number with optional decimal
  if (isNaN(parseFloat(inputValue))) {
    alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Round the weight to two decimal places using toFixed
  const roundedPrice = parseFloat(inputValue).toFixed(2);

  // Update the state with the rounded weight
  setProductPrice(roundedPrice);
}}
  type='tel'
  lang='en'
  ref={productPriceRef}
  placeholder='سعر المنتج'
  style={{
    backgroundColor: "#F0F0F0",
    border: "none",
    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
    height: "50px",
    borderRadius: "8px",
    outline: "none",
    padding: "8px 16px",
    textAlign: "right"
  }}
/>
                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                </div>
              </>
              )
            }

            {
              postType.includes("شراء فوري") && postType.includes("مزاد") &&(
              <div >
              <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> سعر الشراء الفوري:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                <input
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setProductPrice(""); // Clear the state
    return;
  }

  if(parseFloat(inputValue) === 0){
    alert("يجب ان تكون القيمة اعلى من 0")
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Check for non-numeric characters (excluding decimal point)
  if (!/^[0-9.]*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Check if the input is a valid number with optional decimal
  if (isNaN(parseFloat(inputValue))) {
    alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
    productPriceRef.current.value = ""; // Clear the input field
    setProductPrice(""); // Clear the state
    return;
  }

  // Round the weight to two decimal places using toFixed
  const roundedPrice = parseFloat(inputValue).toFixed(2);

  // Update the state with the rounded weight
  setProductPrice(roundedPrice);
}}
  type='tel'
  lang='en'
  ref={productPriceRef}
  placeholder='سعر الشراء الفوري'
  style={{
    backgroundColor: "#F0F0F0",
    border: "none",
    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
    height: "50px",
    borderRadius: "8px",
    outline: "none",
    padding: "8px 16px",
    textAlign: "right"
  }}
/>

                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                </div>
              </div>
              )
            }

            {
              postType.includes("مزاد") && (
              <>
              
              <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> عربون دخول المزاد:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                <input
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setEntryFee(""); // Clear the state
    return;
  }

  if(parseFloat(inputValue) === 0){
    alert("يجب ان تكون القيمة اعلى من 0")
    entryFeeRef.current.value = ""; // Clear the input field
    setEntryFee(""); // Clear the state
    return;
  }

  if(productPrice !== "" && (parseFloat(inputValue) >= parseFloat(productPrice))){
    alert(`يجب ان تكون القيمة اقل من سعر الشراء الفوري (${formatAccountingArabic(parseFloat(productPrice))})`)
    entryFeeRef.current.value = ""; // Clear the input field
    setEntryFee(""); // Clear the state
    return;
  }

  // Check for non-numeric characters (excluding decimal point)
  if (!/^[0-9.]*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
    entryFeeRef.current.value = ""; // Clear the input field
    setEntryFee(""); // Clear the state
    return;
  }

  // Check if the input is a valid number with optional decimal
  if (isNaN(parseFloat(inputValue))) {
    alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
    entryFeeRef.current.value = ""; // Clear the input field
    setEntryFee(""); // Clear the state
    return;
  }

  // Round the weight to two decimal places using toFixed
  const roundedPrice = parseFloat(inputValue).toFixed(2);

  // Update the state with the rounded weight
  setEntryFee(roundedPrice);
}}
  type='tel'
  lang='en'
  ref={entryFeeRef}
  placeholder='عربون دخول المزاد'
  style={{
    backgroundColor: "#F0F0F0",
    border: "none",
    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
    height: "50px",
    borderRadius: "8px",
    outline: "none",
    padding: "8px 16px",
    textAlign: "right"
  }}
/>

                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                </div>
                
                <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: window.innerWidth > window.innerHeight ? "50%" : "100%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
                  <MdInfo size={20} color='#a0a0a0' />
                  <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>المبلغ الذي سيتم دفعه للتمكن من دخول المزاد. أقل مبلغ هو 10 ريال.</p>
                </div> 
              
              <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>مبلغ افتتاح المزاد:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                <input
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setStartingPrice(""); // Clear the state
    return;
  }

  if(parseFloat(inputValue) === 0){
    alert("يجب ان تكون القيمة اعلى من 0")
    startingPriceRef.current.value = ""; // Clear the input field
    setStartingPrice(""); // Clear the state
    return;
  }

  if(productPrice !== "" && (parseFloat(inputValue) >= parseFloat(productPrice))){
    alert(`يجب ان تكون القيمة اقل من سعر الشراء الفوري (${formatAccountingArabic(parseFloat(productPrice))})`)
    startingPriceRef.current.value = ""; // Clear the input field
    setStartingPrice(""); // Clear the state
    return;
  }

  // Check for non-numeric characters (excluding decimal point)
  if (!/^[0-9.]*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
    startingPriceRef.current.value = ""; // Clear the input field
    setStartingPrice(""); // Clear the state

    return;
  }

  // Check if the input is a valid number with optional decimal
  if (isNaN(parseFloat(inputValue))) {
    alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
    startingPriceRef.current.value = ""; // Clear the input field
    setStartingPrice(""); // Clear the state

    return;
  }

  // Round the weight to two decimal places using toFixed
  const roundedPrice = parseFloat(inputValue).toFixed(2);

  // Update the state with the rounded weight
  setStartingPrice(roundedPrice);
}}
  type='tel'
  lang='en'
  ref={startingPriceRef}
  placeholder='مبلغ افتتاح المزاد'
  style={{
    backgroundColor: "#F0F0F0",
    border: "none",
    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
    height: "50px",
    borderRadius: "8px",
    outline: "none",
    padding: "8px 16px",
    textAlign: "right"
  }}
/>

                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                </div>
                <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: window.innerWidth > window.innerHeight ? "50%" : "100%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
                  <MdInfo size={20} color='#a0a0a0' />
                  <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>المزاد يبدأ من هذا المبلغ. أقل مبلغ هو 10 ريال.</p>
                </div> 
              <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>مضاعفات المزايدة:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                <input
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setBidIncrements(""); // Clear the state
    return;
  }

    if(parseFloat(inputValue) === 0){
    alert("يجب ان تكون القيمة اعلى من 0")
    bidIncrementRef.current.value = ""; // Clear the input field
    setBidIncrements(""); // Clear the state
    return;
    }

      if(productPrice !== "" && (parseFloat(inputValue) >= parseFloat(productPrice))){
    alert(`يجب ان تكون القيمة اقل من سعر الشراء الفوري (${formatAccountingArabic(parseFloat(productPrice))})`)
    bidIncrementRef.current.value = ""; // Clear the input field
    setBidIncrements(""); // Clear the state
    return;
  }

  // Check for non-numeric characters (excluding decimal point)
  if (!/^[0-9.]*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
    bidIncrementRef.current.value = ""; // Clear the input field
    setBidIncrements(""); // Clear the state
    return;
  }

  // Check if the input is a valid number with optional decimal
  if (isNaN(parseFloat(inputValue))) {
    alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
    bidIncrementRef.current.value = ""; // Clear the input field
    setBidIncrements(""); // Clear the state

    return;
  }

  // Round the weight to two decimal places using toFixed
  const roundedPrice = parseFloat(inputValue).toFixed(2);

  // Update the state with the rounded weight
  setBidIncrements(roundedPrice);
}}
  type='tel'
  lang='en'
  ref={bidIncrementRef}
  placeholder='مضاعفات المزايدة'
  style={{
    backgroundColor: "#F0F0F0",
    border: "none",
    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
    height: "50px",
    borderRadius: "8px",
    outline: "none",
    padding: "8px 16px",
    textAlign: "right"
  }}
/>

                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                </div>
              <div style={{ display: "flex", backgroundColor: "#f7f7f7", width: window.innerWidth > window.innerHeight ? "50%" : "100%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px" }}>
                <MdInfo size={20} color='#a0a0a0' />
                <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%", marginBottom: "0px" }}>
                  هذا المبلغ سيكون مضاعفات كل مزايدة، مثال:<br />
                  <span>إذا كانت المضاعفات 50، ستكون المزايدات على منتجك مضاعفات 50 (50، 100، 150...). أقل مبلغ هو 10 ريال.</span>
                </p>
              </div>

              </>)
            }

            {/* {
              postType.includes("عروض") && (<>
                <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span> الحد الأدنى للعروض:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                  <input type='tel' lang='en' ref={productPriceRef} placeholder='الحد الأدنى للعروض' style={{backgroundColor:"#F0F0F0", border: "none", width:"50%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                </div>
                <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: "50%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
                  <MdInfo size={20} color='#a0a0a0' />
                  <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>لا يمكن ارسال عرض سعر أقل من هذا المبلغ. </p>
                </div> 
              </>)
            } */}


          </div>

</>
) 
}


{
productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
<>


          <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding: "8px 0px" }}>
            <div ref={productQtyDivRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div style={{marginTop:"8px"}}>
                <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>الكمية</h1>
              </div>
             
            
                <button id='my-button' style={{width:"fit-content", padding:"0px 16px",marginTop:"8px"}} 
                    onClick={()=>{setProductQtyScrollTrigger(true)}}
                    >التالي</button>

            </div>


              <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span>الكمية:</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                <input
onChange={(e) => {
  const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

  // Check if the input value is empty
  if (inputValue === "") {
    setProductQty(""); // Clear the state
    return;
  }

  if(parseFloat(inputValue) === 0){
    alert("يجب ان تكون القيمة اعلى من 0")
    productQtyRef.current.value = ""; // Clear the input field
    setProductQty(""); // Clear the state
    return;
  }

  // Check for non-numeric characters (excluding decimal point)
  if (!/^[0-9.]*$/.test(inputValue)) {
    alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
    productQtyRef.current.value = ""; // Clear the input field
    setProductQty(""); // Clear the state
    return;
  }

  // Check if the input is a valid number with optional decimal
  if (isNaN(parseFloat(inputValue))) {
    alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
    productQtyRef.current.value = ""; // Clear the input field
    setProductQty(""); // Clear the state
    return;
  }

  // Round the weight to two decimal places using toFixed
  const roundedPrice = parseFloat(inputValue);

  // Update the state with the rounded weight
  setProductQty(roundedPrice);
}}

  type='tel'
  lang='en'
  ref={productPriceRef}
  defaultValue={1}
  placeholder='الكمية'
  style={{
    backgroundColor: "#F0F0F0",
    border: "none",
    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
    height: "50px",
    borderRadius: "8px",
    outline: "none",
    padding: "8px 16px",
    textAlign: "right"
  }}
/>

                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>وحدة</p>
                </div>
          </div>

</>
) 
}
    </div>
{
  productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
    
<div ref={productImagesRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "8px 16px"}}>
            <div  style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div>
                <div style={{display:"flex"}}>
                <span style={{color:"red", fontSize:"16px", fontWeight:"bold"}}>*</span>
                <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>صور المنتج </h1>
                </div>
                <h6 style={{ fontWeight: "500", color: "#00A49B"}}>يمكنك اختيار من 3 الى 10 صور</h6>
              </div>
              {
                postImages.length > 2 && (
                  <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                    onClick={()=>{setProductImagesScrollTrigger(true)}}
                    >التالي</button>
                )
              }
            </div>
            <ImageCropper
    imagess={postImages}
    setImages={handleImagesChange}
/>
</div>

  )
}


<div ref={productInfoRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh",padding:window.innerWidth > window.innerHeight ? "0px" : "8px 16px", paddingBottom:"32px" }}>
            <div  style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div>
                <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>معلومات المنتج</h1>
                <h6 style={{ fontWeight: "500", color: "#00A49B"}}>احرص على كتابة كلمات مفتاحية لتعزيز فرص ظهور منتجك في قوائم البحث</h6>
              </div>
              {
                productName.length > 0 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" &&(
                  <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                    onClick={()=>{setProductInfoScrollTrigger(true)}}
                    >التالي</button>
                )
              }
            </div>

              <div style={{display:"flex",flexDirection: window.innerWidth > window.innerHeight ? "column" : "column",justifyContent:"space-between"}}>
              <div style={{width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>
                
            <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>اسم المنتج:</h5>
                  <input onChange={(e)=>{setProductName(e.target.value)}} type='text' lang='en' ref={productNameRef} placeholder='اسم المنتج' style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
            <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}>وصف المنتج:</h5>
                  <textarea onChange={(e)=>{setProductDescription(e.target.value)}} ref={productDescriptionRef} placeholder='وصف المنتج (اختياري)' style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "120px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right",}}/>
              </div>
              {
  postImages.length > 0 && postType.length > 0 && (
    postType.includes("مزاد") && productExpiryDate !== "" ? (
      <div style={{ marginTop: "24px" }}>
        <div style={{ width: window.innerWidth > window.innerHeight ? "230px" : "50%" }}>
          <Product
            imgs={postImages[0].src}
            productName={productName}
            currentBid={0}
            isAuction={true}
            acceptsOffers={postType.includes("عروض")}
            numBidders={0}
            category={productCategory}
            condition={productCondition}
            expiryDate={productExpiryDate}
            carPlateKind={carPlateKind}
          />
        </div>
        <div style={{ display: "flex", backgroundColor: "#f7f7f7", borderRadius: "6px", padding: "8px 10px", marginTop: "4px",width:"fit-content" }}>
          <MdInfo size={20} color="#a0a0a0" />
          <p style={{ color: "#555", fontSize: "15px", flex: 1, marginBottom: "0px", marginRight: "4px" }}>
            سيتم عرض منتجك بهذا الشكل. اذا عرضت منتجك للمزاد, سيتم عرض السعر الحالي للمنتج
          </p>
        </div>
      </div>
    ) : (
      <div style={{ marginTop: "24px"}}>
        <div style={{ width: window.innerWidth > window.innerHeight ? "230px" : "50%" }}>
          <Product
            imgs={postImages[0].src}
            productName={productName}
            currentBid={0}
            acceptsOffers={postType.includes("عروض")}
            isAuction={false}
            numBidders={0}
            category={productCategory}
            condition={productCondition}
            productPrice={parseFloat(productPrice) || 0}
            carPlateKind={carPlateKind}
          />
        </div>
        <div style={{ display: "flex", backgroundColor: "#f7f7f7", borderRadius: "6px", padding: "8px 10px", marginTop: "4px", width:"fit-content" }}>
          <MdInfo size={20} color="#a0a0a0" />
          <p style={{ color: "#555", fontSize: "15px", flex: 1, marginBottom: "0px", marginRight: "4px" }}>
            سيتم عرض منتجك بهذا الشكل.
          </p>
        </div>
      </div>
    )
  )
}

  </div>



  {
  (productCategory === "لوح سيارات" || productCategory === "ارقام جوالات") && (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop:"20vh" }}>
      <button
        id='my-button'
        disabled={addProductLoading}
        style={{ width: window.innerWidth > window.innerHeight ? "30%" : "80%", marginLeft: "auto", marginRight: "auto" }}
        onClick={handleAddProduct}
      >
        {addProductLoading ? (
          <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block" }}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
          </div>
        ) : (
          <p style={{ marginBottom: "0px" }}>اضافة المنتج</p>
        )}
      </button>
    </div>
  )
}

</div>
{
  productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
    <>


<div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding: window.innerWidth > window.innerHeight ? "16px 0px" : "0px 16px", marginTop:"16px" }}>
            <div ref={shippingRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div>
                <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>الشحن و التوصيل</h1>
            <h6 style={{fontWeight: "500", color: "#00A49B" }}>يمكنك اختيار اكثر من خيار</h6>

              </div>
              {/* {
                shippingMethods.length > 0 && (
                shippingMethods.includes("شحن") ? (
                productWeight !== "" && (
                                  <button id='my-button' style={{width:"fit-content", padding:"8px 16px"}} 
                    onClick={()=>{setShippingScrollTrigger(true)}}
                    >التالي</button>
                )
                ) : (
                                  <button id='my-button' style={{width:"fit-content", padding:"8px 16px"}} 
                    onClick={()=>{setShippingScrollTrigger(true)}}
                    >التالي</button>)
                )
              } */}
            </div>

            <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>طريقة توصيل المنتج:</h5>

            <PostType onSelect={handleSelectShippingMethods} selectedCategories={shippingMethods}>
            <div category="شحن" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
              <FaShippingFast  color='#00C8B8' style={{width:"80px", height:"80px", marginRight:"auto", marginLeft:"auto"}} />
              
                  <p style={{marginBottom:"0px", fontWeight:"500"}}>شحن سريع<span style={{fontWeight:"400"}}>: سيكون بإمكانك طباعة بوليصة شحن في حال قام المشتري باختيار طريقة الشحن هذه. </span></p>
            </div>
            <div category="تسليم" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center", width:"100%" }}>
                  <FaRegHandshake  color='#00C8B8' style={{width:"80px", height:"80px", marginRight:"auto", marginLeft:"auto"}} />
                  <p style={{marginBottom:"0px", fontWeight:"500", textAlign:"right"}}>توصيل<span style={{fontWeight:"400"}}>: سأقوم بتسليم المنتج للمشتري وجهاً لوجه.</span></p>
            </div>
            </PostType>


            {
              shippingMethods.includes("شحن") && (
                <div style={{width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>
                                <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>وزن المنتج:</h5>
                  <div style={{display:"flex"}}>

                <input  
                  onChange={(e) => {
                    const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

                    // Check if the input value is empty
                    if (inputValue === "") {
                      return;
                    }

                    // Check for non-numeric characters (excluding decimal point)
                    if (!/^[0-9.]*$/.test(inputValue)) {
                      alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
                      productWeightRef.current.value = ""; // Clear the input field
                      return;
                    }

                    // Check if the input is a valid number with optional decimal
                    if (isNaN(parseFloat(inputValue))) {
                      alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
                      productWeightRef.current.value = ""; // Clear the input field
                      return;
                    }

                    // Round the weight to two decimal places using toFixed
                    const roundedWeight = parseFloat(inputValue).toFixed(1);

                    // Update the state with the rounded weight
                    setProductWeight(roundedWeight);
                  }}





 type='tel' lang='en' defaultValue={productWeight} ref={productWeightRef} placeholder='وزن المنتج' style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
              <p style={{marginBottom: "auto", marginTop: "auto", marginRight:"4px"}}>كجم</p>
                  
                  </div>
                <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: "100%", marginBottom: "0px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px",}}>
              <MdInfo size={20} color='#a0a0a0' />

              <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", marginBottom: "0px", width: "100%" }}>
              وزن المنتج مطلوب لحساب تكلفة الشحن. تبلغ تكلفة الشحن <span style={{fontWeight:"600"}}>{formatAccountingArabic(23)}</span> للـ15 كيلوجرام الأولى، وريالين لكل كيلوجرام يزيد عن ذلك.
<br/>
{
  !isNaN(productWeight) && productWeight > 0 &&
  <span style={{marginBottom:"0px"}}>
     تكلفة شحن المنتج الحالية هي: <span style={{fontWeight:"600"}}>{formatAccountingArabic(calculateShippingCost(parseFloat(productWeight)))}</span>
  </span>
}
              </p>

              </div>
                </div>
              )
            }



<div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"20vh"}}>
<button 
id='my-button' 
disabled={addProductLoading}
style={{width: window.innerWidth > window.innerHeight ? "30%" : "80%", marginLeft:"auto", marginRight:"auto"}} onClick={handleAddProduct}> {
  addProductLoading ? <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", paddingTop:"4px"}}>
  <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            /> 
  </div> : <p style={{marginBottom:"0px"}}>اضافة المنتج</p>
} </button>
</div>
  </div>
    </>
  )
}




        {/* <div style={{ direction: "rtl", textAlign: window.innerWidth > window.innerHeight ? "center" : "center" , display:  window.innerWidth > window.innerHeight ? "flex" : "block", width:  window.innerWidth > window.innerHeight ? "70%" : "100%", marginLeft: "auto", marginRight: "auto", justifyContent: "start", height: "fit-content", paddingTop:"8px"}}> */}

{/* <div style={{width:  window.innerWidth > window.innerHeight ? "600px"  : "100vw", height: "fit-content"}}>

<ImageCropper imagess={postImages} setImages={handleImagesChange} />
      <p>Number of selected files: {postImages.length}</p>
            <p style={{color: productImagesHasError ? "red": "gray", marginRight: "14px", fontSize: "15px", marginTop: "0px"}}>يجب اختيار من 3 إلى 10 صور</p>

  {
    gal.length > 0 ?
    <>
    <div style={{backgroundColor:"yellow",display: "flex", textAlign: "center", justifyContent: "center", marginBottom: "16px"}}>
  
      <button style={{display: "block", backgroundColor: "#00C6B7", border: "none" , outline: "none" , color: "white" ,borderRadius: "8px", margin: "8px", height: "fit-content", width: "fit-content", padding: "8px", paddingRight: "16px", paddingLeft: "16px"}} onClick={()=>{
        productImagesRef.current.click();
      }}>تغيير الصور</button>
    <button style={{display: "block",  border: "none" , outline: "none",  backgroundColor: "#F00057", color: "white",borderRadius: "8px", margin: "8px", height: "fit-content", width: "fit-content", padding: "8px", paddingRight: "16px", paddingLeft: "16px"}} onClick={()=>{
      setImages([]);
      setGal([])
    }}>ازالة الصور</button>
    </div>
    </>
    :""
  }
            </div> */}

            

            {/* <div style={{textAlign: "right", width: window.innerWidth > window.innerHeight ? "auto" : "100%",backgroundColor:"cyan", marginRight:"16px"}}> */}

{/* 
            <input ref={productNameRef} 
            style={{marginTop: "24px", width:"100%"}}
            onChange={(e)=> {
              setProductNameHasError(false);
              setProductName(e.target.value)}} id={productNameHasError ? 'input-field-error' : 'input-field'} placeholder='اسم المنتج' />
             <p style={{display: productName.length > 0 && productName.length < 10 || productNameHasError ? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px"}}>اسم المنتج يجب ان يتكون على الأقل من 10 أحرف</p>
             */}

            {/* <textarea required ={false} ref={productDescriptionRef} id={productDescriptionHasError ? 'text-area-error' : 'text-area'} minLength={100} onChange={(e)=> {
              setProductDescriptionHasError(false);
              setProductDescription(e.target.value)}}  placeholder='وصف المنتج' />
              <p style={{display: productDescription.length > 0 && productDescription.length < 50 || productDescriptionHasError ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px"}}>وصف المنتج يجب ان يتكون على الأقل من 50 حرف</p> */}
              
              {/* <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px"}}>
              <MdInfo size={20} color='#a0a0a0' />
              <p style={{display: "block", color: "#555", marginRight: "4px" ,fontSize: "15px", marginBottom: "0px", width: "100%"}}>ادخل وصف المنتج و تأكد من كتابة أكبر عدد ممكن من الكلمات المفتاحية ليظهر المنتج في قوائم البحث.</p>
              </div> */}
            


            {/* <div style={{display: "flex"}}>
            <input ref={productPriceRef} onChange={(e)=>{
              setProductPriceHasError(false);
              setProductPrice(e.target.value)}} type='tel' id={productPriceHasError ? 'price-input-field-error' : 'price-input-field'} placeholder='سعر المنتج' />
              <p style={{marginBottom: "auto", marginTop: "auto"}}>ر.س</p>
              </div>

              <div style={{display: isAuction ? "flex" : "none", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px"}}>
              <MdInfo size={20} color='#a0a0a0' />
            <p style={{display: isAuction ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px", marginBottom: "0px", width: "100%"}}>ضع 0 اذا كنت تريد السماح بالمزايدة على منتجك بدون سعر شراء فوري. هذا سيسمح بالمزايدة بدون سقف للسعر.</p>
              </div>
            <p style={{display: productPriceHasError && productPrice == ""  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "0px"}}>يجب تحديد سعر المنتج</p>
            <p style={{display: productPriceHasError && isNaN(parseFloat(productPrice))  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", width: window.innerWidth > window.innerHeight ? "100%" : "80%",marginBottom: "16px"}}>يجب ادخال قيمة صحيحة (مسموح بالارقام الانجليزية فقط 0-9)</p> */}

{/* 
            <div style={{display: "flex",marginTop: "16px"}}>
            <input ref={productWeightRef} onChange={(e)=>{
              setProductWeightHasError(false);
              setProductWeight(e.target.value)}} type='tel' id={productWeightHasError ? 'price-input-field-error' : 'price-input-field'} placeholder='وزن المنتج' />
              <p style={{marginBottom: "auto", marginTop: "auto"}}>كجم</p>
              </div>
              {/* <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px"}}>
              <MdInfo size={20} color='#a0a0a0' />
              <p style={{ color: "#555", marginRight: "4px", fontSize: "15px",marginBottom: "0px", width: "100%"}}>مطلوب لتسعيرة الشحن في حال كان المنتج يتطلب شحن</p>
              </div> 
            <p style={{display: productPriceHasError && productPrice == ""  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "0px"}}>يجب تحديد وزن المنتج</p>
            <p style={{display: productWeightHasError && isNaN(parseFloat(productWeight))  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", width: window.innerWidth > window.innerHeight ? "100%" : "80%", marginBottom: "16px"}}>يجب ادخال قيمة صحيحة (مسموح بالارقام الانجليزية فقط 0-9)</p> */}





            {/* <div style={{display: "flex", direction: "rtl", marginRight: "8px", marginTop: "16px", color: "#444"}}>
              <p style={{margin: "8px"}}>السماح بالمزايدات؟</p>
            
            <input ref={acceptsOffersRef} onChange={(e)=>{
              setAcceptsOffers(value.target.checked)}} style={{display: "block", transform: "scale(1.1)",marginTop: "auto", marginBottom: "auto", marginRight: "4px" , accentColor: "#00B6C7"}} type='checkbox' placeholder='test'/>
            
            </div> */}




          {/* <div style={{display: isAuction ? "block" : "none"}}>


            <input ref={productExpiryDateRef} id={productExpiryDateHasError ? 'input-field-error' : 'input-field'} onChange={
                (value)=>{
                  if(new Date(e.target.value) < new Date().getTime()){
                    setProductExpiryDateHasError(true);
                    setPassedDateSelected(true)
                  }else{
                    setPassedDateSelected(false)
                    setProductExpiryDateHasError(false);
                    setProductExpiryDate( Timestamp.fromDate(new Date(e.target.value )))}
                  }
                } type='datetime-local' />
                              <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
              <MdInfo size={20} color='#a0a0a0' />
            <p style={{display: isAuction ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>تاريخ انتهاء المزاد</p>
                </div>
            <p style={{display: productExpiryDateHasError && productExpiryDate == ""  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "0px"}}>يجب تحديد المدة الزمنية لانتهاء المزاد</p>
            <p style={{display: passedDateSelected ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>يجب ان يكون تاريخ الانتهاء في المستقبل</p>





            <div style={{display: "flex"}}>




              <input ref={startingPriceRef} onChange={(e)=>{
                setProductStartingPriceHasError(false);
                setStartingPrice(e.target.value)}} type='tel' min={10} id={productStartingPriceHasError ? 'price-input-field-error' : 'price-input-field'} defaultValue="10" placeholder='مبلغ دخول المزاد'/>
                <p style={{marginBottom: "auto", marginTop: "auto"}}>ر.س</p>
            </div>
            <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
              <MdInfo size={20} color='#a0a0a0' />
            <p style={{display: isAuction ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px", width: "100%" , marginBottom: "0px"}}>المزاد يبدأ من هذا المبلغ. أقل مبلغ هو 10 ريال</p>
            </div>
            <p style={{display: isAuction && productStartingPriceHasError && startingPrice < 10? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>أقل سعر للمزايدة يجب ألا يقل عن 10 ريال</p>
         
         



            <div style={{display: "flex"}}>
              <input ref={bidIncrementRef} onChange={(e)=>{
                setBidIncrementHasError(false);
                setBidIncrements(e.target.value)}} type='tel' min={10} id={bidIncrementHasError ? 'price-input-field-error' : 'price-input-field'} defaultValue="10" placeholder='مبلغ مضاعفات المزايدة'/>
                <p style={{marginBottom: "auto", marginTop: "auto"}}>ر.س</p>
            </div>
            <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
              <MdInfo size={20} color='#a0a0a0' />
            <p style={{display: isAuction ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px",width: "100%", marginBottom: "0px"}}>مضاعفات المزايدة تكون بهذا المبلغ</p>
            </div>
            <p style={{display: isAuction && bidIncrementHasError && bidIncrementHasError < 10? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>أقل سعر للمزايدة يجب ألا يقل عن 10 ريال</p>
         
          </div> */}




           

           
            <div>




            {/* <select ref={productConditionRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
              setProductConditionHasError(false);
              setProductCondition(e.target.value);
              
            }}
            style={{backgroundColor: "#00C6B7", color: "white", borderRadius: "4px"}}
            >
                <option disabled selected>حالة المنتج</option>
                <option style={{backgroundColor: "white"}} value="جديد">جديد</option>
                <option style={{backgroundColor: "white"}} value="مستعمل">مستعمل</option>
                <option style={{backgroundColor: "white"}} value="ممتازة">ممتازة</option>
                <option style={{backgroundColor: "white"}} value="جيدة جدا">جيدة جدا</option>
                <option style={{backgroundColor: "white"}} value="جيدة">جيدة</option>
                <option style={{backgroundColor: "white"}} value="مقبولة">مقبولة</option>
            </select>

            <p style={{display: productConditionHasError ? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>يجب تحديد حالة المنتج</p>
 */}




            
            {/* {
              productCategory == 'الكترونيات' ?
              <select ref={productSubCategoryRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductSubCategoryHasError(false);
                setProductSubCategory(e.target.value);
              }}>
                <option disabled selected>نوع المنتج</option>
                <option style={{backgroundColor: "white"}} value="جوال">جوال</option>
                <option style={{backgroundColor: "white"}} value="شاشة">شاشة</option>
                <option style={{backgroundColor: "white"}} value="سماعة">سماعة</option>
                <option style={{backgroundColor: "white"}} value="لابتوب">لابتوب</option>
                <option style={{backgroundColor: "white"}} value="جهاز لوحي">جهاز لوحي</option>
                <option style={{backgroundColor: "white"}} value="كاميرا">كاميرا</option>
                <option style={{backgroundColor: "white"}} value="طابعة">طابعة</option>
                <option value="مكيف">مكيف</option>
                <option value="جهاز العاب">جهاز العاب</option>
            </select>
            : ""
            } */}
            {/* {
              productCategory == 'اثاث' ?
              <select ref={productSubCategoryRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductSubCategoryHasError(false);
                setProductSubCategory(e.target.value);
              }}>
                <option disabled selected>نوع المنتج</option>
                <option value="كرسي">كرسي</option>
                <option value="كنب">كنب</option>
                <option value="طاولة">طاولة</option>
                <option value="رف">رف</option>
                <option value="دواليب و ادراج">دواليب و أدراج</option>
                <option value="لوحات فنية">لوحات فنية</option>
                <option value="أخرى">أخرى</option>
            </select>
            : ""
            } */}
            {/* {
              productCategory == 'قطع غيار' ?
              <select ref={productSubCategoryRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductSubCategoryHasError(false);
                setProductSubCategory(e.target.value);
              }}>
            <option value="قطع غيار سيارات">قطع غيار السيارات</option>
            </select>
            : ""
            } */}

            {/* {
              productCategory == 'الكترونيات' && productSubCategory == "جوال" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductCompanyHasError(false);
                setProductCompany(e.target.value);
              }}>
                <option style={{backgroundColor: "white"}} disabled selected>الشركة المصنعة</option>
                <option style={{backgroundColor: "white"}} value="ابل">ابل</option>
                <option style={{backgroundColor: "white"}} value="Samsung">Samsung</option>
                <option style={{backgroundColor: "white"}} value="Huawei">Huawei</option>
                <option style={{backgroundColor: "white"}} value="HTC">HTC</option>
                <option style={{backgroundColor: "white"}} value="أخرى">أخرى</option>
              </select>

             {
              productCategory == 'الكترونيات' && productSubCategory == "جوال" && productCompany == 'ابل' ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                
                setProductKind(e.target.value);
              }}>
                <option style={{backgroundColor: "white"}} disabled selected>نوع الجوال</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15 برو ماكس">ايفون 15 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15 برو">ايفون 15 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15 بلس">ايفون 15 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15">ايفون 15</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14 برو ماكس">ايفون 14 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14 برو">ايفون 14 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14 بلس">ايفون 14 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14">ايفون 14</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13 برو ماكس">ايفون 13 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13 برو">ايفون 13 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13">ايفون 13</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13 ميني">ايفون 13 ميني</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12 برو ماكس">ايفون 12 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12 برو">ايفون 12 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12">ايفون 12</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12 ميني">ايفون 12 ميني</option>
                <option style={{backgroundColor: "white"}} value="ايفون 11 برو ماكس">ايفون 11 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 11 برو">ايفون 11 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 11">ايفون 11</option>
                <option style={{backgroundColor: "white"}} value="ايفون XS Max">ايفون XS Max</option>
                <option style={{backgroundColor: "white"}} value="ايفون XS">ايفون XS</option>
                <option style={{backgroundColor: "white"}} value="ايفون XR">ايفون XR</option>
                <option style={{backgroundColor: "white"}} value="ايفون X">ايفون X</option>
                <option style={{backgroundColor: "white"}} value="ايفون 8 بلس">ايفون 8 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 8">ايفون 8</option>
                <option style={{backgroundColor: "white"}} value="ايفون 7 بلس">ايفون 7 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 7">ايفون 7</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6s بلس">ايفون 6s بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6s">ايفون 6s</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6 بلس">ايفون 6 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6">ايفون 6</option>
              </select>            
              :""
            } */}
            {/* {
              productCategory == 'الكترونيات' && productSubCategory == "جوال" && productCompany == 'Samsung' ? 
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductKindHasError(false);
                setProductKind(e.target.value);
              }}> */}
                {/* <option disabled selected>نوع الجوال</option> */}
                {/* S series */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S23 Ultra">Galaxy S23 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S23+">Galaxy S23+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S23">Galaxy S23</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S22 Ultra">Galaxy S22 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S22+">Galaxy S22+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S22">Galaxy S22</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21 FE">Galaxy S21 FE</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21 Ultra">Galaxy S21 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21+">Galaxy S21+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21">Galaxy S21</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20 FE">Galaxy S20 FE</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20 Ultra 5G">Galaxy S20 Ultra 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20+">Galaxy S20+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20">Galaxy S20</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10 Lite">Galaxy S10 Lite</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10 5G">Galaxy S10 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10+">Galaxy S10+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10e">Galaxy S10e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10">Galaxy S10</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S9+">Galaxy S9+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S9">Galaxy S9</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S8+">Galaxy S8+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S7 Active">Galaxy S7 Active</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S7 edge">Galaxy S7 edge</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S7">Galaxy S7</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S6 Edge+">Galaxy S6 Edge+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S6 Edge">Galaxy S6 Edge</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S6">Galaxy S6</option> */}

                {/* Z and Fold */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip 5">Galaxy Z Flip 5</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold 5">Galaxy Z Fold 5</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip 4">Galaxy Z Flip 4</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold 4">Galaxy Z Fold 4</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip 3">Galaxy Z Flip 3</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold 3">Galaxy Z Fold 3</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold">Galaxy Z Fold</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip">Galaxy Z Flip</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Fold">Galaxy Fold</option> */}

              {/* Note series */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 20 Ultra">Galaxy Note 20 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 20">Galaxy Note 20</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 10+">Galaxy Note 10+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 10">Galaxy Note 10</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 9">Galaxy Note 9</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 8">Galaxy Note 8</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 7">Galaxy Note 7</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 5">Galaxy Note 5</option> */}

                {/* M Series */}
                {/* <option style={{direction: "ltr"}} value="Galaxy M54 5G">Galaxy M54 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M34 5G">Galaxy M34 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M14 5G">Galaxy M14 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M04">Galaxy M04</option>
                <option style={{direction: "ltr"}} value="Galaxy M53 5G">Galaxy M53 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M33 5G">Galaxy M33 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M23">Galaxy M23</option>
                <option style={{direction: "ltr"}} value="Galaxy M13 5G">Galaxy M13 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M13">Galaxy M13</option>
                <option style={{direction: "ltr"}} value="Galaxy M62">Galaxy M62</option>
                <option style={{direction: "ltr"}} value="Galaxy M52 5G">Galaxy M52 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M42 5G">Galaxy M42 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M32 5G">Galaxy M32 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M32">Galaxy M32</option>
                <option style={{direction: "ltr"}} value="Galaxy M22">Galaxy M22</option>
                <option style={{direction: "ltr"}} value="Galaxy M12">Galaxy M12</option>
                <option style={{direction: "ltr"}} value="Galaxy M02s">Galaxy M02s</option>
                <option style={{direction: "ltr"}} value="Galaxy M02">Galaxy M02</option>
                <option style={{direction: "ltr"}} value="Galaxy M51">Galaxy M51</option>
                <option style={{direction: "ltr"}} value="Galaxy M31s">Galaxy M31s</option>
                <option style={{direction: "ltr"}} value="Galaxy M31">Galaxy M31</option>
                <option style={{direction: "ltr"}} value="Galaxy M21s">Galaxy M21s</option>
                <option style={{direction: "ltr"}} value="Galaxy M21">Galaxy M21</option>
                <option style={{direction: "ltr"}} value="Galaxy M11">Galaxy M11</option>
                <option style={{direction: "ltr"}} value="Galaxy M01s">Galaxy M01s</option>
                <option style={{direction: "ltr"}} value="Galaxy M01">Galaxy M01</option>
                <option style={{direction: "ltr"}} value="Galaxy M40">Galaxy M40</option>
                <option style={{direction: "ltr"}} value="Galaxy M30s">Galaxy M30s</option>
                <option style={{direction: "ltr"}} value="Galaxy M30">Galaxy M30</option>
                <option style={{direction: "ltr"}} value="Galaxy M20">Galaxy M20</option>
                <option style={{direction: "ltr"}} value="Galaxy M10s">Galaxy M10s</option>
                <option style={{direction: "ltr"}} value="Galaxy M10">Galaxy M10</option>

                {/* J series */}
                {/* <option style={{direction: "ltr"}} value="Galaxy J8">Galaxy J8</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 (2018)">Galaxy J7 (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Duo">Galaxy J7 Duo</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Prime 2">Galaxy J7 Prime 2</option>
                <option style={{direction: "ltr"}} value="Galaxy J7+">Galaxy J7+</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Pro">Galaxy J7 Pro</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 (2017)">Galaxy J7 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Nxt">Galaxy J7 Nxt</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Max">Galaxy J7 Max</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Sky Pro">Galaxy J7 Sky Pro</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 V">Galaxy J7 V</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Prime">Galaxy J7 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 (2016)">Galaxy J7 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J7">Galaxy J7</option>
                <option style={{direction: "ltr"}} value="Galaxy J6+">Galaxy J6+</option>
                <option style={{direction: "ltr"}} value="Galaxy J6">Galaxy J6</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 (2017)">Galaxy J5 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 Prime">Galaxy J5 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 (2016)">Galaxy J5 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 (2015)">Galaxy J5 (2015)</option>
                <option style={{direction: "ltr"}} value="Galaxy J4 Core">Galaxy J4 Core</option>
                <option style={{direction: "ltr"}} value="Galaxy J4+">Galaxy J4+</option>
                <option style={{direction: "ltr"}} value="Galaxy J4">Galaxy J4</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 (2018)">Galaxy J3 (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 (2017)">Galaxy J3 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 Prime">Galaxy J3 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 Pro">Galaxy J3 Pro</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 (2016)">Galaxy J3 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Core (2020)">Galaxy J2 Core (2020)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Pure">Galaxy J2 Pure</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Core">Galaxy J2 Core</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Pro (2018)">Galaxy J2 Pro (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 (2018)">Galaxy J2 (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 (2017)">Galaxy J2 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Prime">Galaxy J2 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Pro (2016)">Galaxy J2 Pro (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 (2016)">Galaxy J2 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2">Galaxy J2</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 mini prime">Galaxy J1 mini prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 mini">Galaxy J1 mini</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 Nxt">Galaxy J1 Nxt</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 (2016)">Galaxy J1 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 Ace Neo">Galaxy J1 Ace Neo</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 Ace">Galaxy J1 Ace</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 4G">Galaxy J1 4G</option>
                <option style={{direction: "ltr"}} value="Galaxy J1">Galaxy J1</option> */}

                {/* F series */}
                {/* <option style={{direction: "ltr"}} value="Galaxy F54 5G">Galaxy F54 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F34 5G">Galaxy F34 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F14 5G">Galaxy F14 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F04">Galaxy F04</option>
                <option style={{direction: "ltr"}} value="Galaxy F23 5G">Galaxy F23 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F13">Galaxy F13</option>
                <option style={{direction: "ltr"}} value="Galaxy F62">Galaxy F62</option>
                <option style={{direction: "ltr"}} value="Galaxy F52 5G">Galaxy F52 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F42 5G">Galaxy F42 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F22">Galaxy F22</option>
                <option style={{direction: "ltr"}} value="Galaxy F12">Galaxy F12</option>
                <option style={{direction: "ltr"}} value="Galaxy F02s">Galaxy F02s</option> */}

                {/* A series */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A05s">Galaxy A05s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A05">Galaxy A05</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A34 5G">Galaxy A34 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A24">Galaxy A24</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A14 5G">Galaxy A14 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A14">Galaxy A14</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A04s">Galaxy A04s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A04">Galaxy A04</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A04e">Galaxy A04e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A23 5G">Galaxy A23 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A23">Galaxy A23</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A13 5G">Galaxy A13 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A13">Galaxy A13</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A03s">Galaxy A03s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A03">Galaxy A03</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A32">Galaxy A32</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A22 5G">Galaxy A22 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A22">Galaxy A22</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A12">Galaxy A12</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A02s">Galaxy A02s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A02">Galaxy A02</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A31">Galaxy A31</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A21s">Galaxy A21s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A21">Galaxy A21</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A11">Galaxy A11</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A01">Galaxy A01</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A20">Galaxy A20</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A20e">Galaxy A20e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A10s">Galaxy A10s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A10">Galaxy A10</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A10e">Galaxy A10e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8* (2018)">Galaxy A8* (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8+ (2018)">Galaxy A8+ (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8 (2018)">Galaxy A8 (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A7 (2018)">Galaxy A7 (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6s (2018)">Galaxy A6s (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6+ (2018)">Galaxy A6+ (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6 (2018)">Galaxy A6 (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A3 (2017)">Galaxy A3 (2017)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A5 (2017)">Galaxy A5 (2017)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A7 (2017)">Galaxy A7 (2017)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A5 (2016)">Galaxy A5 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6 (2016)">Galaxy A6 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A7 (2016)">Galaxy A7 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8 (2016)">Galaxy A8 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A9 (2016)">Galaxy A9 (2016)</option>
          </select>            
              :""
            }
              <select ref={productStorageSizeRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductStorageSizeHasError(false)
                setProductStorageSize(e.target.value);
              }}>
                <option disabled selected>سعة التخزين</option>
                <option style={{backgroundColor: "white"}} value="64 جيجا">64 جيجا</option>
                <option style={{backgroundColor: "white"}} value="128 جيجا">128 جيجا</option>
                <option style={{backgroundColor: "white"}} value="256 جيجا">256 جيجا</option>
                <option style={{backgroundColor: "white"}} value="512 جيجا">512 جيجا</option>
                <option style={{backgroundColor: "white"}} value="1 تيرا">1 تيرا</option>
            </select>
                </>
            : ""
            }
            {
              productCategory == 'الكترونيات' && productSubCategory == "شاشة" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductCompanyHasError(false)
                setProductCompany(e.target.value);
              }}>
                <option style={{backgroundColor: "white"}} disabled selected>الشركة المصنعة</option>
                <option style={{backgroundColor: "white"}} value="Samsung">Samsung</option>
                <option style={{backgroundColor: "white"}} value="سوني">سوني</option>
                <option style={{backgroundColor: "white"}} value="TCL">TCL</option>
                <option style={{backgroundColor: "white"}} value="دانسات">دانسات</option>
                <option style={{backgroundColor: "white"}} value="باناسونيك">باناسونيك</option>
                <option style={{backgroundColor: "white"}} value="ال جي">ال جي</option>
                <option style={{backgroundColor: "white"}} value="اسوس">اسوس</option>
                <option style={{backgroundColor: "white"}} value="BenQ">BenQ</option>
                <option style={{backgroundColor: "white"}} value="أخرى">أخرى</option>
            </select>
              <select ref={productScreenSizeRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductScreenSizeHasError(false)
                setProductScreenSize(e.target.value);
              }}>
                <option disabled selected>حجم الشاشة</option>
                <option style={{backgroundColor: "white"}} value="16 بوصة">16 بوصة</option>
                <option style={{backgroundColor: "white"}} value="18 بوصة">18 بوصة</option>
                <option style={{backgroundColor: "white"}} value="20 بوصة">20 بوصة</option>
                <option style={{backgroundColor: "white"}} value="22 بوصة">22 بوصة</option>
                <option style={{backgroundColor: "white"}} value="24 بوصة">24 بوصة</option>
                <option style={{backgroundColor: "white"}} value="26 بوصة">26 بوصة</option>
                <option style={{backgroundColor: "white"}} value="28 بوصة">28 بوصة</option>
                <option style={{backgroundColor: "white"}} value="30 بوصة">30 بوصة</option>
                <option style={{backgroundColor: "white"}} value="32 بوصة">32 بوصة</option>
                <option style={{backgroundColor: "white"}} value="34 بوصة">34 بوصة</option>
                <option style={{backgroundColor: "white"}} value="36 بوصة">36 بوصة</option>
                <option style={{backgroundColor: "white"}} value="38 بوصة">38 بوصة</option>
                <option style={{backgroundColor: "white"}} value="40 بوصة">40 بوصة</option>
                <option style={{backgroundColor: "white"}} value="45 بوصة">45 بوصة</option>
                <option style={{backgroundColor: "white"}} value="50 بوصة">50 بوصة</option>
                <option style={{backgroundColor: "white"}} value="55 بوصة">55 بوصة</option>
                <option style={{backgroundColor: "white"}} value="60 بوصة">60 بوصة</option>
                <option style={{backgroundColor: "white"}} value="65 بوصة">65 بوصة</option>
                <option style={{backgroundColor: "white"}} value="70 بوصة">70 بوصة</option>
                <option style={{backgroundColor: "white"}} value="75 بوصة">75 بوصة</option>
                <option style={{backgroundColor: "white"}} value="80 بوصة">80 بوصة</option>
                <option style={{backgroundColor: "white"}} value="85 بوصة">85 بوصة</option>
                <option style={{backgroundColor: "white"}} value="100 بوصة">100 بوصة</option>
            </select>
                </>
            : ""
            } */}
            
           
            {/* {
              productCategory == 'الكترونيات' && productSubCategory == "سماعة" ? 
              <select ref={productCompanyRef} style={{display: "block"}} onChange={(e)=>{
                setProductCompany(e.target.value);
              }}>
                <option disabled selected>النوع</option>
                <option value="Samsung">سماعات ابل</option>
                <option value="سوني">سوني</option>
                <option value="TCL">TCL</option>
                <option value="دانسات">دانسات</option>
                <option value="باناسونيك">باناسونيك</option>
                <option value="ال جي">ال جي</option>
                <option value="اسوس">اسوس</option>
                <option value="BenQ">BenQ</option>
            </select>
            : ""
            } */}

              {/* {
              productCategory == 'الكترونيات' && productSubCategory == "لابتوب" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductCompanyHasError(false)
                setProductCompany(e.target.value);
              }}>
                <option disabled selected>الشركة المصنعة</option>
                <option style={{backgroundColor: "white"}} value="ابل">ابل</option>
                <option style={{backgroundColor: "white"}} value="اتش بي">اتش بي</option>
                <option style={{backgroundColor: "white"}} value="ثينك باد">ثينك باد</option>
                <option style={{backgroundColor: "white"}} value="مايكروسوفت">مايكروسوفت</option>
                <option style={{backgroundColor: "white"}} value="ديل">ديل</option>
                <option style={{backgroundColor: "white"}} value="توشيبا">توشيبا</option>
                <option style={{backgroundColor: "white"}} value="Huawei">Huawei</option>
                <option style={{backgroundColor: "white"}} value="اسوس">اسوس</option>
                <option style={{backgroundColor: "white"}} value="لينوفو">لينوفو</option>
                <option style={{backgroundColor: "white"}} value="أخرى">أخرى</option>
            </select>
              <select ref={productStorageSizeRef} style={{display: "block", border: productMemorySizeHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px", backgroundColor: productMemorySizeHasError ? "#fff0f0" : "white" }} onChange={(e)=>{
                setProductMemorySizeHasError(false)
                setProductMemorySize(e.target.value);
              }}>
                <option disabled selected>الحجم</option>
                <option style={{backgroundColor: "white"}} value="4 رام">4 رام</option>
                <option style={{backgroundColor: "white"}} value="8 رام">8 رام</option>
                <option style={{backgroundColor: "white"}} value="16 رام">16 رام</option>
                <option style={{backgroundColor: "white"}} value="32 رام">32 رام</option>
            </select>
            <select ref={productScreenSizeRef} style={{display: "block", border: productScreenSizeHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px",backgroundColor: productScreenSizeHasError ? "#fff0f0" : "white" }} onChange={(e)=>{
                setProductScreenSizeHasError(false)
                setProductScreenSize(e.target.value);
              }}>
                <option disabled selected>حجم الشاشة</option>
                <option style={{backgroundColor: "white"}} value="16 بوصة">11 بوصة</option>
                <option style={{backgroundColor: "white"}} value="18 بوصة">12 بوصة</option>
                <option style={{backgroundColor: "white"}} value="20 بوصة">13 بوصة</option>
                <option style={{backgroundColor: "white"}} value="22 بوصة">14 بوصة</option>
                <option style={{backgroundColor: "white"}} value="24 بوصة">15 بوصة</option>
                <option style={{backgroundColor: "white"}} value="26 بوصة">16 بوصة</option>
                <option style={{backgroundColor: "white"}} value="28 بوصة">17 بوصة</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "جهاز لوحي" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductCompanyHasError(false);
                setProductCompany(e.target.value);
              }}>
                <option disabled selected>الشركة المصنعة</option>
                <option value="ابل">ابل</option>
                <option value="Samsung">Samsung</option>
                <option value="Huawei">Huawei</option>
                <option value="Xiaomi">Xiaomi</option>
                <option value="اونور">اونور</option>
                <option value="أخرى">أخرى</option>
            </select>
              <select ref={productMemorySizeRef} style={{display: "block", border: productMemorySizeHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px", backgroundColor: productMemorySizeHasError ? "#fff0f0" : "white"}} onChange={(e)=>{
                setProductMemorySizeHasError(false)
                setProductMemorySize(e.target.value);
              }}>
                <option style={{backgroundColor: "white"}} disabled selected>الحجم</option>
                <option style={{backgroundColor: "white"}} value="4 رام">4 رام</option>
                <option style={{backgroundColor: "white"}} value="8 رام">8 رام</option>
                <option style={{backgroundColor: "white"}} value="16 رام">16 رام</option>
                <option style={{backgroundColor: "white"}} value="32 رام">32 رام</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "كاميرا" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductCompanyHasError(false);
                setProductCompany(e.target.value);
              }}>
                <option disabled selected>الشركة المصنعة</option>
                <option value="كانون">كانون</option>
                <option value="سوني">سوني</option>
                <option value="فوجي">فوجي</option>
                <option value="أخرى">أخرى</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "طابعة" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductCompanyHasError(false);
                setProductCompany(e.target.value);
              }}>
                <option disabled selected>الشركة المصنعة</option>
                <option value="اتش بي">اتش بي</option>
                <option value="كانون">كانون</option>
                <option value="ايبسون">ايبسون</option>
                <option value="أخرى">أخرى</option>
            </select>
              <select ref={productInkKindRef} style={{display: "block", border: productInkKindHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px",backgroundColor: productInkKindHasError ? "#fff0f0" : "white" }} onChange={(e)=>{
                setProductInkKindHasError(false);
                setProductInkKind(e.target.value);
              }}>
                <option disabled selected>نوع الحبر</option>
                <option value="ليزر">ليزر</option>
                <option value="سائل">سائل</option>
                <option value="حرارية">حرارية</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "جهاز العاب" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductCompanyHasError(false);
                setProductCompany(e.target.value);
              }}>
                <option disabled selected>الشركة المصنعة</option>
                <option value="بلايستيشن">بلايستيشن</option>
                <option value="مايكروسوفت">مايكروسوفت</option>
            </select>
            {
               productCategory == 'الكترونيات' && productSubCategory == "جهاز العاب"  && productCompany == 'بلايستيشن'?
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductKindHasError(false);
                setProductKind(e.target.value);
              }}>
                <option disabled selected>نوع الجهاز</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 1">بلايستيشن 1</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 2">بلايستيشن 2</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 3">بلايستيشن 3</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 4">بلايستيشن 4</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 5">بلايستيشن 5</option>
            </select>
            : ""
            }
            {
              productCategory == 'الكترونيات' && productSubCategory == "جهاز العاب"  && productCompany == 'مايكروسوفت'?
                <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                  setProductKindHasError(false);
                  setProductKind(e.target.value);
                }}>
                  <option disabled selected>نوع الجهاز</option>
                  <option style={{backgroundColor: "white"}} value="اكسبوكس 360">اكسبوكس 360</option>
                  <option style={{backgroundColor: "white"}} value="اكسبوكس 1">اكسبوكس 1</option>
              </select>
              : ""
            }
                </>
            : ""
            }
            {
              productCategory == 'اثاث' && productSubCategory == "كرسي" ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductKindHasError(false);
                setProductKind(e.target.value);
              }}>
                <option disabled selected>النوع</option>
                <option style={{backgroundColor: "white"}} value="منزلي">منزلي</option>
                <option style={{backgroundColor: "white"}} value="مكتبي">مكتبي</option>
                <option style={{backgroundColor: "white"}} value="مدرسي">مدرسي</option>
                <option style={{backgroundColor: "white"}} value="خارجي">خارجي</option>
            </select> 
            : ""
            } 
            { 
              productCategory == 'اثاث' && productSubCategory == "كنب" ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductKindHasError(false);
                setProductKind(e.target.value);
              }}>
                <option disabled selected>النوع</option>
                <option style={{backgroundColor: "white"}} value="منزلي">منزلي</option>
                <option style={{backgroundColor: "white"}} value="مكتبي">مكتبي</option>
                <option style={{backgroundColor: "white"}} value="خارجي">خارجي</option>
                <option style={{backgroundColor: "white"}} value="زاوية">زاوية</option>
            </select> 
            : ""
            }
            {
              productCategory == 'اثاث' && productSubCategory == "طاولة" ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(e)=>{
                setProductKindHasError(false);
                setProductKind(e.target.value);
              }}>
                <option disabled selected>النوع</option>
                <option style={{backgroundColor: "white"}} value="منزلية">منزلية</option>
                <option style={{backgroundColor: "white"}} value="مكتب">مكتب</option>
            </select>
            : ""
            } */}
              {/* </div> */}



            {/* </div> */}

        </div>
    </>
   );
  }

export default AddPostScreen;