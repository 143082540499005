import React, { useEffect, useState, useRef } from 'react'
import e from '../assets/electronics.avif';
import f from '../assets/furniture.jpg';
import { MdOutlineWatchLater } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";
import { AiFillStar } from "react-icons/ai";
import { Divider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {Link , useParams, useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import Countdown from 'react-countdown';
import { getStorage, ref, listAll, getDownloadURL,  } from "firebase/storage";
import { getFirestore, collection, getDocs, onSnapshot, where, Timestamp, Firestore, limit, doc, query , updateDoc, serverTimestamp } from 'firebase/firestore';
import placeholder from '../assets/no-image.jpg';
import { MdDangerous } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import {storage} from '../config/firebase';
import { BsStopwatchFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import logo from '../assets/logo.jpg'
  
  function Product({postId, imgs , isSold, isBidder, productName, heighestBidder, acceptsOffers ,productDescription, productPrice, startingPrice,productSeller, expiryDate, condition, currentBid ,numBidders, numBids, link, sellerCity, buyerId, myId, isAuction, category, carPlateKind}) {
    //   const postRef = doc(db, 'Posts', `post${postId}`);

      // const [downloadURL, setDownloadURL] = useState(null);

      // useEffect(() => {

      //   const fetchImageURL = async () => {
      //       try {
      //         const url = await getDownloadURL(ref(storage, `Posts/post${postId}/1`));
      //         setDownloadURL(url);
      //       } catch (error) {
      //         console.error('Error fetching download URL:', error);
      //       }
      //     };
      
      //   //   fetchImageURL();
      // }, [])
      
    function formatAccountingArabic(number) {
        // Check if the input is a valid number
        if (typeof number !== 'number' || isNaN(number)) {
          return 'Invalid input';
        }
      
        // Use toLocaleString to format the number with commas and two decimal places
        const formattedNumber = number.toLocaleString('en', {
          minimumFractionDigits: number > 100 ? 0 : 2,
          maximumFractionDigits: number > 100 ? 0 : 2,
        });
      
        // Add the Arabic currency symbol manually
        const arabicFormattedNumber = `${formattedNumber} ر.س`;
      
        return arabicFormattedNumber;
    }

    const [imageLoaded, setImageLoaded] = useState(false);

    const skeletonRef = useRef(null);
  
    const handleImageLoad = () => {
      setImageLoaded(true);
      if (skeletonRef.current) {
        skeletonRef.current.style.display = 'none';
      }
    };

  useEffect(() => {
    if (imageLoaded) {
      // Hide the skeleton element here (using a ref or DOM manipulation)
      const skeletonElement = document.querySelector('.skeleton');
      if (skeletonElement) {
        skeletonElement.style.display = 'none';
      }
    }
  }, [imageLoaded]);

    return (
        <>
        <div style={{display: "block", minWidth:"100%", position:"relative", width:"100%"}}>

        <Link to={link}>
            <div className='product-div'>
              {/* product top right icons */}
              <div style={{position:"absolute", right: -5, top: -8, justifyContent:"space-evenly"}}>
                {
                  acceptsOffers && (
                    <FaMoneyBillWave color='white' size={25} style={{padding:"4px", backgroundColor:"#00C8B8",  borderRadius:1000, marginLeft:"4px"}} />
                  )
                }

                {
                  isAuction && isBidder && (
                    heighestBidder === myId ? (
                      <FaCheck color='white' size={28} style={{padding:"6px", backgroundColor:"#00C8B8",  borderRadius:1000, marginLeft:"4px"}} />
                      ) : 
                      <MdOutlineClose color='white' size={28} style={{padding:"2px", backgroundColor:"orange",  borderRadius:1000, marginLeft:"4px"}} />

                  )
                }

              </div>


              <div className="image-container">
      <div className="skeleton" ref={skeletonRef} /> 
      <img
        src={imgs}
        className="product-img"
        style={{
          objectFit: (category === "لوح سيارات" || category === "ارقام جوالات") ? "scale-down" : "cover",
          display: imageLoaded ? "block" : "none",
          padding: category === "لوح سيارات" ? carPlateKind === "لوحة دراجة نارية" ? "24px" : "0px" : "0px",
        }}
        onLoad={handleImageLoad}
        loading='eager'
      />
    </div>



                <p id='product-name-div'>{productName}</p>
                {/* <h5 style={{width:"100%", overflow:"hidden", wordBreak:"break-word", textOverflow:"ellipsis", backgroundColor:"black"}}>testttttestttttestttt</h5> */}
                <div style={{ display: "flex", direction: "rtl", justifyContent: "space-between", alignItems: "center"}}>
              {!isAuction && (
                <p style={{ direction: "rtl", color: "#F00057", marginBottom: "4px", }}>{formatAccountingArabic(productPrice)}</p>
              )}

            {isAuction && (
              <>
              <p style={{marginBottom: "0px", color: "#F00057"}}>{formatAccountingArabic(currentBid)}</p>
                  </>
            )}
          </div>
  {isAuction && (
    <div style={{display: "flex", direction: "rtl", alignItems: "center", marginBottom: "2px", marginTop: "2px"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <PiGavelFill
    style={{color: "#00C6B7", marginTop:"4px"}}
    />
    <p style={{marginBottom: "0px", marginRight: "4px", color: "#F00057"}}>{numBidders}</p>
    </div>
    <div style={{display: "flex",  marginRight:"auto", alignItems:"center"}}>
    <Countdown
    className='product-countdown-class'
    date={expiryDate instanceof Timestamp ? new Date(expiryDate.toDate()).getTime() : expiryDate.getTime() }

    >
      <p style={{ marginBottom: "0px", color: "#F00057"}}>انتهى المزاد</p>
    </Countdown>
    <BsStopwatchFill color='#F00057' style={{marginRight: "4px", marginTop:"2px"}}/>
        </div>
    </div>
  )}
  <div style={{display: "flex", direction: "rtl"}}>

            {
              condition && (
                <>
                <p style={{marginBottom: "4px", marginTop: "0px", color: "#00C6B7"}}>الحالة: </p>
            <p style={{marginBottom: "0px", color: "#F00057", marginRight: "4px"}}>{condition}</p>
                </>
              )
            }
  </div>
                <button id='my-button' style={{width: "100%"}}>رؤية التفاصيل</button>
            </div>
        </Link>
        
        </div>


        
        </>
    );
}
 
export default Product;