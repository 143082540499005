import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion, FieldValue, increment, addDoc, orderBy, Timestamp, writeBatch } from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
import { Divider } from '@mui/material';
import { BiSolidPackage } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import SebhahIcon from '../assets/sebhah00C8B8.PNG'
import { GoNumber } from "react-icons/go";
import carPlateIcon from '../assets/car-plate-icon00C8B8.png'
import { FaSignal } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import logo from '../assets/logo.png'
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link, set} from "react-router-dom";
import Countdown from 'react-countdown';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import f from '../assets/furniture.jpg';
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import Comment from '../components/Comment';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdDangerous } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Oval } from 'react-loader-spinner'
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar';
import { GoPencil } from "react-icons/go";
import { db, auth, storage } from '../config/firebase'
import emailjs from 'emailjs-com';
import { listAll, ref, getDownloadURL, uploadBytes, deleteObject  } from 'firebase/storage';
import galleryPlaceholder from '../assets/galley-placeholder.jpg'
import { MdInfo } from "react-icons/md";
import { BsStopwatchFill } from "react-icons/bs";
import { LuCircleDashed } from "react-icons/lu";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa6";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { IoTime } from "react-icons/io5";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import LoadingScreen from './LoadingScreen';
import { GiBangingGavel } from "react-icons/gi";
import { MdDeleteForever } from "react-icons/md";
import { GiStopwatch } from "react-icons/gi";
import { FaBox } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { FaQuestion } from "react-icons/fa6";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { TiStarburst } from "react-icons/ti";
import { TbCategoryFilled } from "react-icons/tb";
import { FaTransgender } from "react-icons/fa";
import { MdFactory } from "react-icons/md";
import { HiMiniPaintBrush } from "react-icons/hi2";
import { IoResize } from "react-icons/io5";
import { FaShapes } from "react-icons/fa6";
import { IoLanguage } from "react-icons/io5";
import { IoIosWatch } from "react-icons/io";
import { FaShippingFast } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import SuccessAnim from '../components/SuccessAnim';
import { IoStopwatch } from "react-icons/io5";
import { GiSewingMachine } from "react-icons/gi";
import { FaTowerCell } from "react-icons/fa6";
import { GoHomeFill, GoReport } from "react-icons/go";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { getAllByDisplayValue } from '@testing-library/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io"; 
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { GoPaperclip } from "react-icons/go";
import { IoSend } from "react-icons/io5"; 
import Lottie from 'lottie-react';
import noChatAnim from '../assets/animations/NoChatAnim.json'
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import useIsSafari from '../hooks/useIsSafari';
import { useChat } from '../hooks/ChatContext'
import { v4 as uuidv4 } from 'uuid';



function PostDetailsScreen() {
  const location = useLocation();
  let mode = location.state?.mode;
  const [successMessage, setSuccessMessage] = useState('');

  const profitPercentage = 0.10;

  const isSafari = useIsSafari();

  const [Listing, setListing] = useState([]);
  const [gal, setgal] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [similarCategory, setSimilarCategory] = useState([]);
  const [similarSubCategory, setSimilarSubCategory] = useState([]);
  const [productCategory, setProductCategory] = useState("")
  const [numBidders, setNumBidders] = useState(0)
  const [productCondition, setProductCondition] = useState("")
  const [autoReceiveDate, setAutoReceiveDate] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [productSubCategory, setProductSubCategory] = useState("")
  const [productCompany, setProductCompany] = useState("")
  const [entryFee, setEntryFee] = useState("")
  const [productPrice, setProductPrice] = useState(0)
  const [numBids, setNumBids] = useState(0)
  const [productStartingPrice, setProductStartingPrice] = useState(10)
  const [bidIncrements, setBidIncrements] = useState(10)
  const [productStorageSize, setProductStorageSize] = useState("")
  const [productScreenSize, setProductScreenSize] = useState("")
  const [productMemorySize, setProductMemorySize] = useState("")
  const [productInkKind, setProductInkKind] = useState("")
  const [watchInsideColor, setWatchInsideColor] = useState("")
  const [watchNumbersLanguage, setWatchNumbersLanguage] = useState("")
  const [watchBandMaterial, setWatchBandMaterial] = useState("")
  const [watchBandColor, setWatchBandColor] = useState("")
  const [watchYearMade, setWatchYearMade] = useState("")
  const [watchIsWaterResistant, setWatchIsWaterResistant] = useState(null)
  const [watchInsideColorSpecification, setWatchInsideColorSpecification] = useState("")
  const [watchOutsideColor, setWatchOutsideColor] = useState("")
  const [watchDialSize, setWatchDialSize] = useState("")
  const [watchDialShape, setWatchDialShape] = useState("")
  const [watchOutsideColorSpecification, setWatchOutsideColorSpecification] = useState("")
  const [watchDepartment, setWatchDepartment] = useState("")
  const [watchHasOriginalPackaging, setWatchHasOriginalPackaging] = useState(null)
  const [walletDepartment, setWalletDepartment] = useState("");
  const [walletOutsideColor, setWalletOutsideColor] = useState("");
  const [sellerName, setSellerName] = useState("")
  const [sellerRating, setSellerRating] = useState("")
  const [sellerNumRaters, setSellerNumRaters] = useState("")
  const [sellerCity, setSellerCity] = useState("")
  const [sellerIsVerified, setSellerIsVerified] = useState(false)
  const [sellerProfilePicture, setSellerProfilePicture] = useState("")
  const [purseMaterial, setPurseMaterial] = useState("")

  const [rosaryKind, setRosaryKind] = useState("")
  const [rosaryColor, setRosaryColor] = useState("")
  const [rosaryCount, setRosaryCount] = useState("")
  const [rosarySize, setRosarySize] = useState("")

  const [reportReason, setReportReason] = useState("");
  const [reportLoading, setReportLoading] = useState(false);


  
  
  
  const [reportUserShouldRender, setReportUserShouldRender] = useState(false);
  const [isReportUserOpen, setIsReportUserOpen] = useState(false);
  

  useEffect(() => {
    if (isReportUserOpen) {
      setReportUserShouldRender(true);
    }
  }, [isReportUserOpen]);
  
  
  
  
  useEffect(() => {
    if (!isReportUserOpen) {
      setTimeout(() => setReportUserShouldRender(false), 100); // Duration matches the animation time
    }
  }, [isReportUserOpen]);
  
  
  
  
  const toggleReport = () => {
    setIsReportUserOpen(true);
  };
  
  
  

  
  const messagesEndRef = useRef(null);
  
  // Function to scroll to the bottom of the messages
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    // Function to scroll to the bottom of the messages
    const scrollToBottomInstant = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'instant' });
      }
    };
    
    
    
    
    const [sendMessageShouldRender, setSendMessageShouldRender] = useState(false);
    const [isSendMessageOpen, setIsSendMessageOpen] = useState(false);
    
    
    useEffect(() => {
      if (isSendMessageOpen) {
        setSendMessageShouldRender(true);
      }
    }, [isSendMessageOpen]);
    
    
    
    
    useEffect(() => {
      if (!isSendMessageOpen) {
        setTimeout(() => setSendMessageShouldRender(false), 100); // Duration matches the animation time
      }
    }, [isSendMessageOpen]);
    
    
    const { setChatIsOpen, setNewChatUser, setDrawerOpen, setActiveChatId, usersInfo, chats } = useChat();

    const toggleSendMessage = () => {
      setChatIsOpen(true);
      setDrawerOpen(true);
    
      const existingChat = Object.values(chats).find(chat =>
        chat.group.includes(sellerId)
      );
    
      if (existingChat) {
        setActiveChatId(existingChat.id);
      } else {
        setActiveChatId(uuidv4());
      }
    
      setNewChatUser({
        profileImgUrl: sellerProfilePicture,
        isVerified: sellerIsVerified,
        rating: sellerRating,
        numRaters: sellerNumRaters,
        city: sellerCity,
        id: sellerId,
        fullName: sellerName
      });
    };
    


    



const [messageToSeller, setMessageToSeller] = useState("")
const messageToSellerRef = useRef();


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });


  useEffect(() => {
    if (isSendMessageOpen || isReportUserOpen) {
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'instant' });
        }
      }, 5); // Delay to ensure the component is fully rendered
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    // Clean up the class on unmount
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isSendMessageOpen, isReportUserOpen]);



  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlide = (index) => {
    setCurrentIndex(index);
  };

  const [carPlateTransferTime, setCarPlateTransferTime] = useState("")
  const [carPlateKind, setCarPlateKind] = useState("")
  const [carPlateFirstArabicLetter, setCarPlateFirstArabicLetter] = useState("");
  const [carPlateSecondArabicLetter, setCarPlateSecondArabicLetter] = useState("");
  const [carPlateThirdArabicLetter, setCarPlateThirdArabicLetter] = useState("");
  const [carPlateFirstArabicNumber, setCarPlateFirstArabicNumber] = useState("");
  const [carPlateSecondArabicNumber, setCarPlateSecondArabicNumber] = useState("");
  const [carPlateThirdArabicNumber, setCarPlateThirdArabicNumber] = useState("");
  const [carPlateFourthArabicNumber, setCarPlateFourthArabicNumber] = useState("");
  const [carPlateTransferFeeOn, setCarPlateTransferFeeOn] = useState("");

  const [carPlateFirstEnglishLetter, setCarPlateFirstEnglishLetter] = useState("");
  const [carPlateSecondEnglishLetter, setCarPlateSecondEnglishLetter] = useState("");
  const [carPlateThirdEnglishLetter, setCarPlateThirdEnglishLetter] = useState("");
  const [carPlateFirstEnglishNumber, setCarPlateFirstEnglishNumber] = useState("");
  const [carPlateSecondEnglishNumber, setCarPlateSecondEnglishNumber] = useState("");
  const [carPlateThirdEnglishNumber, setCarPlateThirdEnglishNumber] = useState("");
  const [carPlateFourthEnglishNumber, setCarPlateFourthEnglishNumber] = useState("");

  const [shippingMethods, setShippingMethods] = useState([])
  
  const [phoneNumberCourier, setPhoneNumberCourier] = useState("");
  const [phoneNumberToSell, setPhoneNumberToSell] = useState("");

  const [lastBidDate, setLastBidDate] = useState("");
  const [filteredListing, setFilteredListing] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [qu, setQu] = useState(query(collection(db, "Posts")));
  const [isLoading, setIsLoading] = useState(true);
  const [hasExpired, setExpired] = useState(false);
  const [imageLoading, setimageLoading] = useState(true);
  const [productName, setProductName] = useState("");
  const [watchNumbersColor, setWatchNumbersColor] = useState("")
  const [watchNumbersColorSpecification, setWatchNumbersColorSpecification] = useState("")
  const [watchHandlesColor, setWatchHandlesColor] = useState("")
  const [watchHandlesColorSpecification, setWatchHandlesColorSpecification] = useState("")
  const [isReceived, setIsReceived] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [productReceiveLoading, setProductReceiveLoading] = useState(false);
  const [productRejectLoading, setProductRejectLoading] = useState(false);
  const [postType, setPostType] = useState([])
  const [isUpdated, setIsUpdated] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const [currentBid, setCurrentBid] = useState(0);
  const [myBid, setMyBid] = useState(0);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const [heighestBidder, setHeighestBidder] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");
  const [reportNotes, setReportNotes] = useState("");
  const [newBid, setNewBid] = useState(0);
  const [addCommentLoading, setAddCommentLoading] = useState(false);
const [purseInsideColor, setPurseInsideColor] = useState("")
const [purseInsideColorSpecification, setPurseInsideColorSpecification] = useState("")
const [purseOutsideColor, setPurseOutsideColor] = useState("")
const [purseOutsideColorSpecification, setPurseOutsideColorSpecification] = useState("")
const [giveOfferClicked, setGiveOfferClicked] = useState(false)
const [offerGiven, setOfferGiven] = useState(false)
const [showSuccessAnim, setShowSuccessAnim] = useState(false);


  const [sellerId, setSellerId] = useState("");

  const navigate = useNavigate();

  const myBidRef = useRef();
  const myOfferRef = useRef();
  const commentRef = useRef();
  
  const [myOffer, setMyOffer] = useState("");
  const [isDeleted, setisDeleted] = useState(false);
  const [isSold, setisSold] = useState(false);

  const [bidders, setBidders] = useState([]);
  const [keywords, setKeywords] = useState([]);


  const [productExists, setProductExists] = useState(false)
  const [galleryLoading, setGalleryLoading] = useState(true)
  
  const { id } = useParams();
  
  const q = query(collection(db, "Posts"), where("id", "==", parseFloat(id)));
  
  const postRef = doc(db, "Posts", `post${id}`);

  const [myId, setMyId] = useState("");
  const [buyerId, setBuyerId] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the isOpen state
  const toggleDropdown = () => {
    setIsOpen(false);
  };

const [sendingMessage, setSendingMessage] = useState(false)

const [hasStartedChat, setHasStartedChat] = useState(false)


// async function sendMessageToSeller() {
//   if (messageToSeller === "") {
//     alert("يرجى ادخال رسالة");
//     return;
//   }

  
//   let messageId = Math.floor(Math.random() * 100000)
//     // Add the new message to the local messages array
//     const newMessage = {
//       messageId: messageId,
//       message: messageToSeller,
//       date: new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
//       senderId: parseFloat(myId),
//       isDelivered: false
//     };
//     setMessages((prevMessages) => [...prevMessages, newMessage]);

//     setMessageToSeller("");
//     messageToSellerRef.current.value = "";
//     scrollToBottom();

//   try {
//     // setSendingMessage(true);
//     const ChatsCollRef = collection(db, "Chats");

//     // Query to find the chat document with the specified group
//     const chatQuery = query(
//       ChatsCollRef,
//       where("group", "array-contains", parseFloat(myId))
//     );

//     // Execute the query and get the documents
//     const chatQuerySnapshot = await getDocs(chatQuery);

//     // Filter the chats to find the one with both user IDs
//     const chatDoc = chatQuerySnapshot.docs.find(doc => {
//       const group = doc.data().group;
//       return group.includes(parseFloat(sellerId));
//     });

//     let chatDocRef;

//     if (chatDoc) {
//       // If chat document exists, use it
//       chatDocRef = doc(db, "Chats", chatDoc.id);
//     } else {
//       // If no chat document exists, create a new one
//       chatDocRef = await addDoc(ChatsCollRef, {
//         group: [parseFloat(myId), sellerId],
//       });
//     }

//     // Reference to the "Messages" collection inside the chat document
//     const MessagesCollRef = collection(chatDocRef, "Messages");

//     // Add a new message to the "Messages" collection with a generated ID
//     const messageDocRef = await addDoc(MessagesCollRef, {
//       message: messageToSeller,
//       date: Timestamp.now(), // use Firestore's server timestamp
//       senderId: parseFloat(myId),
//       isRead: false,
//       isDelivered: true
//     })

//     setMessages((prevMessages) =>
//       prevMessages.map((msg) =>
//         msg.messageId === messageId ? { ...msg, isDelivered: true } : msg
//       )
//     );
//     // setIsSendMessageOpen(false);
//     // setSendingMessage(false);
//     // setSuccessMessage("تم ارسال رسالتك للبائع بنجاح!");
//     // setShowSuccessAnim(true);
//   } catch (error) {
//     setIsSendMessageOpen(false);
//     setSendingMessage(false);
//     console.error("Error updating document: ", error);
//   }
//   setMessageToSeller("");

// }

  async function reportUser() {

    // report reason
    if(reportReason === ""){
      alert("يرجى اختيار نوع البلاغ")
      return;
    }

    try {
      setReportLoading(true)
      const reportsCollRef = collection(db, "Reports");

        // Add a new document with a generated ID to the "Ratings" collection
        await addDoc(reportsCollRef, {
          reportReason: reportReason,
          reportNotes: reportNotes,
          reporterId: parseFloat(myId),
          targetId: parseFloat(id),
          type: "User"
        });
        setIsReportUserOpen(false);
        setReportLoading(false)
        setReportNotes("")
        setSuccessMessage("!تم الإبلاغ عن البائع بنجاح")
        setShowSuccessAnim(true);
    } catch (error) {
      setIsReportUserOpen(false);
      setReportLoading(false)
      console.error("Error updating document: ", error);
    }
  }



  const [userDropdownIsOpen, setUserDropDownIsOpen] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  // Function to toggle the isOpen state
  const toggleUserDropdown = () => {
    setUserDropDownIsOpen(!userDropdownIsOpen);
  };

  
  useEffect(() => {
    if (userDropdownIsOpen) {
      setShouldRender(true);
    }
  }, [userDropdownIsOpen]);


  // useEffect(()=>{
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'instant'
  //   });
  // }, [])


  const [messages, setMessages] = useState([])
  const [fetchingMessages, setFetchingMessages] = useState(true);

        // Scroll to the bottom whenever messages change
        useEffect(() => {
          scrollToBottom();
        }, [messages]); 


        
        const isSendMessageOpenRef = useRef(isSendMessageOpen);

useEffect(() => {
  isSendMessageOpenRef.current = isSendMessageOpen;
}, [isSendMessageOpen]);



// const fetchMessages = async () => {
//   try {
//     if (!myId || !sellerId) {
//       setMessages([]);
//       setFetchingMessages(false);
//       return;
//     }

//     // Reference to the Chats collection
//     const ChatsCollRef = collection(db, "Chats");

//     // Create a query to find the chat document with exactly [myId, sellerId] in the group array
//     const chatQuery = query(
//       ChatsCollRef,
//       where("group", "==", [myId, sellerId])
//     );

//     // Execute the query to get the chat document
//     const chatQuerySnapshot = await getDocs(chatQuery);

//     // Check if we found exactly one chat document
//     if (chatQuerySnapshot.size !== 1) {
//       setMessages([]);
//       setFetchingMessages(false);
//       return;
//     }

//     // Get the chat document reference
//     const chatDocRef = chatQuerySnapshot.docs[0].ref;

//     // Reference to the Messages collection inside the chat document
//     const messagesCollRef = collection(chatDocRef, "Messages");
//     const messagesQuery = query(messagesCollRef, orderBy("date"));

//     // Subscribe to real-time updates for messages
//     const unsubscribe = onSnapshot(messagesQuery, async (snapshot) => {
//       // Create a new array to hold all messages
//       let updatedMessages = [];

//       const batch = writeBatch(db);

//       snapshot.forEach((messageDoc) => {
//         const messageData = messageDoc.data();
//         const timeSent = messageData.date.toDate().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

//         const message = {
//           messageId: messageDoc.id,
//           message: messageData.message,
//           date: timeSent,
//           senderId: messageData.senderId,
//           isRead: messageData.isRead,
//           isDelivered: messageData.isDelivered
//         };

//         updatedMessages.push(message);

//         // Collect the updates for messages to be marked as read
//         if (isSendMessageOpenRef.current && messageData.senderId !== myId && !messageData.isRead) {
//           console.log("Marking message as read:", messageDoc.id);
//           batch.update(messageDoc.ref, { isRead: true });
//         }
//       });

//       // Commit the batch update to mark messages as read only if the chat is open
//       if (isSendMessageOpenRef.current) {
//         console.log("Committing batch update to mark messages as read.");
//         await batch.commit();
//       }

//       // Update messages state with the new array
//       setMessages(updatedMessages);
//       setFetchingMessages(false);
//     });

//     return () => unsubscribe(); // Clean up message listener
//   } catch (error) {
//     console.error("Error fetching messages: ", error);
//     setFetchingMessages(false);
//   }
// };


// useEffect(() => {
//   if (myId && sellerId) {
//     fetchMessages();
//   }
// }, [myId, sellerId]);

        
        
        

  const fetchComments = async () => {
    try {
      // Build the query to fetch comments from the Comments collection
      const commentsRef = collection(db, 'Comments');
      const commentsQuery = query(
        commentsRef,
        where('targetId', '==', parseFloat(id)), // Ensure postId is a string
        where('commentType', '==', 'Post'),
        where('isDeleted', '==', false),
        orderBy('timestamp', 'asc')
      );
  
      const commentsSnapshot = await getDocs(commentsQuery);
      const comments = [];
  
      for (const commentDoc of commentsSnapshot.docs) {
        const commentData = commentDoc.data();
  
        // Get the user document using the id field with parseFloat
        const userQuerySnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', parseFloat(commentData.user.userId))));
  
        if (!userQuerySnapshot.empty) {
          const userData = userQuerySnapshot.docs[0].data();
          comments.push({
            commentId: commentDoc.id,
            userId: commentData.user.userId,
            comment: commentData.comment,
            timestamp: commentData.timestamp.toDate(),
            isDeleted: commentData.isDeleted,
            isUpdated: commentData.isUpdated,
            targetId: commentData.targetId,
            user: {
              fullName: userData.fullName,
              profileImgUrl: userData.profileImgUrl,
              isVerified: userData.isVerified
            },
            commentType: commentData.commentType
          });
        } else {
          console.error(`User document not found for id: ${commentData.user.userId}`);
        }
      }
  
      setComments(comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
    } finally {
      setCommentsLoading(false);
    }
  };
  
  const handleUpdateComment = (commentId, updatedCommentText) => {
    console.log(commentId);
  
    // Update the comment text and set isUpdated to true in the local array
    setComments(prevComments =>
      prevComments.map(comment => 
        comment.commentId === commentId 
          ? { ...comment, comment: updatedCommentText, isUpdated: true } 
          : comment
      )
    );
  };
  

  useEffect(() => {
    let unsubscribeUserData;

    auth.onAuthStateChanged(async function (user) {
        if (user != null) {
            const userDocRef = doc(db, "Users", user.uid);
            unsubscribeUserData = onSnapshot(userDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setMyId(userData.id);
                    setOfferGiven(userData.hasGivenOffer);
                } else {
                    console.log("User document does not exist");
                }
            }, (error) => {
                console.error("Error fetching user document:", error);
            });
        } else {
            // setIsLoading(false);
        }
    });

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchData = () => {
            const cities = [];
            const imgs = [];
            const gal = [];
            const kwds = [];

            // Check if the querySnapshot is empty
            if (querySnapshot.empty) {
                // Handle the case when no documents are found
                console.log("No documents found");
                setProductExists(false);
                // setIsLoading(false);
                return;
            }
            setProductExists(true);

            querySnapshot.forEach((doc) => {
                const data = doc.data();

                // Extracting data and setting state
                const {
                    subCategory,
                    postType,
                    category,
                    productStorageSize,
                    productScreenSize,
                    productMemorySize,
                    productInkKind,
                    currentBid,
                    productPrice,
                    sellerId,
                    bidders,
                    isSold,
                    comments,
                    heighestBidder,
                    startingPrice,
                    expiryDate,
                    imgs: productImgs,
                    keywords,
                    isReceived,
                    bidIncrements,
                    lastBidDate,
                    numBids,
                    isUpdated,
                    shippingMethods,
                    updatedDate,
                    productName,
                    buyerId,
                    condition,
                    watchDepartment,
                    productCompany,
                    watchInsideColor,
                    watchInsideColorSpecification,
                    watchOutsideColorSpecification,
                    watchOutsideColor,
                    productDescription,
                    watchDialSize,
                    watchDialShape,
                    purseMaterial,
                    watchHandlesColor,
                    watchHandlesColorSpecification,
                    watchNumbersColor,
                    watchNumbersColorSpecification,
                    watchNumbersLanguage,
                    watchBandMaterial,
                    watchBandColor,
                    watchBandColorSpecification,
                    watchHasOriginalPackaging,
                    watchYearMade,
                    watchIsWaterResistant,
                    rosaryKind,
                    rosaryKindSpecification,
                    rosaryColor,
                    rosaryColorSpecification,
                    rosaryCount,
                    rosarySize,
                    walletDepartment,
                    carPlateTransferTime,
                    carPlateTransferFeeOn,
                    carPlateKind,
                    carPlateFirstArabicLetter,
                    carPlateSecondArabicLetter,
                    carPlateThirdArabicLetter,
                    carPlateFirstArabicNumber,
                    carPlateSecondArabicNumber,
                    carPlateThirdArabicNumber,
                    carPlateFourthArabicNumber,
                    carPlateFirstEnglishLetter,
                    carPlateSecondEnglishLetter,
                    carPlateThirdEnglishLetter,
                    carPlateFirstEnglishNumber,
                    carPlateSecondEnglishNumber,
                    carPlateThirdEnglishNumber,
                    carPlateFourthEnglishNumber,
                    phoneNumberCourier,
                    phoneNumberToSell,
                    numBidders,
                    entryFee,
                    autoReceiveDate,
                    isDeleted,
                    isRejected,
                    purseInsideColor, purseInsideColorSpecification,
                    purseOutsideColor,
                    purseOutsideColorSpecification,
                    walletOutsideColor
                } = data;

                // get seller data
                const sellerQuery = query(collection(db, "Users"), where("id", "==", sellerId));
                const unsubscribeSellerData = onSnapshot(sellerQuery, (sellerQuerySnapshot) => {
                    sellerQuerySnapshot.forEach((sellerDoc) => {
                        const sellerData = sellerDoc.data();
                        // Extract the data you need from the seller document
                        const sellerFullName = sellerData.fullName; // Example assuming 'fullName' is the field containing the seller's full name
                        const sellerProfilePicture = sellerData.profileImgUrl; // Example assuming 'profilePictureUrl' is the field containing the seller's profile picture URL
                        setSellerId(sellerData.id)
                        setSellerName(sellerFullName);
                        setSellerProfilePicture(sellerProfilePicture)
                        setSellerIsVerified(sellerData.isVerified)
                        setSellerRating(sellerData.rating)
                        setSellerNumRaters(sellerData.numRaters)
                        setSellerCity(sellerData.city)
                    });
                });


                // Setting state for each attribute
                setProductName(productName);
                setProductSubCategory(subCategory);
                setPostType(postType);
                setPurseMaterial(purseMaterial)
                setProductCategory(category);
                setProductStorageSize(productStorageSize);
                setProductScreenSize(productScreenSize);
                setShippingMethods(shippingMethods);
                setWalletOutsideColor(walletOutsideColor);
                setProductMemorySize(productMemorySize);
                setProductInkKind(productInkKind);
                setPurseInsideColor(purseInsideColor)
                setPurseInsideColorSpecification(purseInsideColorSpecification)
                setPurseOutsideColor(purseOutsideColor)
                setPurseOutsideColorSpecification(purseOutsideColorSpecification)
                setCurrentBid(currentBid);
                setRosaryKind(rosaryKind)
                setRosaryColor(rosaryColor)
                setRosarySize(rosarySize)
                setNumBids(numBids);
                setProductPrice(productPrice);
                setSellerId(sellerId);
                setSellerEmail(sellerEmail);
                setBidIncrements(parseInt(bidIncrements));
                setBidders(bidders);
                setLastBidDate(lastBidDate);
                setisSold(isSold);
                setCarPlateTransferFeeOn(carPlateTransferFeeOn)
                setPostComments(comments);
                setIsUpdated(isUpdated);
                setLastUpdatedDate(updatedDate);
                setHeighestBidder(heighestBidder);
                setProductStartingPrice(startingPrice);
                setMyBid(auth.currentUser !== null ? bidders[auth.currentUser.uid] : 0);
                setKeywords(keywords);
                setIsReceived(isReceived);
                setNewBid(parseInt(currentBid) === 0 ? parseInt(startingPrice) : parseInt(bidIncrements + currentBid));
                setBuyerId(buyerId);
                setProductCondition(condition);
                setWatchDepartment(watchDepartment);
                setProductCompany(productCompany);
                setWatchInsideColor(watchInsideColor);
                setWatchOutsideColor(watchOutsideColor);
                setProductDescription(productDescription);
                setWatchDialSize(watchDialSize);
                setWatchDialShape(watchDialShape);
                setWatchNumbersLanguage(watchNumbersLanguage);
                setWatchBandMaterial(watchBandMaterial);
                setWatchHasOriginalPackaging(watchHasOriginalPackaging);
                setWatchYearMade(watchYearMade);
                setWatchIsWaterResistant(watchIsWaterResistant);
                setRosaryCount(rosaryCount);
                setWalletDepartment(walletDepartment);
                setCarPlateTransferTime(carPlateTransferTime);
                setCarPlateFirstArabicLetter(carPlateFirstArabicLetter);
                setCarPlateSecondArabicLetter(carPlateSecondArabicLetter);
                setCarPlateThirdArabicLetter(carPlateThirdArabicLetter);
                setCarPlateFirstArabicNumber(carPlateFirstArabicNumber);
                setCarPlateSecondArabicNumber(carPlateSecondArabicNumber);
                setCarPlateThirdArabicNumber(carPlateThirdArabicNumber);
                setCarPlateFourthArabicNumber(carPlateFourthArabicNumber);
                setCarPlateFirstEnglishLetter(carPlateFirstEnglishLetter);
                setCarPlateSecondEnglishLetter(carPlateSecondEnglishLetter);
                setCarPlateThirdEnglishLetter(carPlateThirdEnglishLetter);
                setCarPlateFirstEnglishNumber(carPlateFirstEnglishNumber);
                setCarPlateSecondEnglishNumber(carPlateSecondEnglishNumber);
                setCarPlateThirdEnglishNumber(carPlateThirdEnglishNumber);
                setCarPlateFourthEnglishNumber(carPlateFourthEnglishNumber);
                setPhoneNumberCourier(phoneNumberCourier);
                setPhoneNumberToSell(phoneNumberToSell);
                setNumBidders(numBidders);
                setCarPlateKind(carPlateKind);
                setEntryFee(entryFee);
                setAutoReceiveDate(autoReceiveDate);
                setisDeleted(isDeleted);
                setIsRejected(isRejected);

                if (postType.includes("مزاد")) {
                  if (data.expiryDate) {
                    const expiryDate = data.expiryDate.toDate(); // Convert Firestore Timestamp to JavaScript Date object
                    const currentTime = new Date();
                
                    // Check if the post has expired
                    const expired = expiryDate < currentTime;
                
                    // If expired, update the post data to reflect the expired status
                    if (expired) {
                      setExpired(true);
                    } else {
                      setExpired(false);
                    }
                  } else {
                    // If expiryDate is null, handle it accordingly
                    setExpired(false);
                  }
                }
                

                // Setting special attributes based on conditions
                // setSebhahColor(sebhahColor === "آخر" ? sebhahColorSpecification : sebhahColor);
                // setSebhahKind(sebhahKind === "آخر" ? sebhahKindSpecification
                // : sebhahKind);
                // setWatchHandlesColor(watchHandlesColor === "آخر" ? watchHandlesColorSpecification : watchHandlesColor);
                // setWatchNumbersColor(watchNumbersColor === "آخر" ? watchNumbersColorSpecification : watchNumbersColor);
                // setWatchInsideColor(watchInsideColor === "آخر" ? watchInsideColorSpecification : watchInsideColor);
                // setWatchOutsideColor(watchOutsideColor === "آخر" ? watchOutsideColorSpecification : watchOutsideColor);
                // setWatchBandColor(watchBandColor === "آخر" ? watchBandColorSpecification : watchBandColor);

                // Pushing keywords to the array
                kwds.push(...keywords);

                // Pushing data to arrays
                cities.push(data);
                // setIsLoading(false)


                // Pushing images to gallery array
                for (let i = 0; i < Object.keys(productImgs).length; i++) {
                    imgs.push(productImgs[i]);
                    gal.push({
                        original: productImgs[i],
                        thumbnail: productImgs[i],
                        originalClass: "image-gallery-class",
                    });
                }

                setGalleryLoading(false);
            });

            setListing(cities);
            setgal(gal);

            // Cleanup function
            return () => {
                unsubscribe();
                if (unsubscribeUserData) {  
                    unsubscribeUserData();
                }
            };
        };
        
        fetchData();
        fetchComments()
        setIsLoading(false)
    });
}, [id, sellerId, myId]);





  useEffect(() => {
    if(offerGiven){
      setShowSuccessAnim(true)
      setSuccessMessage("!تم تقديم عرضك للبائع")
          // Build the query with where clause on ID
    const q = query(collection(db, 'Users'), where('id', '==', myId));
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        // Document found, update hasGivenOffer
        const docRef = querySnapshot.docs[0].ref; // Access the first matching document
        setTimeout(() => {
          updateDoc(docRef, { hasGivenOffer: false }).then(()=>{
          setOfferGiven(false)
          })
          }, 5000); // 5 seconds timeout
        } else {
          // Handle case where document is not found
        }
      }).catch((error) => {
        // Handle query error
      });
    }
  }, [offerGiven]); // Empty dependency array to run only once on mount


const handleDeleteComment = (commentId) => {
  setComments(comments.filter(comment => comment.commentId !== commentId));
  setSuccessMessage("!تم حذف التعليق بنجاح")
  setShowSuccessAnim(true);
};


const [isCommentOptionsOpen, setIsCommentOptionsOpen] = useState(false);

  const handleCloseComment = () => {
    setIsCommentOptionsOpen(false);
  };




  
  // Call the fetchCommentsWithUserData function with the postId
  // You might call this when the component mounts or when needed
  
  
  // Call the fetchComments function with the postId
  // You might call this when the component mounts or when needed
  
  // const fetchComments = async () => {
  //   try {
  //     const postRef = doc(db, 'Posts', `post${id}`);
  //     const postDoc = await getDoc(postRef);

  //     if (postDoc.exists()) {
  //       const postData = postDoc.data();
  //       const commentsData = postData.comments || [];

  //       // Fetch user data for each comment
  //       const commentsWithUserData = await Promise.all(
  //         commentsData.map(async (comment) => {
  //           const userRef = doc(db, 'Users', comment.userId);
  //           const userDoc = await getDoc(userRef);
  //           const userData = userDoc.exists() ? userDoc.data() : null;

  //           return {
  //             ...comment,
  //             user: userData,
  //           };
  //         })
  //       );

  //       setComments(commentsWithUserData);
  //     } else {
  //       console.error('Post not found');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching comments:', error);
  //   }
  // };
  
  
    // id, isDeleted, currentBid, similarProducts, similarCategory, Object.keys(bidders).length
    
    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }


    const addComment = async (commentText) => {
      try {
        // Ensure the user is authenticated
        if (!auth.currentUser) {
          console.error('User not authenticated');
          return;
        }
    
        // Fetch the user's ID from their user document
        const userDocRef = doc(db, 'Users', auth.currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
    
        // Check if the user document exists before accessing the 'id' field
        if (userDocSnapshot.exists()) {
          const userId = userDocSnapshot.data().id;
    
    
          // Add the comment document to the Comments collection
          const commentDocRef = await addDoc(collection(db, 'Comments'), {
            user: {
              userId: userId,
              profileImgUrl: userDocSnapshot.data().profileImgUrl,
              fullName: userDocSnapshot.data().fullName,
              isVerified: userDocSnapshot.data().isVerified
            },
            comment: commentText,
            timestamp: serverTimestamp(),
            isDeleted: false,
            isUpdated: false,
            commentType: "Post",
            targetId: parseFloat(id)
          });
    
          // Update the local state with the new comment
          setComments(prevComments => [
            ...prevComments,
            {
              commentId: commentDocRef.id,
              userId: userId,
              comment: commentText,
              timestamp: new Date(), // Use local timestamp for the new comment
              isDeleted: false,
              isUpdated: false,
            targetId: parseFloat(id),
              user: {
                fullName: userDocSnapshot.data().fullName,
                profileImgUrl: userDocSnapshot.data().profileImgUrl,
                isVerified: userDocSnapshot.data().isVerified
              },
              commentType: "Post",
              commentId: commentDocRef.id, // Use document ID as commentId

            }
          ]);
    
          // Clear the comment input field after adding the comment
          if (commentRef.current) {
            commentRef.current.value = '';
          }
        } else {
          console.error('User document not found');
        }
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    };
    
    
    
    const sendCommentEmail = async () => {
      const url = `https://men-ela.com/product/${id}`; // Replace with your actual URL
      const templateParams = {
        to_email: sellerEmail,
        // to_name : "يوسف",
        website_url: url, // Add the URL to the template parameters
        // other template parameters if needed
      };
  
      try {
        const response = await emailjs.send("service_60fpize",
        "template_6gtnb4i",
        templateParams,
        "mPwQnQEP1--hqeKcI",
        "zDU_ue_gZ7PQcfiwa"
        );
  
        console.log('Email sent successfully!', response);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };


    const sendPurchasedEmail = async () => {
      const url = `https://men-ela.com/my_products/${id}`; // Replace with your actual URL
      const templateParams = {
        to_email: sellerEmail,
        // to_name : "يوسف",
        product_name: productName,
        product_url: url, // Add the URL to the template parameters
        // other template parameters if needed
      };
  
      try {
        const response = await emailjs.send("service_60fpize",
        "template_7f2zp0p",
        templateParams,
        "mPwQnQEP1--hqeKcI",
        "zDU_ue_gZ7PQcfiwa"
        );
  
        console.log('Email sent successfully!', response);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };


    if(isLoading){
      return <LoadingScreen/>
    }
    
    if (postType.includes("مزاد") && hasExpired) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });

      if (myId !== null && heighestBidder === myId) {
        setTimeout(() => {
          navigate(`/my_expired_bids/${id}`);
        }, 2000); // Delay of 2000 milliseconds (2 seconds)
      }
      
  return  <div id="landing-screen-div" style={{ justifyContent: "center", alignItems: "center", direction: "rtl", height: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", display:"flex" }}>
      
      <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <GiStopwatch color="#F00057" style={{width: window.innerWidth > window.innerHeight ? "250px" : "150px", height: window.innerWidth > window.innerHeight ? "250px" : "150px"}} />
              <h4 style={{marginTop:"16px", marginBottom:"4px"}}>انتهى المزاد</h4>

              {
                heighestBidder === myId && (
                  <p>جاري اعادة توجيهك للصفحة المطلوبة...</p>
                )
              }
            </div>
    </div>
    }
    



    if(isDeleted){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
  return  <div id="landing-screen-div" style={{ justifyContent: "center", alignItems: "center", direction: "rtl", height: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", display:"flex" }}>
      
      <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <MdDeleteForever color="#F00057" style={{width: window.innerWidth > window.innerHeight ? "250px" : "150px", height: window.innerWidth > window.innerHeight ? "250px" : "150px"}} />
              <h4 style={{marginTop:"16px", marginBottom:"4px"}}>تم حذف المنتج</h4>
            </div>
    </div>
    }


    if( auth.currentUser != null && buyerId != myId && isSold){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
  return  <div id="landing-screen-div" style={{ justifyContent: "center", alignItems: "center", direction: "rtl", height: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", display:"flex" }}>
      
      <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <GiBangingGavel color="#00C8B8" style={{width: window.innerWidth > window.innerHeight ? "250px" : "150px", height: window.innerWidth > window.innerHeight ? "250px" : "150px"}} />
              <h4 style={{marginTop:"16px", marginBottom:"4px"}}>تم بيع المنتج!</h4>
            </div>
    </div>
    }

    const handleHasPurchasedAnimationComplete = () => {
      setShowSuccessAnim(false); 
      setTimeout(() => {
        navigate(`/my_purchases/${id}`, { state: { mode: mode } });
      }, 1500); 
    };
    if( auth.currentUser != null && buyerId === myId && isSold){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
  return <div style={{backgroundColor:"white", width:"100vw", height:"100vh"}}>


<SuccessAnim message={
  <>
    <p style={{margin: "0px 32px"}}>!تم شراء المنتج <br/><span style={{fontWeight:"400"}}>...جاري اعادة توجيهك للصفحة المطلوبة</span></p>
  </>
} onAnimationComplete={handleHasPurchasedAnimationComplete}/>

  </div>
    }

    if(!productExists && !isLoading){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    return  <div id="landing-screen-div" style={{ justifyContent: "center", alignItems: "center", direction: "rtl", height: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", display:"flex" }}>
      
      <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <FaQuestion color="#00C8B8" style={{width: window.innerWidth > window.innerHeight ? "250px" : "150px", height: window.innerWidth > window.innerHeight ? "250px" : "150px"}} />
              <h4 style={{marginTop:"16px", marginBottom:"4px"}}>لا يوجد منتج</h4>
            </div>
    </div>
    }

    function formatAccountingArabic(number) {
      // Check if the input is a valid number
      if (typeof number !== 'number' || isNaN(number)) {
        return 'Invalid input';
      }
    
      // Use toLocaleString to format the number with commas and two decimal places
      const formattedNumber = number.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
      // Add the Arabic currency symbol manually
      const arabicFormattedNumber = `${formattedNumber} ر.س`;
    
      return arabicFormattedNumber;
  }
  const handleAnimationComplete = () => {
    setShowSuccessAnim(false); // Reset button state after animation completes
  };


  const renderCustomControls = () => (
    <div className="custom-index-display">
      {`${currentIndex + 1} / ${gal.length}`}
    </div>
  );
  return ( 
    <div style={{overflow:"hidden"}} onClick={()=>{handleCloseComment(); 
      if(userDropdownIsOpen){
        toggleUserDropdown();
      }

    }}>
    {
      // showSuccessAnim && offerGiven && <SuccessAnim onAnimationComplete={handleAnimationComplete} message="!تم تقديم العرض بنجاح" />
      showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />
    }

    {giveOfferClicked && (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex:20
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '16px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        width: window.innerWidth > window.innerHeight ? "35%" : "90%",
        textAlign:"right",
        zIndex:21,
        }}
      >
        <h5>قم بتقديم عرض شراء للبائع</h5>
        <p style={{direction:"rtl"}}>سيتم خصم المبلغ من حسابك و ابلاغ البائع بعرضك, و سيمكنه قبول او رفض العرض. في حال رفض البائع عرضك, سيتم ارجاع المبلغ.</p>
        <p>مبلغ العرض يجب أن يكون أعلى من 0 و أقل من سعر الشراء ({formatAccountingArabic(productPrice)})</p>
        <div style={{display:"flex", direction:"rtl"}}>

                <input onKeyDown={(event) => {
          if (event.key === 'Enter') {
                // Check if the input value is empty
    if (myOffer === "") {
      return;
    }
            navigate(`/checkout/${id}`, { state: { mode: 'Offer', offer: myOffer } })
          }
        }} autoFocus ref={myOfferRef}  onChange={(e) => {
    const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

    // Check if the input value is empty
    if (inputValue === "") {
      setMyOffer(""); // Clear the state
      return;
    }

    // Check if the input value contains non-numeric characters
    if (!/^\d*$/.test(inputValue)) {
      alert("يرجى ادخال أرقام فقط.");
      myOfferRef.current.value = ""; // Clear the input field
      return;
    }

    if(parseFloat(inputValue) <= 0){
      alert("يحب أن يكون العرض أعلى من 0 ر.س")
      myOfferRef.current.value = ""; // Clear the input field
      return;
    }

    if(parseFloat(inputValue) >= productPrice){
      alert(`يجب أن يكون العرض أقل من سعر الشراء (${formatAccountingArabic(productPrice)})`)
      myOfferRef.current.value = ""; // Clear the input field
      return;
    }

    // Update the state with the valid number
    setMyOffer(inputValue);
  }} type='tel' lang='en' placeholder='العرض' style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "40px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
              <p style={{marginBottom: "auto", marginTop: "auto", marginRight:"4px"}}>ر.س</p>
                  
                  </div>
                  <div style={{display:"flex", marginTop:"16px", direction:"rtl",justifyContent:"space-around"}}>
                    <button onClick={()=>{
                          if (myOffer === "") {
                            return;
                          }
            navigate(`/checkout/${id}`, { state: { mode: 'Offer', offer: myOffer } })

                    }} id='my-button'>تقديم العرض</button>
                    <button id='my-button' style={{backgroundColor:"#F00057"}} onClick={() => setGiveOfferClicked(false)}>الغاء</button>
                  </div>
      </div>
    </div>
  )}

{reportUserShouldRender && (
   <div
   style={{
     backgroundColor: "rgba(20, 0, 0, 0.2)",
     width: "100vw",
     height: "calc(100vh - 65px)",
     position: "fixed",
     zIndex: 20,
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     opacity: isReportUserOpen ? 1 : 0,
     transition: "opacity 0.1s ease-out",

    }}
    
    >
          <div style={{
            width: window.innerWidth > window.innerHeight ? "35%" : "90%",
            height: "fit-content",
            backgroundColor: "white",
            position: "relative",
            margin: "auto",
            opacity: 1,
            zIndex: 30,
            borderRadius: "16px",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "translateY(-15%)",
            
          }}
          className={isReportUserOpen ? "pop-up" : "pop-down"}
          >
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "8px"
            }}>
              <IoIosCloseCircle size={27} style={{ cursor: "pointer" }} color='#ccc' onClick={() => {
                setIsReportUserOpen(false);
              }} />
              <div style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center"
              }}>
                <h5 style={{ marginTop: "16px" }}>الإبلاغ عن {sellerName}</h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>
            <div style={{display:"flex", direction:"rtl"}}>


            <p style={{color:"red"}}>*</p>
            <select
              id="input-field"

              onChange={(event) => {
                const selectedValue = event.target.value;
                setReportReason(selectedValue);
              }}
              style={{ color: "black", borderRadius: "4px", marginBottom:"8px", cursor:"pointer" }}
              >
              <option value="">نوع البلاغ</option>  {/* Default option with descriptive text */}
              <option value="الاحتيال والغش" style={{ backgroundColor: "white", textAlign: "right" }}>
              الاحتيال والغش: عرض منتجات غير أصلية أو مزيفة على أنها أصلية أو تقديم معلومات مضللة عن المنتج.
              </option>
              <option value="المنتجات التالفة أو غير المطابقة للوصف" style={{ backgroundColor: "white", textAlign: "right" }}>
              المنتجات التالفة أو غير المطابقة للوصف: تسليم منتجات تالفة أو مغايرة للوصف المعلن أو الصور المعروضة على المنصة.
              </option>
              <option value="سوء خدمة العملاء" style={{ backgroundColor: "white", textAlign: "right" }}>
              سوء خدمة العملاء: تعامل غير لائق مع العملاء، عدم الرد على الاستفسارات أو الشكاوى، أو رفض رد الأموال أو استبدال المنتجات.
              </option>
              <option value="التلاعب في الأسعار" style={{ backgroundColor: "white", textAlign: "right" }}>
              التلاعب في الأسعار: رفع الأسعار بشكل غير مبرر بعد الاتفاق على سعر معين، أو تطبيق رسوم إضافية غير مبررة.
              </option>
              <option value="مخالفة سياسات المنصة" style={{ backgroundColor: "white", textAlign: "right" }}>
              مخالفة سياسات المنصة: بيع منتجات محظورة أو مخالفة لشروط وأحكام المنصة، مثل المنتجات غير القانونية أو التي تنتهك حقوق الملكية الفكرية.
              </option>
              <option value="آخر" style={{ backgroundColor: "white", textAlign: "right" }}>
              آخر (حدد)
              </option>
            </select>
              </div>

              {
                reportReason === "آخر" && (
                  <textarea 
placeholder='وصف البلاغ'
            style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginTop:"16px", direction:"rtl"}}
            onChange={(e) => { setReportNotes(e.target.value) }}>
            </textarea>
                )
              }
            <div style={{marginTop:"14px", display:"flex", justifyContent:"space-between", direction:"rtl"}}>
    <button 
    id='my-button' 
    disabled={reportLoading} 
    style={{height:"30px", padding:"4px 16px", width:"100%", marginLeft:"8px"}} onClick={()=>{reportUser()}} >
    {   
     reportLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{marginBottom:"0px", marginRight:"0px"}}>إبلاغ</p>
              }
    </button>
    <button id='my-button-red' style={{padding:"4px 8px",  width:"100%"}} onClick={()=>{
                setIsReportUserOpen(false);

    }} >إلغاء</button>
    </div>
          </div>
        </div>
      )} 

{sendMessageShouldRender && (
  <div
    style={{
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      zIndex: 1003,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      opacity: isSendMessageOpen ? 1 : 0,
      transition: "opacity 0.1s ease-out",
    }}
  >
    <div
      style={{
        width: window.innerWidth < window.innerHeight ? (window.innerWidth < 600 ? "90vw" : "60vw") : window.innerWidth < 1000 ? "90vw" : window.innerWidth > 1100 ? "30vw" : "50vw",
        height: window.innerWidth < window.innerHeight ?  "75vh" : window.innerWidth > 600 ? "80vh" : "75vh",
        backgroundColor: "white",
        borderRadius: "24px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        cursor: "auto",
        transition: "transform 0.2s ease-out, opacity 0.2s ease-out",
        transform: isSendMessageOpen ? 'translateY(0)' : 'translateY(100%)',
        opacity: isSendMessageOpen ? 1 : 0,
        zIndex: 1002,
        display: "flex",
        flexDirection: "column",
        marginBottom: window.innerWidth > window.innerHeight && window.innerWidth < 1000 ? "15vh" : window.innerWidth < 600 && isSafari ? "20vh" : "0px"
      }}
      className={isSendMessageOpen ? "pop-up" : "pop-down"}
    >
      <div
        style={{
          display: "flex",
          padding: "16px",
          boxShadow: "0 1px 5px #ccc",
        }}
      >
        <IoIosCloseCircle
          size={27}
          style={{ cursor: "pointer", position:"absolute", left: 8, top: 8 }}
          color='#ccc'
          onClick={() => {
            setIsSendMessageOpen(false);
            setMessageToSeller("");
          }}
        />

<div style={{direction:"rtl", display:"flex", justifyContent:"flex-start", width:"100%"}}>
<div style={{ position:"relative"}}>
                <img src={sellerProfilePicture} className='skeleton' alt='' width={50} height={50} style={{borderRadius: "1000px", objectFit: "cover", opacity:1, width:"50px", height:"50px"}}/>
                </div>
                <div style={{height:"fit-content", marginBottom:"auto",justifyContent:"flex-end", display:"flex", flexDirection:"column", width: "fit-content"}}>
                  <div style={{display:"flex"}}>
                  <p style={{marginRight: "8px", marginBottom: "0px"}}>{sellerName}</p>
                    <div style={{display: "flex",alignItems: "center"}}>
                      {sellerIsVerified && (
                        <RiVerifiedBadgeFill size={22} color='#00c8b8' style={{marginRight:"4px"}}/>
                        )}
                    <AiFillStar size={14} color='gold' style={{ marginRight:"4px"}}/>
                         {sellerRating && <p style={{marginBottom:"0px", marginRight:"2px", fontSize: "14px"}}>{parseFloat(sellerRating.toFixed(1))}</p>}
                         <p style={{marginBottom:"0px"   , marginRight:"2px", fontSize: "14px"}}>({sellerNumRaters})</p>
                    </div>
                  </div>

                    {
                      sellerCity != "" &&
                                        <div style={{direction: "rtl", display: "flex"}}>
                                        <FaLocationDot size={14} color='red' style={{marginRight: "4px", marginTop: "4px"}} />
                                        <p style={{marginRight: "2px", marginBottom:"0px"}}>{sellerCity}</p>
                                        </div>
                    }

                    
                </div>
  </div>

      </div>

      
      <div style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
        {!fetchingMessages ? (
          <>
            {messages.length === 0 && !hasStartedChat? (
              <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Lottie
                  style={{ width: window.innerWidth > window.innerHeight && window.innerWidth < 1000 ? "50%" : "50%", height: "auto" }}
                  animationData={noChatAnim}
                  loop={true}
                />
                <p style={{ marginTop: "16px", color: "#00A49B" }}>قم بإرسال سؤالك او استفسارك للبائع مباشرة</p>
              </div>
            ) : (
              <div  style={{ flex: 1, overflowY: "auto", scrollbarWidth: 'none' }}>
                {messages.map((msg) => (
                  <div key={msg.messageId} style={{ display: "flex", flexDirection: "column", padding: "8px" }}>
                    <div
                      style={{
                        alignSelf: msg.senderId === parseFloat(myId) ? "flex-end" : "flex-start",
                        backgroundColor: msg.senderId === parseFloat(myId) ? "#F00057" : "#00C8B8",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "11px",
                        minWidth:window.innerWidth > window.innerHeight ? "25%" : "30%",
                        maxWidth: "70%",
                        marginBottom:"0px"
                      }}
                    >
                      <p style={{ margin: 0 }}>{msg.message}</p>
                      <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                      <p style={{ margin: "8px 0 0", fontSize: "10px", color: "#eee" }}>{msg.date.toString()}</p>

                    {
                      msg.senderId === parseFloat(myId) && (
                        !msg.isDelivered ? (
                          <div style={{alignSelf:"flex-end"}}>
                          <IoCheckmarkOutline />
                          </div>
                        ) : (
                          <>
                                                  <div style={{alignSelf:"flex-end"}}>
                          <IoCheckmarkDoneOutline color={`${msg.isRead ? "dodgerblue" : "white"}`} />


                          </div>
                          </>
                        )
                      )
                    }
                    </div>
                    </div>
                  </div>
                ))}
                      <div ref={messagesEndRef} />

              </div>
            )}

            <div style={{ display: "flex", padding: "8px", alignItems: "center" }}>
              <textarea
              placeholder='اكتب رسالتك...'
                ref={messageToSellerRef}
                autoFocus
                style={{
                  flex: 1,
                  backgroundColor: "#F0F0F0",
                  border: "none",
                  height: "40px",
                  borderRadius: "20px",
                  outline: "none",
                  padding: "8px 16px",
                  direction: "rtl",
                  resize: "none",
                  fontSize:"14px"
                }}
                onChange={(e) => { setMessageToSeller(e.target.value); }}
              />
              {!sendingMessage ? (
                messageToSeller === "" ? (
                  <GoPaperclip
                    size={32}
                    style={{ margin: "0 8px", cursor: "pointer", color: "#00C8B8", borderRadius:"1000px", padding:"4px" }}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = "#F7F7F7"; }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = "transparent"; }}
                  />
                ) : (
                  <IoSend
                    size={32}
                    style={{ margin: "0 8px", cursor: "pointer", color: "#F00057", borderRadius:"1000px", padding:"4px"  }}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = "#F7F7F7"; }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = "transparent"; }}
                    // onClick={sendMessageToSeller}
                  />
                )
              ) : (
                <Oval
                  height={"20px"}
                  width={"20px"}
                  color={"#F00057"}
                  visible={true}
                  wrapperStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 8px",
                  }}
                  ariaLabel='oval-loading'
                  secondaryColor="#ccc"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}
            </div>
          </>
        ) : (
          <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", backgroundColor: "transparent", position: "absolute", top: 0 }}>
            <Oval
              height={"20px"}
              width={"20px"}
              color={"#00C8B8"}
              visible={true}
              wrapperStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
              }}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>
    </div>
  </div>
)}




    <div style={{
      minHeight: window.innerWidth > window.innerHeight ? "calc(110vh - 65px)" : "calc(100vh - 110px)"
      }}
      >
      {
        Listing.map  ((item) => (
        <div
          id='product-details-and-img'
          style={{ width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:"auto", marginRight:"auto"}}
            key={item.id}
            >
<div style={{ padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
          {/* path */}

  <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width:"100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginBottom:"16px"
  }}>
    <div style={{ display: "flex", direction: "rtl" }}>
    <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />

      <a href='/'>
        <h6 style={{
          marginBottom: "0px",
          color: "#01635c",
          cursor: "pointer",
      fontSize:"14px"

        }}
          onMouseEnter={(e) => { e.target.style.color = "#00A49B" }}
          onMouseLeave={(e) => { e.target.style.color = "#01635c" }}
        >
          الرئيسية
        </h6>
      </a>
      <h6 style={{ margin: "0px 6px",
      fontSize:"14px"

       }}>/</h6>





      <h6 style={{
        marginBottom: "0px",
        color: "#01635c",
        cursor: "pointer",
      fontSize:"14px"
      }}
        onMouseEnter={(e) => { e.target.style.color = "#00A49B" }}
        onMouseLeave={(e) => { e.target.style.color = "#01635c" }}
        onClick={() => { navigate(-1) }}
      >
        {productCategory}
      </h6>
      <h6 style={{ margin: "0px 6px",
      fontSize:"14px"

       }}>/</h6>


       
      <h6 style={{
        marginBottom: "0px",
        maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
        overflow: "hidden",  /* Hide any overflow content */
        whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
        textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
      fontSize:"14px"

      }}>
        {productName}
      </h6>
    </div>
  </div>
</div>

          <div
          id='product-details-div'
          >
            
            <div
            id='post-details-img'
            style={{padding:"0px"}}
            >
              {/* {
                imageLoading &&
<div style={{
        width: "100vw",
        height: "20vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
              } */}

<div style={{position:"relative"}}>


  <ImageGallery 
  showBullets={gal.length > 1}
  showNav={false}
  showPlayButton={false}
  showFullscreenButton={false}
  lazyLoad={false}
  showThumbnails={gal.length > 1}
  // showIndex={gal.length > 1}
  isRTL={true}
  infinite={false}
  startIndex={currentIndex} 
        onSlide={handleSlide}
        
        
  items={gal} />

{
  gal.length > 1 && (
    <div className="custom-index-display">
        {`${currentIndex + 1} / ${gal.length}`}
      </div>
  )
}
      </div>

            </div>
                <div
                style={{
                  paddingRight:"16px",
                  paddingLeft: window.innerWidth > window.innerHeight ? "0px" : "16px",
                  marginRight: window.innerWidth > window.innerHeight ? "auto" : "0px",
                  width: window.innerWidth > window.innerHeight ? "40%" : "100%",
                  // marginTop: window.innerWidth > window.innerHeight ? "64px" : "0px",
                }}
                >
                <p style={{marginBottom:"0px", marginTop:"8px", color:"#555"}}>رقم المنتج #<span style={{marginRight:"4px"}}>{id}</span></p>
                      <h2
                      id='product-details-desc'

                      style={{color:"black", fontSize: "18px"}}
                      >{item.productName}</h2> 
{/* <h4
                    id='product-details-desc'
                    style={{fontSize: "16px"}}
                    >{item.productDescription}</h4>  */}

                {
                  isUpdated &&
                  <div style={{display: "flex", width: "fit-content", alignItems: "center"}}>
                  <GoPencil style={{color: "gray"}} />
                  <p style={{marginRight: "4px", marginBottom: "0px", whiteSpace: "nowrap", color: "gray"}}>تم تحديث المنتج {formatDistanceToNow(lastUpdatedDate.toDate(), { addSuffix: true, locale: arLocale })}</p>
                  </div>
                }

                <div 
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    position:"relative"
                  }}
                  >

{
  myId !== sellerId && (
    <div 
    onClick={(e) => {
      e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
      toggleUserDropdown();

    }}
    style={{position:"absolute", left:10, top:0, padding:"4px", cursor:"pointer",

    }}>
<BsThreeDotsVertical />

    </div>
  )
}

{shouldRender && (
        <div
          className={userDropdownIsOpen ? 'pop-up' : 'pop-down'}
          style={{
            width: 'fit-content',
            // width: '500px',
            // padding: '8px 16px',
            position: 'absolute',
            backgroundColor: 'white',
            left: 35,
            top: -10,
            direction: 'rtl',
            borderRadius: '8px',
            boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
            zIndex:10, 
            // border: "1px solid #E8E9E9"
          }}
        >

<div
  style={{
    transition: 'background-color 0.3s',
    padding: "8px",
    borderRadius: "8px",
    cursor: 'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft:"42px",
    borderBottomLeftRadius:"0px",
    borderBottomRightRadius:"0px"
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
  onClick={(e) => {
    if (myId) {
      e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
      toggleUserDropdown();
      toggleSendMessage();
    } else {
      alert("يجب عليك تسجيل الدخول لخدمتك بشكل أفضل");
      toggleDropdown();
    }
  }}
>
  <p
    style={{
      color: '#00C8B8',
      margin: 0,
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      marginLeft:"auto",
      fontSize:"15px"
    }}
  >
    <IoChatboxEllipsesOutline size={17} style={{ marginLeft: '4px' }} />
    ارسال رسالة للبائع
  </p>
</div>

<Divider sx={{ width: '100%' }} />
          <div
  style={{
    transition: 'background-color 0.3s',
    padding: "8px",
    borderRadius: "8px",
    cursor: 'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft:"42px",
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
  onClick={(e) => {
    if(myId){
      e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
      toggleUserDropdown();
      toggleReport();
    }
    else{
      alert("يجب عليك تسجيل الدخول لخدمتك بشكل أفضل");
      toggleUserDropdown();
    }
  }}  

>
  <p
    style={{       color: '#F00057',
    margin: 0,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    marginLeft:"auto",
    fontSize:"15px"}}
  >
    <HiOutlineExclamationCircle size={18} style={{ marginLeft: '4px' }} />
    
    الإبلاغ عن البائع



  </p>
</div>

        </div>
      )}


                 <Link state={{productName, productCategory}} to={{
    pathname: `/user/${sellerId}`,
    state: { productName: 'Awesome Product' }
  }} style={{display: "contents", color: "black", textDecoration: "none"}}>
            <div style={{display: "flex", width: "100%", padding:"16px" , marginBottom:"16px", backgroundColor:"#f7f7f7", borderRadius:"8px", alignItems:"center"}}>
                <div style={{ position:"relative"}}>
                <img src={sellerProfilePicture} className='skeleton' alt='' width={65} height={65} style={{borderRadius: "1000px", objectFit: "cover", opacity:1, width:"65px", height:"65px"}}/>
                </div>
                <div style={{height:"fit-content", marginBottom:"auto",justifyContent:"flex-end", display:"flex", flexDirection:"column", width: "fit-content"}}>
                  <div style={{display:"flex"}}>
                  <p style={{marginRight: "8px", marginBottom: "0px", fontSize:"15px", fontWeight:500}}>{sellerName}</p>
                    <div style={{display: "flex",alignItems: "center"}}>
                      {sellerIsVerified && (
                        <RiVerifiedBadgeFill size={18} color='#00c8b8' style={{marginRight:"4px"}}/>
                        )}
                    <AiFillStar size={17} color='gold' style={{ marginRight:"4px"}}/>
                         {sellerRating && <p style={{marginBottom:"0px", marginRight:"2px", fontSize: "13px"}}>{parseFloat(sellerRating.toFixed(1))}</p>}
                         <p style={{marginBottom:"0px"   , marginRight:"2px", fontSize: "13px"}}>({sellerNumRaters})</p>
                    </div>
                  </div>

                    {
                      sellerCity != "" &&
                                        <div style={{direction: "rtl", display: "flex",marginTop:"4px"}}>
                                        <FaLocationDot size={14} color='red' style={{marginRight: "4px", marginTop: "4px"}} />
                                        <p style={{marginRight: "2px", marginBottom:"0px", fontSize:"14px"}}>{sellerCity}</p>
                                        </div>
                    }

                    
                </div>
            </div>
            </Link>
                </div>

                  <div
                  >
                  {/* {
                    productSubCategory == 'جوال' ? 
                    <div style={{marginTop: "16px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px" , whiteSpace:"nowrap"}}>سعة التخزين: </h4>
                    <h3>{productStorageSize}</h3>
                    </div>
                    : ""
                  } */}
                  {/* {
                    productSubCategory == 'لابتوب' ? 
                    <>
                    <div style={{marginTop: "16px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px", whiteSpace:"nowrap"}}>سعة الذاكرة: </h4>
                    <h3 style={{whiteSpace:"nowrap"}}>{productMemorySize}</h3>
                    </div>
                    <div style={{marginTop: "4px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px", whiteSpace:"nowrap"}}>حجم الشاشة : </h4>
                    <h3 style={{whiteSpace:"nowrap"}}>{productScreenSize}</h3>
                    </div>
                    </>
                    : ""
                  } */}
                  {/* {
                    productSubCategory == 'شاشة' ? 
                    <div style={{marginTop: "4px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px"}}>حجم الشاشة : </h4>
                    <h3>{productScreenSize}</h3>
                    </div>
                    : ""
                  } */}
                  </div>

                  <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
{
  productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
<>
<div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <LuCircleDashed color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>حالة المنتج</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{item.condition}</p>
  </div>
</>
  )
}
</div>


                { postType.includes("مزاد") &&
                  <>


<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <BsStopwatchFill color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>الوقت المتبقي</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{
                    item.expiryDate !== null &&
                    <Countdown
                    className='countdown-class'
                    date={item.expiryDate.seconds * 1000}
                    onComplete={async ()=>{
                        setExpired(true)

                      // here after the product expires, check if the user is not the heighest bidder, return their money
                      // if they are the heighest bidder, transfer the money
                      // this is better being done in the backend !



                      // old code that might be helpful: 
                        // let losingBidders = [];
                        // for(var i = 0 ; i < Object.keys(bidders).filter((userId) => userId !== heighestBidder).length ; i ++){
                        //   losingBidders.push(Object.keys(bidders).filter((userId) => userId !== heighestBidder)[i])
                        // }

                        // for(var i = 0 ; i < losingBidders.length; i ++)
                        // {
                        //   const postRef = doc(db, "Users", losingBidders[i]);
                        //   const docSnap = await getDoc(db, "Users", losingBidders[i])
                        //   var fb = parseFloat(docSnap.data()['feeBalance'])
                        //   fb += Math.round(parseFloat(productStartingPrice * 0.05))
                        //   await updateDoc(postRef, {
                        //     feeBalance: parseFloat(fb)
                        //   });
                        // }

                    }}
                    >
                    </Countdown>
                  }
                  </p>
  </div>
</div>
{/* 
                <div
                id='product-details-icon-with-data'
                >
                <BsStopwatchFill
                  id='product-details-icon'
                  style={{marginBottom: "0px", color:'#F00057'}}
                />
                <p style={{marginBottom: "0px"}}>:</p>
                  {
                    item.expiryDate !== null &&
                    <Countdown
                    className='countdown-class'
                    date={item.expiryDate.seconds * 1000}
                    onComplete={async ()=>{
                        setExpired(true)

                      // here after the product expires, check if the user is not the heighest bidder, return their money
                      // if they are the heighest bidder, transfer the money
                      // this is better being done in the backend !



                      // old code that might be helpful: 
                        // let losingBidders = [];
                        // for(var i = 0 ; i < Object.keys(bidders).filter((userId) => userId !== heighestBidder).length ; i ++){
                        //   losingBidders.push(Object.keys(bidders).filter((userId) => userId !== heighestBidder)[i])
                        // }

                        // for(var i = 0 ; i < losingBidders.length; i ++)
                        // {
                        //   const postRef = doc(db, "Users", losingBidders[i]);
                        //   const docSnap = await getDoc(db, "Users", losingBidders[i])
                        //   var fb = parseFloat(docSnap.data()['feeBalance'])
                        //   fb += Math.round(parseFloat(productStartingPrice * 0.05))
                        //   await updateDoc(postRef, {
                        //     feeBalance: parseFloat(fb)
                        //   });
                        // }

                    }}
                    >
                    </Countdown>
                  }
                </div> */}
                                  <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <PiGavelFill color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>عدد المزايدين</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px",textAlign: "center" }}>{numBidders}</p>
  </div>
</div>

                {/* <div
                id='product-details-icon-with-data'
                >
                <PiGavelFill
                id='product-details-icon'
                color='#00C8B8'
                />
                <p>:</p>
                <p
                id='product-details-numbidders'
                >{numBidders}</p>
                </div> */}
                </>
              }
                <div
                >
                  <div
                  style={{
                  }}
                  >

                  {postType.includes("مزاد") ?
                  <>

<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <FaMoneyBillAlt color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>عربون دخول المزاد</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{formatAccountingArabic(item.entryFee)}</p>
  </div>
</div>


                  {/* <div
                  style={{
                    display: "flex",
                     alignItems: "center",
                     width:"100%",
                  }}
                  >
                  
                  <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "8px"}}>
      عربون دخول المزاد:
    </p>
    <p style={{ marginRight: "4px", marginBottom: "6px", fontSize: "17px", fontWeight: "500", width:"100%", whiteSpace: "nowrap" }}>
    {formatAccountingArabic(item.entryFee)}
</p>
                  </div> */}

<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <FaMoneyBillWave color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>مبلغ افتتاح المزاد</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{formatAccountingArabic(item.startingPrice)}</p>
  </div>
</div>
{/* 
                  <div
                  style={{
                    display: "flex",
                     alignItems: "center"
                  }}
                  >
                  
                  <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "8px" }}>
      مبلغ افتتاح المزاد:
    </p>
    <p style={{ marginRight: "4px", marginBottom: "6px", fontSize: "17px", fontWeight: "500" }}>
        {formatAccountingArabic(item.startingPrice)}
      </p>
                  </div> */}


<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <FaMoneyBillTrendUp color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>مضاعفات المزايدة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center"}}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{formatAccountingArabic(item.bidIncrements)}</p>
  </div>
</div>

                  

                  {/* <div
                  style={{
                    display: "flex",
                  }}
                  >
                  
                  <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "8px" }} >مضاعفات المزايدة:</p>  
                  <p style={{ marginRight: "4px",marginBottom: "8px", fontSize: "17px", fontWeight: "500", }}>{formatAccountingArabic(item.bidIncrements)}</p>
                  </div> */}
                    </>
                  : ""
                  }
                  {item.productPrice > 0 && postType.includes("مزاد") ?
                  
                  <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <BiSolidPurchaseTag color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>سعر الشراء الفوري</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center"}}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{formatAccountingArabic(item.productPrice)}</p>
  </div>
</div>


                  // <div
                  // style={{
                  //   display: "flex",
                  //   marginBottom: "8px"
                  // }}
                  // >
                  
                  // <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "8px" }} >سعر الشراء الفوري:</p>  
                  // <p style={{ marginRight: "4px", marginBottom: "6px", fontSize: "17px", fontWeight: "500",  whiteSpace:"nowrap", width:"100%" }}>{formatAccountingArabic(item.productPrice)}</p>
                  // {/* <h4 style={{marginRight: "4px"}}>ر.س</h4> */}
                  // </div>
                  : ""
                  }


<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <FaMoneyBillWave color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }}>{ postType.includes("مزاد") ? "السعر الحالي" : "السعر"}</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{postType.includes("مزاد") ? formatAccountingArabic(item.currentBid) : formatAccountingArabic(item.productPrice)}</p>
  </div>
</div>



{
  lastBidDate &&
  <>

<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <IoTime color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>آخر مزايدة كانت</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{formatDistanceToNow(lastBidDate.toDate(), { addSuffix: true, locale: arLocale })}</p>
  </div>
</div>
    {/* {postType.includes("مزاد") && Object.keys(bidders).length > 0 && (
    <h6 style={{ color: "black", margin: "4px 8px 0px 0px" }}>
  (آخر مزايدة كانت {formatDistanceToNow(lastBidDate.toDate(), { addSuffix: true, locale: arLocale })})
</h6>

  )} */}
  </>
}

                  {/* <div style={{
                    display: "flex"
                  }}>
                
                { postType.includes("مزاد") ? <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }} >السعر الحالي:</p> : <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }} >السعر:</p>}
                {postType.includes("مزاد") ? 
                <>

                <div style={{
  display: "flex",
  alignItems: "center",
}}>
  <p id='product-details-currentBid'
  style={{ marginRight: "4px", marginBottom: "16px", fontSize: "17px", fontWeight: "bold", whiteSpace: "nowrap", }}
  >
    
    {formatAccountingArabic(item.currentBid)}
  </p> 
{
  lastBidDate &&
  <>
    {postType.includes("مزاد") && Object.keys(bidders).length > 0 && (
    <h6 style={{ color: "black", margin: "4px 8px 0px 0px" }}>
  (آخر مزايدة كانت {formatDistanceToNow(lastBidDate.toDate(), { addSuffix: true, locale: arLocale })})
</h6>

  )}</>
}
</div>

                </>
                : 
                <div
                style={{
                  display: "flex"
                }}
                >
                <p
                id='product-details-currentBid'
                style={{
                  marginRight: "4px", marginBottom: "0px", fontSize: "17px", fontWeight: "bold"
                }}
                >{formatAccountingArabic(item.productPrice)}</p>
                </div>
                }
                </div> */}
                </div>
                </div>

                {
                  auth.currentUser != null && postType.includes("مزاد") && Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? (
                    <>
                  {
  !hasExpired && !isSold && (
    heighestBidder === myId ? (


<div
  style={{
    display: "flex",
    marginTop: "8px",
    marginBottom:"16px"
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"100%" }}>
    <IoMdCheckmarkCircle color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", marginRight: "0px", whiteSpace: "nowrap" }}>أنت صاحب أعلى مزايدة</p>
  </div>
</div>

      // <div style={{ display: "flex", width: "100%", alignItems: "center", backgroundColor:"cyan" }}>
      //   <IoMdCheckmarkCircle size={22} style={{ color: "green" }} />
        // <p style={{ marginBottom: "0px", marginRight: "6px", whiteSpace: "nowrap" }}>أنت صاحب أعلى مزايدة</p>
      // </div>
    ) : (

<div
  style={{
    display: "flex",
    marginTop: "8px",
    marginBottom:"16px"
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"100%" }}>
    <BsFillExclamationCircleFill color='orange' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", marginRight: "0px", whiteSpace: "nowrap" }}>قام شخص ما بالمزايدة عليك</p>
  </div>
</div>

      // <div style={{ display: "flex", width: "100vw", alignItems: "center" }}>
      //   <MdDangerous size={22} style={{ color: "orange" }} />
        // <p style={{ marginBottom: "0px", marginRight: "6px", whiteSpace: "nowrap" }}>قام شخص ما بالمزايدة عليك</p>
      // </div>
    )
  )
}

                    </>
                  ) : null
                }

                
                <div
                id='product-details-buttons-div'
                >

                  {/* {item.productPrice > 0 && !hasExpired && !isSold &&
                  // here
                  // hide after expire
                  // ! hasExpired && heighestBidder === auth.currentUser.uid &&
                  
                  <Button 
                  id='product-details-button'
                  // disabled={auth.currentUser!= null ? sellerId == auth.currentUser.uid : false}
                  onClick={async ()=>{
                    if(auth.currentUser != null){
                      setExpired(true)
                        await updateDoc(postRef, {
                          heighestBidder: auth.currentUser.uid,
                          currentBid: productPrice,
                          isSold: true,
                          expiryDate: serverTimestamp(),
                          soldDate: serverTimestamp()
                        });

                      // update seller balance
                    // const sellerRef = doc(db, "Users", sellerId);
                    // if(sellerId != ""){
                    //   const sellerData = await getDoc(sellerRef);
                    //   console.log(sellerData.data().balance); 
  
                    //   const newBalance = sellerData.data().balance += productPrice
  
                    //   await updateDoc(sellerRef, {
                    //     balance: newBalance
                    //   }).then(()=>{
                    //     navigate("/")
                    //   })
                    // }
                    }
                    else{
                      alert('You need to sign up to buy a product.')
                    }
                  }}
                  variant="contained">الشراء الان<BiSolidPurchaseTagAlt size={20} style={{marginRight:"4px"}}/></Button>
                  } */}
                                  {
                  postType.includes("مزاد") && ! Object.keys(bidders).map(key => parseFloat(key)).includes(myId) &&
                  <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: "100%", marginBottom: "16px", marginTop: "24px", borderRadius: "6px", padding: "8px 10px"}}>
                  <MdInfo size={20} color='#a0a0a0' />
                  <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", marginBottom: "0px", width: "100%", textAlign:"right"}}> للتمكن من المزايدة يجب دفع رسوم دخول المزاد بقيمة <span style={{fontWeight:"500"}}>{formatAccountingArabic(entryFee)}</span>, عند اكمال عملية شراء المنتج بمبلغ المزايدة, يُخصم مبلغ العربون الذي دُفع مُسبقًا من الإجمالي. في حال عدم تحقيق أعلى مزايدة, يتم استرداد المبلغ. إذا كنت قد قمت بأعلى مزايدة ولم تكمل عملية الشراء, فإن المبلغ المدفوع كعربون لن يُسترد.</p>

                </div> 
                }
{
  postType.includes("مزاد") ?
    productPrice > 0  ? 
      auth.currentUser != null && Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? 
        !hasExpired && !isSold &&
        <Button 
        id='my-button'
        style={{width:"100%", marginBottom:"16px"}}
        // disabled={auth.currentUser!= null ? sellerId == myId : false}
        onClick={async ()=>{
          if(auth.currentUser !== null){

            navigate(`/checkout/${id}`, { state: { mode: 'Purchase' } })
          }
          else{
            navigate('/sign_in', { state: { mode: 'Purchase' } })
          }
        }}
        >الشراء الفوري<BiSolidPurchaseTagAlt size={18} style={{marginRight:"4px"}}/></Button>
        : !hasExpired && !isSold &&
        <Button 
        id='my-button'
        // disabled={auth.currentUser!= null ? item.seller['sellerId'] == auth.currentUser.uid : false}
        onClick={async ()=>{
          if(auth.currentUser !== null){

            navigate(`/checkout/${id}`, { state: { mode: 'Purchase' } })
          }
          else{
            navigate('/sign_in', { state: { mode: 'Purchase' } })
          }
          // if(auth.currentUser != null){
          //   setExpired(true)
          //     await updateDoc(postRef, {
          //       heighestBidder: auth.currentUser.uid,
          //       currentBid: productPrice,
          //       isSold: true,
          //       expiryDate: serverTimestamp(),
          //       soldDate: serverTimestamp()
          //     });
          //     // sendPurchasedEmail()
          // }
          // else{
          //   alert('You need to sign up to buy a product.')
          // }
        }}
        style={{width:"100%", backgroundColor:"#F00057"}}>الشراء الآن<BiSolidPurchaseTagAlt size={20} style={{marginRight:"4px"}}/></Button>
        : ""
        :  !isSold &&       
        <Button 
        id='my-button'
        style={{marginTop:"24px", width:"100%", backgroundColor:"#F00057"}}
        // disabled={auth.currentUser!= null ? item.seller['sellerId'] == auth.currentUser.uid : false}
        onClick={async ()=>{
          if(auth.currentUser !== null){

            navigate(`/checkout/${id}`, { state: { mode: 'Purchase' } })
          }
          else{
            navigate('/sign_in', { state: { mode: 'Purchase' } })
          }
          // if(auth.currentUser != null){
          //   setExpired(true)
          //     await updateDoc(postRef, {
          //       heighestBidder: auth.currentUser.uid,
          //       currentBid: productPrice,
          //       isSold: true,
          //       expiryDate: serverTimestamp(),
          //       soldDate: serverTimestamp()
          //     });
          //     // sendPurchasedEmail();
          // }
          // else{
          //   alert('You need to sign up to buy a product.')
          // }
        }}
        >الشراء الان <BiSolidPurchaseTagAlt size={18} style={{marginRight:"4px"}}/></Button>
}
{
  postType.includes("مزاد") && (
    auth.currentUser != null &&
    Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? (
      !hasExpired && !isSold && (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {/* <input
            ref={myBidRef}
            placeholder="مبلغ المزايدة"
            type="tel"
            style={{
              padding: "4px",
              fontSize: "14px",
              width: "45%",
              height: "45px",
              paddingRight: "8px",
              marginBottom: "auto",
              borderRadius: 1000,
              border: "1px solid #ccc",
              direction: "rtl",
            }}
          /> */}
          <button id='my-button'
          onClick={()=>{
            if(newBid - bidIncrements > currentBid){
              setNewBid(newBid - bidIncrements)
            }
            else return;
          }}
          style={{
            width: "20%",
            borderRadius: "8px",
            height: "40px",
            backgroundColor: "#F00057"
          }}
          ><FaMinus size={12} /></button>


          <p style={{
            marginBottom: "0px",
            marginRight: "8px",
            marginLeft: "8px",
            whiteSpace: "nowrap"
          }}>{formatAccountingArabic(newBid)}</p>


          <button id='my-button'
          onClick={()=>{
            if(productPrice > 0 && newBid + bidIncrements > productPrice){
              setNewBid(productPrice)
              return;
            }
            setNewBid(newBid + bidIncrements)
          }}
          style={{
            borderRadius: "8px",
            width: "20%",
            height: "40px",
            backgroundColor: "#00C6B7"
          }}
          ><FaPlus size={12} /></button>
          <Button
          
            onClick={async () => {
              const bidValue = parseFloat(newBid);

              if(isNaN(parseInt(bidValue)) || typeof parseInt(bidValue) !== 'number') return;

              if (bidValue <= currentBid || bidValue < productStartingPrice) {
                return;
              }

              if (bidValue >= productPrice && productPrice !== 0) {
                console.log("bid reached buy now price");
                setCurrentBid(productPrice)
                Object.assign(bidders, {
                  [`${myId}`]: parseFloat(productPrice),
                });

                const postRef = doc(db, "Posts", `post${id}`);
                await updateDoc(postRef, {
                  bidders: bidders,
                  heighestBidder: parseFloat(myId),
                  currentBid: parseFloat(productPrice),
                  lastBidDate: serverTimestamp(),
                  expiryDate: serverTimestamp()
                });

                // const sellerRef = doc(db, "Users", sellerId);

                // if (sellerId !== "") {
                //   const sellerData = await getDoc(sellerRef);
                //   const newBalance = sellerData.data().balance += productPrice;

                //   await updateDoc(sellerRef, {
                //     balance: newBalance,
                //   });

                //   navigate("/");
                // }
              } else {
                Object.assign(bidders, {
                  [`${myId}`]: bidValue,
                });

                const postRef = doc(db, "Posts", `post${id}`);
                await updateDoc(postRef, {
                  bidders: bidders,
                  currentBid: bidValue,
                  heighestBidder: parseFloat(myId),
                  lastBidDate: serverTimestamp()
                });
              }
            }}
            
            id="my-button"

            style={{ width: "42%", height: "40px", borderRadius: "6px" , marginRight: "8px", backgroundColor: "#00C6B7"}}
            variant="contained"
          >
            المزايدة
          </Button>
        </div>
      )
    ) : ! hasExpired && !isSold && (
      <Button
      // disabled={auth.currentUser!= null ? item.seller['sellerId'] == myId : false}
        id="my-button"
        style={{width:"100%", marginTop:"8px"}}
        onClick={async () => {
          if (auth.currentUser !== null) {
            navigate(`/checkout/${id}`, { state: { mode: 'Bid' } })
          } else {
            navigate('/sign_in', { state: { mode: 'Purchase' } })
          }
        }}
        variant="contained"
      >
        قم بالمزايدة
        <PiGavelFill size={20} style={{ marginRight: "4px" }} />
      </Button>
    )
  )
}

{
  postType.includes("عروض") && (
    <Button 
    
        id='my-button'
        // disabled={auth.currentUser!= null ? item.seller['sellerId'] == auth.currentUser.uid : false}
        onClick={async ()=>{
          if(auth.currentUser !== null){
            // set show offer then navigate
            setGiveOfferClicked(true)
            // navigate(`/checkout/${id}`, { state: { mode: 'Purchase' } })
          }
          else{
            alert("يجب تسجيل الدخول لتقديم عرض")
            // navigate('/sign_in', { state: { mode: 'Purchase' } })
          }
        }}
        style={{width:"100%", backgroundColor:"#00C8B8", marginTop: postType.includes("مزاد") && hasExpired ? "24px" : "8px"}}>تقديم عرض <BiSolidPurchaseTagAlt size={20} style={{marginRight:"4px"}}/></Button>
  )
}

{/* 
{
  postType.includes("مزاد") ? (
    isSold && buyerId === myId ? (
      <>
          <p style={{marginTop:"16px", fontSize:16, fontWeight:500, marginBottom:"4px"}}>تم شراء المنتج! عند استلام المنتج و فحصه, يرجى تأكيد استلامه:</p>
          <Button
            id='my-button'
            style={{ width:"100%"}}
            onClick={async () => {
              try {
                setProductReceiveLoading(true)
                const postDocRef = doc(db, 'Posts', `post${id}`);
        
                await updateDoc(postDocRef, {
                    isReceived: true,
                    autoReceiveDate: "" // Set autoReceiveDate to an empty string
                });
        
                setProductReceiveLoading(false)
                console.log('Post document updated successfully.');
                return true; // Indicate success
              } catch (error) {
              setProductReceiveLoading(false)
                console.error('Error updating post document:', error);
                return false; // Indicate failure
            }

            }}
          >
            {   
     productReceiveLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>تأكيد الاستلام</p>
              }
          </Button>

          <p style={{marginTop:"16px", fontSize:16, fontWeight:500, marginBottom:"4px"}}>واجهتك مشكلة في المنتج؟</p>
          <Button
            id='my-button'
            style={{ width:"100%", backgroundColor:"#F00057"}}
            onClick={async () => {
              // Update logic
            }}
          >
            رفض الاستلام
          </Button>

<div style={{display:"flex",alignItems:"center", marginTop:"16px", justifyContent:"center"}}>
  <p style={{fontSize:16, fontWeight:500, marginBottom:"4px"}}>
سيتم تأكيد استلام المنتج خلال
  </p>
<p style={{marginBottom:"2px" }}>
<Countdown
                    className='countdown-class'
                    date={item.autoReceiveDate.seconds * 1000}
                    >
                    </Countdown>
</p>
                      </div>
        </>
    ) : !isSold  && hasExpired? (
      <Button
        id='my-button'
        style={{ color: "white", width:"100%", marginTop: "24px" }}
        onClick={async () => {
          if(auth.currentUser !== null){
            navigate(`/checkout/${id}`, { state: { mode: 'Purchase' } })
          }
          else{
            navigate('/sign_in', { state: { mode: 'Purchase' } })
          }

        }}
      >
        شراء المنتج بمبلغ {formatAccountingArabic(currentBid - entryFee)}
      </Button>
    ) : null
  ) : isSold ? (
    !isReceived ? (
      <>
          <p style={{marginTop:"16px", fontSize:16, fontWeight:500, marginBottom:"4px"}}>تم شراء المنتج! عند استلام المنتج و فحصه, يرجى تأكيد استلامه:</p>
          <Button
            id='my-button'
            style={{ width:"100%"}}
            onClick={async () => {
              try {
                setProductReceiveLoading(true)
                const postDocRef = doc(db, 'Posts', `post${id}`);
        
                await updateDoc(postDocRef, {
                    isReceived: true,
                    autoReceiveDate: "" // Set autoReceiveDate to an empty string
                });
        
                setProductReceiveLoading(false)
                console.log('Post document updated successfully.');
                return true; // Indicate success
              } catch (error) {
              setProductReceiveLoading(false)
                console.error('Error updating post document:', error);
                return false; // Indicate failure
            }

            }}
          >
            {   
     productReceiveLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>تأكيد الاستلام</p>
              }
          </Button>

          <p style={{marginTop:"16px", fontSize:16, fontWeight:500, marginBottom:"4px"}}>واجهتك مشكلة في المنتج؟</p>
          <Button
            id='my-button'
            style={{ width:"100%", backgroundColor:"#F00057"}}
            onClick={async () => {
              // Update logic
            }}
          >
            <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>رفض الاستلام</p>
          </Button>

{
  autoReceiveDate && (

<div style={{display:"flex",alignItems:"center", marginTop:"16px", justifyContent:"center"}}>
  <p style={{fontSize:16, fontWeight:500, marginBottom:"4px"}}>
سيتم تأكيد استلام المنتج خلال
  </p>
<p style={{marginBottom:"2px" }}>
<Countdown
                    className='countdown-class'
                    date={item.autoReceiveDate.seconds * 1000}
                    >
                    </Countdown>
</p>
                      </div>
                      )
                    }
        </>
    ) : (
      <>
      <p style={{fontSize:18, fontWeight: 500, marginTop:"16px"}}> <FaBox color='#00C8B8' size={18} style={{marginLeft:"4px"}} /> تم استلام المنتج!</p>
      </>
    )
  ) : null
} */}


{
  !isSold && hasExpired ? (
    <Button
      id='my-button'
      style={{ color: "white", width: "100%", marginTop: "8px" }}
      onClick={async () => {
        if (auth.currentUser !== null) {
          navigate(`/checkout/${id}`, { state: { mode: 'Purchase' } });
        } else {
          navigate('/sign_in', { state: { mode: 'Purchase' } });
        }
      }}
    >
      شراء المنتج بمبلغ {formatAccountingArabic(currentBid - entryFee)}
    </Button>
  ) : null
}


{/* {
  isSold && (
    <>
    {

      !isReceived && !isRejected ? (
        <>
      <>
          <p style={{marginTop:"16px", fontSize:16, fontWeight:500, marginBottom:"4px"}}>تم شراء المنتج! عند استلام المنتج و فحصه, يرجى تأكيد استلامه:</p>
          <Button
            id='my-button'
            style={{ width:"100%"}}
            onClick={async () => {
              try {
                setProductReceiveLoading(true)
                const postDocRef = doc(db, 'Posts', `post${id}`);
                
                await updateDoc(postDocRef, {
                  isReceived: true,
                  autoReceiveDate: "" // Set autoReceiveDate to an empty string
                });
                
                setProductReceiveLoading(false)
                console.log('Post document updated successfully.');
                return true; // Indicate success
              } catch (error) {
                setProductReceiveLoading(false)
                console.error('Error updating post document:', error);
                return false; // Indicate failure
              }
              
            }}
            >
            {   
     productReceiveLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    
  }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>تأكيد الاستلام</p>
              }
          </Button>

          <p style={{marginTop:"16px", fontSize:16, fontWeight:500, marginBottom:"4px", textAlign:"right"}}>واجهتك مشكلة في المنتج؟</p>
          <Button
            id='my-button'
            style={{ width:"100%", backgroundColor:"#F00057"}}
            onClick={async () => {
              try {
                setProductRejectLoading(true)
                const postDocRef = doc(db, 'Posts', `post${id}`);
                
                await updateDoc(postDocRef, {
                  isRejected: true,
                  autoReceiveDate: "" // Set autoReceiveDate to an empty string
                });
                
                setProductRejectLoading(false)
                console.log('Post document updated successfully.');
                return true; // Indicate success
              } catch (error) {
                setProductRejectLoading(false)
                console.error('Error updating post document:', error);
                return false; // Indicate failure
              }
            }}
            >
             {   
     productRejectLoading ?    
     <div style={{
       alignItems: 'center',
       width: "100%",
       height: "100%",
       justifyContent: 'center',
       
      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>رفض الاستلام</p>
              }
          </Button>

{
  autoReceiveDate && (
    
    <div style={{display:"flex",alignItems:"center", marginTop:"16px", justifyContent:"center"}}>
  <p style={{fontSize:16, fontWeight:500, marginBottom:"4px"}}>
سيتم تأكيد استلام المنتج خلال
  </p>
<p style={{marginBottom:"2px" }}>
<Countdown
                    className='countdown-class'
                    date={item.autoReceiveDate.seconds * 1000}
                    >
                    </Countdown>
</p>
                      </div>
                      )
                    }
        </>
    </>
    )
    : !isRejected ? (
      <>
            <p style={{fontSize:18, fontWeight: 500, marginTop:"16px"}}> <FaBox color='#00C8B8' size={18} style={{marginLeft:"4px"}} /> تم استلام المنتج!</p>

      </>
    ) :
    (
      <>
                    <p style={{fontSize:18, fontWeight: 500, marginTop:"16px"}}> <IoMdCloseCircle color='#F00057' size={24} style={{marginLeft:"4px"}} /> تم رفض استلام المنتج</p>

      </>
    )
  }
                    </>
  )
} */}




                  {/* <Button 
                  id='product-details-button'
                  // disabled={auth.currentUser  != null ? sellerId == auth.currentUser.uid : false}
                  variant="contained">اضافة الى الامنيات<AiOutlineHeart size={20} style={{marginRight:"4px",}}/></Button> */}
                  {/* <Button 
                  id='product-details-button'
                  // disabled={auth.currentUser != null ? sellerId == auth.currentUser.uid : false}
                  style={{backgroundColor: "#ff4942"}}
                  variant="contained">الابلاغ عن المنشور<IoIosWarning size={20} style={{marginRight:"4px",}}/></Button> */}
                </div>
                </div>
          </div>
        </div>
        ))
      }
    </div>


<div style={{height:"fit-content", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginBottom:"24px", borderRadius:"8px", direction:"rtl", margin:"16px auto", padding: window.innerWidth > window.innerHeight ? "0px" : "16px", marginTop:"32px"}}>
<h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px" }}>تفاصيل المنتج</h5>


{
  productDescription !== "" && (

    <h5 style={{fontSize:"18px", color:"#555",wordBreak:"break-word", marginBottom:"16px" }}>{productDescription}</h5>
  )
}
  <div style={{height:"fit-content", width: "100%", display:"flex", justifyContent:"space-between", flexDirection: window.innerWidth > window.innerHeight ? "row" : "column"}}>
    <div style={{width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>

    <div
  style={{
    display: "flex",
    // marginTop: "16px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
{
  productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
    <>
      <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <LuCircleDashed color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>حالة المنتج</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: 
    "16px", textAlign: "center" }}>{productCondition}</p>
  </div>
    </>
  )
}
</div>


  <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <TbCategoryFilled color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>فئة المنتج</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{productCategory}</p>
  </div>
</div>
{
  productCategory === "لوح سيارات" && (
    <>
        <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <img src={carPlateIcon} alt="" width="18" height="18" style={{objectFit:"contain", marginLeft:"8px"}}/>

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>نوع اللوحة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{carPlateKind}</p>
  </div>
</div>
    </>
  )
}
{
  productCategory === "ابواك" && (
    <>


<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <MdFactory color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>الشركة المصنعة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{productCompany}</p>
  </div>
</div>


<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <FaTransgender color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>قسم البوك</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{walletDepartment}</p>
  </div>
</div>


<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون البوك الخارجي</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{walletOutsideColor}</p>
  </div>
</div>
    </>
  )
}
{
  productCategory === "اقلام" && (
    <>
    <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <MdFactory color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>الشركة المصنعة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{productCompany}</p>
  </div>
</div>
    </>
  )
}
{
      productCategory === "ساعات" && 
      <>
      <div style={{display:"flex", justifyContent:"space-between", flexDirection: window.innerWidth > window.innerHeight ? "row" : "column"}}>
        <div style={{width: window.innerWidth > window.innerHeight ? "100%" : "100%"}}>

        {
  productCompany !== "Apple" &&
  productCompany !== "Samsung" && 
  productCompany !== "Huawei" && 
  productCompany !== "Xiaomi" && (
    <div
    style={{
      display: "flex",
      marginTop: "8px",
      justifyContent:  "flex-start", // Adjusted justifyContent
  
    }}
  >
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
      <FaTransgender color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
      <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>قسم الساعات</p>
    </div>
    <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
      <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchDepartment}</p>
    </div>
  </div>
  )
        }

<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <MdFactory color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>الشركة المصنعة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{productCompany}</p>
  </div>
</div>

{
  productCompany !== "Apple" &&
  productCompany !== "Samsung" && 
  productCompany !== "Huawei" && 
  productCompany !== "Xiaomi" && (
<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
    
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون الساعة الداخلي</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchInsideColor}</p>
  </div>
</div>

  
    )
  }

<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون الساعة الخارجي</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center"}}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchOutsideColor}</p>
  </div>
</div>
      {
        !isNaN(watchDialSize) &&
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <IoResize color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>حجم الساعة</p>
        </div>
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center"}}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchDialSize} ملم</p>
        </div>
      </div>
      }
      {
        watchDialShape &&
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <FaShapes color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>شكل قرص الساعة</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center"}}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchDialShape}</p>
        </div>
      </div>
      }
        </div>
        

      </div>

      
      
      
      </>
    }
    {
      productCategory === "حقائب نسائية" && (
        <>
        <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <MdFactory color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>الشركة المصنعة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{productCompany}</p>
  </div>
</div>
        </>
      )
    }

   {
      productCategory === "سبح" && 
      <>
      <div style={{display:"flex", justifyContent:"space-between", flexDirection: window.innerWidth > window.innerHeight ? "row" : "column"}}>
        <div style={{width: window.innerWidth > window.innerHeight ? "100%" : "100%"}}>

        <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <img src={SebhahIcon} alt="" width="18" height="18" style={{marginLeft:"8px"}} />

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>نوع الخرز</p>
  </div>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{rosaryKind}</p>
  </div>
</div>
        </div>
      </div>
      
      
      </>
    }

    </div>
    
    {/* <div style={{display:"flex", justifyContent:"space-between", marginTop:"24px", width: window.innerWidth > window.innerHeight ? "45%" : "100%"}}>
      <h4 style={{fontSize:"16px"}}>حالة المنتج</h4>
      <h4 style={{fontSize:"17px", fontWeight:"400"}}>{productCondition}</h4>
    </div> */}
    
    <div style={{width:window.innerWidth > window.innerHeight ? "45%" : "100%"}}>
    <div style={{width: window.innerWidth > window.innerHeight ? "100%" : "100%"  }} > 
    {
      productCategory === "حقائب نسائية" && (
        <>
            <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون الحقيبة الخارجي</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{purseOutsideColor === "آخر" ? purseOutsideColorSpecification : purseOutsideColor}</p>
  </div>
</div>
            <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <GiSewingMachine color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>المادة المصنوعة منها الحقيبة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{purseMaterial}</p>
  </div>
</div>


        <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون الحقيبة الداخلي</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{purseInsideColor === "آخر" ? purseInsideColorSpecification : purseInsideColor}</p>
  </div>
</div>


        </>
      )
    }
    {
      productCategory === "لوح سيارات" && (
        <>
            <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <FaMoneyBillWave color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>رسوم نقل الملكية على</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{carPlateTransferFeeOn}</p>
  </div>
</div>


            <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <IoStopwatch color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>فترة نقل الملكية</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{carPlateTransferTime}</p>
  </div>
</div>

<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <img src={carPlateIcon} alt="" width="18" height="18" style={{objectFit:"contain", marginLeft:"8px"}}/>

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>اللوحة بالعربية</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center", marginLeft:"8px" }}>{carPlateFirstArabicLetter} {carPlateSecondArabicLetter} {carPlateThirdArabicLetter}</p>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center", marginRight:"8px" }}>{carPlateFirstArabicNumber} {carPlateSecondArabicNumber} {carPlateThirdArabicNumber} {carPlateFourthArabicNumber}</p>
  </div>
</div>
    <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <img src={carPlateIcon} alt="" width="18" height="18" style={{objectFit:"contain", marginLeft:"8px"}}/>

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>اللوحة بالإنجليزية</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <div style={{display:"flex"}}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center", marginLeft:"8px" }}>{carPlateThirdEnglishLetter} {carPlateSecondEnglishLetter} {carPlateFirstEnglishLetter}</p>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center", marginRight:"8px" }}>{carPlateFirstEnglishNumber} {carPlateSecondEnglishNumber} {carPlateThirdEnglishNumber} {carPlateFourthEnglishNumber}</p>

    </div>
  </div>
</div>

        </>
      )
    }
    {
  productCategory === "ارقام جوالات" && (
    <>
    
    <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <FaTowerCell color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>مشغل الخدمة</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{phoneNumberCourier}</p>
  </div>
</div>
    <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
    <FaPhone color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>رقم الجوال</p>
  </div>
  <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{phoneNumberToSell}</p>
  </div>
</div>
    </>
  )
}
    {
      productCategory === "سبح" && (
        <>
                <div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون الخرز</p>
  </div>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{rosaryColor}</p>
  </div>
</div>


<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent

  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <GoNumber color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>عدد الخرز</p>
  </div>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{rosaryCount}</p>
  </div>
</div>
{
  !isNaN(rosarySize) && (

<div
  style={{
    display: "flex",
    marginTop: "8px",
    justifyContent:  "flex-start", // Adjusted justifyContent
    
  }}
>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
  <IoResize color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />

    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>حجم الخرز</p>
  </div>
  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
    <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{rosarySize} ملم</p>
  </div>
</div>
)
}
        </>
      )
    }
        {
        watchHandlesColor &&
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون عقارب الساعة</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchHandlesColor}</p>
        </div>
      </div>
      }
        {
          watchNumbersLanguage && 
          <div
          style={{
            display: "flex",
            marginTop: "8px",
            justifyContent:  "flex-start", // Adjusted justifyContent
        
          }}
        >
          <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
            <IoLanguage color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
            <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لغة أرقام الساعة</p>
          </div>
          <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
            <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchNumbersLanguage}</p>
          </div>
        </div>
        }
      {
        watchNumbersColor &&
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>لون أرقام الساعة</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center" }}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchNumbersColor}</p>
        </div>
      </div>
      }
      {
        watchBandMaterial && 
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <IoIosWatch color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>المادة المصنوع منها السوار</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center" }}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchBandMaterial}</p>
        </div>
      </div>
      }
      {
        watchBandColor && 
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <HiMiniPaintBrush color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>  لون السوار</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center" }}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchBandColor}</p>
        </div>
      </div>
      }
      {
        productCategory === "ساعات" && (
          <>
          {
        watchHasOriginalPackaging !== "" && 
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <BiSolidPackage color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>يوجد صندوق الساعة</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center" }}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchHasOriginalPackaging ? "نعم" : "لا"}</p>
        </div>
      </div>
      }
      {
        !isNaN(watchYearMade) && 
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <FaCalendarAlt color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>سنة الصنع</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center" }}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchYearMade}</p>
        </div>
      </div>
      }
      {
        watchIsWaterResistant !== "" && 
        <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent:  "flex-start", // Adjusted justifyContent
      
        }}
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", display: "flex", width:"50%" }}>
          <IoIosWater color='#00C8B8' size={18} style={{ marginBottom: "0px", marginLeft: "8px" }} />
          <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, whiteSpace: "break-spaces", fontSize: "16px", marginBottom: "0px" }}>مقاومة للماء</p>
        </div>
        <div style={{  backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", textAlign: "center", width:"50%", marginRight:"16px", display:"flex", justifyContent:"center", display:"flex", justifyContent:"center" }}>
          <p style={{ marginBottom: "0px", fontSize: "16px", textAlign: "center" }}>{watchIsWaterResistant ? "نعم" : "لا"}</p>
        </div>
      </div>
      }
          </>
        )
      }
        </div> 
    </div>
  </div>
</div>

{
  productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
    <>

<div style={{height:"fit-content", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginBottom:"24px", borderRadius:"8px", direction:"rtl", margin:"16px auto", padding: window.innerWidth > window.innerHeight ? "0px" : "16px", marginTop:"32px"}}>
<h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px", fontSize:"18px" }}>طرق الشحن و التوصيل</h5>


  <div style={{ backgroundColor: "#f7f7f7", padding: "24px 16px", borderRadius: "8px", alignItems: "center", width:window.innerWidth > window.innerHeight ? "50%" :"100%"}}>
  
  {
    shippingMethods.includes("شحن") && (
      <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center"}}>
      <FaShippingFast color='#00C8B8' size={27} style={{ marginBottom: "0px", marginLeft: "8px" }} />
      <p style={{ marginBottom: "0px", fontSize: "15px", fontWeight: 500 }}>الشحن السريع</p>
    </div>
    )
  }

{
  shippingMethods.includes("تسليم") && (
    <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center", marginTop:shippingMethods.length === 1 ? "0px" : "24px"}}>
    <FaRegHandshake color='#00C8B8' size={32} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>التسليم وجهاً لوجه</p>
  </div>
  )
}
  </div>
</div>


<div style={{height:"fit-content", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginBottom:"24px", borderRadius:"8px", direction:"rtl", margin:"16px auto", padding: window.innerWidth > window.innerHeight ? "0px" : "16px", marginTop:"32px"}}>
<h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px", fontSize:"18px"  }}>الضمان و سياسة الاسترجاع</h5>

  <div style={{ backgroundColor: "#f7f7f7", padding: "8px 16px", borderRadius: "8px", alignItems: "center", width:window.innerWidth > window.innerHeight ? "50%" :"100%"}}>
      <div style={{ display: "flex", width:"100%", alignItems:"center"}}>
      <img src={logo} style={{width:"50px", height:"50px"}}/>
      <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500, marginRight:"8px" }}>نقدم لكم ضمان (بيع) على هذا المنتج, حيث يمكنك فحص المنتج و التأكد من مطابقته للوصف خلال 48 ساعة من استلام المنتج. في حال عدم مطابقة المنتج او وجود خلل, سيتم ارجاع المبلغ.</p>
    </div>
  </div>
</div>

  </>
  )
}
    {
     ! commentsLoading ?
    comments.length > 0 ? (
      <div style={{width:window.innerWidth > window.innerHeight ? "80%" : "100%",padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px",marginTop:"62px" , marginLeft:"auto", marginRight:"auto",direction:"rtl"}}>
<h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px", fontSize:"18px"  }}>التعليقات ({comments.length})</h5>

        {comments.map((comment) => (
          <Comment
          key={comment.timestamp} // Make sure to add a unique key
          comment={comment.comment}
          // userRating={comment.user.rating}
          // numRaters={comment.user.numRaters}
          userImg={comment.user.profileImgUrl}
          commentId={comment.commentId}
          commentRef="Post"
          isUpdated={comment.isUpdated}
          postId={`post${id}`}
          userName={comment.user.fullName}
          timestamp={comment.timestamp}
          userId={comment.userId}
          isCommentOptionsOpen={isCommentOptionsOpen} onClose={handleCloseComment}
          handleDeleteComment={handleDeleteComment} 
          handleUpdateComment={handleUpdateComment}
          link={`/user/${comment.userId}`}
          toggleDropdown={toggleDropdown}
          />
          ))}
          </div>
    ) : (
      <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", margin: "64px auto 16px auto", paddingRight: window.innerWidth > window.innerHeight ? "0px" : "16px" , paddingLeft: window.innerWidth > window.innerHeight ? "0px" : "16px",textAlign:"right" }}>

<h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px", fontSize:"18px"  }}>لا توجد تعليقات حتى الآن</h5>

      </div>
    )
    : 
<div style={{width:"80%",marginLeft:"auto", marginRight:"auto", direction:"rtl"}}>

    <div style={{
      marginTop: "55px",
      display: 'flex',
      width: "fit-content",
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginBottom:"8px",
      
    }}>
      <Oval
            height={"30px"}
            width={"30px"}
            color={"#00C8B8"}
            wrapperClass=""
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
            />
    </div>
            </div>
    }

{ auth.currentUser != null && (
  <div style={{ width:window.innerWidth > window.innerHeight ? "80%" : "100%",direction:"rtl", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px", marginTop:"16px", marginRight:"auto", overflow:"hidden", marginLeft:"auto"}}>

  <div style={{direction:"rtl", display:"flex", flexDirection:"column", position:"relative", height:"fit-content", width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>

    <textarea
      required={false}
      ref={commentRef}
      style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginLeft:"auto", fontSize:"14px" }}
      minLength={1}
      onChange={(event) => {
        setComment(event.target.value);
      }}
      placeholder="أضف تعليقًا أو سؤالًا"
    />

    <button
      id= {addCommentLoading ? 'add-comment-button-disabled' : 'add-comment-button'}
      disabled={addCommentLoading}
      style={{
        height: "30px", 
        position: "absolute", 
        width:"fit-content",
        bottom: 0, 
        left: 8,
        alignItems: "center", // Center vertically
        justifyContent: "center", // Align content to the left
        backgroundColor:"#F00057",
    }}
      onClick={()=>{
         if(comment.length < 1) return;
         
        setAddCommentLoading(true);
        addComment(comment).then(()=>{
          setAddCommentLoading(false);
          setComment("");
          // if(sellerEmail != "" && sellerId != myId){
          //   // sendCommentEmail()
          // }
        })}}
    >
    {   
     addCommentLoading ?    
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{
                display: "flex", // Ensures the wrapper itself uses flexbox for its internal layout
                alignItems: "center", // Center the loader vertically in the wrapper
                justifyContent: "center", // Center the loader horizontally in the wrapper
                margin: "auto" // Add any additional custom styles here
              }}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px", fontSize:"14px" }}>اضافة تعليق</p>
              }
    </button>
  </div>
  </div>
)}

{!hasExpired && auth.currentUser == null && (
  <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", textAlign: "right", paddingRight: window.innerWidth > window.innerHeight ? "0px" : "16px", paddingLeft: window.innerWidth > window.innerHeight ? "0px" : "16px",margin:"auto"}}>

  <p style={{ marginTop: "8px" }}>
    <Link 
    style={{
    }} to="/sign_in">أضف تعليق</Link>
  </p>
    </div>
)}


{/* check more products */}

    <div
            id='more-products-div'
            >

              {/* <Divider sx={{height: "2px"}}/> */}
              <br/>
                <div
                style={{
                  direction: "rtl",
                }}
                >
               {similarProducts.length > 0 ? (
  <>
    <p style={{fontSize: "18px", marginRight: "16px"}}>منتجات مشابهة</p>
    {similarProducts.map((item2) => (
      <div style={{ padding: "0px 8px", marginRight: "8px" }} key={item2.id} onClick={handleScrollToTop}>
        <Product
          postId={item2.id}
          expiryDate={`${item2.expiryDate.seconds}`}
          link={`/product/${item2.id}`}
          numBidders={item2.numBidders}
          currentBid={item2.currentBid}
          category={item2.category}
          carPlateKind={item2.carPlateKind}

          productPrice={item2.productPrice}
          imgs={item2.imgs[0]}
          condition={item2.condition}
          productDescription={item2.productDescription}
          productName={item2.productName}
          productSeller={item2.seller}
          isAuction={item2.postType.includes("مزاد")}
          acceptsOffers={item2.postType.includes("عروض")}
          heighestBidder={item2.heighestBidder}
          isBidder={item2.isBidder}
          isSold={item2.isSold}
          startingPrice={item2.startingPrice}
          numBids={item2.numBids}

        />
        {/* <Divider sx={{ height: "2px" }} /> */}
      </div>
    ))}
  </>
) : null}


<br/>
                <div
                style={{padding: "0px 8px"}}
                >

{similarCategory.length > 0 && (
  <>
    <h2>منتجات تهمك</h2>
    {similarCategory.map((item3) => (
      <div key={item3.key}>
        <Product 
          postId={item3.id}
          expiryDate={`${item3.expiryDate.seconds}`}
          link={`/product/${item3.id}`}
          numBidders={item3.numBidders}
          currentBid={item3.currentBid}
          productPrice={item3.productPrice}
          imgs={item3.imgs[0]}
          category={item3.category}
          carPlateKind={item3.carPlateKind}

          condition={item3.condition}
          productDescription={item3.productDescription}
          productName={item3.productName}
          productSeller={item3.seller}
          isAuction={item3.postType.includes("مزاد")}
          acceptsOffers={item3.postType.includes("عروض")}

          heighestBidder={item3.heighestBidder}
          isBidder={item3.isBidder}

          isSold={item3.isSold}
          startingPrice={item3.startingPrice}
          numBids={item3.numBids}
        />
        {/* <Divider sx={{ height: "2px" }} /> */}
      </div>
    ))}
  </>
)}

          </div>
          </div>
        </div>
    </div>
   );
  }

export default PostDetailsScreen;