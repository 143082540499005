import React, { useRef, useEffect, useState } from 'react';
import whiteCanvas from '../assets/white-canvas.png';

const PhoneNumber = ({
    phoneNumber,
    setPhoneNumber,
    setPostImageUrl
}) => {
    const phoneNumberRef = useRef(null);
    const canvasRef = useRef(null);
    const [inputValue, setInputValue] = useState(""); // Maintain inputValue in state
    const [imageUrl, setImageUrl] = useState(null); // Declare state for the image URL

    const drawTextOnImage = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = new Image();

        image.src = whiteCanvas;
        image.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      
          ctx.font = '60px Arial';
          ctx.fillStyle = '#00C8B8';
          ctx.textAlign = 'center'; // Align text to the center horizontally
          ctx.textBaseline = 'middle'; // Align text to the middle vertically
      
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;
      
          ctx.fillText(inputValue, centerX, centerY); // Center text on the canvas
      
          const dataUrl = canvas.toDataURL('image/png');
          setImageUrl(dataUrl); // Update state with new image URL
          setPostImageUrl([{ isCropped: false, src: dataUrl }]);
      };
      
    };

    useEffect(() => {
        drawTextOnImage();
    }, [inputValue]); // Depend on inputValue to re-render

    return (
        <div style={{ direction: 'rtl' }}>
            <div style={{ paddingTop: "4px" }}>
                <input 
                defaultValue={phoneNumber}
onChange={(e) => {
  const inputValue = e.target.value.trim();
  
  if (inputValue === "") {
      setInputValue(""); // Clear input state
      setPhoneNumber(""); // Clear phone number state
      return;
  }

  // Validate if input contains only digits
  if (!/^\d*$/.test(inputValue)) {
      alert("يرجى ادخال أرقام فقط.");
      phoneNumberRef.current.value = ""; // Clear the input field for non-numeric input
      setInputValue(""); // Clear input state
      setPhoneNumber(""); // Clear phone number state


      return;
  }

  // Enforce the input to start with "05"
  if (inputValue.length === 1 && inputValue !== "0") {
      alert("يرجى ادخال رقم يبدأ بـ 0");
      phoneNumberRef.current.value = ""; // Clear the input field if it doesn't start with '0'
      setInputValue(""); // Clear input state
      setPhoneNumber(""); // Clear phone number state

      return;
  }

  if (inputValue.length === 2 && !inputValue.startsWith("05")) {
      alert("يرجى ادخال رقم جوال يبدأ بـ 05");
      phoneNumberRef.current.value = ""; // Clear the input field if it doesn't start with '05'
      setInputValue(""); // Clear input state
      setPhoneNumber(""); // Clear phone number state

      return;
  }

  // Ensure the input does not exceed 10 digits
  if (inputValue.length > 10) {
      alert("يرجى ادخال رقم جوال لا يتجاوز 10 أرقام.");
      phoneNumberRef.current.value = inputValue.slice(0, 10); // Truncate input to 10 digits
      setInputValue(""); // Clear input state
      setPhoneNumber(""); // Clear phone number state

      return;
  }

  // Update states with the validated input
  setInputValue(inputValue); 
  setPhoneNumber(inputValue);
}}

                    type="tel"
                    lang="en"
                    ref={phoneNumberRef}
                    placeholder="0512345678"
                    style={{
                        backgroundColor: "#F0F0F0",
                        border: "none",
                        width: window.innerWidth > window.innerHeight ? "50%" : "100%",
                        height: "50px",
                        borderRadius: "8px",
                        outline: "none",
                        padding: "8px 16px",
                        textAlign: "right"
                    }}
                />
            </div>
            <canvas
                ref={canvasRef}
                width="400"
                height="400"
                style={{ marginTop: '20px', display: "none" }}
            />
            {/* {imageUrl && <img src={imageUrl} alt="Phone Number" width="100%"  style={{}} />} */}
        </div>
    );
};

export default PhoneNumber;
