import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';

const LoadingScreen = ({text}) => {
  useEffect(() => {
    // Scroll to the top of the page when the LoadingScreen component mounts
    window.scrollTo(0, 0);
  }, []);


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{
      width: "100%",
      height: "100%",
      minHeight: "100vh", // Adjusted height with navbar
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: "-65px", // Adjusted margin to account for navbar height
      marginBottom: window.innerWidth > window.innerHeight ? "0px" : "-55px",
      position:"fixed",
      backgroundColor:"white"
    }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width:"100%", height:"100px", flexDirection:"column" }}>
        <Oval
          height={"35px"}
          width={"35px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={1}
        />
        {
          text && (
            <p>{text}</p>
          )
        }
      </div>
    </div>
  );
}

export default LoadingScreen;