  import React, {useState, useEffect, useRef} from 'react'
  import payment from '../assets/payment_methods.png'
  import furnitureImg from '../assets/furniture.jpg'
  import { IoLocation } from "react-icons/io5";
  import { MdModeEdit } from "react-icons/md";
  import { FaTrashCan } from "react-icons/fa6";
  import cards from '../assets/cards.png';
  import applePay from '../assets/applePay.png'
  import { CiCreditCard1 } from "react-icons/ci";
  import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
  import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion, FieldValue, increment, addDoc, orderBy , getCountFromServer, setDoc} from 'firebase/firestore';
  import { db, auth, storage } from '../config/firebase'
  import { Divider } from '@mui/material';
  import SignInOrSignUpScreen from './SignInOrSignUpScreen';
  import { Oval } from 'react-loader-spinner'
  import { IoMdAddCircle } from "react-icons/io";
  import { IoIosCloseCircle } from "react-icons/io"; 
  import axios from 'axios';
import LoadingScreen from './LoadingScreen';
import { MdInfo } from "react-icons/md";
import { GoHomeFill, GoReport } from "react-icons/go";
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function

  function Checkout () {

  // In your Checkout component
  const location = useLocation();
  const mode = location.state?.mode;
  const offer = location.state?.offer;

          const [selectedOption, setSelectedOption] = useState(null);
          const [isSafari, setIsSafari] = useState(false);
          const [isLoading, setIsLoading] = useState(true);
          const [cardNumber, setCardNumber] = useState('');

          const [paymentStatus, setPaymentStatus] = useState('');

          const cardNumberRef = useRef();
          const monthRef = useRef();
          const yearRef = useRef();
          const cvcRef = useRef();

          const {id} = useParams();

          const q = query(collection(db, "Posts"), where("id", "==", parseFloat(id)));



          const [windowSize, setWindowSize] = useState({
            width: window.innerWidth,
            height: window.innerHeight
          });
      
          useEffect(() => {
            const handleResize = () => {
              setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
              });
            };
        
            window.addEventListener('resize', handleResize);
        
            // Initial call to set the state with current window dimensions
            handleResize();
        
            return () => {
              window.removeEventListener('resize', handleResize);
            };
          }, []);

          const [showAddAddress, setShowAddAddress] = useState(false);
          
          const [addressShouldRender, setAddressShouldRender] = useState(false);

  useEffect(() => {
    if (showAddAddress) {
      setAddressShouldRender(true);
    }
  }, [showAddAddress]);

  useEffect(() => {
    if (!showAddAddress) {
      setTimeout(() => setAddressShouldRender(false), 500); // Duration matches the animation time
    }
  }, [showAddAddress]);



          const [productName, setProductName] = useState("");
          const [productCategory, setProductCategory] = useState("");
          const [productDescription, setProductDescription] = useState("");
          const [productPrice, setProductPrice] = useState("");
          const [entryFee, setEntryFee] = useState("");
          const [isSold, setIsSold] = useState("");
          const [isDeleted, setIsDeleted] = useState("");
          const [isAuction, setIsAuction] = useState(false);
          const [currentBid, setCurrentBid] = useState(0);
          const [productImg, setProductImg] = useState("");
          const [expiryDate, setExpiryDate] = useState("");
          const [myAddress, setMyAddress] = useState();
          const [myCity, setMyCity] = useState();
          const [myNeighbourhood, setMyNeighbourhood] = useState();
          const [myStreet, setMyStreet] = useState();
          const [myNotes, setMyNotes] = useState();
          const [hasExpired, setHasExpired] = useState(false);
          const [startingPrice, setStartingPrice] = useState(false);
          const [bidders, setBidders] = useState("");
          const [bidIncrements, setBidIncrements] = useState(0);
          const [heighestBidder, setHeighestBidder] = useState(0);
          const [numBidders, setNumBidders] = useState(0);
          const [sellerId, setSellerId] = useState("");
          const [productWeight, setProductWeight] = useState("");
          const [postType, setPostType] = useState([]);

          const [generatedString, setGeneratedString] = useState('');

          const [shippingMethods, setShippingMethods] = useState([])


          const [saveMyAddress, setSaveMyAddress] = useState(true);
          const [myId, setMyId] = useState(null);
          const [myUID, setMyUID] = useState(null);
          const [requiresShipping, setRequiresShipping] = useState(false);
          const [deliveryOption, setDeliveryOption] = useState("");


          let price;
          let bidFee;
          let shippingFee;

          if(mode === "Bid"){
              price = entryFee
              bidFee = 0
              shippingFee = 0
            // if(Object.keys(bidders).map(key => parseFloat(key)).includes(myId)){
            // } 
            // else{
            //   bidFee = 0;
            // }
          }


          else if(mode === "Purchase"){
            if(postType.includes("مزاد") && Object.keys(bidders).map(key => parseFloat(key)).includes(myId)){
              bidFee = entryFee;
              if(hasExpired){
                price = currentBid;
              }
              else{
                price = productPrice
              }
            } else {
              price = productPrice
              bidFee = 0
            }
            shippingFee = deliveryOption === "شحن" ? calculateShippingCost(productWeight) : 0;
          }

          else if(mode === "Offer"){
            price = parseFloat(offer)
            shippingFee = deliveryOption === "شحن" ? calculateShippingCost(productWeight) : 0;
            bidFee = 0
          }
          else{
            shippingFee = 0;
          }

          const amount = price - bidFee + shippingFee


          // const price = mode !== "Bid" ? !isAuction ? productPrice : hasExpired ? heighestBidder ? currentBid : null : productPrice : entryFee ;
          // const bidFee = !isAuction ? 0 : Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? entryFee : 0;
          // const shippingFee = requiresShipping && mode === "Purchase" ? calculateShippingCost(productWeight) : 0;
          // const price = mode === "Bid" ? startingPrice * 0.05 : (isAuction && productPrice > 0 ? hasExpired ? currentBid - (startingPrice * 0.05) : Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? productPrice - (startingPrice * 0.05) :  productPrice : isAuction && heighestBidder === myId ? currentBid - (startingPrice * 0.05) : productPrice);
          // const amount = mode === "Purchase" && isAuction ? Object.keys(bidders).map(key => parseFloat(key)).includes(myId) ? parseFloat(price + shippingFee) -  (startingPrice * 0.05) : parseFloat(price + shippingFee) : productPrice  ;

          const [orderNumber, setOrderNumber] = useState(null)

          const navigate = useNavigate();

          const ordersCollection = collection(db, 'Orders');


          
          
          const [paymentLoading, setPaymentLoading] = useState(false);


          const [myReceiverName, setMyRceiverName] = useState("");
          const [receiverName, setReceiverName] = useState("");
          const [city, setCity] = useState("");
          const [neighbourhood, setNeighbourhood] = useState("");
          const [street, setStreet] = useState("");
          const [notes, setNotes] = useState("");

          const receiverNameRef = useRef();
          const cityRef = useRef();
          const neighbourhoodRef = useRef();
          const streetRef = useRef();
          const notesRef = useRef();

          const [cityHasError, setCityHasError] = useState(false);
          const [neighbourhoodHasError, setNeighbourhoodHasError] = useState(false);

          const [saveAddressIsLoading, setSaveAddressIsLoading] = useState(false);
          
          const [addressMode, setAddressMode] = useState("Add");

          const [buyerId, setBuyerId] = useState("");

          const [addressNotEntered, setAddressNotEntered] = useState(false);

          const [tranRef, setTranRef] = useState("")

          useEffect(() => {
            window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }, []);

          useEffect(() => {
            let unsubscribeUserData;
          
            const fetchData = async () => {
              try {
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    
                    if (!data.isDeleted || !data.isSold) {
                            const {
                                    productName,
                                    productPrice,
                                    isSold,
                                    productDescription,
                                    isAuction,
                                    currentBid,
                                    imgs,
                                    category,
                                    expiryDate,
                                    startingPrice,
                                    bidders,
                                    bidIncrements,  
                                    heighestBidder,
                                    isDeleted,
                                    sellerId,
                                    productWeight,
                                    buyerId,
                                    numBidders,
                                    entryFee,
                                    shippingMethods,
                                    postType,
                                    } = data;

                                    setProductName(productName);
                                    setProductPrice(productPrice);
                                    setIsSold(isSold);
                                    setPostType(postType)
                                    setProductCategory(category);
                                    setShippingMethods(shippingMethods);
                                    setProductDescription(productDescription);
                                    setIsAuction(isAuction);
                                    setCurrentBid(currentBid);
                                    setProductImg(imgs[0]);
                                    setExpiryDate(expiryDate);
                                    setStartingPrice(startingPrice);
                                    setBidders(bidders);
                                    setBidIncrements(bidIncrements);
                                    setHeighestBidder(heighestBidder);
                                    setIsDeleted(isDeleted);
                                    setSellerId(sellerId);
                                    setProductWeight(parseFloat(productWeight));
                                    setBuyerId(buyerId);
                                    setNumBidders(numBidders);
                                    setEntryFee(entryFee)


                                    if (doc.data().expiryDate !== "") {
                                    if (Date.now() / 1000 - doc.data().expiryDate.seconds < 0) {
                                        setHasExpired(false);
                                      } else {
                                              setHasExpired(true);
                                      }
                                    }
                                    } else {
                                    data.isSold ? setIsSold(true) : setIsSold(false);
                                    }
                                    });
                });
          
                return unsubscribe;
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            };
          
            const getOrderCount = async () => {
              try {
                const querySnapshot = await getDocs(ordersCollection);
                return querySnapshot.size;
              } catch (error) {
                console.error('Error getting order count:', error);
                return -1;
              }
            };
          
            const getOrderNumber = async () => {
              const count = await getOrderCount();
              if (count !== -1) {
                const newOrderNumber = count + 1;
                setOrderNumber(newOrderNumber);
              } else {
                console.log('Failed to get order count. Handle the error accordingly.');
              }
            };


            // const generateUniqueOrderNumber = async () => {
            //   const ordersCollectionRef = collection(db, "Orders");
            //   let orderNumber;
            //   let isUnique = false;
            
            //   while (!isUnique) {
            //     // Generate a random number
            //     orderNumber = Math.floor(Math.random() * (29999999 - 20000000 + 1)) + 20000000;
            
            //     // Create a query to check if this order number already exists
            //     const q = query(ordersCollectionRef, where("orderNumber", "==", orderNumber));
            
            //     // Execute the query
            //     const querySnapshot = await getDocs(q);
            
            //     // Check if the query returned any documents
            //     if (querySnapshot.empty) {
            //       // If no documents are found, the order number is unique
            //       isUnique = true;
            //     }
            //   }
            
              // Set the unique order number
            //   setOrderNumber(orderNumber);
            // };
          
            auth.onAuthStateChanged(async function (user) {
              if (user != null) {
                const userDocRef = doc(db, "Users", user.uid);
                unsubscribeUserData = onSnapshot(userDocRef, (docSnapshot) => {
                  if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setMyId(userData.id)
                    setMyUID(auth.currentUser.uid)
                    setMyRceiverName(userData.receiverName)
                    if (userData.address) {
                      setMyCity(userData.address['city']);
                      setMyNeighbourhood(userData.address['neighbourhood']);
                      setMyStreet(userData.address['street']);
                      setMyNotes(userData.address['notes']);
                      setMyAddress(userData.address)
                    } else {
                      // console.log("Address not found in user data");
                    }

                  } else {
                    console.log("User document does not exist");
                  }
                }, (error) => {
                  console.error("Error fetching user document:", error);
                });
          
                await fetchData();
                await getOrderNumber();
                // await generateUniqueOrderNumber();
                setIsLoading(false);
              } else {
                setIsLoading(false);
              }
            });
          
            return () => {
              if (unsubscribeUserData) {
                unsubscribeUserData();
              }
            };
          }, [isSold, hasExpired, myId]);

          function calculateShippingCost(weight) {
            const basePrice = 23; // تكلفة الشحن لأول 15 كيلوجرام
            const additionalPricePerKilo = 2; // تكلفة الشحن لكل كيلوجرام إضافي
            const baseWeight = 15; // الوزن الأساسي
        
            if (weight <= baseWeight) {
                return basePrice;
            } else {
                const additionalWeight = weight - baseWeight;
                const additionalCost = additionalWeight * additionalPricePerKilo;
                return basePrice + additionalCost;
            }
        }

        if(isLoading){
          return <LoadingScreen/>
        }

        if(isAuction && hasExpired && heighestBidder !== myId ){
          return <h1 style={{marginTop:"60px"}}>this post has expired</h1>
        }

        if(isDeleted){
          return <h1 style={{marginTop: "60px"}}>the post was deleted</h1>
        }

        if(isSold && buyerId !== myId){
          return <h1 style={{marginTop: "60px"}}>the product was sold</h1>
        }

        // if(isSold && buyerId === myId){
        //   navigate(`/product/${id}`)
        // }

        // if (mode !== "Purchase" && !hasExpired && Object.keys(bidders).map(parseFloat).includes(parseFloat(myId))) {
        //   navigate(`/product/${id}`);
        // } 
      

  //       if(auth.currentUser == null){
  //         return <SignInOrSignUpScreen />
  //       }


  //       async function createOrder(){
  //         const coll = collection(db, "Orders");
  //         const snapshot = await getCountFromServer(coll);

  //         var orderNumber = ++snapshot.data().count;

  //         await setDoc(doc(db, "Orders", `order${orderNumber}`), {
  //                 id: orderNumber,
  //                 buyer: auth.currentUser.uid,
  //                 amount: amount,
  //                 isPaid: false
  //         })
  //       }
        
          const isApplePaySupported = () => {
            return typeof ApplePaySession === 'function';
          };

          // const [supportsApplePay, setSupportsApplePay] = useState(isApplePaySupported());

          const options = [
                  { label : "Cards" , value: 'cards', image: cards },
                  { label: 'Apple Pay', value: 'applePay', image: applePay, size: '80px' },
                ];

          const handleOptionChange = (value) => {
                  setSelectedOption(value);
                };


                const handleInputChange = (e) => {
                  // Remove non-numeric characters
                  const newDigit = e.target.value.replace(/\D/g, '');
              
                  // Limit the input to 16 digits
                  if (newDigit.length > 16) {
                    return;
                  }
              
                  // Create a new card number with space after every 4 digits
                  const newCardNumber = newDigit
                    .match(/.{1,4}/g)
                    ?.join(' ')
                    .trim();
              
                  setCardNumber(newCardNumber || '');
                          if(e.target.value.length === 19){
                                  monthRef.current.focus();
                                }
                };


                function formatAccountingArabic(number) {
                  // Check if the input is a valid number
                  if (typeof number !== 'number' || isNaN(number)) {
                    return 'Invalid input';
                  }
                
                  // Use toLocaleString to format the number with commas and two decimal places
                  const formattedNumber = number.toLocaleString('en', {
                    minimumFractionDigits:2,
                    maximumFractionDigits: 2
                  });
                
                  // Add the Arabic currency symbol manually
                  const arabicFormattedNumber = `${formattedNumber} ر.س`;
                
                  return arabicFormattedNumber;
              }

              // Method to generate a random string
const generateRandomString = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 50; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const handlePayment = async (postId, userId) => {
  try {

    if (auth.currentUser) {
      setPaymentLoading(true);

      const response = await fetch('https://us-central1-men-ela-testing.cloudfunctions.net/processPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: postId,
          userId: userId,
          cart_amount: amount,
          cart_currency: "SAR",
          description: "Payment for XYZ",
          profile_id: 106859,
          tran_type: mode === "Bid" ? "auth" : "sale",
          tran_class: "ecom",
          cart_id: uuidv4(), // Use the generated string as cart_id
          cart_description: `Order number: ${orderNumber}, Product number: ${id}, Buyer ID: ${myId}, Seller ID: ${sellerId}. `,
          return: `http://localhost:3000/orders?order=${orderNumber}`,
          callback: `https://us-central1-men-ela-testing.cloudfunctions.net/handlePaymentCallback?a=${myId}&b=${id}&c=${mode}&d=${deliveryOption === "شحن" ? "Shipping" : deliveryOption === "تسليم" ? "Delivery": ""}&e=${orderNumber}&f=${sellerId}`,
          hide_shipping: true,
        }),
      });

      const responseData = await response.json();

      if (responseData.tran_ref) {
        // Redirect the user to the payment gateway
        window.location.href = responseData.redirect_url;
      } else {
        // Handle other responses or errors
        console.error('Error or unsuccessful response from processPayment Cloud Function:', responseData);
        // Set payment loading to false in case of error
        setPaymentLoading(false);
        // Update UI to display error message
      }
    }
  } catch (error) {
    // Handle error
    console.error('Error calling processPayment Cloud Function:', error);
    // Set payment loading to false in case of error
    setPaymentLoading(false);
    // Update UI to display error message
  }
};



          return (

            <div>
            {/* path */}
            <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: window.innerWidth > window.innerHeight ? "80%" : "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 
          
          style={{marginBottom:"0px", 
          maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
          overflow: "hidden",  /* Hide any overflow content */
          whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
          textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
        fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    onClick={()=>{navigate(-1)}}
          >{productName}</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>الدفع</h6>
        </div>
      </div>
                  <div style={{display: "flex", flexDirection:  window.innerWidth > window.innerHeight ? "row" : "column",justifyContent: window.innerWidth > window.innerHeight ? "space-between" : "start",direction: "rtl", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:"auto", marginRight:"auto", height: "fit-content", position:"relative", paddingBottom:"32px", minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
  
  
  
                  {addressShouldRender && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          opacity: showAddAddress ? 1 : 0,
          transition: "opacity 0.5s ease-out",

            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              width: window.innerWidth > window.innerHeight ? "35%" : "90%",
              height: "fit-content",
              backgroundColor: "white",
              position: "relative",
              margin: "auto",
              opacity: 1,
              zIndex: 2,
              borderRadius: "16px",
              padding: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              
            }}
            className={showAddAddress ? "pop-up" : "pop-down"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "8px",
              }}
            >
              <IoIosCloseCircle
                size={27}
                style={{ cursor: "pointer" }}
                color="#ccc"
                onClick={() => {
                  cityRef.current.value = "";
                  neighbourhoodRef.current.value = "";
                  streetRef.current.value = "";
                  notesRef.current.value = "";
                  setCityHasError(false);
                  setNeighbourhoodHasError(false);
                  setShowAddAddress(false);
                }}
              />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h5 style={{ marginTop: "16px" }}>
                  {addressMode === "Add" ? "أضف عنوانك" : "تحديث العنوان"}
                </h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>
            <div style={{ width: "100%", padding: "0px 16px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ color: "red" }}>*</p>
                <input
                  autoFocus
                  id={cityHasError ? "input-field-error" : "input-field"}
                  defaultValue={receiverName}
                  ref={receiverNameRef}
                  placeholder="اسم المستلم"
                  style={{ paddingBottom: "8px" }}
                  onChange={(e) => { setReceiverName(e.target.value); }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ color: "red" }}>*</p>
                <input
                  autoFocus
                  id={cityHasError ? "input-field-error" : "input-field"}
                  defaultValue=""
                  ref={cityRef}
                  placeholder="المدينة"
                  style={{ paddingBottom: "8px" }}
                  onChange={(e) => { setCityHasError(false); }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "16px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "45%" }}>
                  <div style={{ display: "flex" }}>
                    <p style={{ marginBottom: "0px", color: "red" }}>*</p>
                    <input
                      defaultValue=""
                      id={neighbourhoodHasError ? "input-field-error" : "input-field"}
                      ref={neighbourhoodRef}
                      placeholder="الحي"
                      style={{ paddingBottom: "8px" }}
                      onChange={(e) => { setNeighbourhoodHasError(false); }}
                    />
                  </div>
                </div>
                <div style={{ width: "45%" }}>
                  <input
                    defaultValue=""
                    id="input-field"
                    placeholder="الشارع (اختياري)"
                    ref={streetRef}
                    style={{ paddingBottom: "8px" }}
                  />
                </div>
              </div>
              <input
                defaultValue=""
                id="input-field"
                placeholder="ملاحظات (اختياري)"
                ref={notesRef}
                style={{ paddingBottom: "8px", marginTop: "16px" }}
              />
            </div>
            <button
              id="my-button"
              disabled={saveAddressIsLoading}
              style={{
                height: "40px",
                marginTop: "8px",
                marginBottom: "16px",
                width: window.innerWidth > window.innerHeight ? "50%" : "70%",
              }}
              onClick={async () => {
                if (cityRef.current.value === "") {
                  setCityHasError(true);
                }
                if (neighbourhoodRef.current.value === "") {
                  setNeighbourhoodHasError(true);
                }

                if (
                  saveMyAddress &&
                  cityRef.current.value !== "" &&
                  neighbourhoodRef.current.value !== ""
                ) {
                  setSaveAddressIsLoading(true);
                  const userDocRef = doc(db, "Users", auth.currentUser.uid);

                  try {
                    await updateDoc(userDocRef, {
                      "address.city": cityRef.current.value,
                      "address.neighbourhood": neighbourhoodRef.current.value,
                      "address.street": streetRef.current.value,
                      "address.notes": notesRef.current.value,
                      receiverName: receiverName,
                    });
                    console.log("Address updated successfully.");
                  } catch (error) {
                    console.error("Error updating address:", error);
                  }
                  setShowAddAddress(false);
                }
                if (!saveMyAddress) {
                  if (cityRef.current.value === "" || neighbourhoodRef.current.value === "")
                    return;
                  setCity(cityRef.current.value);
                  setNeighbourhood(neighbourhoodRef.current.value);
                  setStreet(streetRef.current.value);
                  setNotes(notesRef.current.value);

                  cityRef.current.value = "";
                  neighbourhoodRef.current.value = "";
                  streetRef.current.value = "";
                  notesRef.current.value = "";

                  setShowAddAddress(false);
                }
                setSaveAddressIsLoading(false);
              }}
            >
              {saveAddressIsLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "4px",
                  }}
                >
                  <Oval
                    height={"20px"}
                    width={"20px"}
                    color={"white"}
                    wrapperClass=""
                    visible={true}
                    wrapperStyle={{ display: "block" }}
                    ariaLabel="oval-loading"
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                  />
                </div>
              ) : (
                <p style={{ marginBottom: "0px" }}>
                  {addressMode === "Add" ? "إضافة العنوان" : "تحديث العنوان"}
                </p>
              )}
            </button>
          </div>
        </div>
      )}






                          <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                  <div>
                          <h5 style={{marginTop:"16px"}}>ملخص الطلب</h5>
                          <div style={{ height: "fit-content", border : "1.5px solid #00C8B8", borderRadius: "12px", padding: "8px"}}>
                                  <h6 style={{marginRight: "8px"}}>{mode == "Bid" ? `عربون دخول مزاد لمنتج رقم#   ${id}` : mode === "Purchase" ? `طلب رقم#   ${orderNumber}` : `تقديم عرض لمنتج رقم#   ${id}`}</h6>

                                  <div style={{width: "95%", display: "flex"}}>
    <img src={productImg} alt="" style={{width: "30%", borderRadius: "12px", objectFit: productCategory === "لوح سيارات" && "ارقام جوالات" ? "scale-down" : "cover", height: window.innerWidth > window.innerHeight ? "150px" : "100px"}} />
    <div style={{marginRight: "8px", width: "70%"}}>
      <p style={{
        marginBottom: "4px",
        fontWeight: "500",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 8,
        overflow: "hidden"
      }}>
        {productName}
      </p>
      <p style={{
        marginBottom: "0px",
        fontSize: "14px",
        color: "gray",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 10,
        overflow: "hidden"
      }}>
        {productDescription}
      </p>
      <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
        <p style={{marginBottom: "0px"}}>{formatAccountingArabic(price)}</p>
      </div>
    </div>
  </div>

                          </div>

  {
  mode !== "Bid" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
    <>
                          <h5 style={{display:"flex", marginTop: "42px"}}>الشحن و التوصيل</h5>
                          
              <select 
            id='input-field' 
            defaultValue=""
            onChange={(event) => {
              const selectedValue = event.target.value;
              if(selectedValue === "شحن"){
                
                shippingFee = calculateShippingCost(productWeight)
              } 
              else{
                shippingFee = 0
              }
              setDeliveryOption(selectedValue)
            }}
            style={{ color: "black", borderRadius: "4px"}}
          >
            <option value=""></option>
            {shippingMethods.includes("شحن") && (
              <option defaultValue style={{ backgroundColor: "white", textAlign: "right" }} value="شحن">
                الشحن السريع
              </option>
            )}
            {shippingMethods.includes("تسليم") && (
              <option style={{ backgroundColor: "white", textAlign: "right" }} value="تسليم">
                سأستلم المنتج من البائع وجهاً لوجه
              </option>
            )}
          </select>

{
  deliveryOption === "تسليم" && (
    <div style={{display: "flex", backgroundColor: "#fff0f2" , width: "100%", marginBottom: "16px", marginTop: "8px", borderRadius: "6px", padding: "8px 10px"}}>
    <MdInfo size={30} color='#ff7385' />
    <div>

    <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>نوصي بالتقاء البائع في مكان عام لاستلام المنتج و توخي الحذر لأي ظرف كان, و فحص المنتج في حينه, و تجنب استخدام اي وسيلة دفع خارج المنصة.</p>
    <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>منصة (بيع) تخلي مسؤوليتها في حال عدم اتباع اياً من المذكور أعلاه.</p>
    </div>
  </div> 
  )
}
                            </>
                          )}
                          {  
                            mode !== "Bid" && deliveryOption === "شحن" &&  (
                              <>
                              <div style={{display:"flex", marginTop: "24px"}}>

                              <h5 style={{fontWeight: "bold", color: "#444" }}>العنوان</h5>
                              </div>

                              {
    myAddress?.city || (city && neighbourhood) ?
    <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1.5px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
      <IoLocation size={32} color='#00C8B8' />
      <div style={{marginRight: "8px"}}>
        <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px"}}>{receiverName ? receiverName : myReceiverName}</p>    
        <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>{city ? city : myCity}</p>    
        <p style={{ marginBottom: "8px", color: "gray" }}>
        {neighbourhood ? `حي ${neighbourhood}${street ? `, شارع ${street}` : ''}` : myNeighbourhood ? `حي ${myNeighbourhood}${myStreet ? `, شارع ${myStreet}` : ''}` : ''}
        </p>
        <p style={{marginBottom:"0px", color:"gray"}}>{city ? notes : myNotes}</p>
      </div>
      <div style={{justifyContent: "space-between", display: "flex", position:"absolute", left: 12 , top: 10, width: window.innerWidth > window.innerHeight ? "8%" : "13%"}}>
        <MdModeEdit size={17} color='gray' style={{cursor:"pointer"}} onClick={()=>{setShowAddAddress(true); setAddressMode("Edit")}} />
        <FaTrashCan size={17} color='gray'  style={{cursor:"pointer"}} onClick={async ()=>{
          if (window.confirm("حذف العنوان الحالي؟")) {
            if(city && neighbourhood){
              setCity("");
              setNeighbourhood("");
              setStreet("");
              setNotes("");
            } else {
              const userDocRef = doc(db, "Users", auth.currentUser.uid);
              try {
                await updateDoc(userDocRef, {
                'address.city': "",
                'address.neighbourhood': "",
                'address.street': "",
                'address.notes': "" 
              });
              console.log('Address updated successfully.');
            } catch (error) {
              console.error('Error updating address:', error);
            }
            }
        } else {
            console.log("Item deletion cancelled.");
        }
        }}/>
      </div>
    </div>
    : 
    <button onClick={()=>{setShowAddAddress(true); setAddressMode("Add"); setSaveMyAddress(true)}} id='my-button' style={{height:"40px", width:"100%", marginTop:"16px"}}>أضف عنوان</button>
  }

                              </>
                            )
                          }
                          
                          </div>
                          
                          </div>
                          <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                  <h5  style={{fontWeight: "bold", color: "#444", marginTop: window.innerWidth > window.innerHeight ? "16px" : "42px", }}>ملخص المبلغ</h5>

                                  <div style={{ height: "fit-content",borderRadius: "12px" , width: "100%" ,backgroundColor: "#F4F4F4", padding: "16px 0px"}}>
                                  {/* {options.map((option) => (
          <div key={option.value} style={{ marginBottom: '10px', backgroundColor: 'white', borderRadius: '5px', width: '95%', marginRight: 'auto', marginLeft: 'auto' }}>
            {(isApplePaySupported() || option.label !== 'Apple Pay') && (
              <label
                htmlFor={option.value}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <input
                  type="radio"
                  id={option.value}
                  name="radioOptions"
                  value={option.value}
                  checked={selectedOption === option.value}
                  onChange={() => handleOptionChange(option.value)}
                  style={{ marginRight: '20px', paddingRight:"8px" ,height: '24px', width: '24px', accentColor: "#F00057" }}
                />
                <span
                  style={{
                    backgroundColor: option.color,
                    padding: '5px 10px',
                    height: '100%',
                    borderRadius: '5px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '50%', height: '50px' }}>
                    <img
                      src={option.image}
                      alt={option.label}
                      style={{ width: option.size, height: '100%', objectFit: 'cover', borderRadius:"8px" }}
                    />
                  </div>
                </span>
              </label>
            )}
          </div>
        ))} */}
        {/* {selectedOption === "cards" && (
    <>
      <div style={{ position: 'relative', width: '85%', margin: 'auto', marginTop: '24px' }}>
        <input
          type="tel"
          autoFocus
          maxLength="19" // Allowing 19 characters to account for spaces
          placeholder='رقم البطاقة'
          value={cardNumber}
          onChange={handleInputChange}
          ref={cardNumberRef}
          style={{
            width: '100%',
            border: 'none',
            outline: 'none',
            height: '40px',
            borderRadius: '4px',
            direction: 'rtl',
            padding: '0px 8px',
            color: '#555',
            paddingRight: "48px",
            fontSize: "14px",
            fontFeatureSettings: 'tnum',
          }}
        />
        <CiCreditCard1
          size={30}
          color="#555"
          style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }}
        />
      </div>
      <div style={{display: "flex", marginTop: "8px", width: "85%", marginRight: "auto", marginLeft: "auto", alignItems : "center"}}>
        <input ref={monthRef} onChange={(e)=>{
          if(e.target.value.length === 2){
                  yearRef.current.focus();
                }
        }} maxLength={2} type='tel' style={{width: "25%", height: "35px", direction: "rtl", textAlign: "center", borderLeft: "none", marginLeft: "-8px", borderRadius: "0px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px", border: "none", outline: "none"}} placeholder='الشهر'/>
        <p style={{marginBottom: "0px", color: "#555"}}>/</p>
        <input onChange={(e)=>{
          if(e.target.value.length === 2){
                  cvcRef.current.focus();
          }
        }} ref={yearRef} maxLength={2} type='tel' style={{width: "25%", height: "35px", borderRight: "none", borderRadius: "0px", textAlign: "center", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", border: "none", outline: "none"}} placeholder='السنة'/>
        <input maxLength={4} ref={cvcRef} type='tel' style={{width: "47%", height: "35px", marginRight: "auto", textAlign: "center", borderRadius: "4px", border: "none", outline: "none"}} placeholder='CVC' onChange={(e)=>{
          if(e.target.value.length === 4){
            cvcRef.current.blur();
          }
        }}/>
      </div>
    </>
  )} */}

  {/* <Divider sx={{height: "2px", width: "80%", marginRight: "auto", marginLeft: "auto", bgcolor: "white", borderColor: "white" ,marginTop: "16px"}}/> */}
  <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
  <h6>المبلغ</h6>
  <h6>{formatAccountingArabic(price)}</h6>  
  </div>

  { deliveryOption == "شحن" &&   mode !== "Bid" &&
    <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
    <h6>الشحن</h6>
    <h6>{formatAccountingArabic(shippingFee)}</h6>
    </div>
  }

  {
    postType.includes("مزاد") && mode === "Purchase" && Object.keys(bidders).map(key => parseFloat(key)).includes(myId) &&

    <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
  <h6>العربون المدفوع</h6>
  <h6> - {formatAccountingArabic(entryFee)}</h6>
  </div>
  }

  <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
  <h6>الإجمالي</h6>
  <h6>{formatAccountingArabic(amount)}</h6>
  </div>

  <p style={{marginBottom:"0px", marginTop:"8px", textAlign:"center", color:"red", display: addressNotEntered && requiresShipping  ? "block" : "none"}}>يرجى ادخال عنوان صحيح لإكمال عملية الدفع</p>
  <div style={{display:"flex", marginTop: "8px"}}>


          <button style={{marginRight: "auto", marginLeft: "auto", backgroundColor: "#F00057", color:"white", width: "75%", height:"40px"}} id='my-button'
  disabled={paymentLoading}
  onClick={
    async ()=>{
      if(productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && deliveryOption === "" && mode !== "Bid"){
        alert("يرجى اختيار طريقة الشحن و التوصيل")
        return;
      }

      if(deliveryOption === "شحن" && mode !== "Bid"){
        if(!myAddress.city && !city){
          alert("يرجى ادخال عنوان")
          return;
        }
      }


      if(!isSold){
        handlePayment(id, myId)
      }
  }}
  >{
    paymentLoading ? <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", paddingTop:"4px"}}>
    <Oval
                height={"20px"}
                width={"20px"}
                color={"white"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 
    </div> : <p style={{marginBottom:"0px"}}>اكمال الدفع</p>
  }</button>

  </div>


                                  </div>
                          </div>
                  </div>
          
                  </div>
        
        );
  }
  
  export default Checkout;