import React from 'react';
import { IoMdCheckmark } from "react-icons/io";

function PostTypeSingle({ onSelect, selectedCategory = null, children }) {
  const handleOptionClick = (category) => {
    onSelect(selectedCategory === category ? null : category);
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: window.innerWidth > window.innerHeight ? `repeat(3, 1fr)` : `repeat(1, 1fr)`,
        gap: '10px',
        direction: 'rtl',
        alignItems: 'start',
        justifyContent: 'start',
        rowGap: 32
      }}>
        {React.Children.toArray(children)
          .filter(child => React.isValidElement(child))
          .map(child => {
            const category = child.props.category;
            const isSelected = selectedCategory === category;
            return (
              <div
                key={category}
                onClick={() => handleOptionClick(category)}
                style={{
                  border: isSelected ? '3px solid #00C8B8' : '1px solid #CCCCCC', // Ensure the unselected border is visible
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  textAlign: 'center',
                  position: "relative",
                  borderRadius: "16px",
                  width: "100%",
                  padding: "20px 10px",
                  backgroundColor: isSelected ? "#E0FFF8" : "#FFFFFF", // Adjust for contrast
                  display: "flex",
                  alignItems: 'center', // Vertically center the content
                  justifyContent: 'center' // Horizontally center the content
                }}
              >
                {isSelected && (
                  <IoMdCheckmark size={24} style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "#FFFFFF",
                    backgroundColor: "#00C8B8",
                    borderRadius: "50%",
                    padding: "5px"
                  }} />
                )}
                {child}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default PostTypeSingle;
