import React, {useEffect, useState} from 'react'
import { IoSend } from "react-icons/io5"; 
import { Divider } from '@mui/material';


function MoreScreen () {
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [])


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const whatsAppLink = (phoneNumber) => {
        return `https://wa.me/${phoneNumber}`;
      };  
        return (
          <div style={{direction:"rtl", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginRight:"auto", marginLeft:"auto", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px", height:"fit-content", minHeight: "calc(100vh - 65px)", marginTop:"32px"}}>
            <a href='contact_us' style={{color:"black", textDecoration:"none"}}>
              <div style={{display:"flex", alignItems:"center", position:"relative", marginBottom:"16px"}}>
                <h6>تواصل معنا</h6>
                  <IoSend style={{
                    position:"absolute",
                    left:8,
                    top:"50%",
                    bottom:"50%",
                    transform: "translateY(-50%) scaleX(-1)", // Flip horizontally and adjust vertical position
                    color:"#F00057"
                  }}/>
              </div>
            </a>
            <Divider  />
            <a href='about_us' style={{color:"black", textDecoration:"none"}}>
              <div style={{display:"flex", alignItems:"center", position:"relative", margin:"16px 0px"}}>
                <h6>من نحن</h6>
                  <IoSend style={{
                    position:"absolute",
                    left:8,
                    top:"50%",
                    bottom:"50%",
                    transform: "translateY(-50%) scaleX(-1)", // Flip horizontally and adjust vertical position
                    color:"#F00057"
                  }}/>
              </div>
            </a>
            <Divider />
            <a href='user_policy' style={{color:"black", textDecoration:"none"}}>
              <div style={{display:"flex", alignItems:"center", position:"relative", margin:"16px 0px"}}>
                <h6>شروط الاستخدام</h6>
                  <IoSend style={{
                    position:"absolute",
                    left:8,
                    top:"50%",
                    bottom:"50%",
                    transform: "translateY(-50%) scaleX(-1)", // Flip horizontally and adjust vertical position
                    color:"#F00057"
                  }}/>
              </div>
            </a>
          </div>
        );
}
 
export default MoreScreen;