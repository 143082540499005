import React from 'react';
import { IoMdCheckmark } from "react-icons/io";

function SelectableCondition({ onSelect, selectedCategory, children }) {
  const handleOptionClick = (category) => {
    onSelect(category);
  };

  return (
    <div style={{ textAlign: 'right'}}>
      <div style={{display: 'grid', gridTemplateColumns: window.innerWidth > window.innerHeight
          ? `repeat(3, 1fr)`
          :  `repeat(1, 1fr)`, gap: '10px', direction: 'rtl', alignItems: 'start', justifyContent: 'start', rowGap:32 }}>
        {React.Children.map(children, child => {
          const category = child.props.category;
          const isSelected = category === selectedCategory;
          return (
            <div
              style={{
                border: isSelected ? '2px solid #00C8B8' : '1px solid #CCCCCC', // Change border color when selected
                border: isSelected ? '2px solid #00C8B8' : '1px solid transparent',
                transition: 'border-color 0.2s',
                cursor: 'pointer',
                textAlign: 'center',
                position: "relative",
                borderRadius: "16px",
                width: "100%",
                padding: "16px 8px",
                backgroundColor: "#F7F7F7",
                display: "flex",
                textAlign: 'right',
                boxShadow: !isSelected ? '0px 1px 0px rgba(0, 0, 0, 0.1)' : 'none', // Add 1px bottom shadow when selected
              }}
              onClick={() => handleOptionClick(category)}
            >
              {
                isSelected && (
                  <IoMdCheckmark size={20} color='white' style={{ position: "absolute", top: 0, right: 0, backgroundColor: "#00C8B8", padding: "1px", borderTopRightRadius: "13px", borderBottomLeftRadius: "4px" }} />
                )
              }
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SelectableCondition;
