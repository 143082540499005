import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../animations/ComingSoon.json'

function ComingSoon () {


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData, // Animation data
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };

        return (
           <div style={{
            // marginTop: "55px",
            direction: "rtl",
            // padding: "32px",
            justifyContent: "center",
            alignContent: "center",
            width: "100vw",
            height: "80vh"
           }}>
 <Lottie options={defaultOptions} height={200} width={200} />
           </div>
        );
}
 
export default ComingSoon;