import React, { useRef, useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const ScrollableDivWithArrows = ({ children, styles }) => {
  const elementRef = useRef(null);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [touchStartX, setTouchStartX] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false); // New state to track scrollability
  const [rightArrowDisabled, setRightArrowDisabled] = useState(!isScrollable);

useEffect(() => {
  const element = elementRef.current;

  const handleScroll = () => {
    setLeftArrowDisabled(element.scrollLeft <= element.clientWidth - element.scrollWidth);
    setRightArrowDisabled(element.scrollLeft + element.clientWidth >= element.scrollWidth);
    if (Math.floor(element.scrollLeft) <= element.clientWidth - element.scrollWidth) {
      // console.log("Scrolled all the way to the left");
      setLeftArrowDisabled(false);
      setRightArrowDisabled(true);
    } else if (Math.floor(element.scrollLeft) === 0) {
      // console.log("Scrolled all the way to the right");
      setLeftArrowDisabled(true);
      setRightArrowDisabled(false);
    }
  };

  const handleResize = () => {
    setIsScrollable(element.scrollWidth > element.clientWidth);
    // console.log("Is scrollable:", element.scrollWidth > element.clientWidth); // Log scrollability
    // console.log("left arrow disabled:", rightArrowDisabled); // Log scrollability
  };

  element.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);

  setIsScrollable(element.scrollWidth > element.clientWidth);
  
  if(isScrollable){
    setRightArrowDisabled(false)
  }
  else{
    setRightArrowDisabled(true)
    setLeftArrowDisabled(true)
  }

  return () => {
    element.removeEventListener("scroll", handleScroll);
    window.removeEventListener("resize", handleResize);
  };
}, [isScrollable]);

  
  const handleHorizontalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft -= step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const touchDeltaX = touchStartX - touchEndX;
    if (touchDeltaX > 50) {
      handleHorizontalScroll(elementRef.current, 20, 40, -10);
    } else if (touchDeltaX < -50) {
      handleHorizontalScroll(elementRef.current, 20, 40, 10);
    }
  };

  return (
    <div style={{ position: "relative", ...styles }}>
      <IoIosArrowForward
        size={38}
        color="white"
        style={{
          position: "absolute",
          right: window.innerWidth > window.innerHeight ? -20 : 0,
          top: "40%",
          transform: "translateY(-50%)",
          backgroundColor: "#00C8B880",
          borderRadius: 100,
          padding: "4px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          display: isScrollable ? leftArrowDisabled ? "none" : "block" : "none"
        }}
        onClick={() => {
          handleHorizontalScroll(elementRef.current, 20, 150, -10);
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#00C8B8";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#00C8B880";
        }}
      />

      <IoIosArrowBack
        size={38}
        color="white"
        style={{
          position: "absolute",
          left: window.innerWidth > window.innerHeight ? -20 : 0,
          top: "40%",
          transform: "translateY(-50%)",
          backgroundColor: "#00C8B880",
          borderRadius: 100,
          padding: "4px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          display: isScrollable ? rightArrowDisabled ? "none" : "block" : "none"
        }}
        onClick={() => {
          handleHorizontalScroll(elementRef.current, 20, 150, 10);
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#00C8B8";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#00C8B880";
        }}
      />

      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          width: "100%",
          direction: "rtl",
          scrollbarWidth: "none",
          "::WebkitScrollbar": {
            display: "none"
          }
        }}
        ref={elementRef}
      >
        {React.Children.map(children, (child, index) => (
          <div key={index} style={{ width: "fit-content" }}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollableDivWithArrows;
