import React, { useEffect } from 'react';
import { useChat } from '../hooks/ChatContext';

const TestScreen = () => {
  const { isSendMessageOpen, setIsSendMessageOpen } = useChat();

  useEffect(() => {
    // Example: Open chat when the component mounts
    setIsSendMessageOpen(true);

    return () => {
      // Clean up: Close chat when the component unmounts
      setIsSendMessageOpen(false);
    };
  }, [setIsSendMessageOpen]);

  return (
    <div>
      {isSendMessageOpen ? 'Chat is open' : 'Chat is closed'}
    </div>
  );
};

export default TestScreen;
