import React from 'react';
import { IoMdCheckmark } from "react-icons/io";

function PostType({ onSelect, selectedCategories = [], children }) {
  const handleOptionClick = (category) => {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter(c => c !== category)
      : [...selectedCategories, category];
    onSelect(newSelectedCategories);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'grid', gridTemplateColumns: window.innerWidth > window.innerHeight ? `repeat(3, 1fr)` : `repeat(1, 1fr)`, gap: '10px', direction: 'rtl', alignItems: 'start', justifyContent: 'start', rowGap: 32 }}>
        {React.Children.toArray(children)
          .filter(child => React.isValidElement(child))  // Only process valid React elements
          .map(child => {
            const category = child.props.category;
            const isSelected = selectedCategories.includes(category);
            return (
              <div
                key={category}
                onClick={() => handleOptionClick(category)}
                style={{
                  border: isSelected ? '2px solid #00C8B8' : '1px solid transparent',
                  transition: 'border-color 0.2s',
                  cursor: 'pointer',
                  textAlign: 'center',
                  position: "relative",
                  borderRadius: "16px",
                  width: "100%",
                  padding: "16px 8px",
                  backgroundColor: "#F7F7F7",
                  display: "flex",
                  textAlign: 'right',
                  boxShadow: !isSelected ? '0px 1px 0px rgba(0, 0, 0, 0.1)' : 'none', // Add 1px bottom shadow when selected
                }}
              >
                {isSelected && (
                  <IoMdCheckmark size={20} color='white' style={{ position: "absolute", top: 0, right: 0, backgroundColor: "#00C8B8", padding: "1px", borderTopRightRadius: "13px", borderBottomLeftRadius: "4px" }} />
                )}
                {child}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default PostType;
