import { useState, useEffect, useRef, useCallback } from "react";
import {
  limit,
  collection,
  orderBy,
  where,
  getDocs,
  getAggregateFromServer,
  count,
  or,
  startAfter,
} from "firebase/firestore";
import { query } from "firebase/database";
import { db } from "../config/firebase";

const pageSize = 10;

const useInfiniteScroll = (
  res,
  numBidders,
  orderSelectedName,
  urlSortOption,
  lastDoc,
  categorySearch,
  conditionFilter,
  postTypeFilter,
  lowestPriceFilter,
  heighestPriceFilter,
  lowestEntryFeeFilter,
  heighestEntryFeeFilter,
  lowestStartingPriceFilter,
  heighestStartingPriceFilter,
  lowestBidIncrementFilter,
  heighestBidIncrementFilter,
  shippingMethodsFilter,
  companyFilter,
  watchDepartmentFilter,
  watchOutsideColorFilter,
  watchInsideColorFilter,
  watchDialShapeFilter,
  smallestDialSizeFilter,
  biggestDialSizeFilter,
  watchHandlesColorFilter,
  watchNumbersColorFilter,
  watchNumbersLanguageFilter,
  watchBandMaterialFilter,
  watchBandColorFilter,
  watchHasOriginalPackagingFilter,
  watchIsWaterResistantFilter,
  watchOldestYearMadeFilter,
  watchNewestYearMadeFilter,
  rosaryKindFilter,
  rosaryColorFilter,
  leastRosaryCountFilter,
  mostRosaryCountFilter,
  smallestRosarySizeFilter,
  biggestRosarySizeFilter,
  walletDepartmentFilter,
  walletOutsideColorFilter,
  purseMaterialFilter,
  purseOutsideColorFilter,
  purseInsideColorFilter,
  carPlateKindFilter,
  carPlateTransferFeeOnFilter,
  carPlateTransferTimeFilter,
  phoneNumberCourierFilter
) => {
  const [listing, setListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allCount, setAllCount] = useState(0);
  const lastVisible = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [mostExpensiveItemEntryFee, setMostExpensiveItemEntryFee] = useState(0);
  const [leastExpensiveItemEntryFee, setLeastExpensiveItemEntryFee] =
    useState(0);

  const [mostExpensiveItemStartingPrice, setMostExpensiveItemStartingPrice] =
    useState(0);
  const [leastExpensiveItemStartingPrice, setLeastExpensiveItemStartingPrice] =
    useState(0);
  const [mostExpensiveBidIncrement, setMostExpensiveBidIncrement] = useState(0);
  const [leastExpensiveBidIncrement, setLeastExpensiveBidIncrement] =
    useState(0);

  const [smallestDialSize, setSmallestWatchDialSize] = useState("");
  const [biggestDialSize, setBiggestWatchDialSize] = useState("");
  const [biggestRosarySize, setBiggestRosarySize] = useState(null);
  const [oldestWatchYear, setOldestWatchYear] = useState(null);
  const [newestWatchYear, setNewestWatchYear] = useState(null);

  const [leastRosaryCount, setLeastRosaryCount] = useState(null);
  const [smallestRosarySize, setSmallestRosarySize] = useState(null);
  const [mostRosaryCount, setMostRosaryCount] = useState(null);

  const [allData, setAllData] = useState(null);

  const fetchAll = useRef(false);
  const hasError = useRef(false);

  const applyFilters = () =>
    [
      !res
        ? numBidders &&
          ({
            field: "postType",
            operator: "array-contains-any",
            value: ["مزاد"],
          },
          {
            field: "numBidders",
            operator: ">=",
            value: "numBidders",
          })
        : {
            field: "keywords",
            operator: "array-contains-any",
            value: res.split(" "),
          },
      conditionFilter &&
        conditionFilter.length > 0 && {
          field: "condition",
          operator: "in",
          value: conditionFilter.split(","),
        },

      lowestPriceFilter &&
        parseFloat(lowestPriceFilter) > 0 &&
        ({
          field: "productPrice",
          operator: "!=",
          value: "",
        },
        {
          field: "productPrice",
          operator: ">=",
          value: parseFloat(lowestPriceFilter),
        }),
      heighestPriceFilter &&
        parseFloat(heighestPriceFilter) > 0 &&
        ({
          field: "productPrice",
          operator: "!=",
          value: "",
        },
        {
          field: "productPrice",
          operator: "<=",
          value: parseFloat(heighestPriceFilter),
        }),
      lowestEntryFeeFilter &&
        parseFloat(lowestEntryFeeFilter) > 0 && {
          field: "entryFee",
          operator: ">=",
          value: parseFloat(lowestEntryFeeFilter),
        },
      heighestEntryFeeFilter &&
        parseFloat(heighestEntryFeeFilter) > 0 && {
          field: "entryFee",
          operator: "<=",
          value: parseFloat(heighestEntryFeeFilter),
        },
      lowestStartingPriceFilter &&
        parseFloat(lowestStartingPriceFilter) > 0 && {
          field: "startingPrice",
          operator: ">=",
          value: parseFloat(lowestStartingPriceFilter),
        },
      heighestStartingPriceFilter &&
        parseFloat(heighestStartingPriceFilter) > 0 && {
          field: "startingPrice",
          operator: "<=",
          value: parseFloat(heighestStartingPriceFilter),
        },
      lowestBidIncrementFilter &&
        parseFloat(lowestBidIncrementFilter) > 0 && {
          field: "bidIncrements",
          operator: ">=",
          value: parseFloat(lowestBidIncrementFilter),
        },
      heighestBidIncrementFilter &&
        parseFloat(heighestBidIncrementFilter) > 0 && {
          field: "bidIncrements",
          operator: "<=",
          value: parseFloat(heighestBidIncrementFilter),
        },
      postTypeFilter &&
        postTypeFilter.length > 0 && {
          field: "postType",
          operator: "array-contains-any",
          value: postTypeFilter.split(","),
        },
      shippingMethodsFilter &&
        shippingMethodsFilter.length > 0 && {
          field: "shippingMethods",
          operator: "array-contains-any",
          value: shippingMethodsFilter.split(","),
        },
      companyFilter &&
        companyFilter.length > 0 && {
          field: "productCompany",
          operator: "in",
          value: companyFilter.split(","),
        },
      watchDepartmentFilter &&
        watchDepartmentFilter.length > 0 && {
          field: "watchDepartment",
          operator: "in",
          value: watchDepartmentFilter.split(","),
        },
      watchOutsideColorFilter &&
        watchOutsideColorFilter.length > 0 && {
          field: "watchOutsideColor",
          operator: "in",
          value: watchOutsideColorFilter.split(","),
        },
      watchInsideColorFilter &&
        watchInsideColorFilter.length > 0 && {
          field: "watchInsideColor",
          operator: "in",
          value: watchInsideColorFilter.split(","),
        },
      rosaryKindFilter &&
        rosaryKindFilter.length > 0 && {
          field: "rosaryKind",
          operator: "in",
          value: rosaryKindFilter.split(","),
        },
      rosaryColorFilter &&
        rosaryColorFilter.length > 0 && {
          field: "rosaryColor",
          operator: "in",
          value: rosaryColorFilter.split(","),
        },

      leastRosaryCountFilter &&
        parseFloat(leastRosaryCountFilter) > 0 && {
          field: "rosaryCount",
          operator: ">=",
          value: parseFloat(leastRosaryCountFilter),
        },
      mostRosaryCountFilter &&
        parseFloat(mostRosaryCountFilter) > 0 && {
          field: "rosaryCount",
          operator: "<=",
          value: parseFloat(mostRosaryCountFilter),
        },
      walletDepartmentFilter &&
        walletDepartmentFilter.length > 0 && {
          field: "walletDepartment",
          operator: "in",
          value: walletDepartmentFilter.split(","),
        },
      walletOutsideColorFilter &&
        walletOutsideColorFilter.length > 0 && {
          field: "walletOutsideColor",
          operator: "in",
          value: walletOutsideColorFilter.split(","),
        },
      purseMaterialFilter &&
        purseMaterialFilter.length > 0 && {
          field: "purseMaterial",
          operator: "in",
          value: purseMaterialFilter.split(","),
        },
      purseOutsideColorFilter &&
        purseOutsideColorFilter.length > 0 && {
          field: "purseOutsideColor",
          operator: "in",
          value: purseOutsideColorFilter.split(","),
        },
      carPlateKindFilter &&
        carPlateKindFilter.length > 0 && {
          field: "carPlateKind",
          operator: "in",
          value: carPlateKindFilter.split(","),
        },
      carPlateTransferFeeOnFilter &&
        carPlateTransferFeeOnFilter.length > 0 && {
          field: "carPlateTransferFeeOn",
          operator: "in",
          value: carPlateTransferFeeOnFilter.split(","),
        },
      carPlateTransferTimeFilter &&
        carPlateTransferTimeFilter.length > 0 && {
          field: "carPlateTransferTime",
          operator: "in",
          value: carPlateTransferTimeFilter.split(","),
        },
      phoneNumberCourierFilter &&
        phoneNumberCourierFilter.length > 0 && {
          field: "phoneNumberCourier",
          operator: "in",
          value: phoneNumberCourierFilter.split(","),
        },
      watchDialShapeFilter &&
        watchDialShapeFilter.length > 0 &&
        ({
          field: "watchDialShape",
          operator: "!=",
          value: "",
        },
        {
          field: "watchDialShape",
          operator: "in",
          value: watchDialShapeFilter.split(","),
        }),
      smallestDialSizeFilter &&
        parseFloat(smallestDialSizeFilter) > 0 &&
        ({
          field: "watchDialSize",
          operator: "!=",
          value: "",
        },
        {
          field: "watchDialSize",
          operator: ">=",
          value: parseFloat(smallestDialSizeFilter),
        }),
      biggestDialSizeFilter &&
        parseFloat(biggestDialSizeFilter) > 0 &&
        ({
          field: "watchDialSize",
          operator: "!=",
          value: "",
        },
        {
          field: "watchDialSize",
          operator: "<=",
          value: parseFloat(smallestDialSizeFilter),
        }),
      watchHandlesColorFilter &&
        watchHandlesColorFilter.length > 0 &&
        ({
          field: "watchHandlesColor",
          operator: "!=",
          value: "",
        },
        {
          field: "watchHandlesColor",
          operator: "in",
          value: watchHandlesColorFilter.split(","),
        }),
      watchNumbersColorFilter &&
        watchNumbersColorFilter.length > 0 &&
        ({
          field: "watchNumbersColor",
          operator: "!=",
          value: "",
        },
        {
          field: "watchNumbersColor",
          operator: "in",
          value: watchNumbersColorFilter.split(","),
        }),
      watchNumbersLanguageFilter &&
        watchNumbersLanguageFilter.length > 0 &&
        ({
          field: "watchNumbersLanguage",
          operator: "!=",
          value: "",
        },
        {
          field: "watchNumbersLanguage",
          operator: "in",
          value: watchNumbersLanguageFilter.split(","),
        }),
      watchBandMaterialFilter &&
        watchBandMaterialFilter.length > 0 &&
        ({
          field: "watchBandMaterial",
          operator: "!=",
          value: "",
        },
        {
          field: "watchBandMaterial",
          operator: "in",
          value: watchBandMaterialFilter.split(","),
        }),
      watchBandColorFilter &&
        watchBandColorFilter.length > 0 &&
        ({
          field: "watchBandColor",
          operator: "!=",
          value: "",
        },
        {
          field: "watchBandColor",
          operator: "in",
          value: watchBandColorFilter.split(","),
        }),
      watchHasOriginalPackagingFilter &&
        watchHasOriginalPackagingFilter.length > 0 &&
        ({
          field: "watchHasOriginalPackaging",
          operator: "!=",
          value: "",
        },
        {
          field: "watchHasOriginalPackaging",
          operator: "in".split(","),
          value: watchHasOriginalPackagingFilter,
        }),
      watchIsWaterResistantFilter &&
        watchIsWaterResistantFilter.length > 0 &&
        ({
          field: "watchIsWaterResistant",
          operator: "!=",
          value: "",
        },
        {
          field: "watchIsWaterResistant",
          operator: "in",
          value: watchIsWaterResistantFilter.split(","),
        }),
      watchOldestYearMadeFilter &&
        parseFloat(watchOldestYearMadeFilter) > 0 &&
        ({
          field: "yearMade",
          operator: "!=",
          value: "",
        },
        {
          field: "watchYearMade",
          operator: ">=",
          value: parseFloat(watchOldestYearMadeFilter),
        }),
      watchNewestYearMadeFilter &&
        parseFloat(watchNewestYearMadeFilter) > 0 &&
        ({
          field: "yearMade",
          operator: "!=",
          value: "",
        },
        {
          field: "watchYearMade",
          operator: "<=",
          value: parseFloat(watchNewestYearMadeFilter),
        }),

      categorySearch === "سبح" &&
        (smallestRosarySizeFilter &&
          parseFloat(smallestRosarySizeFilter) > 0 && {
            field: "rosarySize",
            operator: ">=",
            value: parseFloat(smallestRosarySizeFilter),
          },
        biggestRosarySizeFilter &&
          parseFloat(biggestRosarySizeFilter) > 0 && {
            field: "rosarySize",
            operator: "<=",
            value: parseFloat(biggestRosarySizeFilter),
          }),
      categorySearch === "حقائب نسائية" &&
        purseInsideColorFilter &&
        purseInsideColorFilter.length > 0 &&
        ({
          field: "purseInsideColor",
          operator: "!=",
          value: "",
        },
        {
          field: "purseInsideColor",
          operator: "in",
          value: purseInsideColorFilter.split(","),
        }),
    ].filter(Boolean);

  const queryBuilder = useCallback(() => {
    let _query = query(
      collection(db, "Posts"),
      where("isDeleted", "==", false),
      where("isSold", "==", false)
    );

    if (categorySearch) {
      _query = query(_query, where("category", "==", categorySearch));
    }

    let filteredAuctions, filteredDialSize, filteredRosaryCount;

    if (!fetchAll.current) {
      applyFilters().forEach((filter) => {
        _query = query(
          _query,
          where(filter.field, filter.operator, filter.value)
        );
      });

      filteredAuctions = query(
        _query,
        where("postType", "array-contains-any", ["مزاد"])
      );

      filteredDialSize = query(_query, where("watchDialSize", "!=", ""));

      filteredRosaryCount = query(_query, where("rosaryCount", "!=", ""));
    }

    _query = applySorting(_query);

    return [_query, filteredAuctions, filteredDialSize, filteredRosaryCount];
  }, [
    res,
    numBidders,
    orderSelectedName,
    urlSortOption,
    lastDoc,
    categorySearch,
    conditionFilter,
    postTypeFilter,
    lowestPriceFilter,
    heighestPriceFilter,
    lowestEntryFeeFilter,
    heighestEntryFeeFilter,
    lowestStartingPriceFilter,
    heighestStartingPriceFilter,
    lowestBidIncrementFilter,
    heighestBidIncrementFilter,
    shippingMethodsFilter,
    companyFilter,
    watchDepartmentFilter,
    watchOutsideColorFilter,
    watchInsideColorFilter,
    watchDialShapeFilter,
    smallestDialSizeFilter,
    biggestDialSizeFilter,
    watchHandlesColorFilter,
    watchNumbersColorFilter,
    watchNumbersLanguageFilter,
    watchBandMaterialFilter,
    watchBandColorFilter,
    watchHasOriginalPackagingFilter,
    watchIsWaterResistantFilter,
    watchOldestYearMadeFilter,
    watchNewestYearMadeFilter,
    rosaryKindFilter,
    rosaryColorFilter,
    leastRosaryCountFilter,
    mostRosaryCountFilter,
    smallestRosarySizeFilter,
    biggestRosarySizeFilter,
    walletDepartmentFilter,
    walletOutsideColorFilter,
    purseMaterialFilter,
    purseOutsideColorFilter,
    purseInsideColorFilter,
    carPlateKindFilter,
    carPlateTransferFeeOnFilter,
    carPlateTransferTimeFilter,
    phoneNumberCourierFilter,
  ]);

  const fetchData = async () => {
    if (allData) {
      const _lastVisible = lastVisible.current;
      let arr = applyLocalSort(applyLocalSearch(allData));

      if (_lastVisible) {
        arr = arr.slice(_lastVisible, _lastVisible + pageSize);

        setListing((prevListing) => [...prevListing, ...arr]);
      } else {
        setListing(arr.slice(0, pageSize));
      }

      setHasMore(arr.length >= pageSize);
      lastVisible.current = lastVisible.current + pageSize;
      return;
    }

    const [_query, filteredAuctions, filteredDialSize, filteredRosaryCount] =
      queryBuilder();

    setIsLoading(true);

    let paginationQuery = _query;

    if (!fetchAll.current) {
      paginationQuery = query(_query, limit(pageSize));

      if (lastVisible.current)
        paginationQuery = query(
          paginationQuery,
          startAfter(lastVisible.current)
        );
    }

    await getDocs(paginationQuery)
      .then((snapshot) => {
        hasError.current = false;
        if (!fetchAll.current) setHasMore(!snapshot.empty);
        if (!snapshot.empty) {
          const data = snapshot.docs.map((doc) => doc.data());
          const products = data.filter((data) => {
            const isWithinExpiry = data.expiryDate
              ? Date.now() / 1000 - data.expiryDate.seconds < 0
              : true;

            return isWithinExpiry;
          });

          setAllCount(products.length);

          if (!fetchAll.current && lastVisible.current) {
            setListing((prevListing) => [
              ...prevListing,
              ...products.filter(
                (item) => !prevListing.find((_item) => _item.id === item.id)
              ),
            ]);
            lastVisible.current = snapshot.docs[snapshot.docs.length - 1];
          } else {
            if (fetchAll.current) {
              setAllData(products);

              let arr = applyLocalSort(applyLocalSearch(products));
              setAllCount(arr.length);

              arr = arr.slice(0, pageSize);
              setListing(arr);

              setHasMore(arr.length >= pageSize);

              lastVisible.current = pageSize;
            } else {
              lastVisible.current = snapshot.docs[snapshot.docs.length - 1];
              setListing(products);
            }
          }
        } else {
          if (!lastVisible.current) {
            setAllCount(0);
            setListing([]);
          }
        }
      })
      .catch((er) => {
        if (!fetchAll.current) {
          lastVisible.current = null;
          fetchAll.current = true;
          fetchData();
        }
        hasError.current = true;

        console.log(er);
      })
      .finally(() => {
        setIsLoading(false);
      });

    if (!hasError.current && !fetchAll.current) {
      getDocs(query(filteredAuctions, orderBy("entryFee", "desc"), limit(1)))
        .then((snapshot) => {
          if (!snapshot.empty) {
            setMostExpensiveItemEntryFee(
              parseFloat(snapshot.docs[0]?.data()?.entryFee ?? 0)
            );
          }
        })
        .catch((er) => console.log(er));

      getDocs(query(filteredAuctions, orderBy("entryFee", "asc"), limit(1)))
        .then((snapshot) => {
          if (!snapshot.empty) {
            setLeastExpensiveItemEntryFee(
              parseFloat(snapshot.docs[0]?.data()?.entryFee ?? 0)
            );
          }
        })
        .catch((er) => console.log(er));

      getDocs(
        query(filteredAuctions, orderBy("startingPrice", "desc"), limit(1))
      )
        .then((snapshot) => {
          if (!snapshot.empty) {
            setMostExpensiveItemStartingPrice(
              parseFloat(snapshot.docs[0]?.data()?.startingPrice ?? 0)
            );
          }
        })
        .catch((er) => console.log(er));

      getDocs(
        query(filteredAuctions, orderBy("startingPrice", "asc"), limit(1))
      )
        .then((snapshot) => {
          if (!snapshot.empty) {
            setLeastExpensiveItemStartingPrice(
              parseFloat(snapshot.docs[0]?.data()?.startingPrice ?? 0)
            );
          }
        })
        .catch((er) => console.log(er));

      getDocs(
        query(filteredAuctions, orderBy("bidIncrements", "desc"), limit(1))
      )
        .then((snapshot) => {
          if (!snapshot.empty) {
            setMostExpensiveBidIncrement(
              parseFloat(snapshot.docs[0]?.data()?.bidIncrements ?? 0)
            );
          }
        })
        .catch((er) => console.log(er));

      getDocs(
        query(filteredAuctions, orderBy("bidIncrements", "asc"), limit(1))
      )
        .then((snapshot) => {
          if (!snapshot.empty) {
            setLeastExpensiveBidIncrement(
              parseFloat(snapshot.docs[0]?.data()?.bidIncrements ?? 0)
            );
          }
        })
        .catch((er) => console.log(er));

      if (categorySearch === "ساعات") {
        getDocs(
          query(filteredDialSize, orderBy("watchDialSize", "asc"), limit(1))
        )
          .then((snapshot) => {
            if (!snapshot.empty)
              setSmallestWatchDialSize(
                parseFloat(snapshot.docs[0]?.data()?.watchDialSize ?? 0)
              );
          })
          .catch((er) => console.log(er));

        getDocs(
          query(filteredDialSize, orderBy("watchDialSize", "desc"), limit(1))
        )
          .then((snapshot) => {
            if (!snapshot.empty)
              setBiggestWatchDialSize(
                parseFloat(snapshot.docs[0]?.data()?.watchDialSize ?? 0)
              );
          })
          .catch((er) => console.log(er));

        getDocs(
          query(filteredDialSize, orderBy("watchYearMade", "asc"), limit(1))
        )
          .then((querySnapshot) => {
            if (!querySnapshot.empty)
              setOldestWatchYear(
                parseFloat(querySnapshot.docs[0]?.data()?.watchYearMade ?? 0)
              );
          })
          .catch((er) => console.log(er));

        getDocs(
          query(filteredDialSize, orderBy("watchYearMade", "desc"), limit(1))
        )
          .then((querySnapshot) => {
            if (!querySnapshot.empty)
              setNewestWatchYear(
                parseFloat(querySnapshot.docs[0]?.data()?.watchYearMade ?? 0)
              );
          })
          .catch((er) => console.log(er));
      } else if (categorySearch === "سبح") {
        getDocs(
          query(filteredRosaryCount, orderBy("rosaryCount", "asc"), limit(1))
        )
          .then((querySnapshot) => {
            if (!querySnapshot.empty)
              setLeastRosaryCount(
                parseFloat(querySnapshot.docs[0]?.data()?.rosaryCount ?? 0)
              );
          })
          .catch((er) => console.log(er));

        getDocs(
          query(filteredRosaryCount, orderBy("rosaryCount", "desc"), limit(1))
        )
          .then((querySnapshot) => {
            if (!querySnapshot.empty)
              setMostRosaryCount(
                parseFloat(querySnapshot.docs[0]?.data()?.rosaryCount ?? 0)
              );
          })
          .catch((er) => console.log(er));
        getDocs(
          query(filteredRosaryCount, orderBy("rosarySize", "asc"), limit(1))
        )
          .then((querySnapshot) => {
            if (!querySnapshot.empty)
              setSmallestRosarySize(
                parseFloat(querySnapshot.docs[0]?.data()?.rosarySize ?? 0)
              );
          })
          .catch((er) => console.log(er));

        getDocs(
          query(filteredRosaryCount, orderBy("rosarySize", "desc"), limit(1))
        )
          .then((querySnapshot) => {
            if (!querySnapshot.empty)
              setBiggestRosarySize(
                parseFloat(querySnapshot.docs[0]?.data()?.rosarySize ?? 0)
              );
          })
          .catch((er) => console.log(er));
      }
    }
  };

  const applySorting = (_query) => {
    switch (urlSortOption) {
      case "من الأقدم إلى الأحدث":
        return query(_query, orderBy("createdAt", "desc"));
      case "من الأحدث إلى الأقدم":
        return query(_query, orderBy("createdAt", "asc"));
      case "سعر الشراء تنازليا":
        return query(
          _query,
          // where("productPrice", "!=", ""),
          orderBy("productPrice", "desc")
        );
      case "سعر الشراء تصاعديا":
        return query(
          _query,
          // where("productPrice", "!=", ""),
          orderBy("productPrice", "asc")
        );
      case "تاريخ انتهاء المزاد تصاعديا":
        return query(
          _query,
          where("postType", "array-contains-any", ["مزاد"]),
          orderBy("expiryDate", "asc")
        );
      case "تاريخ انتهاء المزاد تنازليا":
        return query(
          _query,
          where("postType", "array-contains-any", ["مزاد"]),
          orderBy("expiryDate", "desc")
        );
      case "سعر المزايدة الحالي تنازليا":
        return query(
          _query,
          where("postType", "array-contains-any", ["مزاد"]),
          orderBy("currentBid", "desc")
        );
      case "سعر المزايدة الحالي تصاعديا":
        return query(
          _query,
          where("postType", "array-contains-any", ["مزاد"]),
          orderBy("currentBid", "asc")
        );
      case "عدد المزايدين تصاعديا":
        return query(
          _query,
          where("postType", "array-contains-any", ["مزاد"]),
          orderBy("numBidders", "asc")
        );
      case "عدد المزايدين تنازليا":
        return query(
          _query,
          where("postType", "array-contains-any", ["مزاد"]),
          orderBy("numBidders", "desc")
        );
      default:
        return query(_query, orderBy("createdAt", "desc"));
    }
  };

  const applyLocalSearch = (arr) => {
    if (arr.length > 0) {
      // Condition filter
      if (conditionFilter && conditionFilter.length > 0) {
        arr = arr.filter((item) => conditionFilter.includes(item.condition));
      }

      if (res) {
        arr = arr.filter((item) => {
          if (Array.isArray(item.keywords)) {
            return res.split(" ").every((term) => item.keywords.includes(term));
          }
          return false;
        });
      }

      // Post type filter
      if (postTypeFilter && postTypeFilter.length > 0) {
        arr = arr.filter((item) => {
          if (Array.isArray(item.postType)) {
            return item.postType.some((pt) => postTypeFilter.includes(pt));
          }
          return false;
        });
      }

      // Lowest price filter
      if (lowestPriceFilter && parseFloat(lowestPriceFilter) > 0) {
        arr = arr.filter(
          (item) => item.productPrice >= parseFloat(lowestPriceFilter)
        );
      }

      // Highest price filter
      if (heighestPriceFilter && parseFloat(heighestPriceFilter) > 0) {
        arr = arr.filter(
          (item) => item.productPrice <= parseFloat(heighestPriceFilter)
        );
      }

      // Lowest entry fee filter
      if (lowestEntryFeeFilter && parseFloat(lowestEntryFeeFilter) > 0) {
        arr = arr.filter(
          (item) => item.entryFee >= parseFloat(lowestEntryFeeFilter)
        );
      }

      // Highest entry fee filter
      if (heighestEntryFeeFilter && parseFloat(heighestEntryFeeFilter) > 0) {
        arr = arr.filter(
          (item) => item.entryFee <= parseFloat(heighestEntryFeeFilter)
        );
      }

      // Lowest starting price filter
      if (
        lowestStartingPriceFilter &&
        parseFloat(lowestStartingPriceFilter) > 0
      ) {
        arr = arr.filter(
          (item) => item.startingPrice >= parseFloat(lowestStartingPriceFilter)
        );
      }

      // Highest starting price filter
      if (
        heighestStartingPriceFilter &&
        parseFloat(heighestStartingPriceFilter) > 0
      ) {
        arr = arr.filter(
          (item) =>
            item.startingPrice <= parseFloat(heighestStartingPriceFilter)
        );
      }

      // Lowest bid increment filter
      if (
        lowestBidIncrementFilter &&
        parseFloat(lowestBidIncrementFilter) > 0
      ) {
        arr = arr.filter(
          (item) => item.bidIncrements >= parseFloat(lowestBidIncrementFilter)
        );
      }

      // Highest bid increment filter
      if (
        heighestBidIncrementFilter &&
        parseFloat(heighestBidIncrementFilter) > 0
      ) {
        arr = arr.filter(
          (item) => item.bidIncrements <= parseFloat(heighestBidIncrementFilter)
        );
      }

      // Shipping methods filter
      if (shippingMethodsFilter && shippingMethodsFilter.length > 0) {
        arr = arr.filter((item) => {
          if (Array.isArray(item.shippingMethods)) {
            return item.shippingMethods.some((pt) =>
              shippingMethodsFilter.includes(pt)
            );
          }
          return false;
        });
      }

      // Company filter
      if (companyFilter && companyFilter.length > 0) {
        arr = arr.filter((item) => companyFilter.includes(item.productCompany));
      }

      // watch department filter
      if (watchDepartmentFilter && watchDepartmentFilter.length > 0) {
        arr = arr.filter((item) =>
          watchDepartmentFilter.includes(item.watchDepartment)
        );
      }

      // watch outside color filter
      if (watchOutsideColorFilter && watchOutsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          watchOutsideColorFilter.includes(item.watchOutsideColor)
        );
      }

      // watch outside color filter
      if (watchInsideColorFilter && watchInsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          watchInsideColorFilter.includes(item.watchInsideColor)
        );
      }

      // rosary kind filter
      if (rosaryKindFilter && rosaryKindFilter.length > 0) {
        arr = arr.filter((item) => rosaryKindFilter.includes(item.rosaryKind));
      }

      // rosary kind filter
      if (rosaryColorFilter && rosaryColorFilter.length > 0) {
        arr = arr.filter((item) =>
          rosaryColorFilter.includes(item.rosaryColor)
        );
      }

      // rosary least count filter
      if (leastRosaryCountFilter && parseFloat(leastRosaryCountFilter) > 0) {
        arr = arr.filter(
          (item) => item.rosaryCount >= parseFloat(leastRosaryCountFilter)
        );
      }

      // rosary most count filter
      if (mostRosaryCountFilter && parseFloat(mostRosaryCountFilter) > 0) {
        arr = arr.filter(
          (item) => item.rosaryCount <= parseFloat(mostRosaryCountFilter)
        );
      }

      // wallet department filter
      if (walletDepartmentFilter && walletDepartmentFilter.length > 0) {
        arr = arr.filter((item) =>
          walletDepartmentFilter.includes(item.walletDepartment)
        );
      }

      // wallet outside color filter
      if (walletOutsideColorFilter && walletOutsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          walletOutsideColorFilter.includes(item.walletOutsideColor)
        );
      }

      // purse material filter
      if (purseMaterialFilter && purseMaterialFilter.length > 0) {
        arr = arr.filter((item) =>
          purseMaterialFilter.includes(item.purseMaterial)
        );
      }

      // purse outside coloe filter
      if (purseOutsideColorFilter && purseOutsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          purseOutsideColorFilter.includes(item.purseOutsideColor)
        );
      }

      // car plate kind filter
      if (carPlateKindFilter && carPlateKindFilter.length > 0) {
        arr = arr.filter((item) =>
          carPlateKindFilter.includes(item.carPlateKind)
        );
      }

      // Car plate transfer fee on filter
      if (
        carPlateTransferFeeOnFilter &&
        carPlateTransferFeeOnFilter.length > 0
      ) {
        arr = arr.filter((item) =>
          carPlateTransferFeeOnFilter.includes(item.carPlateTransferFeeOn)
        );
      }

      // Car plate transfer time filter
      if (carPlateTransferTimeFilter && carPlateTransferTimeFilter.length > 0) {
        arr = arr.filter((item) =>
          carPlateTransferTimeFilter.includes(item.carPlateTransferTime)
        );
      }

      // phone number courier filter
      if (phoneNumberCourierFilter && phoneNumberCourierFilter.length > 0) {
        arr = arr.filter((item) =>
          phoneNumberCourierFilter.includes(item.phoneNumberCourier)
        );
      }

      // filter for !== "" (optional product values)

      // watch dial shape filter
      if (watchDialShapeFilter && watchDialShapeFilter.length > 0) {
        const filteredWatchDialShapeArr = arr.filter(
          (item) => item.watchDialShape !== ""
        );
        arr = filteredWatchDialShapeArr.filter((item) =>
          watchDialShapeFilter.includes(item.watchDialShape)
        );
      }

      // watch smallest dial size filter
      if (smallestDialSizeFilter && parseFloat(smallestDialSizeFilter) > 0) {
        const filteredWatchSDialSizeArr = arr.filter(
          (item) => item.watchDialSize !== ""
        );
        arr = filteredWatchSDialSizeArr.filter(
          (item) => item.watchDialSize >= parseFloat(smallestDialSizeFilter)
        );
      }

      // watch biggest dial size filter
      if (biggestDialSizeFilter && parseFloat(biggestDialSizeFilter) > 0) {
        const filteredWatchSDialSizeArr = arr.filter(
          (item) => item.watchDialSize !== ""
        );
        arr = filteredWatchSDialSizeArr.filter(
          (item) => item.watchDialSize <= parseFloat(biggestDialSizeFilter)
        );
      }

      // watch handles color filter
      if (watchHandlesColorFilter && watchHandlesColorFilter.length > 0) {
        const filteredWatchHandlesColorArr = arr.filter(
          (item) => item.watchHandlesColor !== ""
        );
        arr = filteredWatchHandlesColorArr.filter((item) =>
          watchHandlesColorFilter.includes(item.watchHandlesColor)
        );
      }

      // watch numbers color filter
      if (watchNumbersColorFilter && watchNumbersColorFilter.length > 0) {
        const filteredWatchNumbersColorArr = arr.filter(
          (item) => item.watchNumbersColor !== ""
        );
        arr = filteredWatchNumbersColorArr.filter((item) =>
          watchNumbersColorFilter.includes(item.watchNumbersColor)
        );
      }

      // watch numbers language filter
      if (watchNumbersLanguageFilter && watchNumbersLanguageFilter.length > 0) {
        const filteredWatchNumbersLanguageArr = arr.filter(
          (item) => item.watchNumbersLanguage !== ""
        );
        arr = filteredWatchNumbersLanguageArr.filter((item) =>
          watchNumbersLanguageFilter.includes(item.watchNumbersLanguage)
        );
      }

      // watch band material filter
      if (watchBandMaterialFilter && watchBandMaterialFilter.length > 0) {
        const filteredWatchBandMaterialArr = arr.filter(
          (item) => item.watchBandMaterial !== ""
        );
        arr = filteredWatchBandMaterialArr.filter((item) =>
          watchBandMaterialFilter.includes(item.watchBandMaterial)
        );
      }

      // watch band color filter
      if (watchBandColorFilter && watchBandColorFilter.length > 0) {
        const filteredWatchBandColorArr = arr.filter(
          (item) => item.watchBandColor !== ""
        );
        arr = filteredWatchBandColorArr.filter((item) =>
          watchBandColorFilter.includes(item.watchBandColor)
        );
      }

      // watch has original packaging filter
      if (
        watchHasOriginalPackagingFilter &&
        watchHasOriginalPackagingFilter.length > 0
      ) {
        const filteredWatchHasOriginalPackagingArr = arr.filter(
          (item) => item.watchHasOriginalPackaging !== ""
        );
        arr = filteredWatchHasOriginalPackagingArr.filter((item) =>
          watchHasOriginalPackagingFilter.includes(
            item.watchHasOriginalPackaging
          )
        );
      }

      // watch is water resistant filter
      if (
        watchIsWaterResistantFilter &&
        watchIsWaterResistantFilter.length > 0
      ) {
        const filteredWatchIsWaterResistantArr = arr.filter(
          (item) => item.watchIsWaterResistant !== ""
        );
        arr = filteredWatchIsWaterResistantArr.filter((item) =>
          watchIsWaterResistantFilter.includes(item.watchIsWaterResistant)
        );
      }

      // watch oldest year made filter
      if (
        watchOldestYearMadeFilter &&
        parseFloat(watchOldestYearMadeFilter) > 0
      ) {
        const filteredWatchOldestYearMadeArr = arr.filter(
          (item) => item.yearMade !== ""
        );
        arr = filteredWatchOldestYearMadeArr.filter(
          (item) => item.watchYearMade >= parseFloat(watchOldestYearMadeFilter)
        );
      }

      // watch newest year made filter
      if (
        watchNewestYearMadeFilter &&
        parseFloat(watchNewestYearMadeFilter) > 0
      ) {
        const filteredWatchNewestYearMadeArr = arr.filter(
          (item) => item.yearMade !== ""
        );
        arr = filteredWatchNewestYearMadeArr.filter(
          (item) => item.watchYearMade <= parseFloat(watchNewestYearMadeFilter)
        );
      }

      // Sort and filter logic
      const filteredPriceArr = arr.filter((item) => item.productPrice !== "");
      if (filteredPriceArr.length > 0) {
        const sortedByPrice = [...filteredPriceArr].sort(
          (a, b) => b.productPrice - a.productPrice
        );
        // setMostExpensiveItemPrice(parseFloat(sortedByPrice[0].productPrice));
        // setLeastExpensiveItemPrice(parseFloat(sortedByPrice[sortedByPrice.length - 1].productPrice));
      }

      const filteredAuctionsArr = arr.filter((item) =>
        item.postType.includes("مزاد")
      );
      if (filteredAuctionsArr.length > 0) {
        // setHasAucionPosts(true);
        const sortedByEntryFee = [...filteredAuctionsArr].sort(
          (a, b) => b.entryFee - a.entryFee
        );
        setMostExpensiveItemEntryFee(parseFloat(sortedByEntryFee[0].entryFee));
        setLeastExpensiveItemEntryFee(
          parseFloat(sortedByEntryFee[sortedByEntryFee.length - 1].entryFee)
        );

        const sortedByStartingPrice = [...filteredAuctionsArr].sort(
          (a, b) => b.startingPrice - a.startingPrice
        );
        setMostExpensiveItemStartingPrice(
          parseFloat(sortedByStartingPrice[0].startingPrice)
        );
        setLeastExpensiveItemStartingPrice(
          parseFloat(
            sortedByStartingPrice[sortedByStartingPrice.length - 1]
              .startingPrice
          )
        );

        const sortedByBidIncrement = [...filteredAuctionsArr].sort(
          (a, b) => b.bidIncrements - a.bidIncrements
        );
        setMostExpensiveBidIncrement(
          parseFloat(sortedByBidIncrement[0].bidIncrements)
        );
        setLeastExpensiveBidIncrement(
          parseFloat(
            sortedByBidIncrement[sortedByBidIncrement.length - 1].bidIncrements
          )
        );
      }

      // Watches
      if (categorySearch === "ساعات") {
        const filteredDialSizeArr = arr.filter(
          (item) => item.watchDialSize !== ""
        );
        if (filteredDialSizeArr.length > 0) {
          const sortedByDialSize = [...filteredDialSizeArr].sort(
            (a, b) => b.watchDialSize - a.watchDialSize
          );
          setSmallestWatchDialSize(
            parseFloat(
              sortedByDialSize[sortedByDialSize.length - 1].watchDialSize
            )
          );
          setBiggestWatchDialSize(
            parseFloat(sortedByDialSize[0].watchDialSize)
          );
        }

        const filteredWatchYearMadeArr = arr.filter(
          (item) => item.watchYearMade !== ""
        );
        if (filteredWatchYearMadeArr.length > 0) {
          const sortedByYearMade = [...filteredWatchYearMadeArr].sort(
            (a, b) => b.watchYearMade - a.watchYearMade
          );
          setNewestWatchYear(parseFloat(sortedByYearMade[0].watchYearMade));
          setOldestWatchYear(
            parseFloat(
              sortedByYearMade[sortedByYearMade.length - 1].watchYearMade
            )
          );
        }
      } else if (categorySearch === "سبح") {
        const filteredRosaryCountArr = arr.filter(
          (item) => item.rosaryCount !== ""
        );

        if (filteredRosaryCountArr.length > 0) {
          const sortedByRosaryCount = [...arr].sort(
            (a, b) => b.rosaryCount - a.rosaryCount
          );
          setMostRosaryCount(parseFloat(sortedByRosaryCount[0].rosaryCount));
          setLeastRosaryCount(
            parseFloat(
              sortedByRosaryCount[sortedByRosaryCount.length - 1].rosaryCount
            )
          );
        }

        const filteredRosarySizeArr = arr.filter(
          (item) => item.rosarySize !== ""
        );
        if (filteredRosarySizeArr.length > 0) {
          const sortedByRosarySize = [...filteredRosarySizeArr].sort(
            (a, b) => b.rosarySize - a.rosarySize
          );
          setBiggestRosarySize(parseFloat(sortedByRosarySize[0].rosarySize));
          setSmallestRosarySize(
            parseFloat(
              sortedByRosarySize[sortedByRosarySize.length - 1].rosarySize
            )
          );

          // rosary smallest size filter
          if (
            smallestRosarySizeFilter &&
            parseFloat(smallestRosarySizeFilter) > 0
          ) {
            arr = arr.filter(
              (item) => item.rosarySize >= parseFloat(smallestRosarySizeFilter)
            );
          }

          // rosary biggest size filter
          if (
            biggestRosarySizeFilter &&
            parseFloat(biggestRosarySizeFilter) > 0
          ) {
            arr = arr.filter(
              (item) => item.rosarySize <= parseFloat(biggestRosarySizeFilter)
            );
          }
        }
      } else if (categorySearch === "حقائب نسائية") {
        const filteredPurseInsideColorArr = arr.filter(
          (item) => item.purseInsideColor !== ""
        );
        if (filteredPurseInsideColorArr.length > 0) {
          // purse inside coloe filter
          if (purseInsideColorFilter && purseInsideColorFilter.length > 0) {
            arr = arr.filter((item) =>
              purseInsideColorFilter.includes(item.purseInsideColor)
            );
          }
        }
      }
    }

    return arr;
  };

  const applyLocalSort = (arr) => {
    let sortedArr = arr;
    switch (urlSortOption) {
      case "من الأقدم إلى الأحدث":
        sortedArr = [...arr].sort((a, b) => b.createdAt - a.createdAt);
        break;
      case "من الأحدث إلى الأقدم":
        sortedArr = [...arr].sort((a, b) => a.createdAt - b.createdAt);
        break;
      case "سعر الشراء تنازليا":
        const filteredAndSortedDescHasProductPrice = [...arr]
          .filter((post) => post.productPrice !== "") // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.productPrice - a.productPrice);
        sortedArr = filteredAndSortedDescHasProductPrice;
        break;
      case "سعر الشراء تصاعديا":
        const filteredAndSortedAscHasProductPrice = [...arr]
          .filter((post) => post.productPrice !== "") // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.productPrice - b.productPrice);
        sortedArr = filteredAndSortedAscHasProductPrice;
        break;
      case "تاريخ انتهاء المزاد تصاعديا":
        const filteredAndSortedAscArr_expiryDate = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.expiryDate - b.expiryDate);
        sortedArr = filteredAndSortedAscArr_expiryDate;
        break;
      case "تاريخ انتهاء المزاد تنازليا":
        const filteredAndSortedDescArr_expiryDate = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.expiryDate - a.expiryDate);
        sortedArr = filteredAndSortedDescArr_expiryDate;
        break;
      case "سعر المزايدة الحالي تنازليا":
        const filteredAndSortedDescArr_currentBid = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.currentBid - a.currentBid);
        sortedArr = filteredAndSortedDescArr_currentBid;
        break;
      case "سعر المزايدة الحالي تصاعديا":
        const filteredAndSortedAscArr_currentBid = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.currentBid - b.currentBid);
        sortedArr = filteredAndSortedAscArr_currentBid;
        break;
      case "عدد المزايدين تصاعديا":
        const filteredAndSortedAscArr_numBidders = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.numBidders - b.numBidders);
        sortedArr = filteredAndSortedAscArr_numBidders;
        break;
      case "عدد المزايدين تنازليا":
        const filteredAndSortedDescArr_numBidders = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.numBidders - a.numBidders);
        sortedArr = filteredAndSortedDescArr_numBidders;
        break;
      default:
        sortedArr = [...arr].sort((a, b) => b.createdAt - a.createdAt);
        break;
    }

    return sortedArr;
  };

  useEffect(() => {
    window.scroll(0, 0);
    setTimeout(() => {
      lastVisible.current = null;
      fetchData();
    }, 100);
  }, [
    res,
    orderSelectedName,
    urlSortOption,
    categorySearch,
    numBidders,
    conditionFilter,
    postTypeFilter,
    lowestPriceFilter,
    heighestPriceFilter,
    lowestEntryFeeFilter,
    heighestEntryFeeFilter,
    lowestStartingPriceFilter,
    heighestStartingPriceFilter,
    lowestBidIncrementFilter,
    heighestBidIncrementFilter,
    shippingMethodsFilter,
    companyFilter,
    watchDepartmentFilter,
    watchOutsideColorFilter,
    watchInsideColorFilter,
    watchDialShapeFilter,
    smallestDialSizeFilter,
    biggestDialSizeFilter,
    watchHandlesColorFilter,
    watchNumbersColorFilter,
    watchNumbersLanguageFilter,
    watchBandMaterialFilter,
    watchBandColorFilter,
    watchHasOriginalPackagingFilter,
    watchIsWaterResistantFilter,
    watchOldestYearMadeFilter,
    watchNewestYearMadeFilter,
    rosaryKindFilter,
    rosaryColorFilter,
    leastRosaryCountFilter,
    mostRosaryCountFilter,
    smallestRosarySizeFilter,
    biggestRosarySizeFilter,
    walletDepartmentFilter,
    walletOutsideColorFilter,
    purseMaterialFilter,
    purseOutsideColorFilter,
    purseInsideColorFilter,
    carPlateKindFilter,
    carPlateTransferFeeOnFilter,
    carPlateTransferTimeFilter,
    phoneNumberCourierFilter,
  ]);

  useEffect(() => {
    if (lastDoc > 0) {
      fetchData();
    }
  }, [lastDoc]);

  return {
    isLoading,
    listing,
    allCount,
    hasMore,
    leastExpensiveBidIncrement,
    mostExpensiveBidIncrement,
    leastExpensiveItemStartingPrice,
    mostExpensiveItemStartingPrice,
    leastExpensiveItemEntryFee,
    mostRosaryCount,
    smallestRosarySize,
    leastRosaryCount,
    newestWatchYear,
    oldestWatchYear,
    biggestRosarySize,
    biggestDialSize,
    mostExpensiveItemEntryFee,
    smallestDialSize,
  };
};

export default useInfiniteScroll;
