import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

const useFetchPriceRange = (categorySearch) => {
  const [mostExpensiveItemPrice, setMostExpensiveItemPrice] = useState(0);
  const [leastExpensiveItemPrice, setLeastExpensiveItemPrice] = useState(0);

  useEffect(() => {
    const fetchPriceRange = async () => {
      try {
        // Query for least expensive item price
        const leastPriceQuery = query(
          collection(db, 'Posts'),
          where('isDeleted', '==', false),
          where('isSold', '==', false),
          where('productPrice', '!=', ""), // Filter out products without productPrice
          where('category', '==', categorySearch), // Filter by categorySearch
          orderBy('productPrice', 'asc'),
          limit(1)
        );

        const leastPriceSnapshot = await getDocs(leastPriceQuery);

        if (!leastPriceSnapshot.empty) {
          const leastPrice = leastPriceSnapshot.docs[0].data().productPrice;
          setLeastExpensiveItemPrice(leastPrice);
        } else {
          setLeastExpensiveItemPrice(0);
        }

        // Query for most expensive item price
        const mostPriceQuery = query(
          collection(db, 'Posts'),
          where('isDeleted', '==', false),
          where('isSold', '==', false),
          where('productPrice', '>', 0), // Filter out products without productPrice
          where('category', '==', categorySearch), // Filter by categorySearch
          orderBy('productPrice', 'desc'),
          limit(1)
        );

        const mostPriceSnapshot = await getDocs(mostPriceQuery);

        if (!mostPriceSnapshot.empty) {
          const mostPrice = mostPriceSnapshot.docs[0].data().productPrice;
          setMostExpensiveItemPrice(mostPrice);
        } else {
          setMostExpensiveItemPrice(0);
        }
      } catch (error) {
        console.error('Error fetching price range:', error);
      }
    };

    if (categorySearch) {
      fetchPriceRange();
    } else {
      setLeastExpensiveItemPrice(0);
      setMostExpensiveItemPrice(0);
    }
  }, [categorySearch]);

  return { mostExpensiveItemPrice, leastExpensiveItemPrice };
};

export default useFetchPriceRange;
