import React, { useRef, useEffect, useState } from 'react';
import carPlateEmpty from '../assets/car-plate-empty.png';
import carPlateEmptyYellow from '../assets/car-plate-empty-yellow.png';
import carPlateEmptyBlue from '../assets/car-plate-empty-blue.png';

const CarPlate = ({
    kind, 
    firstArabicLetter,
    setFirstArabicLetter,
    secondArabicLetter,
    setSecondArabicLetter,
    thirdArabicLetter,
    setThirdArabicLetter,
  
    firstArabicNumber,
    setFirstArabicNumber,
    secondArabicNumber,
    setSecondArabicNumber,
    thirdArabicNumber,
    setThirdArabicNumber,
    fourthArabicNumber,
    setFourthArabicNumber,
  
    firstEnglishLetter,
    setFirstEnglishLetter,
    secondEnglishLetter,
    setSecondEnglishLetter,
    thirdEnglishLetter,
    setThirdEnglishLetter,
  
    firstEnglishNumber,
    setFirstEnglishNumber,
    setSecondEnglishNumber,
    secondEnglishNumber,
    thirdEnglishNumber,
    setThirdEnglishNumber,
    fourthEnglishNumber,
    setFourthEnglishNumber,

    setPostImageUrl
}) => {
  // Define refs for the inputs
  const firstArabicLetterRef = useRef(null);
  const secondArabicLetterRef = useRef(null);
  const thirdArabicLetterRef = useRef(null);

  const firstArabicNumberRef = useRef(null);
  const secondArabicNumberRef = useRef(null);
  const thirdArabicNumberRef = useRef(null);
  const fourthArabicNumberRef = useRef(null);

  const firstEnglishLetterRef = useRef(null);
  const secondEnglishLetterRef = useRef(null);
  const thirdEnglishLetterRef = useRef(null);

  const firstEnglishNumberRef = useRef(null);
  const secondEnglishNumberRef = useRef(null);
  const thirdEnglishNumberRef = useRef(null);
  const fourthEnglishNumberRef = useRef(null);

  const [firstArabicLetterSelected, setFirstArabicLetterSelected] = useState(firstArabicLetter !== "")
  const [secondArabicLetterSelected, setSecondArabicLetterSelected] = useState(secondArabicLetter !== "")

  const [firstArabicNumberSelected, setFirstArabicNumberSelected] = useState(firstArabicNumber !== "")
  const [secondArabicNumberSelected, setSecondArabicNumberSelected] = useState(secondArabicNumber !== "")
  const [thirdArabicNumberSelected, setThirdArabicNumberSelected] = useState(thirdArabicNumber !== "")
  

  // Canvas reference for drawing
  const canvasRef = useRef(null);


  const [imageUrl, setImageUrl] = useState(null);

  // Function to draw text on the image canvas
  const drawTextOnImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();

    let plateImageSrc;

    switch (kind) {
        case "لوحة سيارة خاصة":
        case "لوحة دراجة نارية":
          plateImageSrc = carPlateEmpty;
          break;
        case "لوحة سيارة تجارية":
          plateImageSrc = carPlateEmptyBlue;
          break;
        case "لوحة سيارة نقل":
          plateImageSrc = carPlateEmptyYellow;
          break;
        default:
          plateImageSrc = carPlateEmpty; // Default to a fallback image if kind is not recognized
          break;
      }

    image.src = plateImageSrc;

  
    image.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  
      ctx.font = '60px Arial';
      ctx.fillStyle = 'black';
      ctx.direction = 'rtl'; // Set text direction to RTL
      ctx.textAlign = 'right'; // Align text to the right
  
// Render the value of the first Arabic letter input on the canvas
let firstArabicLetterValue = '';
let secondArabicLetterValue = '';
let thirdArabicLetterValue = '';

let firstArabicNumberValue = '';
let secondArabicNumberValue = '';
let thirdArabicNumberValue = '';
let fourthArabicNumberValue = '';

let firstEnglishLetterValue = '';
let secondEnglishLetterValue = '';
let thirdEnglishLetterValue = '';

let firstEnglishNumberValue = '';
let secondEnglishNumberValue = '';
let thirdEnglishNumberValue = '';
let fourthEnglishNumberValue = '';

// Check and assign values only if the corresponding ref is not null
if (firstArabicLetterRef.current !== null) {
  firstArabicLetterValue = firstArabicLetterRef.current.value;
}
if (secondArabicLetterRef.current !== null) {
  secondArabicLetterValue = secondArabicLetterRef.current.value;
}
if (thirdArabicLetterRef.current !== null) {
  thirdArabicLetterValue = thirdArabicLetterRef.current.value;
}

if (firstArabicNumberRef.current !== null) {
  firstArabicNumberValue = firstArabicNumberRef.current.value;
}
if (secondArabicNumberRef.current !== null) {
  secondArabicNumberValue = secondArabicNumberRef.current.value;
}
if (thirdArabicNumberRef.current !== null) {
  thirdArabicNumberValue = thirdArabicNumberRef.current.value;
}
if (fourthArabicNumberRef.current !== null) {
  fourthArabicNumberValue = fourthArabicNumberRef.current.value;
}

if (firstEnglishLetterRef.current !== null) {
  firstEnglishLetterValue = firstEnglishLetterRef.current.value;
}
if (secondEnglishLetterRef.current !== null) {
  secondEnglishLetterValue = secondEnglishLetterRef.current.value;
}
if (thirdEnglishLetterRef.current !== null) {
  thirdEnglishLetterValue = thirdEnglishLetterRef.current.value;
}

if (firstEnglishNumberRef.current !== null) {
  firstEnglishNumberValue = firstEnglishNumberRef.current.value;
}
if (secondEnglishNumberRef.current !== null) {
  secondEnglishNumberValue = secondEnglishNumberRef.current.value;
}
if (thirdEnglishNumberRef.current !== null) {
  thirdEnglishNumberValue = thirdEnglishNumberRef.current.value;
}
if (fourthEnglishNumberRef.current !== null) {
  fourthEnglishNumberValue = fourthEnglishNumberRef.current.value;
}

// Now you can use these values in your component logic



      // only one letter
      if(firstArabicLetterValue !== "" && secondArabicLetterValue === "" && thirdArabicLetterValue === ""){
        
// Render first Arabic letter
if (firstArabicLetterValue === "ا") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 492, 124);
} else if (firstArabicLetterValue === "ب") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 500, 114);
} else if (firstArabicLetterValue === "ح") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 502, 104);
} else if (firstArabicLetterValue === "د") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 495, 117);
} else if (firstArabicLetterValue === "ر") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 496, 111);
} else if (firstArabicLetterValue === "س") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 509, 112);
} else if (firstArabicLetterValue === "ط") {
  ctx.font = '60px Arial'; // Adjust font size as needed
  ctx.fillText(firstArabicLetterValue, 504, 121);
} else if (firstArabicLetterValue === "ص") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 520, 112);
} else if (firstArabicLetterValue === "ع") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 506, 104);
} else if (firstArabicLetterValue === "ق") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 500, 112);
} else if (firstArabicLetterValue === "ك") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 500, 117);
} else if (firstArabicLetterValue === "ل") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 500, 116);
} else if (firstArabicLetterValue === "م") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 500, 104);
} else if (firstArabicLetterValue === "ن") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 500, 112);
} else if (firstArabicLetterValue === "هـ") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 510, 114);
} else if (firstArabicLetterValue === "و") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 495, 110);
} else if (firstArabicLetterValue === "ى") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 505, 109);
}




if (firstEnglishLetterValue === "A" || firstEnglishLetterValue === "B") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 506, 280);
} else if (firstEnglishLetterValue === "J") {
  ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 497, 280);
      } else if (firstEnglishLetterValue === "D") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 510, 280);
      } else if (firstEnglishLetterValue === "R") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 507, 280);
      } else if (firstEnglishLetterValue === "S" || firstEnglishLetterValue === "X" || firstEnglishLetterValue === "T") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 506, 280);
      } else if (firstEnglishLetterValue === "E" || firstEnglishLetterValue === "G") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 506, 280);
      } else if (firstEnglishLetterValue === "K" || firstEnglishLetterValue === "H" || firstEnglishLetterValue === "U") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 506, 280);
      } else if (firstEnglishLetterValue === "L" || firstEnglishLetterValue === "Z") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 503, 280);
      } else if (firstEnglishLetterValue === "N") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 505, 280);
      } else if (firstEnglishLetterValue === "V") {
        ctx.font = '60px Arial';
        ctx.fillText(firstEnglishLetterValue, 506, 280);
      }


      }


      // two letters
      if(firstArabicLetterValue !== "" && secondArabicLetterValue !== "" && thirdArabicLetterValue === ""){
        
// Render first Arabic letter
if (firstArabicLetterValue === "ا") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 535, 124);
} else if (firstArabicLetterValue === "ب") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 549, 114);
} else if (firstArabicLetterValue === "ح") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 545, 104);
} else if (firstArabicLetterValue === "د") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 537, 117);
} else if (firstArabicLetterValue === "ر") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 535, 111);
} else if (firstArabicLetterValue === "س") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 553, 112);
} else if (firstArabicLetterValue === "ط") {
  ctx.font = '60px Arial'; // Adjust font size as needed
  ctx.fillText(firstArabicLetterValue, 550, 121);
} else if (firstArabicLetterValue === "ص") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 565, 112);
} else if (firstArabicLetterValue === "ع") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 550, 104);
} else if (firstArabicLetterValue === "ق") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 547, 112);
} else if (firstArabicLetterValue === "ك") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 545, 117);
} else if (firstArabicLetterValue === "ل") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 545, 116);
} else if (firstArabicLetterValue === "م") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 540, 104);
} else if (firstArabicLetterValue === "ن") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 543, 112);
} else if (firstArabicLetterValue === "هـ") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 555, 114);
} else if (firstArabicLetterValue === "و") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 540, 110);
} else if (firstArabicLetterValue === "ى") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 550, 109);
}





// Render second Arabic letter
if (secondArabicLetterValue === "ا") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 455, 124);
} else if (secondArabicLetterValue === "ب") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 469, 114);
} else if (secondArabicLetterValue === "ح") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 466, 104);
} else if (secondArabicLetterValue === "د") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 457, 117);
} else if (secondArabicLetterValue === "ر") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 455, 111);
} else if (secondArabicLetterValue === "س") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 473, 112);
} else if (secondArabicLetterValue === "ط") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 470, 121);
} else if (secondArabicLetterValue === "ص") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 485, 112);
} else if (secondArabicLetterValue === "ع") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 470, 104);
} else if (secondArabicLetterValue === "ق") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 467, 112);
} else if (secondArabicLetterValue === "ك") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 465, 117);
} else if (secondArabicLetterValue === "ل") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 465, 116);
} else if (secondArabicLetterValue === "م") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 460, 104);
} else if (secondArabicLetterValue === "ن") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 463 , 112);
} else if (secondArabicLetterValue === "هـ") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 475, 114);
} else if (secondArabicLetterValue === "و") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 460, 110);
} else if (secondArabicLetterValue === "ى") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 470, 109);
}




// Render first English letter
if (firstEnglishLetterValue === "A" || firstEnglishLetterValue === "B") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "J") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 540, 280);
} else if (firstEnglishLetterValue === "D") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "R") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "S" || firstEnglishLetterValue === "X" || firstEnglishLetterValue === "T") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "E" || firstEnglishLetterValue === "G") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "K" || firstEnglishLetterValue === "H" || firstEnglishLetterValue === "U") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "L" || firstEnglishLetterValue === "Z") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "N") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
} else if (firstEnglishLetterValue === "V") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 550, 280);
}





// Render second English letter
if (secondEnglishLetterValue === "A" || secondEnglishLetterValue === "B") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "J") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 460, 280);
} else if (secondEnglishLetterValue === "D") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "R") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "S" || secondEnglishLetterValue === "X" || secondEnglishLetterValue === "T") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "E" || secondEnglishLetterValue === "G") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "K" || secondEnglishLetterValue === "H" || secondEnglishLetterValue === "U") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "L" || secondEnglishLetterValue === "Z") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "N") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
} else if (secondEnglishLetterValue === "V") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 470, 280);
}

      }

      // three letters 
      else if(firstArabicLetterValue !== "" && secondArabicLetterValue !== "" && thirdArabicLetterValue !== ""){

        
        // Render first Arabic letter
        if (firstArabicLetterValue === "ا") {
          ctx.font = '60px Arial';
          ctx.fillText(firstArabicLetterValue, 576, 124);
        } else if (firstArabicLetterValue === "ب") {
          ctx.font = '60px Arial';
          ctx.fillText(firstArabicLetterValue, 590, 114);
        } else if (firstArabicLetterValue === "ح") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 584, 104);
} else if (firstArabicLetterValue === "د") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 577, 117);
} else if (firstArabicLetterValue === "ر") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 580, 111);
} else if (firstArabicLetterValue === "س") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 590, 112);
} else if (firstArabicLetterValue === "ط") {
  ctx.font = '60px Arial'; // Adjust font size as needed
  ctx.fillText(firstArabicLetterValue, 586, 121);
} else if (firstArabicLetterValue === "ص") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 599, 112);
} else if (firstArabicLetterValue === "ط") {
  ctx.font = '60px Arial'; // Adjust font size as needed
  console.log("here")
  ctx.fillText(firstArabicLetterValue, 586, 117);
} else if (firstArabicLetterValue === "ع") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 587, 104);
} else if (firstArabicLetterValue === "ق") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 585, 112);
} else if (firstArabicLetterValue === "ك") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 585, 117);
} else if (firstArabicLetterValue === "ل") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 584, 116);
} else if (firstArabicLetterValue === "م") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 580, 104);
} else if (firstArabicLetterValue === "ن") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 586, 112);
} else if (firstArabicLetterValue === "هـ") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 590, 114);
} else if (firstArabicLetterValue === "و") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 579, 110);
} else if (firstArabicLetterValue === "ى") {
  ctx.font = '60px Arial';
  ctx.fillText(firstArabicLetterValue, 590, 109);
}


// Render second Arabic letter
if (secondArabicLetterValue === "ا") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 490, 124);
} else if (secondArabicLetterValue === "ب") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 503, 114);
} else if (secondArabicLetterValue === "ح") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 502, 104);
} else if (secondArabicLetterValue === "د") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 490, 117);
} else if (secondArabicLetterValue === "ر") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 492, 111);
} else if (secondArabicLetterValue === "س") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 510, 112);
} else if (secondArabicLetterValue === "ط") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 505, 121);
} else if (secondArabicLetterValue === "ص") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 522, 112);
} else if (secondArabicLetterValue === "ع") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 507, 104);
} else if (secondArabicLetterValue === "ق") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 505, 112);
} else if (secondArabicLetterValue === "ك") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 500, 117);
} else if (secondArabicLetterValue === "ل") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 496, 116);
} else if (secondArabicLetterValue === "م") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 495, 104);
} else if (secondArabicLetterValue === "ن") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 498, 112);
} else if (secondArabicLetterValue === "هـ") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 507, 114);
} else if (secondArabicLetterValue === "و") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 492, 110);
} else if (secondArabicLetterValue === "ى") {
  ctx.font = '60px Arial';
  ctx.fillText(secondArabicLetterValue, 505, 109);
}

// Render third Arabic letter
if (thirdArabicLetterValue === "ا") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 404, 124); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ب") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 420, 114); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ح") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 418, 104); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "د") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 406, 117); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ر") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 408, 111); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "س") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 425, 112); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ط") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 421, 121); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ص") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 433, 112);
} else if (thirdArabicLetterValue === "ع") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 420, 104); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ق") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 416, 112); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ك") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 421, 117); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ل") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 412, 116); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "م") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 410, 104); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ن") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 415, 112); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "هـ") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 422, 114); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "و") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 408, 110); // Adjust x-coordinate for third letter
} else if (thirdArabicLetterValue === "ى") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdArabicLetterValue, 418, 109); // Adjust x-coordinate for third letter
}





// Render third English letter
if (firstEnglishLetterValue === "A" || firstEnglishLetterValue === "B") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 590, 280);  // 504 - 85
} else if (firstEnglishLetterValue === "J") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 577, 280);  // 497 - 85
} else if (firstEnglishLetterValue === "D") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 590, 280);  // 506 - 85
} else if (firstEnglishLetterValue === "R") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 585, 280);  // 506 - 85
} else if (firstEnglishLetterValue === "S" || firstEnglishLetterValue === "X" || firstEnglishLetterValue === "T") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 590, 280);  // 503 - 85
} else if (firstEnglishLetterValue === "E" || firstEnglishLetterValue === "G") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 587, 280);  // 509 - 85
} else if (firstEnglishLetterValue === "K" || firstEnglishLetterValue === "H" || firstEnglishLetterValue === "U") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 590, 280);  // 505 - 85
} else if (firstEnglishLetterValue === "L" || firstEnglishLetterValue === "Z") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 586, 280);  // 500 - 85
} else if (firstEnglishLetterValue === "N") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 590, 280);  // 506 - 85
} else if (firstEnglishLetterValue === "V") {
  ctx.font = '60px Arial';
  ctx.fillText(firstEnglishLetterValue, 590, 280);  // 503 - 85
}





// Render third English letter
if (secondEnglishLetterValue === "A" || secondEnglishLetterValue === "B") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 505, 280);  // 504 - 85
} else if (secondEnglishLetterValue === "J") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 492, 280);  // 497 - 85
} else if (secondEnglishLetterValue === "D") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 506, 280);  // 506 - 85
} else if (secondEnglishLetterValue === "R") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 505, 280);  // 506 - 85
} else if (secondEnglishLetterValue === "S" || secondEnglishLetterValue === "X" || secondEnglishLetterValue === "T") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 505, 280);  // 503 - 85
} else if (secondEnglishLetterValue === "E" || secondEnglishLetterValue === "G") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 500, 280);  // 509 - 85
} else if (secondEnglishLetterValue === "K" || secondEnglishLetterValue === "H" || secondEnglishLetterValue === "U") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 507, 280);  // 505 - 85
} else if (secondEnglishLetterValue === "L" || secondEnglishLetterValue === "Z") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 500, 280);  // 500 - 85
} else if (secondEnglishLetterValue === "N") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 500, 280);  // 506 - 85
} else if (secondEnglishLetterValue === "V") {
  ctx.font = '60px Arial';
  ctx.fillText(secondEnglishLetterValue, 504, 280);  // 503 - 85
}





// Render third English letter
if (thirdEnglishLetterValue === "A" || thirdEnglishLetterValue === "B") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 418, 280);  // 504 - 85
} else if (thirdEnglishLetterValue === "J") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 405, 280);  // 497 - 85
} else if (thirdEnglishLetterValue === "D") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 421, 280);  // 506 - 85
} else if (thirdEnglishLetterValue === "R") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 421, 280);  // 506 - 85
} else if (thirdEnglishLetterValue === "S" || thirdEnglishLetterValue === "X" || thirdEnglishLetterValue === "T") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 418, 280);  // 503 - 85
} else if (thirdEnglishLetterValue === "E" || thirdEnglishLetterValue === "G") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 426, 280);  // 509 - 85
} else if (thirdEnglishLetterValue === "K" || thirdEnglishLetterValue === "H" || thirdEnglishLetterValue === "U") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 420, 280);  // 505 - 85
} else if (thirdEnglishLetterValue === "L" || thirdEnglishLetterValue === "Z") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 417, 280);  // 500 - 85
} else if (thirdEnglishLetterValue === "N") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 422, 280);  // 506 - 85
} else if (thirdEnglishLetterValue === "V") {
  ctx.font = '60px Arial';
  ctx.fillText(thirdEnglishLetterValue, 420, 280);  // 503 - 85
}
}



// one number
if(firstArabicNumberValue !== "" && secondArabicNumberValue === "" && thirdArabicNumberValue === "" && fourthArabicNumberValue === ""){
  ctx.fillText(firstArabicNumberValue, 195, 124);
  ctx.fillText(firstEnglishNumberValue, 195, 280);
}

// two numbers
else if(firstArabicNumberValue !== "" && secondArabicNumberValue !== "" && thirdArabicNumberValue === "" && fourthArabicNumberValue === ""){
  ctx.fillText(firstArabicNumberValue, 235, 124);
  ctx.fillText(firstEnglishNumberValue, 236, 280);

  ctx.fillText(secondArabicNumberValue, 144, 124);
  ctx.fillText(secondEnglishNumberValue, 145, 280);
}

// three numbers
else if(firstArabicNumberValue !== "" && secondArabicNumberValue !== "" && thirdArabicNumberValue !== "" && fourthArabicNumberValue === ""){
  ctx.fillText(firstArabicNumberValue, 295, 124);
  ctx.fillText(firstEnglishNumberValue, 296, 280);

  ctx.fillText(secondArabicNumberValue, 195, 124);
  ctx.fillText(secondEnglishNumberValue, 197, 280);

  ctx.fillText(thirdArabicNumberValue, 95, 124);
  ctx.fillText(thirdEnglishNumberValue, 97, 280);
}

// four numbers
else if(firstArabicNumberValue !== "" && secondArabicNumberValue !== "" && thirdArabicNumberValue !== "" && fourthArabicNumberValue !== ""){
  ctx.fillText(firstArabicNumberValue, 310, 124);
  ctx.fillText(firstEnglishNumberValue, 310 , 280);

  ctx.fillText(secondArabicNumberValue, 230, 124);
  ctx.fillText(secondEnglishNumberValue, 230, 280);

  ctx.fillText(thirdArabicNumberValue, 150, 124);
  ctx.fillText(thirdEnglishNumberValue, 150, 280);

  ctx.fillText(fourthArabicNumberValue, 70, 124);
  ctx.fillText(fourthEnglishNumberValue, 70, 280);
}

const dataUrl = canvas.toDataURL('image/png');

// Set the base64-encoded URL as the image URL
setImageUrl(dataUrl);
setPostImageUrl([{
    isCropped: false,
    src: dataUrl
}])

    };
  };
  

  useEffect(() => {
    drawTextOnImage();
  }, [kind]); // Empty dependency array to run once on mount


  return (
    <div style={{ direction: 'rtl', display:"flex", flexDirection:"column", alignItems:"center"  }}>
      {/* Arabic Inputs Row */}
      <div style={{ display: 'flex', marginBottom: '10px', alignItems:"center", width: window.innerWidth > window.innerHeight ? "90%" : "100%" }}>
        
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", width: window.innerWidth > window.innerHeight ? "50%" : "100%", textAlign:"center"}}>
          <h5 style={{color:"#00A49B", fontWeight:"500"}}>الحروف</h5>
        <div style={{display:"flex"}}>



        <select
        ref={firstArabicLetterRef}
        defaultValue={firstArabicLetter ? firstArabicLetter : ""}
        
        onChange={(e) => {
        if(e.target.value !== "")
        setFirstArabicLetterSelected(true)
        else
        setFirstArabicLetterSelected(false)
            
          if(e.target.value === "ا")
          firstEnglishLetterRef.current.value = "A"
        
        else if(e.target.value === "ب")
        firstEnglishLetterRef.current.value = "B"

      else if(e.target.value === "ح")
      firstEnglishLetterRef.current.value = "J"

    else if(e.target.value === "د")
          firstEnglishLetterRef.current.value = "D"

        else if(e.target.value === "ر")
          firstEnglishLetterRef.current.value = "R"
        
        else if(e.target.value === "س")
        firstEnglishLetterRef.current.value = "S"

      else if(e.target.value === "ص")
      firstEnglishLetterRef.current.value = "X"
    
    else if(e.target.value === "ط")
    firstEnglishLetterRef.current.value = "T"
  
          else if(e.target.value === "ع")
          firstEnglishLetterRef.current.value = "E"
        
        else if(e.target.value === "ق")
          firstEnglishLetterRef.current.value = "G"
        
          else if(e.target.value === "ك")
          firstEnglishLetterRef.current.value = "K"
        
        else if(e.target.value === "ل")
        firstEnglishLetterRef.current.value = "L"
      
      else if(e.target.value === "م")
      firstEnglishLetterRef.current.value = "Z"
    
    else if(e.target.value === "ن")
          firstEnglishLetterRef.current.value = "N"
        
        else if(e.target.value === "هـ")
        firstEnglishLetterRef.current.value = "H"
      
      else if(e.target.value === "و")
      firstEnglishLetterRef.current.value = "U"

    else if(e.target.value === "ى")
          firstEnglishLetterRef.current.value = "V"
        
        setFirstArabicLetter(e.target.value)
        setFirstEnglishLetter(firstEnglishLetterRef.current.value)
        drawTextOnImage();
      }}
      id='input-field'
      >
                <option disabled value=""></option>

        <option value="ا">ا</option>
        <option value="ب">ب</option>
        <option value="ح">ح</option>
        <option value="د">د</option>
        <option value="ر">ر</option>
        <option value="س">س</option>
        <option value="ص">ص</option>
        <option value="ط">ط</option>
        <option value="ع">ع</option>
        <option value="ق">ق</option>
        <option value="ك">ك</option>
        <option value="ل">ل</option>
        <option value="م">م</option>
        <option value="ن">ن</option>
        <option value="هـ">هـ</option>
        <option value="و">و</option>
        <option value="ى">ى</option>
      </select>

{
    firstArabicLetterSelected && (

        <select
        id='input-field'

        ref={secondArabicLetterRef}
        defaultValue={secondArabicLetter ? secondArabicLetter : ""}
        
        onChange={(e) => {
            if(e.target.value !== "")
            setSecondArabicLetterSelected(true)
            else
            setSecondArabicLetterSelected(false)


          if(e.target.value === "ا")
          secondEnglishLetterRef.current.value = "A"
        
        else if(e.target.value === "ب")
        secondEnglishLetterRef.current.value = "B"
    
    else if(e.target.value === "ح")
    secondEnglishLetterRef.current.value = "J"

else if(e.target.value === "د")
secondEnglishLetterRef.current.value = "D"

else if(e.target.value === "ر")
secondEnglishLetterRef.current.value = "R"

else if(e.target.value === "س")
secondEnglishLetterRef.current.value = "S"

else if(e.target.value === "ص")
secondEnglishLetterRef.current.value = "X"

else if(e.target.value === "ط")
secondEnglishLetterRef.current.value = "T"

else if(e.target.value === "ع")
secondEnglishLetterRef.current.value = "E"

else if(e.target.value === "ق")
secondEnglishLetterRef.current.value = "G"

else if(e.target.value === "ك")
secondEnglishLetterRef.current.value = "K"

else if(e.target.value === "ل")
secondEnglishLetterRef.current.value = "L"

else if(e.target.value === "م")
secondEnglishLetterRef.current.value = "Z"

else if(e.target.value === "ن")
secondEnglishLetterRef.current.value = "N"

else if(e.target.value === "هـ")
secondEnglishLetterRef.current.value = "H"

else if(e.target.value === "و")
secondEnglishLetterRef.current.value = "U"

else if(e.target.value === "ى")
secondEnglishLetterRef.current.value = "V"

else
secondEnglishLetterRef.current.value = ""

setSecondArabicLetter(e.target.value)
setSecondEnglishLetter(secondEnglishLetterRef.current.value)
drawTextOnImage();
}}
style={{ marginRight: '4px' }}
>
                <option value=""></option>

                <option value="ا">ا</option>
        <option value="ب">ب</option>
        <option value="ح">ح</option>
        <option value="د">د</option>
        <option value="ر">ر</option>
        <option value="س">س</option>
        <option value="ص">ص</option>
        <option value="ط">ط</option>
        <option value="ع">ع</option>
        <option value="ق">ق</option>
        <option value="ك">ك</option>
        <option value="ل">ل</option>
        <option value="م">م</option>
        <option value="ن">ن</option>
        <option value="هـ">هـ</option>
        <option value="و">و</option>
        <option value="ى">ى</option>
        {/* Add more options as needed */}
      </select>
    )
    }

    {
        secondArabicLetterSelected && (

            <select
            id='input-field'

        ref={thirdArabicLetterRef}
        defaultValue={thirdArabicLetter ? thirdArabicLetter : ""}
        onChange={(e) => {
            if(e.target.value === "ا")
            thirdEnglishLetterRef.current.value = "A"
        
        else if(e.target.value === "ب")
        thirdEnglishLetterRef.current.value = "B"
    
    else if(e.target.value === "ح")
    thirdEnglishLetterRef.current.value = "J"
    
    else if(e.target.value === "د")
    thirdEnglishLetterRef.current.value = "D"

else if(e.target.value === "ر")
thirdEnglishLetterRef.current.value = "R"

else if(e.target.value === "س")
thirdEnglishLetterRef.current.value = "S"

else if(e.target.value === "ص")
thirdEnglishLetterRef.current.value = "X"

else if(e.target.value === "ط")
thirdEnglishLetterRef.current.value = "T"

else if(e.target.value === "ع")
thirdEnglishLetterRef.current.value = "E"

else if(e.target.value === "ق")
thirdEnglishLetterRef.current.value = "G"

else if(e.target.value === "ك")
thirdEnglishLetterRef.current.value = "K"

else if(e.target.value === "ل")
thirdEnglishLetterRef.current.value = "L"

else if(e.target.value === "م")
thirdEnglishLetterRef.current.value = "Z"

else if(e.target.value === "ن")
thirdEnglishLetterRef.current.value = "N"

else if(e.target.value === "هـ")
thirdEnglishLetterRef.current.value = "H"

else if(e.target.value === "و")
thirdEnglishLetterRef.current.value = "U"

else if(e.target.value === "ى")
thirdEnglishLetterRef.current.value = "V"
else
thirdEnglishLetterRef.current.value = ""

setThirdArabicLetter(e.target.value)
setThirdEnglishLetter(thirdEnglishLetterRef.current.value)
drawTextOnImage();
}}
style={{ marginRight: '4px' }}
>
        <option value=""></option>
        <option value="ا">ا</option>
        <option value="ب">ب</option>
        <option value="ح">ح</option>
        <option value="د">د</option>
        <option value="ر">ر</option>
        <option value="س">س</option>
        <option value="ص">ص</option>
        <option value="ط">ط</option>
        <option value="ع">ع</option>
        <option value="ق">ق</option>
        <option value="ك">ك</option>
        <option value="ل">ل</option>
        <option value="م">م</option>
        <option value="ن">ن</option>
        <option value="هـ">هـ</option>
        <option value="و">و</option>
        <option value="ى">ى</option>
      </select>
    )
    }
        </div>
          </div>


          <div style={{display:"flex", flexDirection:"column", alignItems:"center", width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>
          <h5 style={{color:"#00A49B", fontWeight:"500"}}>الأرقام</h5>
        <div style={{display:"flex", alignItems:"center"}}>
      <select
      id='input-field'

        ref={firstArabicNumberRef}
        defaultValue={firstArabicNumber ? firstArabicNumber : ""}
        onChange={(e) => {
            if(e.target.value !== "")
            setFirstArabicNumberSelected(true)
            else
            setFirstArabicNumberSelected(false)


          if(e.target.value === "٠")
          firstEnglishNumberRef.current.value = "0"
        else if(e.target.value === "١")
        firstEnglishNumberRef.current.value = "1"
      else if(e.target.value === "٢")
          firstEnglishNumberRef.current.value = "2"
          else if(e.target.value === "٣")
          firstEnglishNumberRef.current.value = "3"
          else if(e.target.value === "٤")
          firstEnglishNumberRef.current.value = "4"
          else if(e.target.value === "٥")
          firstEnglishNumberRef.current.value = "5"
          else if(e.target.value === "٦")
          firstEnglishNumberRef.current.value = "6"
          else if(e.target.value === "٧")
          firstEnglishNumberRef.current.value = "7"
          else if(e.target.value === "٨")
          firstEnglishNumberRef.current.value = "8"
          else if(e.target.value === "٩")
          firstEnglishNumberRef.current.value = "9"

          setFirstArabicNumber(e.target.value)
        setFirstEnglishNumber(firstEnglishNumberRef.current.value)
          drawTextOnImage();
        }}
      >
        <option disabled value=""></option>
        <option value="٠">٠</option>
        <option value="١">١</option>
        <option value="٢">٢</option>
        <option value="٣">٣</option>
        <option value="٤">٤</option>
        <option value="٥">٥</option>
        <option value="٦">٦</option>
        <option value="٧">٧</option>
        <option value="٨">٨</option>
        <option value="٩">٩</option>
        {/* Add more options as needed */}
      </select>

      {
        firstArabicNumberSelected && (

            
            <select
            id='input-field'

        ref={secondArabicNumberRef}
        defaultValue={"" || secondArabicNumber}
        onChange={(e) => {
            if(e.target.value !== "")
            setSecondArabicNumberSelected(true)
        else
        setSecondArabicNumberSelected(false)
    
    
    if(e.target.value === "٠")
    secondEnglishNumberRef.current.value = "0"

else if(e.target.value === "١")
secondEnglishNumberRef.current.value = "1"

else if(e.target.value === "٢")
secondEnglishNumberRef.current.value = "2"

else if(e.target.value === "٣")
secondEnglishNumberRef.current.value = "3"

else if(e.target.value === "٤")
secondEnglishNumberRef.current.value = "4"

else if(e.target.value === "٥")
secondEnglishNumberRef.current.value = "5"

else if(e.target.value === "٦")
secondEnglishNumberRef.current.value = "6"

else if(e.target.value === "٧")
secondEnglishNumberRef.current.value = "7"

else if(e.target.value === "٨")
secondEnglishNumberRef.current.value = "8"

else if(e.target.value === "٩")
secondEnglishNumberRef.current.value = "9"

setSecondArabicNumber(e.target.value)
setSecondEnglishNumber(secondEnglishNumberRef.current.value)
drawTextOnImage();

}}
style={{ marginRight: '4px' }}
>
                <option value=""></option>
        <option value="٠">٠</option>
        <option value="١">١</option>
        <option value="٢">٢</option>
        <option value="٣">٣</option>
        <option value="٤">٤</option>
        <option value="٥">٥</option>
        <option value="٦">٦</option>
        <option value="٧">٧</option>
        <option value="٨">٨</option>
        <option value="٩">٩</option>
        {/* Add more options as needed */}
      </select>
)
}

{
    secondArabicNumberSelected && (


      <select
      id='input-field'
      
      ref={thirdArabicNumberRef}
      defaultValue={"" || thirdArabicNumber}
      onChange={(e) => {
          if(e.target.value !== "")
          setThirdArabicNumberSelected(true)
        else
        setThirdArabicNumberSelected(false)
    
    
    if(e.target.value === "٠")
    thirdEnglishNumberRef.current.value = "0"

else if(e.target.value === "١")
thirdEnglishNumberRef.current.value = "1"

else if(e.target.value === "٢")
thirdEnglishNumberRef.current.value = "2"

else if(e.target.value === "٣")
thirdEnglishNumberRef.current.value = "3"

else if(e.target.value === "٤")
thirdEnglishNumberRef.current.value = "4"

else if(e.target.value === "٥")
thirdEnglishNumberRef.current.value = "5"

else if(e.target.value === "٦")
thirdEnglishNumberRef.current.value = "6"

else if(e.target.value === "٧")
thirdEnglishNumberRef.current.value = "7"

else if(e.target.value === "٨")
thirdEnglishNumberRef.current.value = "8"

else if(e.target.value === "٩")
thirdEnglishNumberRef.current.value = "9"


setThirdArabicNumber(e.target.value)
setThirdEnglishNumber(thirdEnglishNumberRef.current.value)
drawTextOnImage();

}}
style={{ marginRight: '4px' }}
>
                <option value=""></option>
        <option value="٠">٠</option>
        <option value="١">١</option>
        <option value="٢">٢</option>
        <option value="٣">٣</option>
        <option value="٤">٤</option>
        <option value="٥">٥</option>
        <option value="٦">٦</option>
        <option value="٧">٧</option>
        <option value="٨">٨</option>
        <option value="٩">٩</option>
      </select>

)
}

{
    thirdArabicNumberSelected && (

      
      <select
      id='input-field'
      
      ref={fourthArabicNumberRef}
      defaultValue={fourthArabicNumber ? fourthArabicNumber : ""}
      onChange={(e) => {
          if(e.target.value === "٠")
          fourthEnglishNumberRef.current.value = "0"
        
        else if(e.target.value === "١")
        fourthEnglishNumberRef.current.value = "1"
    
    else if(e.target.value === "٢")
    fourthEnglishNumberRef.current.value = "2"

else if(e.target.value === "٣")
fourthEnglishNumberRef.current.value = "3"

else if(e.target.value === "٤")
fourthEnglishNumberRef.current.value = "4"

else if(e.target.value === "٥")
fourthEnglishNumberRef.current.value = "5"

else if(e.target.value === "٦")
fourthEnglishNumberRef.current.value = "6"

else if(e.target.value === "٧")
fourthEnglishNumberRef.current.value = "7"

else if(e.target.value === "٨")
fourthEnglishNumberRef.current.value = "8"

else if(e.target.value === "٩")
fourthEnglishNumberRef.current.value = "9"


setFourthArabicNumber(e.target.value)
setFourthEnglishNumber(fourthEnglishNumberRef.current.value)
drawTextOnImage();

}}
style={{ marginRight: '4px' }}
>
                <option value=""></option>
        <option value="٠">٠</option>
        <option value="١">١</option>
        <option value="٢">٢</option>
        <option value="٣">٣</option>
        <option value="٤">٤</option>
        <option value="٥">٥</option>
        <option value="٦">٦</option>
        <option value="٧">٧</option>
        <option value="٨">٨</option>
        <option value="٩">٩</option>
      </select>

)
}

        </div>
        </div>


      <select
      ref={firstEnglishLetterRef}
      defaultValue={firstEnglishLetter ? firstEnglishLetter : ""}
      onChange={(e) => {
          drawTextOnImage();
        }}
        style={{ marginRight: '10px', display:"none" }}
      >
        <option value=""></option>
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="J">J</option>
        <option value="D">D</option>
        <option value="R">R</option>
        <option value="S">S</option>
        <option value="X">X</option>
        <option value="T">T</option>
        <option value="E">E</option>
        <option value="G">G</option>
        <option value="K">K</option>
        <option value="L">L</option>
        <option value="Z">Z</option>
        <option value="N">N</option>
        <option value="H">H</option>
        <option value="U">U</option>
        <option value="V">V</option>
      </select>

        <select
        ref={secondEnglishLetterRef}
        defaultValue={secondEnglishLetter ? secondEnglishLetter : "" }
        onChange={(e) => {
          drawTextOnImage();
        }}
        style={{ marginRight: '10px', display:"none" }}
      >
        <option value=""></option>
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="J">J</option>
        <option value="D">D</option>
        <option value="R">R</option>
        <option value="S">S</option>
        <option value="X">X</option>
        <option value="T">T</option>
        <option value="E">E</option>
        <option value="G">G</option>
        <option value="K">K</option>
        <option value="L">L</option>
        <option value="Z">Z</option>
        <option value="N">N</option>
        <option value="H">H</option>
        <option value="U">U</option>
        <option value="V">V</option>
      </select>
        <select
        ref={thirdEnglishLetterRef}
        defaultValue={thirdEnglishLetter ? thirdEnglishLetter : "" }
        onChange={(e) => {
          drawTextOnImage();
        }}
        style={{ marginRight: '10px', display:"none" }}

      >
        <option value=""></option>
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="J">J</option>
        <option value="D">D</option>
        <option value="R">R</option>
        <option value="S">S</option>
        <option value="X">X</option>
        <option value="T">T</option>
        <option value="E">E</option>
        <option value="G">G</option>
        <option value="K">K</option>
        <option value="L">L</option>
        <option value="Z">Z</option>
        <option value="N">N</option>
        <option value="H">H</option>
        <option value="U">U</option>
        <option value="V">V</option>
      </select>

      <select
        ref={firstEnglishNumberRef}
        defaultValue={firstEnglishNumber ? firstEnglishNumber : ""}
        onChange={(e) => {
          drawTextOnImage();
        }}
        style={{ marginRight: '10px', display:"none" }}

      >
        <option value=""></option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
      </select>

      <select
        ref={secondEnglishNumberRef}
        defaultValue={secondEnglishNumber ? secondEnglishNumber : "" }
        onChange={(e) => {
          drawTextOnImage();
        }}
        style={{ marginRight: '10px', display:"none" }}

      >
        <option value=""></option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
      </select>

      <select
        ref={thirdEnglishNumberRef}
        defaultValue={thirdEnglishNumber ? thirdEnglishNumber : "" }
        onChange={(e) => {
          drawTextOnImage();
        }}
        style={{ marginRight: '10px', display:"none" }}

      >
        <option value=""></option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
      </select>

      
      <select
        ref={fourthEnglishNumberRef}
        defaultValue={fourthEnglishNumber ? fourthEnglishNumber : "" }
        onChange={(e) => {
          drawTextOnImage();
        }}
        style={{ marginRight: '10px', display:"none" }}
      >
        <option value=""></option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        {/* Add more options as needed */}
      </select>

      </div>

      {/* Canvas for drawing */}
      <canvas
        ref={canvasRef}
        width="719px"
        height="347px"
        style={{ marginTop: '20px', display:"none" }}
      />
      <img id='img' src={imageUrl} width="100%" />
    </div>
  );
};

export default CarPlate;
