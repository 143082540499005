import React, { useState, useEffect, useContext, createContext, useRef } from 'react';
import { IoIosCloseCircle, IoIosArrowBack } from 'react-icons/io';
import Lottie from 'lottie-react';
import noChatAnim from '../assets/animations/NoChatAnim.json';
import { Oval } from 'react-loader-spinner';
import { collection, query, where, onSnapshot, addDoc, serverTimestamp, orderBy, getDocs, doc, setDoc, Timestamp, getDoc } from 'firebase/firestore';
import { db, storage } from '../config/firebase'; // Adjust the import based on your project structure
import { IoSend } from "react-icons/io5";
import { Divider } from '@mui/material';
import { IoCheckmark } from "react-icons/io5";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { GoPaperclip } from "react-icons/go";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { IoCloseOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaRegImage } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa";




const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [chatShouldRender, setChatShouldRender] = useState(false);
  const [newChatUser, setNewChatUser] = useState(null);
  const [loading, setChatLoading] = useState(true);
  const [myId, setMyId] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState({});
  const [activeChatId, setActiveChatId] = useState(null);
  const [usersInfo, setUsersInfo] = useState({});
  const [chatIsOpen, setChatIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const messageRef = useRef();
  const messageAttachmentRef = useRef();
  const messagesEndRef = useRef(null); // Reference for scrolling to the bottom



  const [fullScreenMedia, setFullScreenMedia] = useState(null);
  const [isMediaLoading, setIsMediaLoading] = useState(true);
  const [isVideo, setIsVideo] = useState(false);

  const showMediaFullScreen = (mediaUrl, mediaType) => {
    console.log(mediaType )
    setIsMediaLoading(true);
    setFullScreenMedia(mediaUrl);
    setIsVideo(mediaType === 'video');
  };

  const closeFullScreenMedia = () => {
    setFullScreenMedia(null);
    setIsMediaLoading(true); // Reset loading state when closing
  };

  useEffect(()=>{
    if(fullScreenMedia){
      setIsMediaLoading(false);
    }
  }, [fullScreenMedia])
  







  const handleListItemClick = (chatId) => {
    setDrawerOpen(true);
    setActiveChatId(chatId);
  };



  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);





  const getLastMessage = (chatId) => {
    const chatMessages = messages[chatId] || [];
    return chatMessages[chatMessages.length - 1] || {};
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' }); // Smooth scrolling
    }
  }, [messages, activeChatId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setChatShouldRender(chatIsOpen);
    }, 0); // Delay to match the transition duration

    return () => clearTimeout(timer); // Cleanup timeout if component unmounts
  }, [chatIsOpen]);

  useEffect(() => {
    // Control chat rendering based on chatShouldRender state
    if (chatShouldRender) {
      setChatIsOpen(true);
    } else {
      setChatIsOpen(false);
    }
  }, [chatShouldRender]);

  const activeChat = chats.find(chat => chat.id === activeChatId);
  const otherUserId = activeChat ? activeChat.group.find(userId => userId !== myId) : null;
  const otherUser = otherUserId ? usersInfo[otherUserId] || {} : {};


  useEffect(()=>{

  }, [chats])


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  };

  const scrollToBottomSmooth = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages[activeChatId]]);
  


  const sendMessage = async (file = null, fileType = null) => {
    console.log("sendMessage called with file:", file, "fileType:", fileType);
  
    if (!activeChatId || (!message.trim() && !file)) {
      console.log("No active chat ID or message/file is empty.");
      return;
    }
  
    const newMessage = {
      id: uuidv4(),
      senderId: myId,
      message: file ? "" : message,
      fileUrl: file ? URL.createObjectURL(file) : null,
      fileType: fileType, // Store the type of the file (image or video)
      date: new Date(),
      isDelivered: false,
      isRead: false,
    };
  
    // Log the new message object
    console.log("New message object:", newMessage);
  
    // Add the message locally with isDelivered set to false
    setMessages(prevMessages => ({
      ...prevMessages,
      [activeChatId]: [
        ...(prevMessages[activeChatId] || []),
        newMessage,
      ],
    }));
  
    setMessage(''); // Clear the input after sending
    messageRef.current.value = "";
  
    const otherUserId = newChatUser?.id || chats.find(chat => chat.id === activeChatId)?.group.find(userId => userId !== myId);
  
    if (!otherUserId) {
      console.error("No other user ID found.");
      return;
    }
  
    try {
      const chatExists = chats.some(chat => chat.id === activeChatId);
      console.log("Chat exists:", chatExists);
  
      if (!chatExists) {
        console.log('Attempting to create new chat document...');
        await setDoc(doc(db, 'Chats', activeChatId), {
          group: [myId, otherUserId],
          isDeleted: false,
          createdAt: serverTimestamp(),
        });
        console.log("Chat document created.");
      }
  
      const messageDoc = {
        ...newMessage,
        date: serverTimestamp(),
        isDelivered: true,
      };
  
      if (file) {
        // Log before uploading the file
        console.log("Uploading file to storage...");
        const storageRef = ref(storage, `chats/${activeChatId}/${newMessage.id}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        messageDoc.fileUrl = downloadURL;
  
        // Log the download URL
        console.log("File uploaded, download URL:", downloadURL);
  
        // Update the local state with the correct downloadURL
        setMessages(prevMessages => ({
          ...prevMessages,
          [activeChatId]: prevMessages[activeChatId].map(msg =>
            msg.id === newMessage.id ? { ...msg, fileUrl: downloadURL } : msg
          ),
        }));
  
        // Log when the video file is uploaded and added to Firestore
        if (fileType === "video") {
          console.log("Video file uploaded and added to Firestore:", messageDoc);
        }
      }
  
      // Log before adding the message document to Firestore
      console.log("Adding message document to Firestore...");
      await addDoc(collection(db, 'Chats', activeChatId, 'Messages'), messageDoc);
      console.log("Message document added to Firestore:", messageDoc);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };
  
  
  
  
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type.startsWith("video/") ? "video" : "image";
      console.log("File selected:", file, "File type:", fileType);
      await sendMessage(file, fileType);
    }
  };
  
  
  
  
  
  
  
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
  
    let jsDate;
    if (timestamp.toDate) {
      jsDate = timestamp.toDate(); // Firestore timestamp
    } else {
      jsDate = new Date(timestamp); // JavaScript Date
    }
  
    if (isNaN(jsDate.getTime())) return "";
  
    const hours = jsDate.getHours();
    const minutes = jsDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };


  useEffect(() => {
    if (!chats.length || !messages) return;
  
    // Compute sorted chats based on last message date
    const sortedChats = chats.map(chat => ({
      ...chat,
      lastMessageDate: messages[chat.id]?.[messages[chat.id].length - 1]?.date || null,
    })).sort((a, b) => {
      if (!a.lastMessageDate && !b.lastMessageDate) return 0;
      if (!a.lastMessageDate) return 1; // Place chats with no lastMessageDate at the end
      if (!b.lastMessageDate) return -1; // Place chats with no lastMessageDate at the end
      return b.lastMessageDate - a.lastMessageDate;
    });
  
    // Update state only if there's a change in the order
    setChats(prevChats => {
      if (JSON.stringify(prevChats) !== JSON.stringify(sortedChats)) {
        return sortedChats;
      }
      return prevChats;
    });
  }, [messages, chats]);
  
  
  
  

  return (
    <ChatContext.Provider
      value={{
        setChatIsOpen,
        setNewChatUser,
        setMyId,
        setChats,
        setMessages,
        setUsersInfo,
        activeChatId,
        setActiveChatId,
        drawerOpen,
        setDrawerOpen,
        handleListItemClick,
        setChatLoading,
        getLastMessage,
        message,
        setActiveChatId,
        usersInfo,
        chats
      }}
    >
      {children}


      {fullScreenMedia && (
        <div 
          style={{ 
            position: "fixed", 
            top: 0, 
            left: 0, 
            width: "100%", 
            height: "100%", 
            backgroundColor: "rgba(0, 0, 0, 0.8)", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            zIndex: 1005 
          }}
          onClick={closeFullScreenMedia}
          >
          <div style={{position:"absolute", left: 0, top: 0, padding:"8px"}}
            onClick={closeFullScreenMedia}
          >
            <IoCloseOutline size={42} color='white' 
            
            />
          </div>


          {isMediaLoading && <p style={{ color: "white" }}>Loading...</p>}
          {!isMediaLoading && (
            isVideo ? (
              <video src={fullScreenMedia} controls style={{ maxWidth: "90%", maxHeight: "90%" }} />
            ) : (
              <img src={fullScreenMedia} style={{ maxWidth: "90%", maxHeight: "90%" }} alt="Full screen media" />
            )
          )}
        </div>
      )}


      <div
        style={{
          width: window.innerWidth > window.innerHeight ? window.innerWidth > 1100 ? '27vw' : "50vw" : window.innerWidth < 600 ? "95vw" : "70vw",
          height: '80vh',
          position: 'fixed',
          right: window.innerWidth > window.innerHeight ? 32 : window.innerWidth < 600 ? 8 : 16,
          bottom: chatShouldRender ? window.innerWidth > window.innerHeight ? '32px' : "64px" : '-80vh',
          backgroundColor: 'white',
          borderRadius: '24px',
          zIndex: 1003,
          overflow: 'hidden',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'bottom 0.2s ease-in-out'
        }}
      >
        <div style={{
          width: "100%",
          backgroundColor: "white",
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: '1px solid #ddd',
        }}>
          <IoIosCloseCircle size={22} color='#ccc' style={{ cursor: "pointer", minWidth:"22px", minHeight:"22px" }} onClick={() => { setChatShouldRender(false); setActiveChatId(null); setNewChatUser(null); setChatIsOpen(false) }} />
          <p style={{ margin: "0px", alignSelf: "center", fontWeight: 500, color: "#01635c" }}>الرسائل الخاصة</p>
          <div />
        </div>
  
        {myId === null ? (
          // Show Lottie animation if myId is null
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Lottie animationData={noChatAnim} loop={true} />
            <p style={{ marginBottom: 'auto', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color:"#01635c" }}>قم بتسجيل الدخول لعرض الرسائل</p>
          </div>
        ) : loading ? (
          // Show loading spinner if loading is true
          <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", position: "absolute" }}>
            <Oval
              height={"30px"}
              width={"30px"}
              color={"#00C8B8"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block" }}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={2}
              strokeWidthSecondary={1}
            />
          </div>
        ) : (
          <>
            {!newChatUser && (
              chats.length > 0 ? (
                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                  {chats.map(chat => {
                    const otherUserId = chat.group.find(userId => userId !== myId);
                    const otherUser = usersInfo[otherUserId] || {};
                    const lastMessage = getLastMessage(chat.id);
  
                    return (
                      <div key={chat.id}>
                        <li
                          onClick={() => handleListItemClick(chat.id)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            cursor: 'pointer',
                            direction: "rtl",
                          }}
                        >
                          <img
                            src={otherUser.image || 'default-image-url'}
                            alt={otherUser.name}
                            className='skeleton'
                            style={{ width: 45, height: 45, borderRadius: '50%', marginLeft: '10px', objectFit:"cover", opacity:1, border:"0.5px solid #ccc" }}
                          />
                          <div style={{ display: "flex", flexDirection: "column", marginLeft: '10px' }}>
                            <div style={{display:"flex"}}>
                            <span style={{ textAlign: "right", fontWeight:500, fontSize:"15px" }}>{otherUser.name || 'Unknown User'} </span>
                            {otherUser.isVerified && <RiVerifiedBadgeFill size={18}  style={{marginLeft: "4px", marginRight:"2px", marginTop:"3px"}} color='#00C8B8'/>}
                               
                            <div style={{display: "flex"}}>
                              <AiFillStar size={17} style={{marginTop: "3px"}} color='gold' />
                              <p style={{marginBottom: "0px", fontSize:"13px"}}>{otherUser.rating.toFixed(1)}</p>
                              <p style={{marginBottom: "0px", marginRight: "4px", fontSize:"13px"}}>({otherUser.numRaters})</p>
                            </div>
                            </div>
                            <span style={{ textAlign: "right", marginTop:"8px", fontSize:"14px", direction:"rtl" }}>
                            {lastMessage.senderId === myId && (
                                !lastMessage.isDelivered ? (
                                  <IoCheckmark style={{ marginLeft: "4px", marginRight: "0px", color: "gray" }} />
                                ) : (
                                  <IoCheckmarkDoneOutline style={{ marginLeft: "4px", marginRight: "0px", color: "gray" }} />
                                )
                              )}
                              {lastMessage.fileType === "image" ? <FaRegImage color='gray' /> : lastMessage.fileType === "video" ? <FaVideo color='gray'/> : lastMessage.message || 'No messages'}</span>
                          </div>
                          <p style={{marginBottom:"auto", fontSize:"11px", marginRight:"auto", direction:"ltr"}}>{formatDate(lastMessage.date)}</p>
                        </li>
                        <Divider style={{ width: '80%', margin: '8px 0' }} /> {/* Adjust style as needed */}
                      </div>
                    );
                  })}
                </ul>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <Lottie animationData={noChatAnim} loop={true} />
                  <p style={{ marginBottom: 'auto', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color:"#01635c" }}>لا توجد محادثات بعد</p>
                </div>
              )
            )}
  
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                position: 'absolute',
                top: 0,
                right: drawerOpen ? 0 : '-100%',
                transition: 'right 0.2s ease-in-out',
                zIndex: 1004,
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {(activeChatId || newChatUser) && (
                <>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    backgroundColor: 'white',
                    borderBottom: '1px solid #ddd',
                    direction: "rtl",
                    position: "sticky",
                    width: "100%",
                    top: 0,
                    justifyContent: "space-between"
                  }}>
                    <a href={`/user/${ otherUser ? otherUser.id : newChatUser.id }`}>
                    <div style={{width:"fit-content", display:"flex", 
                    marginLeft:"auto"

                    }}>
                      <img
                        src={otherUser.image || (newChatUser && newChatUser.profileImgUrl)}
                        alt={otherUser.name || (newChatUser && newChatUser.fullName)}
                        className='skeleton'
                        style={{ width: 45, height: 45, borderRadius: '50%', marginLeft: '8px', objectFit:"cover", opacity:1, border:"0.5px solid #ccc" }}
                      />
                      <div>

                    <div style={{display:"flex"}}>


                      <span style={{fontWeight:500, fontSize:"15px", color:"black"}}>{otherUser.name || (newChatUser && newChatUser.fullName)}

</span>
{(otherUser.isVerified || (newChatUser && newChatUser.isVerified)) && <RiVerifiedBadgeFill size={20}  style={{marginLeft: "4px", marginRight:"2px", marginTop:"3px"}} color='#00C8B8'/>}
  <div style={{display: "flex"}}>
    <AiFillStar size={17} style={{marginTop: "3px"}} color='gold' />
    <p style={{marginBottom: "0px", fontSize:"13px", color:"black"}}>
        {otherUser?.rating?.toFixed(1) || newChatUser?.rating?.toFixed(1) || 0}
</p>

    <p style={{marginBottom: "0px", marginRight: "4px", fontSize:"13px", color:"black"}}>({otherUser.numRaters || (newChatUser && newChatUser.numRaters) || 0})</p>
  </div>

  </div>


                        {
                          (otherUser.city || (newChatUser && newChatUser.city)) &&
                          <div style={{direction: "rtl", display: "flex"}}>
                          <FaLocationDot size={14} color='red' style={{marginTop: "4px"}} />
                          <p style={{marginRight: "2px", marginBottom:"0px", fontSize:"13px", color:"black"}}>{otherUser.city || (newChatUser && newChatUser.city)}</p>
                          </div>
                        }
                      </div>
                               
                    </div>
                    </a>
                    <IoIosArrowBack size={22} color='#000' style={{ cursor: "pointer", marginRight: '8px' }} onClick={() => { setDrawerOpen(false); setMessage(""); setNewChatUser(null) }} />
                  </div>
                </>
              )}
              <div style={{ padding: '8px 16px', flex: 1 }}>
                {activeChatId && messages[activeChatId] && messages[activeChatId].length > 0 ? (
                  <>

{messages[activeChatId].map((msg) => (
  <div key={msg.id} style={{ display: "flex", width: "100%" }}>
    <div
      style={{
        marginLeft: msg.senderId === myId ? "auto" : "0px",
        padding: "8px",
        backgroundColor: "#F7F7F7",
        minWidth: "25%",
        marginBottom: "8px",
        borderRadius: "12px",
        textAlign: "right",
      }}
      onClick={() => {
        if (msg.fileType === "image") {
          showMediaFullScreen(msg.fileUrl, msg.fileType);
        }
      }}
    >
      {
        !msg.fileUrl ? (
          <p style={{ fontSize: "14px", marginBottom: "4px", direction: "rtl" }}>{msg.message}</p>
        ) : (
          msg.fileType === "video" ? (
            <video
              style={{ width: "200px", minWidth: "70%", marginBottom: "4px", borderRadius: "8px", objectFit: "contain", maxHeight: "350px", opacity: 1 }}
              src={msg.fileUrl}
              controls
            />
          ) : (
            <img
              style={{ width: "200px", minWidth: "70%", marginBottom: "4px", borderRadius: "12px", objectFit: "contain", maxHeight: "350px", opacity: 1,  }}
              src={msg.fileUrl}
              alt=""
            />
          )
        )
      }
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%", display: "flex", textAlign: "right", justifyContent: "flex-end" }}>
          <small style={{ fontSize: '10px' }}>{formatDate(msg.date)}</small>
          {msg.senderId === myId && (
            !msg.isDelivered ? (
              <IoCheckmark style={{ marginLeft: "4px", marginRight: "0px", color: "gray" }} />
            ) : (
              <IoCheckmarkDoneOutline style={{ marginLeft: "4px", marginRight: "0px", color: "gray" }} />
            )
          )}
        </div>
      </div>
    </div>
  </div>
))}




                    <div ref={messagesEndRef} />
                  </>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor:"white" }}>
                    <Lottie animationData={noChatAnim} loop={true} style={{height:"50%", backgroundColor:"white", marginTop:"auto", maxHeight:"50vh"}} />
                    <p style={{ marginBottom: 'auto', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color:"#01635c" }}>قم بإرسال رسالتك للبائع</p>
                  </div>
                )}
              </div>
  
              {(activeChatId || newChatUser) && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  backgroundColor: 'white',
                  direction: "rtl",
                  position: "sticky",
                  width: "100%",
                  bottom: 0,
                  justifyContent: "space-between",
                  borderTop:"0.5px solid #ccc"
                }}>
                  {
                    message.length > 0 ? (
                      <IoSend
                        onClick={() => { 
                          if(messageRef){
                            messageRef.current.focus();
                          }
                          sendMessage() }}
                        size={32} color='#F00057' style={{ marginLeft: "8px", padding: "4px", borderRadius: "50%", cursor: "pointer" }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                        onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                      />
                    ) : (
                      <>
                        <input
                          ref={messageAttachmentRef}
                          type="file"
                          accept="image/*,video/*"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <GoPaperclip
                          onClick={() => { 
                            if (messageAttachmentRef.current) {
                              messageAttachmentRef.current.click();
                            }
                          }}
                          size={32} color='#00C8B8' style={{ marginLeft: "8px", padding: "4px", borderRadius: "50%", cursor: "pointer" }}
                          onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                          onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                        />
                      </>
                    )
                  }

                  <input
                    ref={messageRef}
                    onChange={(e) => {
                      setMessage(e.target.value.trim())
                    }}
                    placeholder='اكتب رسالتك' style={{ border: "none", backgroundColor: "#F7F7F7", width: "100%", padding: "8px", borderRadius: "12px", fontSize: "14px" }}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </ChatContext.Provider>
  );
  


};