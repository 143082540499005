import React, { useEffect, useState } from 'react'
import Product from '../components/Product';
import {auth, db} from '../config/firebase'
import { getFirestore, collection, getDocs ,onSnapshot, serverTimestamp ,query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, FieldValue } from 'firebase/firestore';
import { Oval } from 'react-loader-spinner'
import LoadingScreen from './LoadingScreen';
import { GoHomeFill, GoReport } from "react-icons/go";

function MyBidsScreen() {

    const [myBids, setMyBids] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [userId, setUserId] = useState("")


    const [qu, setQu] = useState(query(collection(db, "Posts"), where("isDeleted", "==", false), where("isAuction", "==", true), where("numBidders", ">", 0)));


    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(async function (user) {
        if (user != null) {
          try {
  
            const docRef = doc(db, 'Users', user.uid);
    
            // Use onSnapshot to listen for changes in the user document
            const unsubscribeUser = onSnapshot(docRef, (docu) => {
              if (docu.exists()) {
                const userData = docu.data();
                setUserId(userData.id)
    
                // Set loading to false here, after the data is fetched
                setLoading(false);
              } else {
                console.error('User data not found');
                // Set loading to false in case of an error
                setLoading(false);
              }
            });
            // Use onSnapshot to listen for changes in the Posts collection
            return () => {
              unsubscribeUser();
            };
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Set loading to false in case of an error
            setLoading(false);
          }
        } else {
          // Set loading to false if the user is null
          setLoading(false);
        }
      });

      const unsubscribe2 = onSnapshot(
        query(collection(db, 'Posts')),
        (querySnapshot) => {
          const bids = querySnapshot.docs
            .filter(doc => {
              const bidders = doc.data().bidders || {};
              const numericUserId = parseFloat(userId);
              return Object.keys(bidders).map(Number).includes(numericUserId);
            })
            .map(doc => doc.data())
            .sort((a, b) => a.expiryDate - b.expiryDate);
      
          setMyBids(bids);
          setLoading(false);
        },
        (err) => {
          console.error('Error fetching bids:', err);
          setLoading(false);
        }
      );
      

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [myBids]);

  const gridTemplateColumns =
    window.innerWidth > window.innerHeight ? `repeat(3, 1fr)` : `repeat(2, 1fr)`;


    if(isLoading){
        return <LoadingScreen/>
      }



    return (
        <div style={{ direction: "rtl", padding: window.innerWidth > window.innerHeight ? "0px" : "8px 16px", minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", width: window.innerWidth > window.innerHeight ? "80%" : "100%", margin:"auto" }}>
          
                      {/* path */}
                      <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto",
        marginBottom:"16px"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>مزايداتي</h6>
        </div>
      </div>
          
          <div style={{width:"100%", padding: window.innerWidth > window.innerHeight ? "0px" : "16px"}}>
          {
            myBids.length > 0 ? (
              <div
                style={{
                  display: "grid",
                  width: "100%",
                  height: "fit-content",
                  direction: "rtl",
                  rowGap: "16px",
                  columnGap: "16px",
                  gridTemplateColumns: window.innerWidth > window.innerHeight ? `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
                  justifyContent: "center",
                  justifyItems: "stretch",
                  alignItems: "stretch",
                }}
              >
                {myBids.map((product) => (
                  <Product
                    key={product.id}
                    imgs={product.imgs[0]}
                    productName={product.productName}
            carPlateKind={product.carPlateKind}

                    numBidders={product.numBidders}
                    expiryDate={product.expiryDate.seconds}
                    currentBid={product.currentBid}
            acceptsOffers={product.postType.includes("عروض")}

                    condition={product.condition}
                    isAuction={product.isAuction}
                    category={product.category}
                    link={`/product/${product.id}`}
                  />
                ))}
              </div>
            ) : (
                <div style={{ width: '100%', textAlign: 'center',  minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", justifyContent: "center", display: "flex", alignItems: "center" }}>
                <h6>أنت لم تقم بأي مزايدة بعد</h6>
              </div>
              
            )
          }
          </div>
        </div>
      );
      
};

export default MyBidsScreen;