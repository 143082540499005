import React from 'react'
import CustomNavbar from '../components/CustomNavbar';
import Product from '../components/Product';
import { Link } from 'react-router-dom';
import ElectronicsImg from '../assets/electronics.avif'
import watch from '../assets/watch.jpg'
import carParts from '../assets/car-parts.jpg'
import laptopBanner from '../assets/laptop-banner.jpg'
import perfumesBanner from '../assets/perfumes-banner.jpg'  
import FurnitureImg from '../assets/furniture.jpg';
import iphoneImg from '../assets/iphone-image.jpg';
import laptopsImg from '../assets/laptops-img.jpeg';
import printersImg from '../assets/printers.png';
import screensImg from '../assets/screens-img.jpeg';
import ipadImg from '../assets/ipad-img.jpg';
import cameraImg from '../assets/camera-img.jpg';
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useSearchParams , useNavigate} from 'react-router-dom';


function MyPurchases () {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    return ( 
        <div style={{marginTop:"65px"}}>
       <h1>my purchases</h1>
        </div>
       );
}
 
export default MyPurchases;