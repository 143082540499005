import React, { useState, useEffect } from 'react';
import { IoChevronDown } from 'react-icons/io5';
import { IoMdCheckmark } from 'react-icons/io';

function YearMade({ onSelect, selectedYear }) {
  const currentYear = new Date().getFullYear();

  const [isOpen, setIsOpen] = useState(Array.from({ length: 5 }, () => false)); // Initialize isOpen state as an array of false values

  useEffect(() => {
    if (selectedYear) {
      const yearIndex = Math.floor((selectedYear - 1980) / 10);
      const updatedIsOpen = [...isOpen];
      updatedIsOpen[yearIndex] = true; // Set the specific year's dropdown to be open
      setIsOpen(updatedIsOpen);
    }
  }, [selectedYear]); // Update isOpen state when selectedYear changes

  const toggleDropdown = (index) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
  };

  const handleYearSelect = (year) => {
    onSelect(year); // Pass selected year to parent component
  };

  return (
    <div style={{ display: 'inline-block', direction: 'rtl', width: '100%' }}>
      {Array.from({ length: 5 }, (_, index) => {
        const startYear = 1980 + index * 10;
        const endYear = startYear + 9 > currentYear ? currentYear : startYear + 9;
        const yearIndex = Math.floor((selectedYear - 1980) / 10);

        return (
          <div key={index}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                direction: 'rtl',
                backgroundColor: '#F7F7F7',
                marginBottom: '16px',
                borderRadius: '8px',
                padding: '8px 16px',
              }}
              onClick={() => toggleDropdown(index)}
            >
              <h2 style={{ marginLeft: '8px', color: '#00C8B8', fontWeight: 'bold' }}>{`${startYear}-${endYear}`}</h2>
              <IoChevronDown
                size={24}
                style={{
                  transition: 'transform 0.3s',
                  transform: isOpen[index] ? 'rotate(180deg)' : 'none',
                  marginRight: 'auto',
                  color: '#00c8b8',
                }}
              />
            </div>
            <div
              style={{
                overflow: 'hidden',
                transition: 'max-height 0.3s',
                maxHeight: isOpen[index] ? 'fit-content' : '0',
                marginBottom: isOpen[index] ? '16px' : '0',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: window.innerWidth > window.innerHeight ? 'repeat(5, 1fr)' : 'repeat(2, 1fr)',
                  gap: '16px',
                  marginTop: '8px',
                  transition: 'opacity 0.5s',
                  opacity: isOpen[index] ? '1' : '0',
                }}
              >
                {[...Array(endYear - startYear + 1)].map((_, yearIndex) => (
                  <div
                    key={yearIndex}
                    style={{
                      position: 'relative',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      border: selectedYear === startYear + yearIndex ? '2px solid #00C8B8' : 'none',
                      borderRadius: '16px',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleYearSelect(startYear + yearIndex)}
                  >
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                      <h1 style={{ fontSize: window.innerWidth > window.innerHeight ? '52px' : '32px', fontWeight: 'bold', color: '#00C8B8' }}>{startYear + yearIndex}</h1>
                    </div>
                    {selectedYear === startYear + yearIndex && (
                      <IoMdCheckmark
                        size={20}
                        color='white'
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: '#00C8B8',
                          padding: '1px',
                          borderTopRightRadius: '13px',
                          borderBottomLeftRadius: '4px',
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default YearMade;
