import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db, auth } from '../config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Oval } from 'react-loader-spinner';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { SlCheck } from "react-icons/sl";
import { getDoc } from 'firebase/firestore';
import LoadingScreen from './LoadingScreen';
import Lottie from 'lottie-react';
import PaymentSuccess from '../assets/animations/paymentSuccess.json'
import PaymentFailed from '../assets/animations/paymentFailed.json'


function PaymentStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [myId, setMyId] = useState(null);
  const [buyerId, setBuyerId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [mode, setMode] = useState("");
  
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const orderNumber = urlParams.get('order');

  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    let unsubscribeOrderData;

    const fetchData = async () => {
        try {
          const orderDocRef = doc(db, "Orders", `Order${orderNumber}`);
          unsubscribeOrderData = onSnapshot(orderDocRef, (docSnapshot) => {
              if (docSnapshot.exists()) {
                  const orderData = docSnapshot.data();
                  setPaymentStatus(orderData.status);
                  setBuyerId(orderData.buyerId);
                  setProductId(orderData.productId);
                  setMode(orderData.type);
              } else {
                  console.log("Order document does not exist for order number:", orderNumber);
                  // Set initial state for non-existent order
                  setPaymentStatus("error");
                  setBuyerId(null);
                  setProductId(null);
                  setMode(null);
              }
          }, (error) => {
              console.error("Error fetching order document:", error);
          });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
            setUserDataLoaded(true);
        }
    };

    fetchData();

    return () => {
        // if (unsubscribeUserData) unsubscribeUserData();
        if (unsubscribeOrderData) unsubscribeOrderData();
    };
}, [orderNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);




  useEffect(() => {
    if (paymentStatus === "success") {
      if(mode === "Purchase"){
        setTimeout(() => {
          navigate(`/my_purchases/${productId}`, {state: {mode: mode}});  
        }, 3000); // 2 seconds delay
      } else {
        setTimeout(() => {
          navigate(`/product/${productId}`, {state: {mode: mode}});  
        }, 3000); // 2 seconds delay
      }
    } else if (paymentStatus === "failed") {
      setTimeout(() => {
        navigate(`/product/${productId}/checkout`, { state: { mode: mode } });
      }, 3000); // 2 seconds delay
    }
  }, [paymentStatus]);

  if (isLoading || paymentStatus === null) {
    return <LoadingScreen text={"جاري معالجة عملية الدفع"} />
  }



  if(buyerId !== null && myId !== null){
    if (buyerId != myId) {
      // navigate(`/`);
      return <h1 style={{ marginTop: "60px" }}>not my order</h1>;
    }
  }

  return (
    <div id="landing-screen-div" style={{ justifyContent: "center", alignItems: "center", direction: "rtl", height: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", display:"flex" }}>
      
      {
        paymentStatus !== null && (
          paymentStatus === "success" ? (
            <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <Lottie animationData={PaymentSuccess} loop={false}/>
              <p style={{marginTop:"0px", marginBottom:"4px"}}>تمت عملية الدفع بنجاح!</p>
              <p>جاري إعادة توجيهك للصفحة المطلوبة...</p>
            </div>
          ) : paymentStatus === "failed" ? (
            <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <Lottie animationData={PaymentFailed} loop={false}/>

            <p style={{marginTop:"0px", marginBottom:"4px"}}>لقد فشلت عملية الدفع</p>
            <p>جاري إعادة توجيهك للصفحة المطلوبة...</p>
          </div>
          ) : ""

        )
      }
    </div>
  );
}

export default PaymentStatus;