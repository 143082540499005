// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  // production
  // apiKey: "AIzaSyDiuLQqOACCeKVHQ08PM7xYUjkDbu0fQqw",
  // authDomain: "men-ela-production.firebaseapp.com",
  // projectId: "men-ela-production",
  // storageBucket: "men-ela-production.appspot.com",
  // messagingSenderId: "696126865654",
  // appId: "1:696126865654:web:22e718f52cb7a01552ecd7",
  // measurementId: "G-K22Z7JL41W"

  // testing
  apiKey: "AIzaSyDNNUi8_RDRLY7Bs1gQzkJ_y6bjM7ZSe4A",
  authDomain: "men-ela-testing.firebaseapp.com",
  projectId: "men-ela-testing",
  storageBucket: "men-ela-testing.appspot.com",
  messagingSenderId: "248776373380",
  appId: "1:248776373380:web:50c35934a750f3d178fed1",
  measurementId: "G-ZQSJZVD12M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Export firestore, auth, and storage
// It will be imported into your React app whenever it is needed
export { db, auth, storage };
