import React from 'react';
import { IoMdCheckmark } from "react-icons/io";

function SelectableWatchCompany({ onSelect, selectedCategory, children }) {
  const handleOptionClick = (category) => {
    onSelect(category);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'grid', gridTemplateColumns: window.innerWidth > window.innerHeight
          ?  `repeat(auto-fill, minmax(170px, 1fr))`
          :  `repeat(auto-fill, minmax(150px, 1fr))`, gap: '10px', direction: 'rtl', alignItems: 'center', justifyContent: 'center', rowGap:32 }}>
        {React.Children.map(children, child => {
          const category = child.props.category;
          const isSelected = category === selectedCategory;
          return (
            <div
              style={{
                border: isSelected ? '2px solid #00C8B8' : '2px solid transparent', // Set initial border color to transparent
                transition: 'border-color 0.2s', // Add transition for border color change
                cursor: 'pointer',
                textAlign: 'center',
                position: "relative",
                borderRadius: "16px",
                width: "100%",
                margin:"auto",
                padding:"8px"
              }}
              onClick={() => handleOptionClick(category)}
            >
              {
                isSelected && (
                  <IoMdCheckmark size={20} color='white' style={{ position: "absolute", top: 0, right: 0, backgroundColor: "#00C8B8", padding: "1px", borderTopRightRadius: "13px", borderBottomLeftRadius: "4px" }} />
                )
              }
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SelectableWatchCompany;
